<template>
  <v-dialog v-model="dialog" height="300px" width="800px">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title style="font-size: 16px; font-weight: 400"
          >Criar Etiqueta</v-card-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          class="ml-7 mr-7 mt-4"
          label="Título"
          hide-details="auto"
          v-model="tagName"
        />
        <v-row
          ><v-col
            ><div class="ml-7 mr-7 mt-8">
              Selecione a cor da etiqueta:
              <v-color-picker
                v-model="selectedColor"
                dot-size="16"
                hide-canvas
                hide-inputs
                hide-sliders
                show-swatches
                swatches-max-height="250"
                :swatches="colorPalette"
              /></div></v-col
          ><v-col>
            <span class="mr-n8">Pré-visualização:</span>
            <v-chip
              class="mt-14 ml-n16"
              :color="selectedColor"
              text-color="white"
            >
              {{ tagName || "Etiqueta" }}
            </v-chip>
          </v-col></v-row
        >
      </v-card-text>
      <v-card-actions class="mt-n8">
        <v-spacer></v-spacer>
        <v-btn
          class="text-capitalize"
          color="#0D47A1"
          style="color: white"
          small
          @click="addTask"
          >Criar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["kanbanId", "permission"],
  data() {
    return {
      dialog: true,
      showTags: false,
      selectedColor: "",
      tagName: "",
      colorPalette: [
        ["#EF5350", "#FFA726", "#FFCA28"], // Vermelhos e laranjas
        ["#AB47BC", "#CE93D8", "#F48FB1"], // Roxos e tons claros
        ["#2196F3", "#81D4FA", "#9E9E9E"], // Azuis e cinza
        ["#4CAF50", "#A5D6A7", "#8D6E63"], // Verdes e marrom claro
        ["#FF5722", "#FF7043", "#FF8A65"], // Tons quentes adicionais
        ["#673AB7", "#9575CD", "#D1C4E9"], // Roxos mais profundos
        ["#00BCD4", "#4DD0E1", "#B2EBF2"], // Ciano e tons suaves
        ["#607D8B", "#78909C", "#B0BEC5"], // Cinza-azulados
      ],
    };
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("close");
      }
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
  },
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    async addTask() {
      console.log(`Nome: ${this.tagName}, Cor: ${this.selectedColor}`);
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
        color: this.selectedColor,
        title: this.tagName,
      };
      console.log("Obj:", obj);
      let objCrypt = await this.cryptoMsg(obj);
      console.log(objCrypt);

      const res = await this.axios.post(`${this.server}/kanban/addTagKanban`, {
        data: objCrypt,
      });
      switch (res.status) {
        case 200:
          console.log("Aqui!!");
          this.$emit("close");
          break;
        case 201:
          break;
        case 202:
          break;
        case 203:
          break;
        default:
          console.warn("Resposta inesperada:", res.status);
      }
    },
  },
};
</script>

<style></style>
