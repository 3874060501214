<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Adicionar Cliente</span>
      </v-card-title>
      <form class="ma-3">
        <v-text-field v-model="corpName" label="Nome Empresa"></v-text-field>
        <v-text-field v-model="numberName" label="Nome do Numero"></v-text-field>
        <v-text-field v-model="number" label="Numero"></v-text-field>
        <v-text-field v-model="adminEmail" label="E-mail administrador"></v-text-field>
        <v-text-field v-model="adminPass" label="Senha"></v-text-field>
        <v-text-field v-model="sessionsNumber" label="Numero de sessões"></v-text-field>
      </form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" @click="add" class="text-capitalize">Adicionar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  data() {
    return {
      corpName: "",
      numberName: "",
      adminEmail: "",
      adminPass: "",
      number: "",
      sessionsNumber: "",
      dialog: false,
    };
  },
  created() {},
  computed: {
    show() {
      return this.$store.state.screens.addClients;
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.dialog = true;
      } else {
        this.$store.state.screens.addClients = false;
      }
    },
    dialog(newValue) {
      if (!newValue) {
        this.$store.state.screens.addClients = false;
      }
    },
  },
  methods: {
    add() {
      if (
        this.corpName != "" &&
        this.numberName != "" &&
        this.adminEmail != "" &&
        this.adminPass != "" &&
        this.number != "" &&
        this.sessionsNumber != ""
      ) {
        this.dialog = false;
        this.$store.state.interactions.addClient = {
          corpName: this.corpName,
          numberName: this.numberName,
          adminEmail: this.adminEmail,
          adminPass: this.adminPass,
          number: this.number,
          sessionsNumber: this.sessionsNumber,
        };
      }
    },
  },
};
</script>
