<template>
  <v-card class="justify-center align-center" height="400px">
    <span class="d-flex justify-center align-center text-capitalize">{{
      automation.nome
    }}</span>
    <v-row>
      <v-col cols="12">
        <v-text-field
          class="ml-12 mr-12"
          v-model="automation.title"
          label="Título"
          :rules="dataRules"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="mt-n8">
        <v-textarea
          rows="4"
          v-model="automation.description"
          label="Descrição"
          :rules="dataRules"
          required
          class="ml-12 mr-12"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="ml-12 mt-n5">
        <v-checkbox
          v-model="isOptionsExpandedData"
          label="Transferir dados deste cartão"
          color="info"
          v-if="datas.length > 0"
        ></v-checkbox>
        <v-list
          v-if="isOptionsExpandedData"
          class="overflow-y-auto mt-n4"
          style="max-height: 18vh"
        >
          <v-list-item v-for="data in datas" :key="data.id" dense>
            <v-list-item-action>
              <v-checkbox
                dense
                v-model="data.checked"
                color="primary"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ data.titulo }}</v-list-item-title>
              <v-list-item-subtitle>{{ data.conteudo }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col class="mt-n5">
        <v-checkbox
          v-model="isOptionsExpandedFile"
          label="Transferir arquivos deste cartão"
          color="info"
          v-if="files.length > 0"
        ></v-checkbox>
        <v-list
          v-if="isOptionsExpandedFile"
          class="overflow-y-auto mt-n4"
          style="max-height: 18vh"
        >
          <v-list-item v-for="file in files" :key="file.id" dense>
            <v-list-item-action>
              <v-checkbox dense v-model="file.checked" color="primary">
              </v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon :color="getIconColor(file.tipo)">{{
                  getIcon(file.tipo)
                }}</v-icon>
                {{ file.nome }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: [
    "card",
    "selectCard",
    "kanbanId",
    "permission",
    "automationData",
    "filesData",
    "datasData",
  ],
  data() {
    return {
      automation: "",
      dataRules: [(v) => !!v || "Esta é uma informação obrigatória"],
      isOptionsExpandedData: false,
      isOptionsExpandedFile: false,
      datas: [],
      files: [],
    };
  },
  created() {
    console.warn(this.automationData);
    this.automation = this.automationData;
    this.files = JSON.parse(JSON.stringify(this.filesData));
    this.datas = JSON.parse(JSON.stringify(this.datasData));
  },
  name: "ChildComponent",
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),

    async createCard() {
      let checkedDatas = this.datas.filter((data) => data.checked);
      let checkedFiles = this.files.filter((file) => file.checked);
      let formattedString = checkedDatas
        .map((data) => `${data.titulo}: ${data.conteudo}`)
        .join("\n");
      const axios = this.$store.state.axios;
      const server = this.$store.state.serverExpress;
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.automation.id_kanban_destino,
        id_coluna: this.automation.id_coluna_destino,
        title: this.automation.title,
        description: this.automation.description + "\n" + formattedString,
        file: checkedFiles,
        dateIni: null,
        dateEnd: null,
        endTime: null,
        startTime: null,
        tags: [],
      };
      console.log("Objeto: ", obj);
      let objCrypt = await this.cryptoMsg(obj);
      axios
        .post(`${server}/kanban/addCard`, { data: objCrypt })
        .then((response) => {
          if (response.status == 200) {
            this.$root.$emit("updateDataForColumn", this.idColumn);
            this.$emit("archive");
          }
        })
        .catch((error) => {
          console.error("Error adding card:", error);
        });
    },

    getIcon(fileType) {
      switch (fileType) {
        case "application/pdf":
          return "mdi-file-pdf-box";
        case "image/png":
        case "image/jpeg":
          return "mdi-file-image";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "mdi-file-excel";
        default:
          return "mdi-file";
      }
    },
    getIconColor(fileType) {
      switch (fileType) {
        case "application/pdf":
          return "red";
        case "image/png":
        case "image/jpeg":
          return "green";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "blue";
        default:
          return "grey";
      }
    },
    resetSelections() {
      this.datas.forEach((data) => {
        data.checked = false;
      });
      this.files.forEach((file) => {
        file.checked = false;
      });
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
};
</script>
