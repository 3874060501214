<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card flat class="pa-4">
      <v-row justify="center" class="mb-5">
        <img src="/icon.png" alt="Icon" width="50px" height="50px" />
        <h3 class="mt-3">EaseChat</h3>
      </v-row>
      <v-form>
        <v-text-field
          label="Usuário"
          prepend-inner-icon="mdi-account"
          v-model="userField"
          variant="outlined"
          hint="Ex. usuario@email.com"
        ></v-text-field>
        <v-text-field
          label="Senha"
          prepend-inner-icon="mdi-lock"
          :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          v-model="passwordField"
          variant="outlined"
        ></v-text-field>
        <v-row justify="center" class="mt-n4">
          <v-btn
            rounded
            append-icon="mdi-login"
            color="red-lighten-1"
            class="text-capitalize"
            @click="auth"
            >Acessar</v-btn
          >
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  data() {
    return {
      show1: false,
      rules: {
        required: (value) => !!value || "Campo obrigatorio.",
        min: (v) => v.length >= 4 || "Minimo de 4 caracteres",
        emailMatch: () => `E-mail`,
      },
      /* eslint-disable no-useless-escape */
      readonly: false,
      authBtnColor: "primary",
      authBtnWord: "Autenticar",
      userField: "",
      passwordField: "",
      dialog: true,
      label: "Agente",
      alert: false,
    };
  },
  created() {},
  computed: {
    isAuth() {
      return this.$store.state.isAuth;
    },
    authFailed() {
      return this.$store.state.authFailed;
    },
  },
  watch: {
    authFailed(newValue) {
      if (newValue) {
        this.alert = true;
        this.userField = "";
        this.passwordField = "";
      }
    },
    isAuth(newValue) {
      if (newValue) {
        this.dialog = false;
      } else {
        this.dialog = true;
      }
    },
  },
  methods: {
    reload() {
      window.location.reload();
    },
    async auth() {
      this.$store.state.agent = this.userField;
      this.$store.state.agentPass = this.passwordField;
      if (Notification.permission === "granted") {
        // A permissão já foi concedida
        return;
      }

      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          // Permissão concedida, ativar notificações
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
