<template>
  <span class="mr-5 mt-1">
    <v-row>
      <v-select
        style="max-width: 150px"
        class="mr-3"
        v-model="valueGroup"
        :items="formattedItemsGroup"
        attach
        chips
        dense
        label="Etiquetas do Grupo"
        multiple
      >
        <template v-slot:selection="{ item, index }">
          <v-chip small :key="index" input-value="true" :color="item.color">
            {{ item.text }}
          </v-chip>
        </template>
      </v-select>
      <v-select
        style="max-width: 150px"
        v-model="value"
        :items="formattedItems"
        attach
        chips
        dense
        label="Etiquetas do Departamento"
        multiple
      >
        <template v-slot:selection="{ item, index }">
          <v-chip small :key="index" input-value="true" :color="item.color">
            {{ item.text }}
          </v-chip>
        </template>
      </v-select>
    </v-row>
  </span>
</template>

<script>
export default {
  props: ["id_dep", "id_service", "id_group"],
  data() {
    return {
      itemsGroup: [],
      valueGroup: [],
      selectGroup: [],
      readyGroup: false,
      items: [],
      value: [],

      rgtg: "/tag/get_to_group/",
      rstg: "/tag/get_to_service_group/",
      rag: "/tag/add_service_group/",
      rrg: "/tag/remove_service_group/",

      rat: "/tag/get_to_dep/",
      rd: "/tag/get_to_service_dep/",
      ra: "/tag/add_service_dep/",
      rr: "/tag/remove_service_dep/",
      select: [],
      ready: false,
    };
  },
  created() {
    this.data();
  },
  methods: {
    data() {
      this.axios
        .post(`${this.server}${this.rgtg}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          di: this.id_group,
        })
        .then((res) => {
          console.log(res);
          switch (res.status) {
            case 200:
              console.log(res.data.data);
              this.itemsGroup = res.data.data;
              break;
            case 201:
              this.itemsGroup = [];
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.axios
        .post(`${this.server}${this.rstg}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          di: this.id_service,
          id: this.id_group,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              console.log(res.data.data);
              this.selectGroup = res.data.data;
              this.valueGroup = this.formattedItemsSelectGroup;
              setTimeout(() => {
                this.readyGroup = true;
              }, 1000);
              break;
            case 201:
              setTimeout(() => {
                this.readyGroup = true;
              }, 1000);
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              setTimeout(() => {
                this.readyGroup = true;
              }, 1000);
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.axios
        .post(`${this.server}${this.rat}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          di: this.id_dep,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              console.log(res.data.data);
              this.items = res.data.data;
              break;
            case 201:
              this.items = [];
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              this.items = [];
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.axios
        .post(`${this.server}${this.rd}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          di: this.id_service,
          id: this.id_dep,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              console.log(res.data.data);
              this.select = res.data.data;
              this.value = this.formattedItemsSelect;
              setTimeout(() => {
                this.ready = true;
              }, 1000);
              break;
            case 201:
              setTimeout(() => {
                this.ready = true;
              }, 1000);
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getItemColor(item) {
      return item.cor;
    },
  },
  watch: {
    value(newVal, oldVal) {
      // Detectar tags adicionadas
      const addedTags = newVal.filter((x) => !oldVal.includes(x));
      addedTags.forEach((tagName) => {
        let tag = this.items.find((tag) => tag.nome === tagName);
        if (tag && this.ready) {
          console.log("Tag adicionada: ", tag);
          this.axios
            .post(`${this.server}${this.ra}`, {
              n: this.info.id_numero,
              c: this.info.id_cliente,
              si: this.id_service,
              ti: tag.id,
            })
            .then((res) => {
              switch (res.status) {
                case 200:
                  break;
                case 201:
                  break;
                case "202":
                  break;
                case "203":
                  break;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
      // Detectar tags removidas
      const removedTags = oldVal.filter((x) => !newVal.includes(x));
      console.log(removedTags);
      removedTags.forEach((tagName) => {
        console.log(tagName);
        let tag = this.items.find((tag) => tag.nome === tagName);
        if (tag && this.ready) {
          console.log("Tag removida: ", tag);
          this.axios
            .post(`${this.server}${this.rr}`, {
              n: this.info.id_numero,
              c: this.info.id_cliente,
              si: this.id_service,
              ti: tag.id,
            })
            .then((res) => {
              switch (res.status) {
                case 200:
                  break;
                case 201:
                  break;
                case "202":
                  break;
                case "203":
                  break;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    valueGroup(newVal, oldVal) {
      // Detectar tags adicionadas
      const addedTags = newVal.filter((x) => !oldVal.includes(x));
      addedTags.forEach((tagName) => {
        let tag = this.itemsGroup.find((tag) => tag.nome === tagName);
        if (tag && this.readyGroup) {
          console.log("Tag adicionada: ", tag);
          this.axios
            .post(`${this.server}${this.rag}`, {
              n: this.info.id_numero,
              c: this.info.id_cliente,
              si: this.id_service,
              ti: tag.id,
            })
            .then((res) => {
              switch (res.status) {
                case 200:
                  break;
                case 201:
                  break;
                case "202":
                  break;
                case "203":
                  break;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
      // Detectar tags removidas
      const removedTags = oldVal.filter((x) => !newVal.includes(x));
      console.log(removedTags);
      removedTags.forEach((tagName) => {
        console.log(tagName);
        let tag = this.itemsGroup.find((tag) => tag.nome === tagName);
        if (tag && this.readyGroup) {
          console.log("Tag removida: ", tag);
          this.axios
            .post(`${this.server}${this.rrg}`, {
              n: this.info.id_numero,
              c: this.info.id_cliente,
              si: this.id_service,
              ti: tag.id,
            })
            .then((res) => {
              switch (res.status) {
                case 200:
                  break;
                case 201:
                  break;
                case "202":
                  break;
                case "203":
                  break;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
  computed: {
    formattedItemsGroup() {
      return this.itemsGroup.map((item) => ({
        text: item.nome,
        color: item.cor,
      }));
    },
    formattedItemsSelectGroup() {
      return this.selectGroup.map((item) => item.nome);
    },
    formattedItems() {
      return this.items.map((item) => ({
        text: item.nome,
        color: item.cor,
      }));
    },
    formattedItemsSelect() {
      return this.select.map((item) => item.nome);
    },
    info() {
      return this.$store.state.info;
    },
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
  },
};
</script>
