<template>
  <v-dialog v-model="dialog" max-width="900px">
    <v-card>
      <v-card-title
        >Etiquetas do Grupo "{{ depName }}"<v-spacer></v-spacer>
        <v-select
          class="mr-8"
          v-model="tagToDep"
          style="max-width: 250px"
          :items="tagsAvailable"
          item-text="nome"
          label="Etiquetas disponiveis"
        ></v-select>
        <v-btn
          small
          class="ml-2 text-capitalize"
          @click="addToDep"
          color="red lighten-3"
          >Adicionar Etiqueta</v-btn
        >
      </v-card-title>
      <v-data-table
        class="ma-3"
        :headers="header"
        :items="filteredItems"
        :footer-props="{ itemsPerPageOptions: [10] }"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-chip input-value="true" :color="item.cor">
                {{ item.nome }}
              </v-chip>
            </td>
            <td>
              <v-btn small icon @click="showEdit(item)">
                <v-icon color="primary">mdi-account-edit-outline</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                small
                icon
                @click="(showDelete = true), (itemForDelete = item)"
              >
                <v-icon color="red">mdi-delete-alert-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          class="ml-2 text-capitalize"
          @click="dialog = false"
          color="primary"
          >Voltar</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog v-if="showDelete" v-model="showDelete" width="500px">
      <v-card width="500px">
        <v-toolbar dense dark color="primary">
          <v-card-title style="font-size: 16px; font-weight: 400"
            >Deseja deletar a etiqueta "{{ itemForDelete.nome }}"
            ?</v-card-title
          ><v-spacer></v-spacer>
          <v-btn icon dark @click="showDelete = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="text-capitalize"
            @click="deleteTag"
            color="red"
            style="color: white"
            >Deletar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <editTag
      v-if="dialogEdit"
      @hide="dialogEdit = false"
      @reload="data"
      :id="editItem.id"
      :color="editItem.cor"
      :name="editItem.nome"
    />
  </v-dialog>
</template>
<script>
import editTag from "./tagEdit.vue";
export default {
  props: ["id", "depName"],
  components: {
    editTag,
  },
  data() {
    return {
      tagToDep: "",
      tagsAvailable: [],
      showDelete: false,
      itemForDelete: "",
      dialogEdit: false,
      editItem: "",
      dialogAdd: false,
      search: "",
      rat: "/tag/get_to_group/",
      routeG: "/tag/get_to_add_group",
      routeA: "/tag/add_to_group",
      routeD: "/tag/remove_to_group",
      sheet: false,
      sheetDialog: "",
      dialog: true,
      header: [
        { text: "nome", value: "data0" },
        { text: "Editar", value: "data0" },
        { text: "Apagar", value: "data0" },
      ],
      items: [
        /*{
              data0:"Grupo A",
              check:false,

          }*/
      ],
    };
  },
  created() {
    this.data();
  },
  methods: {
    addToDep() {
      let tag = this.tagsAvailable.find((tag) => tag.nome == this.tagToDep);
      console.log(tag);
      this.axios
        .post(`${this.server}${this.routeA}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          di: this.id,
          ti: tag.id,
        })
        .then((res) => {
          this.tagsAvailable = res.data.data;
          console.log(res.data);
          this.data();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showEdit(item) {
      this.editItem = item;
      this.dialogEdit = true;
    },
    data() {
      this.axios
        .post(`${this.server}${this.routeG}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          di: this.id,
        })
        .then((res) => {
          this.tagsAvailable = res.data.data;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      this.axios
        .post(`${this.server}${this.rat}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          di: this.id,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              console.log(res.data.data);
              this.items = res.data.data;
              break;
            case 201:
              this.items = [];
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    action(item) {
      console.log(item);
    },
    deleteTag() {
      console.log(this.itemForDelete);
      this.axios
        .post(`${this.server}${this.routeD}`, {
          n: this.info.id_numero,
          ti: this.itemForDelete.id,
          di: this.id,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              this.showDelete = false;
              this.itemForDelete = "";
              this.data();
              break;
            case "201":
              this.showDelete = false;
              this.itemForDelete = "";
              this.data();
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        let nome = item.nome.toLowerCase();
        return nome.indexOf(this.search) > -1;
      });
    },
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide");
      }
    },
  },
};
</script>
