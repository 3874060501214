<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title style="font-size: 16px; font-weight: 400"
          >Nova Automação</v-card-title
        ><v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-8">
        <form
          class="mb-2 overflow-y-auto overflow-x-hidden"
          style="max-height: 68vh"
        >
          <span class="mb-n5 custom-text">Nome da automação:</span>
          <v-text-field
            class="ma-2"
            required
            label="Ex: Enviar para financeiro "
            solo
            dense
            v-model="automationName"
            :rules="dataRules"
          ></v-text-field>

          <span class="mb-n5 custom-text" v-if="automationName != ''"
            >Selecione a coluna de origem:
          </span>
          <v-select
            v-if="automationName != ''"
            required
            label="Coluna"
            dense
            solo
            item-text="titulo"
            item-value="id"
            :items="columns"
            v-model="firstColumnSelected"
            :rules="dataRules"
            class="ma-2"
          ></v-select>

          <span class="mb-n5 custom-text" v-if="firstColumnSelected != ''"
            >O evento acontecerá quando:</span
          >
          <v-select
            v-if="firstColumnSelected != ''"
            required
            label="Evento"
            dense
            solo
            :items="events"
            v-model="eventSelected"
            :rules="dataRules"
            class="ma-2"
          ></v-select>

          <span v-if="eventSelected != ''" class="mb-n5 custom-text"
            >Ativar a ação de:</span
          >
          <v-select
            v-if="eventSelected != ''"
            required
            label="Ação"
            dense
            solo
            :items="actions"
            v-model="actionSelected"
            :rules="dataRules"
            class="ma-2"
          ></v-select>
          <span
            v-if="actionSelected == 'Criar cartão'"
            class="mb-n5 custom-text"
            >Selecione o quadro de destino:</span
          >
          <v-select
            v-if="actionSelected == 'Criar cartão'"
            required
            label="Quadros"
            dense
            solo
            item-value="id"
            item-text="titulo"
            :items="kanbans"
            v-model="kanbanSelected"
            :rules="dataRules"
            class="ma-2"
          ></v-select>
          <span v-if="kanbanSelected != ''" class="mb-n5 custom-text"
            >Selecione a coluna de destino:</span
          >
          <v-select
            v-if="kanbanSelected != ''"
            required
            label="Colunas"
            dense
            solo
            item-value="id"
            item-text="titulo"
            :items="kanbanColumns()"
            v-model="columnSelected"
            :rules="dataRules"
            class="ma-2"
          ></v-select>
          <span
            v-if="actionSelected == 'Enviar mensagem'"
            class="mb-n5 custom-text"
            >Mensagem a ser enviada:</span
          >
          <v-textarea
            v-if="actionSelected == 'Enviar mensagem'"
            solo
            name="input-7-4"
            label="Conteúdo da mensagem"
            v-model="messageContent"
            counter
            :rules="charactRules"
            class="ma-2"
          ></v-textarea>
          <v-row v-if="actionSelected == 'Enviar mensagem'"
            ><v-col cols="6"
              ><v-checkbox
                v-model="isOptionsExpandedData"
                label="Transferir dados deste cartão"
                hide-details
              ></v-checkbox>
              <v-list
                v-if="isOptionsExpandedData"
                class="overflow-y-auto mt-4"
                style="max-height: 40vh"
                ><v-list-item v-for="data in datas" :key="data.id" dense>
                  <template>
                    <v-list-item-action>
                      <v-checkbox
                        dense
                        color="primary"
                        v-model="data.checked"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.titulo }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item></v-list
              ></v-col
            ><v-col cols="6"
              ><span
                v-if="actionSelected == 'Enviar mensagem'"
                class="mb-n5 custom-text"
                >Selecione os contatos:</span
              >
              <v-text-field
                v-if="actionSelected == 'Enviar mensagem'"
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar contato"
                single-line
                class="mr-5"
                hide-details
              ></v-text-field>
              <v-data-table
                v-model="contactsSelected"
                v-if="actionSelected == 'Enviar mensagem'"
                :search="search"
                :items="contacts"
                show-select
                item-key="id"
                :headers="headers"
                class="elevation-1 mr-5"
              >
                <template v-slot:[`item.avatar`]="{ item }">
                  <v-avatar>
                    <img :src="item.img" alt="Avatar" class="avatar-img" />
                  </v-avatar>
                </template> </v-data-table></v-col
          ></v-row>
        </form>
      </v-card-text>
      <v-card-actions class="mt-n8">
        <v-spacer></v-spacer>
        <v-btn
          color="#0D47A1"
          style="color: white"
          @click="addAutomation"
          class="text-capitalize"
          small
          >Salvar Automação</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["kanbanId", "permission"],
  data() {
    return {
      dialog: true,
      events: ["Arquivar cartão"],
      actions: ["Criar cartão", "Enviar mensagem"],
      actionSelected: "",
      eventSelected: "",
      kanbanSelected: "",
      kanbans: [],
      columns: [],
      switch1: true,
      automationName: "",
      columnSelected: "",
      firstColumnSelected: "",
      dataRules: [(v) => !!v || "Esta é uma informação obrigatória"],
      charactRules: [(v) => v.length <= 150 || "Máximo 150 carcteres"],
      messageContent: "",
      contactsSelected: [],
      contacts: [],
      headers: [
        {
          text: "Avatar",
          value: "avatar",
        },
        {
          text: "Nome",
          value: "contato_4red",
        },
        {
          text: "Contato",
          value: "numero",
        },
      ],
      search: "",
      isOptionsExpandedData: false,
      datas: [],
      datasSelected: [],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
  },
  watch: {
    contactsSelected(newValue) {
      if (newValue.length > 5) {
        newValue.splice(newValue.length - 1, 1);
      }
    },
    messageContent(newValue) {
      if (newValue.length > 150) {
        this.messageContent = newValue.substring(0, 149);
      }
    },
    dialog(newValue) {
      if (!newValue) {
        this.$emit("close");
      }
    },
    actionSelected(newValue) {
      if (newValue == "Enviar mensagem") {
        this.getContacts();
      }
    },
    isOptionsExpandedData(newValue) {
      if (newValue) {
        this.getDataToCard();
      }
    },
  },
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    kanbanColumns() {
      let kanban = this.kanbans.find(
        (kanban) => kanban.id == this.kanbanSelected
      );
      return kanban.colunas;
    },

    async fetchData() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
      };
      let objCrypt = await this.cryptoMsg(obj);
      console.log(objCrypt);
      this.axios
        .post(`${this.server}/kanban/getKanbans`, { data: objCrypt })
        .then(async (res) => {
          if (res.status == 200) {
            let decrypt = await this.decrypt(res.data.data);
            this.kanbans = decrypt;
            let kanbanIdentifier = this.kanbans.find(
              (kanban) => kanban.id == this.kanbanId
            );
            this.columns = kanbanIdentifier.colunas;
          }
        });
    },
    async addAutomation() {
      if (this.actionSelected == "Enviar mensagem") {
        if (
          this.automationName !== "" &&
          this.eventSelected !== "" &&
          this.actionSelected !== ""
        ) {
          console.log(this.datas);
          let obj = {
            id_client: this.info.id_cliente,
            id_attendant: this.info.id,
            id_number: this.info.id_numero,
            id_kanban: this.kanbanId,
            name: this.automationName,
            event: this.eventSelected,
            action: this.actionSelected,
            originColumn: this.firstColumnSelected,
            message: this.messageContent,
            contacts: this.contactsSelected,
            datas: this.datas.filter((data) => data.checked),
          };
          console.log("Objeto:", obj);
          let objCrypt = await this.cryptoMsg(obj);
          console.log(objCrypt);
          this.axios
            .post(`${this.server}/kanban/addAutomationSendMessage`, {
              data: objCrypt,
            })
            .then((res) => {
              if (res.status == 200) {
                this.$emit("close");
              }
            });
        } else {
          console.error(
            "Dados necessários para gerar a automação não foram preenchidos."
          );
        }
      } else if (this.actionSelected == "Criar cartão") {
        if (
          this.automationName !== "" &&
          this.eventSelected !== "" &&
          this.actionSelected !== "" &&
          this.columnSelected !== "" &&
          this.kanbanSelected !== ""
        ) {
          let obj = {
            id_client: this.info.id_cliente,
            id_attendant: this.info.id,
            id_number: this.info.id_numero,
            id_kanban: this.kanbanId,
            name: this.automationName,
            event: this.eventSelected,
            action: this.actionSelected,
            destinyKanban: this.kanbanSelected,
            destinyColumn: this.columnSelected,
            originColumn: this.firstColumnSelected,
          };
          console.log("Objeto:", obj);
          let objCrypt = await this.cryptoMsg(obj);
          console.log(objCrypt);
          this.axios
            .post(`${this.server}/kanban/addAutomationCreateCard`, {
              data: objCrypt,
            })
            .then((res) => {
              if (res.status == 200) {
                this.$emit("close");
              }
            });
        } else {
          console.error(
            "Dados necessários para gerar a automação não foram preenchidos."
          );
        }
      }
    },
    async getContacts() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
      };

      let objCrypt = await this.cryptoMsg(obj);
      console.log(objCrypt);
      this.axios
        .post(`${this.server}/kanban/getContacts`, { data: objCrypt })
        .then(async (res) => {
          if (res.status == 200) {
            console.log(res.data);
            let decryp = await this.decrypt(res.data.data);
            console.log("Decryp: ", decryp);
            this.contacts = this.formatContacts(decryp.data);
          }
        });
    },
    async getDataToCard() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
      };
      let objCrypt = await this.cryptoMsg(obj);
      console.log(objCrypt);

      this.axios
        .post(`${this.server}/kanban/getDatasToAutomationMessage/`, {
          data: objCrypt,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              console.log(res.data);
              this.datas = [...res.data.data];
              console.warn(this.datas);
              break;
            case 201:
              break;
            case 202:
              break;
            case 203:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatPhoneNumber(value) {
      let formatted = value.split("@")[0];
      if (formatted.startsWith("55")) {
        formatted = formatted.slice(2);
      }

      return formatted;
    },
    formatContacts(contacts) {
      return contacts.map((contact) => {
        return {
          ...contact,
          numero: this.formatPhoneNumber(contact.numero),
        };
      });
    },
  },
};
</script>
<style scoped>
.custom-text {
  color: #333; /* Ajuste a cor conforme necessário */
}
.avatar-img {
  width: 32px;
  height: 32px;
  object-fit: cover; /* Isso garante que a imagem se ajuste bem dentro do avatar */
}
</style>
