<template>
  <v-dialog v-model="dialog" height="250px" width="450px">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title>Etiquetas</v-card-title><v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <form>
          <v-row justify="end" class="mt-3">
            <v-col class="justify-center" cols="auto"
              ><v-text-field
                label="Busca"
                append-icon="mdi-magnify"
                dense
                solo
                v-model="search"
                small
              ></v-text-field
            ></v-col>
          </v-row>
        </form>
        <div style="max-height: 58vh" class="overflow-y-auto">
          <v-card-text
            ><v-list class="mt-n8">
              <v-list-item
                v-for="(label, index) in filteredLabels"
                :key="index"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip :color="label.cor" text-color="white">
                      {{ label.titulo }}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    title="Editar"
                    x-small
                    @click="(showEdit = true), (selectionTag = label)"
                    color="#42A5F5"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-btn
                    icon
                    title="Excluir"
                    x-small
                    @click="(showDelete = true), (selectionTag = label)"
                  >
                    <v-icon color="red">mdi-delete-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list></v-card-text
          >
        </div>
      </v-card-text>
      <kanbanTagEdit
        v-if="showEdit"
        @close="showEdit = false"
        :kanbanId="kanbanId"
        :permission="permission"
        :selectionTag="selectionTag"
      />
      <kanbanTags
        v-if="addTag"
        @close="addTag = false"
        :kanbanId="kanbanId"
        :permission="permission"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="addTag = true"
          class="text-capitalize"
          small
          color="primary"
          >Criar nova etiqueta</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showDelete" persistent height="250px" width="450px">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-card-title style="font-size: 16px; font-weight: 400"
            >Tem certeza que deseja excluir essa etiqueta?</v-card-title
          ><v-spacer></v-spacer>
          <v-btn icon dark @click="showDelete = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="text-capitalize"
            @click="deleteTag"
            color="red"
            style="color: white"
            >Excluir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import kanbanTagEdit from "./kanbanTagEdit.vue";
import kanbanTags from "./kanbanTags.vue";
export default {
  props: ["kanbanId", "permission"],
  components: { kanbanTagEdit, kanbanTags },
  data() {
    return {
      dialog: true,
      search: "",
      tags: [],
      showEdit: false,
      showDelete: false,
      addTag: false,
      selectionTag: "",
    };
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("close");
      }
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
    filteredLabels() {
      return this.tags.filter((tag) => {
        if (this.search.length < 1) {
          return this.tags;
        } else {
          return (
            tag.titulo.toLowerCase().indexOf(this.search.toLocaleLowerCase()) >
            -1
          );
        }
      });
    },
  },
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    listenupdateTagsKanban() {
      this.socket.on("updateTagsKanban", async (data) => {
        let decryp = await this.decrypt(data);
        var decrypData = await this.decrypt(decryp.data);
        console.log("Decryp: ", decrypData);
        if (decrypData.data[0].id_quadro == this.kanbanId) {
          setTimeout(() => {
            this.fecthData();
          }, 1000);
        }
      });
    },
    async fecthData() {
      console.log("Aqui!!!");
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
      };
      console.log("Obj:", obj);
      let objCrypt = await this.cryptoMsg(obj);
      console.log(objCrypt);

      this.axios
        .post(`${this.server}/kanban/getTagsKanban`, { data: objCrypt })
        .then(async (res) => {
          if (res.status == 200) {
            console.log(res);
            let decrypt = await this.decrypt(res.data.data);
            this.tags = decrypt.data;
          } else {
            this.tags = [];
          }
        });
    },
    async deleteTag() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
        id_tag: this.selectionTag.id,
      };
      let objCrypt = await this.cryptoMsg(obj);
      console.log(objCrypt);

      this.axios
        .post(`${this.server}/kanban/deleteTagKanban`, { data: objCrypt })
        .then(async (res) => {
          if (res.status == 200) {
            this.showDelete = false;
          }
        });
    },
  },
  created() {
    this.fecthData();
    this.listenupdateTagsKanban();
  },
};
</script>

<style scoped>
.v-chip {
  font-size: 14px;
  height: auto;
}
.v-list-item {
  padding: 0;
}
</style>
