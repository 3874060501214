<template>
    <div v-if="dialog" class="d-flex justify-center align-center" style="height: 40vh;">
      <canvas :id="`avarage${dep.id}`" ></canvas>
    </div>
  </template>
  <script>
  import { Chart, registerables } from "chart.js";
  Chart.register(...registerables);
  
  export default {
    props:['date','dep'],
    data(){
      return{
        dialog:false,
        r:"/report/get_averageAttendanceDurationByAttendantAndDay",
        chartData: {
          labels: [],
          datasets: []
        }
      }
    },
      created() {
        this.fetchData();
      },
      computed:{
        axios() {
              return this.$store.state.axios;
          },
          server() {
              return this.$store.state.serverExpress;
          },
          info() {
              return this.$store.state.info;
          },
      },
      watch:{
        date(newValue){
          if(newValue.length==2){
            this.dialog = false
            this.fetchData()
          }
        }
      },
      methods: {
        transformData(rows) {
          const dataByDay = {};
          rows.forEach(row => {
              const day = row.dia.split('T')[0];
              const atendenteKey = `${row.nome_atendente}`;
              if (!dataByDay[day]) {
                  dataByDay[day] = {};
              }
              dataByDay[day][atendenteKey] = row.media_minutos;
          });
          return Object.entries(dataByDay).map(([day, attendances]) => ({
              day,
              ...attendances
          }));
        },
        fetchData() {
        this.axios
          .post(`${this.server}${this.r}`, {
            n: this.info.id_numero,
            c: this.info.id_cliente,
            d: this.date,
            i: this.dep.id
          })
          .then((res) => {
            if (res.status === 200) {
              this.dialog = true
              console.log(res.data.data)
              console.log(this.transformData(res.data.data))
              setTimeout(() => {
                this.processData(this.transformData(res.data.data));
              }, 100);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      processData(data) {
        const atendentes = new Set();
        data.forEach(dayData => {
            Object.keys(dayData).forEach(key => {
                if (key !== 'day') {
                    atendentes.add(key);
                }
            });
        });

        // Converter os tempos médios em minutos para exibição no gráfico
        const datasets = Array.from(atendentes).map(atendente => {
            return {
                label: atendente,
                data: data.map(item => item[atendente]),
                backgroundColor: this.getRandomColor(),
                borderColor: this.getRandomColor(),
                borderWidth: 1
            };
        });

        this.chartData.labels = data.map(item => item.day);
        this.chartData.datasets = datasets;

        this.createChart();
    },
    getRandomColor() {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgba(${r}, ${g}, ${b}, 0.2)`;
    },
    createChart() {
        const ctx = document.getElementById(`avarage${this.dep.id}`).getContext("2d");
        if (this.chart) {
            this.chart.destroy();
        }
        this.chart = new Chart(ctx, {
            type: "bar",
            data: this.chartData,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: `Tempo medio dos atendimento em minutos por atendente por Dia no Departamento ${this.dep.departamento}`
                    },
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            precision: 0,
                        },
                    },
                },
            },
        });
    }
      }
  }
  </script>
  