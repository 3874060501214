<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title>Cartões Arquivados</v-card-title><v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        ><form>
          <v-row justify="end" class="mt-3">
            <v-col class="justify-center" cols="auto"
              ><v-text-field
                style="max-width: 200px"
                label="Busca"
                append-icon="mdi-magnify"
                dense
                solo
                v-model="search"
              ></v-text-field
            ></v-col>
          </v-row>
        </form>
        <div style="max-height: 58vh" class="overflow-y-auto">
          <v-card
            @click="showCard(card)"
            class="ma-2"
            v-for="card in filteredList"
            :key="card.id_cartao"
          >
            <v-card-title
              >Cartão: {{ card.titulo }}<v-spacer></v-spacer> </v-card-title
            ><v-card-subtitle class="custom-subtitle"
              >Coluna: {{ card.titulo_coluna }}</v-card-subtitle
            >
            <v-card-text>
              <v-row>
                <v-col class="custom-text">
                  Data de criação: {{ card.horario_criacao }} <br />
                </v-col>
                <v-col class="custom-text">
                  Data de arquivação: {{ card.horario_arquivacao }}<br />
                </v-col>
                <v-col class="custom-text">
                  <v-chip
                    v-for="tag in card.etiquetas"
                    :key="tag.id"
                    x-small
                    :color="tag.cor"
                    dark
                    class="ml-1"
                    >{{ tag.titulo }} </v-chip
                  ><br />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <kanbanCardDetails
          v-if="showCardDetail"
          @closeCardDetail="showCardDetail = false"
          :selectCard="selectCard"
          :kanbanId="kanbanId"
          :permission="'Leitura'"
      /></v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import kanbanCardDetails from "./kanbanCardDetails.vue";
export default {
  props: ["kanbanId", "permission"],
  data() {
    return {
      selectCard: "",
      dialog: true,
      search: "",
      archivedCards: [],
      showCardDetail: false,
    };
  },
  components: {
    kanbanCardDetails,
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("close");
      }
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
    filteredList() {
      return this.archivedCards.filter((card) => {
        if (this.search.length < 1) {
          return this.archivedCards;
        } else {
          return (
            card.titulo_cartao
              .toLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) > -1
          );
        }
      });
    },
  },
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    async fecthData() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
      };
      console.log("Obj:", obj);
      let objCrypt = await this.cryptoMsg(obj);
      console.log(objCrypt);

      this.axios
        .post(`${this.server}/kanban/showArchivedCards`, { data: objCrypt })
        .then(async (res) => {
          if (res.status == 200) {
            console.log(res);
            let decrypt = await this.decrypt(res.data.data);
            console.log("decrypt: ", decrypt);
            this.archivedCards = decrypt.data;
            console.log("cartões:", this.archivedCards);
          } else {
            this.archivedCards = [];
          }
        });
    },
    showCard(card) {
      this.selectCard = card;
      this.showCardDetail = true;
    },
  },
  created() {
    this.fecthData();
  },
  mounted() {
    this.$root.$on("showCardDetail", (card) => {
      console.warn(card);
      this.selectCard = card;
      console.log("ShowCArdDetail: ", this.showCardDetail);
      this.showCardDetail = true;
    });
  },
};
</script>

<style></style>
