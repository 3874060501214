<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">QrCode</span>
      </v-card-title>
      <v-row justify="center">
        <v-img :src="path" max-width="400" max-height="400">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="dialog = false">Voltar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  data() {
    return {
      /* eslint-disable no-useless-escape */
      dialog: false,
      updateCounter: 0,
    };
  },
  created() {},
  computed: {
    showQrCode() {
      return this.$store.state.interactions.showQrcode;
    },
    path() {
      return `https://${
        window.location.href.split("/")[2].split(":")[0]
      }:3020/midias/qrCodes/qrcode_${this.$store.state.info.cliente}_${
        this.$store.state.info.numeronome
      }.png?update=${this.updateCounter}`; // Adiciona o contador como parâmetro de query
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$store.state.interactions.showQrcode = false;
      }
    },
    showQrCode(newValue) {
      if (newValue) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
  },
  methods: {},
  mounted() {
    this.interval = setInterval(() => {
      this.updateCounter++; // Incrementa o contador para atualizar a imagem
    }, 30000); // 30000ms = 30 segundos
  },
  beforeDestroy() {
    clearInterval(this.interval); // Limpa o intervalo quando o componente é destruído
  },
};
</script>
