<template>
  <div v-if="dialog" class="d-flex justify-center align-center" style="height: 40vh;width:100%;">
      <canvas id="chartPeak" ></canvas>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  props:['date'],
  data(){
    return{
      dialog:false,
      r:"/report/get_peakAttendancesByHour",
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Atendimentos',
            data: [],
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          },
        ]
      }
    }
  },
    created() {
      this.fetchData();
    },
    computed:{
      axios() {
            return this.$store.state.axios;
        },
        server() {
            return this.$store.state.serverExpress;
        },
        info() {
            return this.$store.state.info;
        },
    },
    watch:{
      date(newValue){
        if(newValue.length==2){
          this.dialog = false
          this.fetchData()
        }
      }
    },
    methods: {
      fetchData() {
      this.axios
        .post(`${this.server}${this.r}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          d: this.date
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.data)
            this.dialog = true
            setTimeout(() => {
              this.$emit("peakAttendancesByHour_ready")
              this.processData(res.data.data);
              this.createChart();
            }, 100);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    processData(data) {
      this.chartData.labels = data.map(item => `${item.hour}:00`);
      this.chartData.datasets[0].data = data.map(item => item.total_attendances);
    },
    createChart() {
      const ctx = document.getElementById("chartPeak").getContext("2d");
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(ctx, {
        type: "line", // Tipo de gráfico em linha
        data: this.chartData,
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Volume de Atendimentos por Hora'
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
              }
            }
          }
        }
      });
    }
    }
}
</script>
