<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title>Relatórios</v-card-title><v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              style="max-width: 250px"
              class="mt-5 ml-5"
              v-model="date"
              label="Periodo"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            dense
            range
            locale="pt-BR"
            v-model="date"
            :active-picker.sync="activePicker"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
            min="1950-01-01"
            @change="save"
          ></v-date-picker>
        </v-menu>
        <template v-slot:extension>
          <v-tabs centered v-model="tab">
            <v-tabs-slider></v-tabs-slider>
            <v-tab class="text-capitalize" v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card>
            <resumeCarousel :date="this.date" />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <extractCarousel :date="this.date" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import resumeCarousel from "./resume/resumeCarousel.vue";
import extractCarousel from "./extract/extractCarousel.vue";

export default {
  components: {
    resumeCarousel,
    extractCarousel,
  },
  data() {
    return {
      selectedDepartament: "",
      date: null,
      selectedDate: "",
      activePicker: null,
      menu: false,
      dialog: true,
      tab: null,
      items: ["Resumo", "Extrato"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  created() {
    let today = new Date();
    let sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    let formattedToday = today.toISOString().split("T")[0];
    let formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];

    this.date = [formattedSevenDaysAgo, formattedToday];
    this.save([formattedSevenDaysAgo, formattedToday]);
    this.selectedDepartament = "Todos";
  },
  methods: {
    save(date) {
      this.selectedDate = date;
    },
  },
  computed: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    selectedDate(newValue) {
      console.log(newValue);
      this.date = newValue;
    },
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide");
      }
    },
  },
};
</script>
