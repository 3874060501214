<template>
  <v-list-group dense nav v-model="isExpanded">
    <template v-slot:activator>
      <v-list-item-icon>
        <v-badge content="!" :value="newMessage" bordered color="red" overlap>
          <v-icon>mdi-chat-outline</v-icon>
        </v-badge>
      </v-list-item-icon>
      <v-list-item-title>Chat Interno</v-list-item-title>
    </template>
    <v-card class="custom-scrollbar">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar Atendente"
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-list dense>
          <v-list-item
            v-for="attendant in filteredAttendants"
            :key="attendant.id"
            class="py-1"
            @click="openChat(attendant)"
          >
            <v-list-item-avatar>
              <v-icon :color="attendant.online ? 'green' : 'red'">
                mdi-circle
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text--primary">
                {{ attendant.nome }}
              </v-list-item-title>
              <v-list-item-subtitle class="text--secondary">
                {{ attendant.usuario }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
              <v-badge
                v-if="attendant.hasNewMessage"
                content="!"
                color="red"
                overlap
              >
                <v-icon>mdi-message-outline</v-icon>
              </v-badge>
              <v-icon v-else>mdi-message-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <chat-window
      style="max-height: 60vh"
      v-if="selectedAttendant"
      :attendant="selectedAttendant"
      @close="closeChat"
      @read="markMessagesAsRead"
      @new="verifyNew"
    />
  </v-list-group>
</template>

<script>
import ChatWindow from "./ChatWindow.vue";
import { mapActions } from "vuex";
import debounce from "lodash/debounce";

export default {
  components: {
    ChatWindow,
  },
  data() {
    return {
      isExpanded: false,
      search: "",
      attendants: [],
      selectedAttendant: false,
      unreadMessagesCount: 0,
      newMessage: "",
      resizeObserver: null,
    };
  },
  watch: {
    selectedAttendant(newValue) {
      if (newValue) {
        this.selectedAttendant.mensagens_nao_lidas = 0;
        this.newMessage = this.attendants.some(
          (attendant) => attendant.hasNewMessage
        );
        this.sortAttendants();
      }
    },
  },
  computed: {
    filteredAttendants() {
      return this.attendants.filter((attendant) =>
        attendant.nome.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
  },
  methods: {
    verifyNew() {
      if (this.selectedAttendant) {
        const index = this.attendants.findIndex(
          (attendant) => attendant.id === this.selectedAttendant.id
        );
        if (index !== -1) {
          this.attendants[index].hasNewMessage = false;
        }
        this.newMessage = this.attendants.some(
          (attendant) => attendant.hasNewMessage
        );
      }
    },
    ...mapActions(["cryptoMsg", "decrypt"]),
    fetchAttendants() {
      const params = {
        id_number: this.info.id_numero,
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
      };

      this.axios
        .get(`${this.server}/chat/atendentes/status`, { params })
        .then((res) => {
          this.attendants = res.data.status.map((attendant) => ({
            ...attendant,
            hasNewMessage: attendant.mensagens_nao_lidas > 0,
          }));
          this.sortAttendants();
        })
        .catch((error) => {
          console.error("Erro ao buscar atendentes:", error);
        });
    },
    sortAttendants() {
      this.attendants.sort((a, b) => {
        if (a.hasNewMessage && !b.hasNewMessage) return -1;
        if (!a.hasNewMessage && b.hasNewMessage) return 1;
        if (a.online && !b.online) return -1;
        if (!a.online && b.online) return 1;
        const nameA = a.nome.toLowerCase();
        const nameB = b.nome.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    },
    openChat(attendant) {
      this.selectedAttendant = attendant;
      this.unreadMessagesCount = 0; // Reset unread messages count when opening chat
      attendant.hasNewMessage = false; // Reset new message indicator
      this.sortAttendants();
    },
    closeChat() {
      this.selectedAttendant = false;
    },
    handleResize(entries) {
      for (let entry of entries) {
        if (entry.contentRect.width < 200) {
          this.isExpanded = false;
        }
      }
    },
    async handleChatUpdate(data) {
      let decryp = await this.decrypt(data);
      if (decryp.id_destiny == this.info.id) {
        this.newMessage = true;
        const attendant = this.attendants.find(
          (att) => att.id == decryp.id_origin
        );
        if (attendant) {
          attendant.hasNewMessage = true;
        }
        this.sortAttendants();
      }
    },
    markMessagesAsRead() {
      this.unreadMessagesCount = 0; // Reset unread messages count when marking messages as read
      this.sortAttendants();
    },
  },
  created() {
    this.fetchAttendants();
    this.socket.on("chatUpdate", this.handleChatUpdate);
    // Atualiza o status do atendente para offline
    this.socket.on("updateAttendantOff", (attendant) => {
      const index = this.attendants.findIndex((att) => att.id === attendant);
      if (index !== -1) {
        this.$set(this.attendants, index, {
          ...this.attendants[index],
          online: false,
        });
      }
      this.sortAttendants();
    });

    // Atualiza o status do atendente para online
    this.socket.on("updateAttendantOnline", (attendant) => {
      const index = this.attendants.findIndex((att) => att.id === attendant);
      if (index !== -1) {
        this.$set(this.attendants, index, {
          ...this.attendants[index],
          online: true,
        });
      }
      this.sortAttendants();
    });
  },
  mounted() {
    this.debouncedHandleResize = debounce(this.handleResize, 1);
    this.resizeObserver = new ResizeObserver(this.debouncedHandleResize);
    this.resizeObserver.observe(this.$el);
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
};
</script>

<style scoped>
.v-list-item-avatar {
  min-width: 30px;
}
.v-card {
  max-height: 30vh;
  overflow-y: auto;
}
.v-list-item {
  margin-bottom: 4px;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
