<template>
  <v-dialog v-model="showDialog" persistent max-width="1100px">
    <v-card v-if="!automationShow">
      <v-toolbar dense dark color="primary">
        <v-card-title style="font-size: 16px; font-weight: 400"
          >Tem certeza que deseja finalizar esse cartão?</v-card-title
        ><v-spacer></v-spacer>
        <v-btn icon dark @click="showDeleteAttendant = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          style="color: white"
          class="text-capitalize"
          small
          v-if="!automationShow"
          @click="finishCard"
          automationShow="false"
        >
          Finalizar
        </v-btn>
        <v-btn
          color="blue-accent-4"
          class="text-capitalize"
          small
          @click="close"
        >
          Voltar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-toolbar dense dark color="primary">
        <v-card-title style="font-size: 16px; font-weight: 400"
          >Para arquivar o cartão, preencha os dados necessários para a
          automação:</v-card-title
        >
      </v-toolbar>
      <v-card-actions class="justify-space-between" v-if="automationShow">
        <v-btn :disabled="window === 0" @click="prev" color="primary" small>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-item-group v-model="onboarding" class="text-center" mandatory>
          <v-item
            v-for="n in length"
            :key="`btn-${n}`"
            v-slot="{ active, toggle }"
          >
            <v-btn :input-value="active" icon @click="toggle">
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
        <v-btn
          :disabled="window === automations.length - 1"
          @click="next"
          color="primary"
          small
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
      <v-container>
        <v-window v-model="window">
          <v-window-item
            v-for="(automation, index) in automations"
            :key="automation.id"
          >
            <cardFinish
              :automationData="automation"
              :filesData="files"
              :datasData="datas"
              :ref="'childComponent' + index"
            />
          </v-window-item>
        </v-window>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="#0D47A1"
          style="color: white"
          class="text-capitalize"
          small
          :disabled="!isSaveEnabled"
          :class="{ 'disabled-button': !isSaveEnabled }"
          @click="finishCard"
        >
          Salvar
        </v-btn>
        <v-btn
          color="blue-accent-4"
          class="text-capitalize"
          small
          @click="close"
        >
          Voltar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import cardFinish from "./finish/kanbanCardFinish.vue";

export default {
  props: ["card", "selectCard", "kanbanId", "permission"],
  name: "ParentComponent",
  components: { cardFinish },
  data() {
    return {
      onboarding: true,
      showDialog: true,
      automationShow: false,
      automation: "",
      automations: [],
      datas: [],
      files: [],
      length: this.automations,
      window: 0,
    };
  },
  created() {
    console.warn(this.card);
    this.fetchData();
  },
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    close() {
      this.$emit("close");
    },
    async createCard() {
      let checkedDatas = this.datas.filter((data) => data.checked);
      let checkedFiles = this.files.filter((file) => file.checked);
      let formattedString = checkedDatas
        .map((data) => `${data.titulo}: ${data.conteudo}`)
        .join("\n");
      const axios = this.$store.state.axios;
      const server = this.$store.state.serverExpress;
      let obj = {
        id_cliente: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.automation.id_kanban_destino,
        id_coluna: this.automation.id_coluna_destino,
        title: this.automation.title,
        description: this.automation.description + "\n" + formattedString,
        file: checkedFiles,
        dateIni: null,
        dateEnd: null,
        endTime: null,
        startTime: null,
        tags: [],
      };
      console.log("Objeto: ", obj);
      let objCrypt = await this.cryptoMsg(obj);
      axios
        .post(`${server}/kanban/addCard`, { data: objCrypt })
        .then((response) => {
          if (response.status == 200) {
            console.log(
              this.automation.id,
              this.automations[this.automations.length - 1].id
            );
            if (
              this.automation.id ==
              this.automations[this.automations.length - 1].id
            ) {
              console.warn("Card added:", response.data);
              this.$root.$emit("updateDataForColumn", this.idColumn);
              this.$emit("archive");
            } else {
              console.log(this.automations);
              this.automation =
                this.automations[
                  this.automations.findIndex(
                    (auto) => auto.id == this.automation.id
                  ) + 1
                ];
              this.automationShow = false;
              setTimeout(() => {
                this.automationShow = true;
                this.resetSelections(); // Limpa seleções ao mudar de automação
              }, 300);
            }
          }
        })
        .catch((error) => {
          console.error("Error adding card:", error);
        });
    },
    async finishCard() {
      this.automations.forEach((automation, index) => {
        const childComponent = this.$refs["childComponent" + index][0];
        if (childComponent) {
          childComponent.createCard();
        }
      });
      const axios = this.$store.state.axios;
      const server = this.$store.state.serverExpress;

      console.log("Finalizando card!");
      let obj = {
        id_card: this.card.id,
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
        id_column: this.card.id_coluna,
        client: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
        numberOrigin: this.$store.state.info.numero,
      };
      console.log(obj);
      let objCrypt = await this.cryptoMsg(obj);
      axios
        .post(`${server}/kanban/archiveCard`, {
          data: objCrypt,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              this.$emit("archive");
              this.showDialog = false;
              break;
            case 201:
              break;
            case 202:
              break;
            case 203:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchData() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
        id_column: this.card.id_coluna,
        id_card: this.card.id,
      };
      console.log(obj);

      let objCrypt = await this.cryptoMsg(obj);
      console.log(objCrypt);

      this.axios
        .post(`${this.server}/kanban/verifyAutomationCard`, { data: objCrypt })
        .then(async (res) => {
          if (res.status == 200) {
            let decrypt = await this.decrypt(res.data.data);
            this.automationShow = true;
            this.automation = decrypt.verify[0];
            this.automations = decrypt.verify;
            this.datas = decrypt.datas;
            this.files = decrypt.archives;
            console.log("Verificando retorno:\n", decrypt.datas[0]);
          }
        });
    },
    resetSelections() {
      this.datas.forEach((data) => {
        data.checked = false;
      });
      this.files.forEach((file) => {
        file.checked = false;
      });
    },
    next() {
      this.window = this.window + 1 === this.length ? 0 : this.window + 1;
    },
    prev() {
      this.window = this.window - 1 < 0 ? this.length - 1 : this.window - 1;
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    isSaveEnabled() {
      return this.automations.every(
        (automation) => automation.title && automation.description
      );
    },
  },
  watch: {
    showDialog(newValue) {
      if (!newValue) {
        this.close();
      }
    },
  },
};
</script>

<style>
.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
