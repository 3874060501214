<template>
  <span>
    <audio id="meuSom">
      <source src="https://painel.4red.com.br/monitor/zap.wav" />
    </audio>
  </span>
</template>

<script>
import io from "socket.io-client";
import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      soundAlert: "",
      token: "640f6e69Interface26883",
      iv: "_3926883",
    };
  },
  created() {
    setTimeout(() => {
      this.soundAlert = document.getElementById("meuSom");
    }, 1000);
  },
  computed: {
    socket() {
      return this.$store.state.socket;
    },
    transferServiceToAttendant() {
      return this.$store.state.interactions.transferServiceToAttendant;
    },
    addOptionEnd() {
      return this.$store.state.interactions.addOptionEnd;
    },
    editOptionsEnd() {
      return this.$store.state.interactions.editOptionsEnd;
    },
    getOptionEnd() {
      return this.$store.state.interactions.getOptionEnd;
    },
    transferServiceToDep() {
      return this.$store.state.interactions.transferServiceToDep;
    },
    agent() {
      return this.$store.state.agent;
    },
    getTabForDep() {
      return this.$store.state.interactions.getTabForDep;
    },
    getIntegrations() {
      return this.$store.state.interactions.getIntegrations;
    },
    addIntegration() {
      return this.$store.state.interactions.addIntegration;
    },
    agentPass() {
      return this.$store.state.agentPass;
    },
    finish() {
      return this.$store.state.interactions.finish;
    },
    askForService() {
      return this.$store.state.interactions.askForService;
    },
    editDep() {
      return this.$store.state.interactions.editDep;
    },
    getService() {
      return this.$store.state.interactions.getService;
    },
    insertDepToTab() {
      return this.$store.state.interactions.insertDepToTab;
    },
    addWallet() {
      return this.$store.state.interactions.addWallet;
    },
    removeTab() {
      return this.$store.state.interactions.removeTab;
    },
    getTabs() {
      return this.$store.state.interactions.getTabs;
    },
    getAttendantsForWallet() {
      return this.$store.state.interactions.getAttendantsForWallet;
    },
    sendMessageToZap() {
      return this.$store.state.interactions.sendMessage;
    },
    insertTabAnswer() {
      return this.$store.state.interactions.insertTabAnswer;
    },
    getContactsForWallet() {
      return this.$store.state.interactions.getContactsForWallet;
    },
    sendFileToZap() {
      return this.$store.state.interactions.sendFile;
    },
    addClient() {
      return this.$store.state.interactions.addClient;
    },
    addSatisctionSurvey() {
      return this.$store.state.interactions.addSatisctionSurvey;
    },
    deleteSurvey() {
      return this.$store.state.interactions.deleteSurvey;
    },
    addTab() {
      return this.$store.state.interactions.addTab;
    },
    insertDepToSatisfaction() {
      return this.$store.state.interactions.insertDepToSatisfaction;
    },
    addIntegrationToDep() {
      return this.$store.state.interactions.addIntegrationToDep;
    },
    deleteTab() {
      return this.$store.state.interactions.deleteTab;
    },
    rebootClient() {
      return this.$store.state.interactions.rebootClient;
    },
    qrCodeMake() {
      return this.$store.state.interactions.qrCodeMake;
    },
    sendIntegration() {
      return this.$store.state.interactions.sendIntegration;
    },
    getSatisfactionSurvey() {
      return this.$store.state.interactions.getSatisfactionSurvey;
    },
    editContact() {
      return this.$store.state.interactions.editContact;
    },
    deleteDepSurvey() {
      return this.$store.state.interactions.deleteDepSurvey;
    },
    deleteIntegration() {
      return this.$store.state.interactions.deleteIntegration;
    },
    getInfoForTransfer() {
      return this.$store.state.interactions.getInfoForTransfer;
    },
    getContacts() {
      return this.$store.state.interactions.getContacts;
    },
    getAttendants() {
      return this.$store.state.interactions.getAttendants;
    },
    getHistory() {
      return this.$store.state.interactions.getHistory;
    },
    getHistoryRange() {
      return this.$store.state.interactions.getHistoryRange;
    },
    addOptionInteraction() {
      return this.$store.state.interactions.addOptionInteraction;
    },
    getHistoryConversation() {
      return this.$store.state.interactions.getHistoryConversation;
    },
    openService() {
      return this.$store.state.interactions.openService;
    },
    addContact() {
      return this.$store.state.interactions.addContact;
    },
    getDataForReport() {
      return this.$store.state.interactions.getDataForReport;
    },
    getReportFull() {
      return this.$store.state.interactions.getReportFull;
    },
    getReportDep() {
      return this.$store.state.interactions.getReportDep;
    },
    getUra() {
      return this.$store.state.interactions.getUra;
    },
    getUraSub() {
      return this.$store.state.interactions.getUraSub;
    },
    addAttendant() {
      return this.$store.state.interactions.addAttendant;
    },
    editAttendant() {
      return this.$store.state.interactions.editAttendant;
    },
    deleteAttendant() {
      return this.$store.state.interactions.deleteAttendant;
    },
    deleteDep() {
      return this.$store.state.interactions.deleteDep;
    },
    addDep() {
      return this.$store.state.interactions.addDep;
    },
    addOptionDepUra() {
      return this.$store.state.interactions.addOptionDepUra;
    },
    editUra() {
      return this.$store.state.interactions.editUra;
    },
    editOptions() {
      return this.$store.state.interactions.editOptions;
    },
    addOptionsSub() {
      return this.$store.state.interactions.addOptionsSub;
    },
    deleteOptionDep() {
      return this.$store.state.interactions.deleteOptionDep;
    },
    deleteOptionSub() {
      return this.$store.state.interactions.deleteOptionSub;
    },
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    idNumber() {
      return this.$store.state.info.id_numero;
    },
  },
  methods: {
    getStatus() {
      this.axios
        .post(`${this.server}/attendant/get_status/`, {
          id: this.idNumber,
        })
        .then((res) => {
          if (res.status == 200) {
            console.log(res.data.status[0].status);
            this.$store.state.libStatus = res.data.status[0].status;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    con() {
      let agent = this.cryptoMsg(this.agent.trim());
      let agentPass = this.cryptoMsg(this.agentPass.trim());
      //PRODUCAO
      this.$store.state.socket = io(
        "https://" + window.location.href.split("/")[2].split(":")[0] + ":3030",
        {
          //this.socket = io("https://127.0.0.1:3030", {
          transports: ["websocket"],
          auth: {
            token: "640f6e6a54s0f248f3f34!!#_3926883",
            agent: agent,
            agentPass: agentPass,
          },
          rejectUnauthorized: false,
        }
      );
      this.socket.on("connect_error", () => {
        this.$store.state.sip = "";
        this.$store.state.sipPass = "";
        this.$store.state.authFailed = true;
        window.location.reload();
      });
      this.socket.on("connect", () => {
        this.$store.state.isAuth = true;
        //PRODUCAO
        this.$store.state.serverExpress =
          "https://" +
          window.location.href.split("/")[2].split(":")[0] +
          ":3030";
      });
      this.socket.on("initClientsForAdmin", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.clients = data;
      });
      this.socket.on("newDeps", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.info.deps = data;
      });
      this.socket.on("getReportPeak", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.reportPeak = data;
      });
      this.socket.on("getReportTab", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.tabReport = data;
      });
      this.socket.on("getReportSurvey", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.reportSurvey = data;
      });
      this.socket.on("getMediaSurvey", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.reportMediaSurvey = data;
      });
      this.socket.on("getReportTotals", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.reportTotals = data;
      });
      this.socket.on("sendInfo", (info) => {
        var data = this.decrypt(info);
        this.$store.state.info = data[0];
        setTimeout(() => {
          this.getStatus();
        }, 2000);
        setInterval(() => {
          this.getStatus();
        }, 60000);
        if (
          localStorage.getItem(`${this.$store.state.info.nome}_ramal`) &&
          data[0].telefonia
        ) {
          this.$store.state.extension = localStorage.getItem(
            `${this.$store.state.info.nome}_ramal`
          );
        }
        this.$store.state.info.deps.forEach((dep) => {
          dep.services.sort((a, b) => {
            if (a.status === "aguardando" && b.status === "aguardando") {
              return a.horario.localeCompare(b.horario);
            } else if (a.status === "aguardando" && b.status !== "aguardando") {
              return -1;
            } else if (a.status !== "aguardando" && b.status === "aguardando") {
              return 1;
            } else {
              return b.horario.localeCompare(a.horario);
            }
          });
        });
      });
      this.socket.on("getReportDep", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.report = data;
      });
      this.socket.on("getReportFull", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.report = data;
      });
      this.socket.on("getDataForReport", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.dataForReport = data;
      });
      this.socket.on("getContactsForWallet", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.contactsForWallet = data;
      });
      this.socket.on("getHistoryConversation", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.historyConversation = data;
      });
      this.socket.on("getConversation", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.conversation = data;
      });
      this.socket.on("tabForDep", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        let prov = data.map((obj) => obj.json_data[0]);
        if (prov[0]?.questions) {
          prov[0].questions.forEach((question) => {
            question.answer = "";
          });
        }
        this.$store.state.tabForDep = prov;
      });
      this.socket.on("updateMsgId", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        let index = this.$store.state.conversation?.findIndex(
          (msg) => msg.horario == data.horario && msg.mensagem == data.mensagem
        );
        if (index >= 0) {
          this.$store.state.conversation[index].id = data.id;
          setTimeout(() => {
            this.$store.state.conversation[index].id_mensagem_lib =
              data.id_mensagem_lib;
            this.$store.state.conversation[index].id_resposta =
              data.id_resposta;
          }, 200);
        }
      });
      this.socket.on("updateMsgView", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.info.deps.forEach((dep) => {
          dep.services.forEach((service) => {
            if (service.numero == data.contact_number) {
              if (
                this.$store.state.serviceFromChat.id_atendimento ==
                service.id_atendimento
              ) {
                this.$store.state.serviceFromChat.ultima_mensagem_status =
                  data.status;
              }
              service.ultima_mensagem_status = data.status;
            }
          });
        });
      });
      this.socket.on("getAttendants", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.attendants = data;
      });
      this.socket.on("getContacts", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.contacts = data;
      });
      this.socket.on("getAttendantsForWallet", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.attendantsForWallet = data;
      });
      this.socket.on("getHistory", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.history = data;
      });
      this.socket.on("getUra", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.ura = data;
      });
      this.socket.on("getTabs", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.tabs = data.map((obj) => obj.json_data[0]);
      });
      this.socket.on("getSatisfactionSurvey", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.satisfactionSurvey = data;
      });
      this.socket.on("getIntegrations", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.integrations = data;
      });
      this.socket.on("sendIntegration", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.sendIntegrationRes = data;
      });
      this.socket.on("getUraSub", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.uraSub = data;
      });
      this.socket.on("getOptionEnd", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.optionEnd = data;
      });
      this.socket.on("getHistoryRange", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.history = data;
      });
      this.socket.on("SendInfoForTransfer", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.transferAttendant = data.attendants;
        this.$store.state.transferDeps = data.deps;
      });
      this.socket.on("newValuesForContact", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        this.$store.state.info.deps.forEach((dep) => {
          let service = dep.services.findIndex(
            (service) => service.id_contato == data.id
          );
          if (service !== -1) {
            dep.services[service].contato_4red = data.contato_4red;
            dep.services[service].contato_zap = data.contato_zap;
          }
        });
      });
      this.socket.on("addTag", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        console.log(data);
        this.$store.state.info.deps.forEach((dep) => {
          let service = dep.services.findIndex(
            (service) => service.id_atendimento == data.id_service
          );
          if (service !== -1) {
            console.log(dep.services[service]);
            dep.services[service].tags.push(data.tag);
          }
        });
      });
      this.socket.on("removeTag", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        console.log(data);
        this.$store.state.info.deps.forEach((dep) => {
          let service = dep.services.findIndex(
            (service) => service.id_atendimento == data.id_service
          );
          if (service !== -1) {
            console.log(dep.services[service]);
            let tagId = dep.services[service].tags.findIndex(
              (tag) => tag.nome == data.tag.nome
            );
            if (tagId !== -1) {
              dep.services[service].tags.splice(tagId, 1);
            }
          }
        });
      });
      this.socket.on("sendNewMessageToFront", (dataEncripted) => {
        let data = this.decrypt(dataEncripted);
        if (
          this.$store.state.serviceFromChat.id_atendimento ==
          data.id_atendimento
        ) {
          if (data.status == "finalizado") {
            this.$store.state.conversation = "";
          } else {
            if (this.$store.state.conversation != "") {
              this.socket.emit("updateToRead", data.id_atendimento);
              this.$store.state.conversation.push(data);
            }
          }
        }
        this.$store.state.info.deps.forEach((dep) => {
          let service = dep.services.findIndex(
            (service) => service.id_atendimento == data.id_atendimento
          );
          if (service !== -1) {
            console.log(service, data);
            console.warn(dep.services[service]);
            if (
              data.status == "finalizado" ||
              (data.id_atendente != this.$store.state.info.id &&
                this.$store.state.info.permissao == "atendente" &&
                data.status == "em atendimento") ||
              dep.services[service].id_departamento != data.id_departamento
            ) {
              dep.services.splice(service, 1);
            } else {
              if (
                this.$store.state.serviceFromChat.id_atendimento !=
                  data.id_atendimento &&
                data.id_atendente == this.$store.state.info.id
              ) {
                if (!this.$store.state.openServiceScreen) {
                  this.$store.state.newMessage++;
                }
                this.soundAlert.play();
                if (Notification.permission !== "granted") {
                  Notification.requestPermission();
                } else {
                  let notification = new Notification("EaseChat", {
                    body: "Nova Mensagem: " + data.mensagem,
                  });
                  notification.onclick = function () {
                    window.focus(); // Ativa a janela do navegador
                  };
                }
              }
              dep.services[service].id_atendente = data.id_atendente;
              dep.services[service].nome_atendente = data.nome_atendente;
              dep.services[service].mensagem = data.mensagem;
              dep.services[service].horario = data.horario;
              dep.services[service].sentido = data.sentido;
              dep.services[service].tipo = data.tipo;
              dep.services[service].visualizado = data.visualizado;
              dep.services[service].status = data.status;
              if (
                this.$store.state.serviceFromChat.id_atendimento ==
                data.id_atendimento
              ) {
                dep.services[service].visualizado = true;
              }
              dep.services.sort((a, b) => {
                if (a.status === "aguardando" && b.status === "aguardando") {
                  return a.horario.localeCompare(b.horario);
                } else if (
                  a.status === "aguardando" &&
                  b.status !== "aguardando"
                ) {
                  return -1;
                } else if (
                  a.status !== "aguardando" &&
                  b.status === "aguardando"
                ) {
                  return 1;
                } else {
                  return b.horario.localeCompare(a.horario);
                }
              });
            }
          } else if (
            (data.status.includes("aguardando") &&
              data.id_departamento == dep.id) ||
            (data.status.includes("em atendimento") &&
              ((data.id_atendente == this.$store.state.info.id &&
                this.$store.state.info.permissao == "atendente") ||
                this.$store.state.info.permissao == "supervisor" ||
                this.$store.state.info.permissao == "admin") &&
              data.id_departamento == dep.id)
          ) {
            if (
              data.status.includes("aguardando") ||
              this.$store.state.info.id == data.id_atendente
            ) {
              if (!this.$store.state.openServiceScreen) {
                console.log("AQUI");
                this.$store.state.newMessage++;
              }
              this.soundAlert.play();
              if (Notification.permission !== "granted") {
                Notification.requestPermission();
              }
              let notification = new Notification("EaseChat", {
                body: "Nova Mensagem" + data.mensagem,
              });
              notification.onclick = function () {
                window.focus(); // Ativa a janela do navegador
              };
            }
            dep.services.push(data);
            dep.services.sort((a, b) => {
              if (a.status === "aguardando" && b.status === "aguardando") {
                return a.horario.localeCompare(b.horario);
              } else if (
                a.status === "aguardando" &&
                b.status !== "aguardando"
              ) {
                return -1;
              } else if (
                a.status !== "aguardando" &&
                b.status === "aguardando"
              ) {
                return 1;
              } else {
                return b.horario.localeCompare(a.horario);
              }
            });
          }
        });
      });
    },
    cryptoMsg(msg) {
      let jsonString = JSON.stringify(msg);
      let encrypted = CryptoJS.AES.encrypt(jsonString, this.token, {
        iv: this.iv,
      });
      return encrypted.toString();
    },
    decrypt(data) {
      // Descriptografa a mensagem usando AES
      var decrypted = CryptoJS.AES.decrypt(data, this.token, {
        iv: CryptoJS.enc.Utf8.parse(this.iv),
      });
      // Converte a string decifrada em um objeto JSON
      var decryptedJsonString = decrypted.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedJsonString);
    },
  },
  watch: {
    openService(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("openService", msg);
        this.$store.state.interactions.openService = "";
      }
    },
    getHistoryConversation(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getHistoryConversation", msg);
        this.$store.state.interactions.getHistoryConversation = "";
      }
    },
    getHistory(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getHistory", msg);
        this.$store.state.interactions.getHistory = "";
      }
    },
    getHistoryRange(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getHistoryRange", msg);
        this.$store.state.interactions.getHistoryRange = "";
      }
    },
    getContacts(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(this.$store.state.info.id_numero);
        this.socket.emit("getContacts", msg);
        this.$store.state.interactions.getContacts = "";
      }
    },
    getAttendants(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(this.$store.state.info.id_numero);
        this.socket.emit("getAttendants", msg);
        this.$store.state.interactions.getAttendants = "";
      }
    },
    sendFileToZap(newValue) {
      if (newValue != "") {
        this.socket.emit("sendFileToZap", newValue, newValue.name);
      }
    },
    sendMessageToZap(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("sendMessageToZap", msg);
        this.$store.state.interactions.sendMessage = "";
      }
    },
    agent(newValue) {
      if (newValue != "" && this.agentPass != "") {
        this.con();
      }
    },
    askForService(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("askForService", msg);
      }
    },
    finish(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("finish", msg);
      }
    },
    getService(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getService", msg);
        this.$store.state.interactions.getService = "";
      }
    },
    editContact(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("editContact", msg);
        this.$store.state.interactions.editContact = "";
      }
    },
    rebootClient(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("rebootClient", msg);
        this.$store.state.interactions.rebootClient = "";
      }
    },
    insertDepToTab(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("insertDepToTab", msg);
        this.$store.state.interactions.insertDepToTab = "";
      }
    },
    removeTab(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("removeTab", msg);
        this.$store.state.interactions.removeTab = "";
      }
    },
    qrCodeMake(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("qrCodeMake", msg);
        this.$store.state.interactions.qrCodeMake = "";
      }
    },
    getReportFull(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getReportFull", msg);
        this.$store.state.interactions.getReportFull = "";
      }
    },
    getReportDep(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getReportDep", msg);
        this.$store.state.interactions.getReportDep = "";
      }
    },
    getDataForReport(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getDataForReport", msg);
        this.$store.state.interactions.getDataForReport = "";
      }
    },
    getInfoForTransfer(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getInfoForTransfer", msg);
        this.$store.state.interactions.getInfoForTransfer = "";
      }
    },
    getUra(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getUra", msg);
        this.$store.state.interactions.getUra = "";
      }
    },
    getUraSub(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getUraSub", msg);
        this.$store.state.interactions.getUraSub = "";
      }
    },
    editDep(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("editDep", msg);
        this.$store.state.interactions.editDep = "";
      }
    },
    transferServiceToDep(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("transferServiceToDep", msg);
        this.$store.state.interactions.transferServiceToDep = "";
      }
    },
    transferServiceToAttendant(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("transferServiceToAttendant", msg);
        this.$store.state.interactions.transferServiceToAttendant = "";
      }
    },
    getContactsForWallet(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getContactsForWallet", msg);
        this.$store.state.interactions.getContactsForWallet = "";
      }
    },
    addWallet(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("addWallet", msg);
        this.$store.state.interactions.addWallet = "";
      }
    },
    addContact(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("addContact", msg);
        this.$store.state.interactions.addContact = "";
      }
    },
    deleteTab(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("deleteTab", msg);
        this.$store.state.interactions.deleteTab = "";
      }
    },
    addClient(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("addClient", msg);
        this.$store.state.interactions.addClient = "";
      }
    },
    sendIntegration(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("sendIntegration", msg);
        this.$store.state.interactions.sendIntegration = "";
      }
    },
    getTabForDep(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getTabForDep", msg);
        this.$store.state.interactions.getTabForDep = "";
      }
    },
    deleteIntegration(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("deleteIntegration", msg);
        this.$store.state.interactions.deleteIntegration = "";
      }
    },
    getIntegrations(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getIntegrations", msg);
        this.$store.state.interactions.getIntegrations = "";
      }
    },
    deleteDepSurvey(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("deleteDepSurvey", msg);
        this.$store.state.interactions.deleteDepSurvey = "";
      }
    },
    addSatisctionSurvey(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("addSatisctionSurvey", msg);
        this.$store.state.interactions.addSatisctionSurvey = "";
      }
    },
    deleteSurvey(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("deleteSurvey", msg);
        this.$store.state.interactions.deleteSurvey = "";
      }
    },
    addIntegration(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("addIntegration", msg);
        this.$store.state.interactions.addIntegration = "";
      }
    },
    addIntegrationToDep(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("addIntegrationToDep", msg);
        this.$store.state.interactions.addIntegrationToDep = "";
      }
    },
    insertDepToSatisfaction(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("insertDepToSatisfaction", msg);
        this.$store.state.interactions.insertDepToSatisfaction = "";
      }
    },
    getSatisfactionSurvey(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getSatisfactionSurvey", msg);
        this.$store.state.interactions.getSatisfactionSurvey = "";
      }
    },
    addTab(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("addTab", msg);
        this.$store.state.interactions.addTab = "";
      }
    },
    getTabs(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getTabs", msg);
        this.$store.state.interactions.getTabs = "";
      }
    },
    addOptionInteraction(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("addOptionInteraction", msg);
        this.$store.state.interactions.addOptionInteraction = "";
      }
    },
    insertTabAnswer(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("insertTabAnswer", msg);
        this.$store.state.interactions.insertTabAnswer = "";
      }
    },
    editAttendant(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("editAttendant", msg);
        this.$store.state.interactions.editAttendant = "";
      }
    },
    addAttendant(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("addAttendant", msg);
        this.$store.state.interactions.addAttendant = "";
      }
    },
    deleteAttendant(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("deleteAttendant", msg);
        this.$store.state.interactions.deleteAttendant = "";
      }
    },
    deleteDep(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("deleteDep", msg);
        this.$store.state.interactions.deleteDep = "";
      }
    },
    addDep(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("addDep", msg);
        this.$store.state.interactions.addDep = "";
      }
    },
    addOptionDepUra(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("addOptionDepUra", msg);
        this.$store.state.interactions.addOptionDepUra = "";
      }
    },
    addOptionEnd(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("addOptionEnd", msg);
        this.$store.state.interactions.addOptionEnd = "";
      }
    },
    getAttendantsForWallet(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getAttendantsForWallet", msg);
        this.$store.state.interactions.getAttendantsForWallet = "";
      }
    },
    editOptionsEnd(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("editOptionsEnd", msg);
        this.$store.state.interactions.editOptionsEnd = "";
      }
    },
    addOptionsSub(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("addOptionsSub", msg);
        this.$store.state.interactions.addOptionsSub = "";
      }
    },
    editUra(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("editUra", msg);
        this.$store.state.interactions.editUra = "";
      }
    },
    getOptionEnd(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("getOptionEnd", msg);
        this.$store.state.interactions.getOptionEnd = "";
      }
    },
    editOptions(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("editOptions", msg);
        this.$store.state.interactions.editOptions = "";
      }
    },
    deleteOptionDep(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("deleteOptionDep", msg);
        this.$store.state.interactions.deleteOptionDep = "";
      }
    },
    deleteOptionSub(newValue) {
      if (newValue != "") {
        let msg = this.cryptoMsg(newValue);
        this.socket.emit("deleteOptionSub", msg);
        this.$store.state.interactions.deleteOptionSub = "";
      }
    },
  },
};
</script>
