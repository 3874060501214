<template>
  <v-container>
    <draggable
      :style="height()"
      class="overflow-y-auto custom-scrollbar"
      group="cartoes"
      :list="cards"
      @change="changeItem"
      :emptyInsertThreshold="10"
      :disabled="isDragDisabled"
    >
      <v-card
        @click="showCard(element)"
        class="mt-2 mr-2 ml-2"
        v-for="(element, index) in filteredCards"
        :key="index"
        @dragover="handleDragOver"
        @drop="stopAutoScroll"
      >
        <v-chip
          v-for="tag in element.etiquetas"
          :key="tag.id"
          class="mt-2 ml-1 mr-1"
          x-small
          :color="tag.cor"
          dark
          >{{ tag.titulo }}
        </v-chip>
        <v-card-subtitle style="font-weight: bold"
          >{{ element.titulo }}
        </v-card-subtitle>
        <v-card-subtitle
          class="mt-n6 mb-4"
          style="line-height: 1.2; margin-bottom: 0; padding-bottom: 0"
          >{{ cleanAndTruncate(element.descricao, 50) }}</v-card-subtitle
        >
        <v-chip
          :color="
            dataChipColor(
              element.data_inicial,
              element.data_final,
              element.status
            )
          "
          label
          :text-color="
            dataWordColor(
              element.data_inicial,
              element.data_final,
              element.status
            )
          "
          x-small
          class="mt-n4 ml-2"
        >
          <v-icon x-small class="mr-2" v-if="!element.status"
            >mdi-clock-outline</v-icon
          >{{
            calculateStatus(
              element.data_inicial,
              element.data_final,
              element.status
            )
          }}
        </v-chip>
      </v-card>
    </draggable>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions } from "vuex";
export default {
  props: ["idColumn", "permission", "idKanban"],
  components: { draggable },
  data() {
    return {
      r: "/kanban/getCards/",
      cards: [],
      updateQueue: [],
      autoScrollTimeout: null,
      scrollDirection: 0, // 0 = parado, -1 = cima, 1 = baixo
      isUserScrolling: false, // Indica se o usuário está realizando scroll manualmente
      manualScrollTimeout: null, // Timeout para definir quando o scroll manual termina
    };
  },
  created() {
    this.fetchData();
    this.listenUpdate();
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    isDragDisabled() {
      return this.permission === "Leitura";
    },
    socket() {
      return this.$store.state.socket;
    },
    search() {
      return this.$store.state.searchKanbanCards;
    },
    filteredCards() {
      const query = this.removeAccents(
        (this.search ?? "").toLowerCase()
      ).trim();
      const queryWords = query.split(/\s+/).filter(Boolean);

      return this.cards.filter((card) => {
        // Remover acentuação e converter para minúsculas
        const title = this.removeAccents((card.titulo ?? "").toLowerCase());
        const description = this.removeAccents(
          (card.descricao ?? "").toLowerCase()
        );
        const tags = (card.etiquetas ?? []).map((tag) =>
          this.removeAccents((tag.titulo ?? "").toLowerCase())
        );

        // Calcular o status e remover acentuação
        const statusText = this.removeAccents(
          (
            this.calculateStatus(
              card.data_inicial,
              card.data_final,
              card.status
            ) ?? ""
          ).toLowerCase()
        );

        // Verificar se a consulta está incluída no título, descrição, tags ou status
        const matchesTitle = queryWords.every((word) => title.includes(word));
        const matchesDescription = queryWords.every((word) =>
          description.includes(word)
        );
        const matchesTags = queryWords.every((word) =>
          tags.some((tag) => tag.includes(word))
        );
        const matchesStatus = queryWords.every((word) =>
          statusText.includes(word)
        );

        return (
          matchesTitle || matchesDescription || matchesTags || matchesStatus
        );
      });
    },
  },
  mounted() {
    this.$root.$on("updateDataForColumn", (columnId) => {
      console.log(columnId, this.idColumn);
      if (columnId == this.idColumn) {
        this.fetchData();
      }
    });
    const container = this.$el.querySelector(".overflow-y-auto");
    container.addEventListener("scroll", this.handleUserScroll);
  },
  beforeDestroy() {
    const container = this.$el.querySelector(".overflow-y-auto");
    container.removeEventListener("scroll", this.handleUserScroll);
  },
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    cleanAndTruncate(text, length) {
      // Remove todas as tags HTML
      let cleanText = text.replace(/<\/?[^>]+(>|$)/g, "");

      // Remove os hiperlinks (tanto âncoras quanto URLs)
      cleanText = cleanText.replace(/https?:\/\/[^\s]+/g, ""); // Remove URLs
      cleanText = cleanText.replace(/&nbsp;/g, " "); // Remove entidades HTML comuns como &nbsp;

      // Trunca o texto para o comprimento desejado
      return cleanText.length > length
        ? cleanText.substring(0, length) + "..."
        : cleanText;
    },
    height() {
      if (window.screen.height > 768) {
        return "max-height: 79vh";
      } else {
        return "max-height: 70vh";
      }
    },
    listenUpdate() {
      this.socket.on("updateCards", async (data) => {
        let decryp = await this.decrypt(data);
        console.error("Card decryp:", decryp);
        if (decryp.data.id_kanban == this.idKanban) {
          this.fetchData();
        }
      });
      this.socket.on("addTagsToCard", async (data) => {
        let decryp = await this.decrypt(data);
        console.error("Card decryp:", decryp);
        if (decryp.id_column == this.idColumn) {
          this.fetchData();
        }
      });
      this.socket.on("updateTagsKanban", async (data) => {
        let decryp = await this.decrypt(data);
        var decrypData = await this.decrypt(decryp.data);
        console.log("Decryp updateTagsKanban: ", decrypData);
        if (decrypData.data[0].id_quadro == this.idKanban) {
          setTimeout(() => {
            this.fetchData();
          }, 1000);
        }
      });
    },
    enqueueUpdate(id, id_coluna, ordem) {
      const update = { id, id_coluna, ordem };
      console.error(JSON.stringify(update));
      this.updateQueue.push(update);
      console.error(JSON.stringify(this.updateQueue));
    },
    async processUpdates() {
      if (this.updateQueue.length > 0) {
        const updates = [...this.updateQueue];
        console.warn(updates);

        let obj = {
          data: updates,
          id_client: this.info.id_cliente,
          id_column: this.idColumn,
          id_kanban: this.idKanban,
          id_attendant: this.info.id,
          id_number: this.info.id_numero,
        };

        let objCrypt = await this.cryptoMsg(obj);

        this.axios
          .post(`${this.server}/kanban/updateCards`, {
            data: objCrypt,
          })
          .then((res) => {
            this.updateQueue = [];
            switch (res.status) {
              case 200:
                if (res.data.data) {
                  console.log(res.data.data);
                }
                break;
              case 201:
                break;
              case 202:
                break;
              case 203:
                break;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    showCard(card) {
      this.$root.$emit("showCardDetail", card);
    },
    changeItem(item) {
      if (item.removed) {
        const removedCard = item.removed.element;
        console.log({
          type: "removed",
          id_old_column: removedCard.id_coluna,
          id_card: removedCard.id,
          title: removedCard.titulo,
          oldIndex: item.removed.oldIndex,
        });
        this.cards.slice(item.removed.oldIndex).forEach((card, index) => {
          card.ordem = item.removed.oldIndex + index; // Corrige a ordem
          console.log(`Card ID ${card.id} new order: ${card.ordem}`);
          this.enqueueUpdate(card.id, card.id_coluna, card.ordem);
        });
      }
      if (item.added) {
        const addedCard = item.added.element;
        addedCard.id_coluna = this.idColumn;
        console.log({
          type: "added",
          id_new_column: this.idColumn,
          id_card: addedCard.id,
          title: addedCard.titulo,
          newIndex: item.added.newIndex,
        });
        this.cards.forEach((card, index) => {
          if (index >= item.added.newIndex) {
            card.ordem = index + 1;
            console.log(`Card ID ${card.id} new order: ${card.ordem}`);
            this.enqueueUpdate(card.id, card.id_coluna, card.ordem);
          }
        });
      }
      if (item.moved) {
        const movedCard = item.moved.element;
        movedCard.id_coluna = this.idColumn;
        console.log({
          type: "moved",
          id_old_column: movedCard.id_coluna,
          id_new_column: this.idColumn,
          id_card: movedCard.id,
          title: movedCard.titulo,
          oldIndex: item.moved.oldIndex,
          newIndex: item.moved.newIndex,
        });
        this.cards.forEach((card, index) => {
          card.ordem = index + 1;
          this.enqueueUpdate(card.id, card.id_coluna, card.ordem);
        });
      }
      if (this.debouncedUpdate) {
        clearTimeout(this.debouncedUpdate);
      }
      this.debouncedUpdate = setTimeout(() => {
        this.processUpdates();
      }, 100);
    },
    fetchData() {
      this.axios
        .post(`${this.server}${this.r}`, {
          i: this.idColumn,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              if (res.data.error) {
                if (this.cards.status) {
                  this.isFinished = true;
                }
                this.cards = [];
              } else {
                console.log("cards:", res.data.cards);
                this.cards = res.data.cards;
              }
              break;
            case 201:
              break;
            case 202:
              break;
            case 203:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    isNullOrEmpty(value) {
      return !value || value.trim().length === 0;
    },
    calculateStatus(dateIni, dateEnd, status) {
      if (status) {
        return "Cartão concluído";
      } else if (!this.isNullOrEmpty(dateIni) && !this.isNullOrEmpty(dateEnd)) {
        const endDate = new Date(dateEnd);
        const startDate = new Date(dateIni);
        const diffDays = Math.ceil(
          (endDate - startDate) / (1000 * 60 * 60 * 24)
        ); // Diferença em dias
        if (diffDays >= 7) {
          return "Dentro do prazo";
        } else if (diffDays > 0 && diffDays < 7) {
          return "Entregar em breve";
        } else if (startDate > endDate) {
          return "Em atraso";
        }
      } else {
        return "Sem prazo";
      }
    },
    dataChipColor(dateIni, dateEnd, status) {
      if (status) {
        return "#1B5E20";
      } else if (!this.isNullOrEmpty(dateIni) && !this.isNullOrEmpty(dateEnd)) {
        const status = this.calculateStatus(dateIni, dateEnd);
        switch (status) {
          case "Dentro do prazo":
            return "#81C784";
          case "Entregar em breve":
            return "#FFF9C4";
          case "Em atraso":
            return "#EF9A9A";
        }
      } else {
        return "#BDBDBD";
      }
    },
    dataWordColor(dateIni, dateEnd, status) {
      if (status) {
        return "white";
      } else if (!this.isNullOrEmpty(dateIni) && !this.isNullOrEmpty(dateEnd)) {
        const status = this.calculateStatus(dateIni, dateEnd);
        switch (status) {
          case "Dentro do prazo":
            return "#1B5E20";
          case "Entregar em breve":
            return "#FFAB00";
          case "Em atraso":
            return "#D50000";
        }
      } else {
        return "#424242";
      }
    },
    startAutoScroll(speed) {
      const scroll = () => {
        const container = this.$el.querySelector(".overflow-y-auto");

        if (this.scrollDirection === -1 && container.scrollTop > 0) {
          container.scrollTop -= speed;
        } else if (
          this.scrollDirection === 1 &&
          container.scrollTop + container.clientHeight < container.scrollHeight
        ) {
          container.scrollTop += speed;
        }

        if (this.scrollDirection !== 0) {
          this.autoScrollTimeout = requestAnimationFrame(scroll);
        }
      };

      this.autoScrollTimeout = requestAnimationFrame(scroll);
    },
    stopAutoScroll() {
      cancelAnimationFrame(this.autoScrollTimeout);
      this.scrollDirection = 0;
      this.autoScrollTimeout = null;
    },
    handleDragOver(event) {
      if (this.isUserScrolling) return; // Não faz nada se o usuário estiver interagindo com o scroll

      const container = this.$el.querySelector(".overflow-y-auto");
      const boundingRect = container.getBoundingClientRect();
      const mouseY = event.clientY - boundingRect.top;
      const containerHeight = boundingRect.height;

      const topThreshold = containerHeight * 0.15;
      const bottomThreshold = containerHeight * 0.85;

      if (mouseY < topThreshold && this.scrollDirection !== -1) {
        this.scrollDirection = -1;
        this.startAutoScroll(30);
      } else if (mouseY > bottomThreshold && this.scrollDirection !== 1) {
        this.scrollDirection = 1;
        this.startAutoScroll(30);
      } else if (mouseY >= topThreshold && mouseY <= bottomThreshold) {
        this.stopAutoScroll();
      }
    },
    handleUserScroll() {
      this.isUserScrolling = true;
      this.stopAutoScroll(); // Para o auto-scroll se o usuário estiver rolando manualmente

      // Define um timeout para considerar que o scroll manual parou
      clearTimeout(this.manualScrollTimeout);
      this.manualScrollTimeout = setTimeout(() => {
        this.isUserScrolling = false;
      }, 100); // Ajuste o tempo conforme necessário
    },
  },
  watch: {},
};
</script>

<style scoped>
.kanban-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
