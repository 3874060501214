import { render, staticRenderFns } from "./servicesScreen.vue?vue&type=template&id=29e7f87e"
import script from "./servicesScreen.vue?vue&type=script&lang=js"
export * from "./servicesScreen.vue?vue&type=script&lang=js"
import style0 from "./servicesScreen.vue?vue&type=style&index=0&id=29e7f87e&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports