<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card v-if="dialog">
      <v-card-title>
        <span class="headline">Opções</span>
      </v-card-title>
      <v-card-text>
        <v-switch
          class="ml-3 mr-3"
          v-model="kanbanLayout"
          :value="kanbanLayout"
          label="Usar layout Kanban"
        ></v-switch>
        <v-select
          class="ml-3 mr-3"
          v-model="depColValue"
          :items="depCol"
          label="Quantidade de colunas por departamento"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" @click="save" class="text-capitalize">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  data() {
    return {
      depColValue: "",
      dialog: false,
      depCol: [{ text: "1" }, { text: "2" }, { text: "3" }, { text: "4" }],
      kanbanLayout: "",
    };
  },
  created() {},
  computed: {
    show() {
      return this.$store.state.screens.options;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.dialog = true;
        let layout = localStorage.getItem(
          `${this.$store.state.info.nome}_kanbanLayout`
        );
        this.kanbanLayout = layout;

        if (this.kanbanLayout == null) {
          this.kanbanLayout = true;
        }
        if (layout == "false") {
          this.kanbanLayout = false;
        }
        if (layout == "true") {
          this.kanbanLayout = true;
        }
        console.log("AQUI", layout);
      }
    },
    dialog(newValue) {
      if (!newValue) {
        this.$store.state.screens.options = false;
      }
    },
  },
  methods: {
    save() {
      console.warn(this.kanbanLayout);
      if (this.depColValue == "") {
        this.depColValue = "1";
      }
      if (this.kanbanLayout == "") {
        this.kanbanLayout = true;
      }
      if (this.kanbanLayout == null || !this.kanbanLayout) {
        this.kanbanLayout = false;
      }
      localStorage.setItem(
        `${this.$store.state.info.nome}_depColValue`,
        this.depColValue
      );
      localStorage.setItem(
        `${this.$store.state.info.nome}_kanbanLayout`,
        this.kanbanLayout
      );
      this.$store.state.screens.servicesDepCol = this.depColValue;
      this.$store.state.screens.kanbanLayout = this.kanbanLayout.toString();
      this.dialog = false;
    },
  },
};
</script>
