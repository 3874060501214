<template>
  <v-dialog v-model="dialog" v-if="dialog" max-width="800px">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title style="font-size: 16px; font-weight: 400"
          >Registro de autenticações e pausas do atendente "{{
            attendantName
          }}"</v-card-title
        ><v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <form>
          <v-row justify="end" class="mt-3">
            <v-col class="justify-center" cols="auto"
              ><v-text-field
                class="mt-n4"
                style="max-width: 200px"
                v-model="search"
                clearable
                flat
                hide-details
                append-outer-icon="mdi-magnify"
                label="Procurar"
              ></v-text-field
            ></v-col>
          </v-row>
        </form>
        <div style="max-height: 58vh" class="overflow-y-auto">
          <v-data-table
            class="ma-3"
            :headers="header"
            :items="filteredItems"
            :footer-props="{ itemsPerPageOptions: [10] }"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <v-chip small :color="convertColor(item.status)">
                    {{ item.status }}
                  </v-chip>
                </td>
                <td>{{ item.horario_inicial }}</td>
                <td>{{ item.horario_final }}</td>
                <td>{{ item.duracao }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["id_attendant", "attendantName"],
  components: {},
  data() {
    return {
      search: "",
      dialog: true,
      header: [
        { text: "Status", value: "data0" },
        { text: "Horário Inicial", value: "data1" },
        { text: "Horário Final", value: "data1" },
        { text: "Duração", value: "data1" },
      ],
      items: [],
      rg: "/attendant/get_auth",
    };
  },
  created() {
    this.data();
  },
  methods: {
    convertColor(status) {
      if (status == "Logado" || status == "Liberado") {
        return "success";
      } else if (status == "Deslogado") {
        return "red";
      } else {
        return "gray";
      }
    },
    data() {
      this.axios
        .post(`${this.server}${this.rg}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          ai: this.id_attendant,
        })
        .then((res) => {
          console.log(res.data.attendants);
          this.items = res.data.attendants;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        const search = this.search.toLowerCase();
        return Object.values(item).some(
          (value) => value && value.toString().toLowerCase().includes(search)
        );
      });
    },
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide");
      }
    },
  },
};
</script>
