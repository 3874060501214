<template>
  <v-dialog v-model="dialog" width="700px">
    <v-card >
      <v-card-title class="text-center">
        Lista de contatos do grupo {{ groupName }}
        <v-spacer></v-spacer>
      <v-text-field class="mb-2" v-model="search"  style="max-width: 300px;" clearable flat hide-details append-outer-icon="mdi-magnify" label="Procurar"></v-text-field>
      
      </v-card-title>
      <v-data-table class="ma-3" :headers="header" :items="filteredItems" >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.contato_4red }}</td>
            <td>{{ item.contato_zap }}</td>
            <td>{{ item.numero }}</td>
            <td>
              <v-simple-checkbox @click="handleCheckboxChange(item)"
                v-model="item.grupo"
              ></v-simple-checkbox>
            </td>
            <td v-if="item.grupo">
              <v-simple-checkbox @click="handleCheckboxChange(item)"
                v-model="item.receber_msg_grupo"
              ></v-simple-checkbox>
            </td>                              
          </tr>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn small class="ml-2 text-capitalize" @click="save" color="red">Salvar</v-btn>
        <v-spacer></v-spacer>
        <v-btn small class="ml-2 text-capitalize" @click="(dialog = false)" color="primary">Voltar</v-btn>
      </v-card-actions>
    </v-card>
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
      </v-sheet>
    </v-bottom-sheet>
  </v-dialog>
</template>

<script>
export default {
  props: ["id","groupName"],
  components: {},
  data() {
    return {
      search:"",
      header: [
        { text: "Nome Whatsapp", value: "contato_4red" },
        { text: "Nome Cadastro", value: "contato_zap" },
        { text: "Numero", value: "numero" },
        { text: 'Ativo', value: 'grupo'},
        { text: 'Receber mensagem do grupo', value: 'action' },
      ],
      items: [],
      sheet: false,
      sheetDialog: "",
      dialog: true,
      name: "",
      route: "/groups/get_list",
      routeS: "/groups/save",
      newItems:[]
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleCheckboxChange(item) {
    const itemIndex = this.newItems.findIndex(i => i.id === item.id);
      if (itemIndex === -1) {
        this.newItems.push({id:item.id,group:item.grupo,msg:item.receber_msg_grupo});
      }else{
        this.newItems[itemIndex].group = item.grupo
        this.newItems[itemIndex].msg = item.receber_msg_grupo
      }
  },
    getList() {
      this.axios
        .post(`${this.server}${this.route}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          g: this.id
        })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            this.items = res.data.contacts
          } else {
            this.sheetDialog = res.data.error;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
            }, 4000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    add() {
      this.axios
        .post(`${this.server}${this.route}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          g: this.name
        })
        .then((res) => {
          if (res.status != 200) {
            this.sheetDialog = res.data.message;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
              this.dialog = false;
              this.$emit("reload");
            }, 4000);
          } else {
            this.sheetDialog = res.data.error;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
            }, 4000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    customFilter(item, queryText, itemText) {
      // Implementa sua própria função de filtro personalizado
      const textToSearch = queryText.toLowerCase();
      return itemText.toLowerCase().includes(textToSearch);
    },
    save(){
      this.axios
        .post(`${this.server}${this.routeS}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          g: this.newItems,
          i:this.id
        })
        .then((res) => {
          if (res.status == 200) {
            this.sheetDialog = res.data.message;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
              this.dialog = false;
              this.$emit("reload");
            }, 4000);
          } else {
            this.sheetDialog = res.data.error;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
            }, 4000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  computed: {
    filteredItems() {
      const search = this.search.toLowerCase().toString();
      
      return this.items.filter((item) => {
        const contato_4red = item.contato_4red.toLowerCase();
        const numero = item.numero.toLowerCase();
        const contato_zap = item.contato_zap.toLowerCase();

        return (
          contato_4red.indexOf(search) > -1 ||
          numero.indexOf(search) > -1 ||
          contato_zap.indexOf(search) > -1
        );
      });
    },
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("closeList");
      }
    }
  },
};
</script>
