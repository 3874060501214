<template>
  <v-dialog v-model="dialog" v-if="dialog" max-width="500px">
    <v-card width="500px">
      <v-toolbar dense dark color="primary">
        <v-card-title style="font-size: 16px; font-weight: 400"
          >Informações do contato</v-card-title
        ><v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <form class="ma-3">
        <v-text-field
          v-model="newName"
          id="newName"
          label="Nome de Cadastro"
        ></v-text-field>
        <v-text-field
          v-model="newNumber"
          label="Número (DDD+número, exemplo: 1621039264)"
        ></v-text-field>
      </form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="addContact"
          small
          class="ml-2 text-capitalize"
          color="#0D47A1"
          style="color: white"
          >Adicionar</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
        <v-row align="center" justify="center">
          <v-col>
            <v-progress-circular
              indeterminate
              color="purple"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
  </v-dialog>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      sheet: false,
      sheetDialog: "Validando contato, aguarde.",
      newName: "",
      newNumber: "",
      dialog: true,
      rca: "/contact/add",
    };
  },
  created() {
    setTimeout(() => {
      document.getElementById("newName").focus();
    }, 300);
  },
  methods: {
    addContact() {
      this.newNumber = this.newNumber.replace(/\D/g, "");
      this.sheet = true;
      this.axios
        .post(`${this.server}${this.rca}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          nna: this.newName,
          nnu: `55${this.newNumber}`,
          cliente: `${this.info.cliente}_${this.info.numeronome}`,
        })
        .then((res) => {
          console.log(res.data);
          if (res.status == 200 && res.data.error) {
            this.sheetDialog = `ERRO: ${res.data.error}`;
            setTimeout(() => {
              this.sheet = false;
              this.sheetDialog = "Validando contato, aguarde.";
            }, 5000);
          } else if (res.status == 200 && res.data.message) {
            this.sheetDialog = res.data.message;
            this.$emit("newContact", res.data.add[0]);
            setTimeout(() => {
              this.sheet = false;
              this.sheetDialog = "Validando contato, aguarde.";
            }, 5000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    screen() {
      return this.$store.state.screens.group;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    screen(newValue) {
      if (newValue) {
        this.dialog = true;
        this.data();
      } else {
        this.dialog = false;
      }
    },
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide");
      }
    },
  },
};
</script>

<style></style>
