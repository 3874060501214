<template>
  <v-container class="my-container" fluid>
  <v-row class="row-flex-wrap">
    <v-card class="dep-card overflow-y-auto mt-n3" elevation="7" color="#ECEFF1">
      <v-row>
        <v-col v-for="client in clients" :key="client.id" class="client-col">
          <v-tooltip right color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-card v-bind="attrs" v-on="on" class="mt-3 client-card" elevation="10" width="350px" height="180px">
                <v-card-title>{{ client.cliente }}<v-spacer></v-spacer>
            </v-card-title>
             <v-card-title class="mt-n8">{{client.nome}}</v-card-title>
                <v-card-subtitle class="mt-n3">Status: {{ client.status }}<br>Numero: {{ client.numero }}<br>Atendimentos: {{ client.total_atendimentos }}<br>Contatos: {{ client.total_contatos }}  </v-card-subtitle>
              </v-card>
            </template>
            <span></span>
          </v-tooltip>
        </v-col>
      </v-row>
      <addClients />
    </v-card>
  </v-row>
</v-container>

</template>

<script>
import addClients from "./addClients.vue";

/* eslint-disable */
export default {
  components: {    addClients,

  },
  data() {
    return {
      clientsDialog: false,
    };
  },
  created(){
    console.error(this.info)
    setTimeout(() => {
      this.$store.state.screens.addClients = true
    }, 2000);
  },
  computed: {
    auth() {
      return this.$store.state.isAuth;
    },
    clients() {
      return this.$store.state.clients;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {

  },
  methods: {
    rebootClient(client){
      this.$store.state.interactions.rebootClient = `${client.cliente}_${client.nome}`
    },
    qrCodeMake(client){
      this.$store.state.interactions.qrCodeMake = `${client.cliente}_${client.nome}`
    }
  }
};
</script>

</style>

<style>
.client-card {
  margin: 3px;
}</style>