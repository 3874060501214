<template>
  <div v-if="true">
    <v-navigation-drawer
      v-if="auth && info != ''"
      permanent
      expand-on-hover
      class="overflow-y-auto"
      style="max-height: 97vh"
    >
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-face-agent</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ info.nome }}</v-list-item-title>
            <v-list-item-subtitle>{{ info.usuario }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list-group
        v-if="info.permissao == 'admin'"
        dense
        nav
        :value="false"
        prepend-icon="mdi-shield-crown-outline"
      >
        <template v-slot:activator>
          <v-list-item-title>Admin</v-list-item-title>
        </template>
        <v-list-item dense nav link class="ml-3" @click="showUra">
          <v-list-item-icon>
            <v-icon>mdi-robot</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Assistente Virtual</v-list-item-title>
        </v-list-item>
        <v-list-item dense nav link class="ml-3" @click="showQrcode">
          <v-list-item-icon>
            <v-icon>mdi-qrcode-scan</v-icon>
          </v-list-item-icon>
          <v-list-item-title>QrCode</v-list-item-title>
        </v-list-item>
        <v-list-item
          dense
          nav
          link
          class="ml-3"
          @click="showIntegrations = true"
        >
          <v-list-item-icon>
            <v-icon>mdi-vector-intersection</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Integrações</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group
        dense
        nav
        :value="false"
        v-if="
          (auth && info.permissao == 'admin') || info.permissao == 'supervisor'
        "
        prepend-icon="mdi-account-supervisor-outline"
      >
        <template v-slot:activator>
          <v-list-item-title>Supervisão</v-list-item-title>
        </template>
        <v-list-item dense nav link class="ml-3" @click="getAttendants">
          <v-list-item-icon>
            <v-icon>mdi-face-agent</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Atendentes</v-list-item-title>
        </v-list-item>
        <v-list-item dense nav link class="ml-3" @click="showDepsScreen">
          <v-list-item-icon>
            <v-icon>mdi-office-building</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Departamentos</v-list-item-title>
        </v-list-item>
        <v-list-item
          dense
          nav
          link
          class="ml-3"
          @click="dialogTag = true"
          @hide="dialogTag = false"
        >
          <v-list-item-icon>
            <v-icon>mdi-tag-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Etiquetas</v-list-item-title>
        </v-list-item>
        <v-list-item dense nav link class="ml-3" @click="dialogReport = true">
          <v-list-item-icon>
            <v-icon>mdi-chart-bar</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Relatórios</v-list-item-title>
        </v-list-item>
        <v-list-item dense nav link class="ml-3" @click="showTab">
          <v-list-item-icon>
            <v-icon>mdi-format-list-checks</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Tabulação</v-list-item-title>
        </v-list-item>
        <v-list-item
          dense
          nav
          link
          class="ml-3"
          @click="showSatisfactionSurvey"
        >
          <v-list-item-icon>
            <v-icon>mdi-emoticon-happy-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Pesquisa de satisfação</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <attendant-list />
      <v-list-item link @click="dialogContact = true">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Lista de contatos</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="openService">
        <v-list-item-icon>
          <v-badge content="!" :value="newMessage" bordered color="red" overlap>
            <v-icon>mdi-forum-outline</v-icon>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-title>Atendimentos</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="dialogHistory = true">
        <v-list-item-icon>
          <v-icon>mdi-history</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Historico</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="showGroups">
        <v-list-item-icon>
          <v-icon>mdi-account-group-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Grupos</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="showKanban" v-if="info.kanban">
        <v-list-item-icon>
          <v-icon>mdi-order-bool-ascending-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Kanban</v-list-item-title>
      </v-list-item>

      <v-list-item link v-if="info.pausa" @click="pause">
        <v-list-item-icon>
          <v-icon color="red">mdi-pause-octagon-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Despausar</v-list-item-title>
      </v-list-item>
      <v-list-item v-else link @click="pause">
        <v-list-item-icon>
          <v-icon>mdi-pause-octagon-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Pausa</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="showExtSelect" v-if="info.telefonia">
        <v-list-item-icon>
          <v-icon>mdi-phone</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Telefonia</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="showOptions">
        <v-list-item-icon>
          <v-icon>mdi-tune-vertical-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Opções</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="reload">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Sair</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>
    <loginScreen v-if="!isAuth" />
    <contactsScreen
      v-if="!isAuth && dialogContact"
      @hide="dialogContact = false"
    />
    <tagScreen v-if="!isAuth && dialogTag" @hide="dialogTag = false" />
    <historyScreen
      v-if="!isAuth && dialogHistory"
      @hide="dialogHistory = false"
    />
    <reportScreen v-if="!isAuth && dialogReport" @hide="dialogReport = false" />
    <attendantsScreen v-if="!isAuth" />
    <qrCodeScreen v-if="!isAuth" />
    <extensionSelection v-if="!isAuth && dialogExt" @hide="dialogExt = false" />
    <optionsScreen />
    <uraScreen v-if="info.permissao == 'admin'" />
    <tabScreen
      v-if="info.permissao == 'admin' || info.permissao == 'supervisor'"
    />
    <satisfactionSurvey
      v-if="info.permissao == 'admin' || info.permissao == 'supervisor'"
    />
    <integrationScreen
      v-if="info.permissao == 'admin' && showIntegrations"
      @hide="showIntegrations = false"
    />
  </div>
</template>

<script>
/* eslint-disable */
import contactsScreen from "../contact/contactScreen.vue";
import tabScreen from "../tabScreen.vue";
import tagScreen from "../tags/tagScreen.vue";
//import integrationScreen from "./integrationsScreen.vue";
import integrationScreen from "../integration/integrationScreen.vue";
//import reportScreen from "../reportScreen.vue";
import loginScreen from "../loginScreen.vue";
//import contactsScreen from "./contactsScreen.vue";
import historyScreen from "../history/historyScreen";
import attendantsScreen from "../attendantsScreen.vue";
import qrCodeScreen from "../qrCodeScreen.vue";
import optionsScreen from "../optionsScreen.vue";
import uraScreen from "../ura/newUraScreen.vue";
import satisfactionSurvey from "../satisfactionSurveyScreen.vue";
import extensionSelection from "../extensionSelection.vue";
import reportScreen from "../report/reportScreen.vue";
import AttendantList from "../internalChat/attendantsList.vue";

export default {
  components: {
    reportScreen,
    AttendantList,
    extensionSelection,
    satisfactionSurvey,
    tabScreen,
    reportScreen,
    loginScreen,
    contactsScreen,
    historyScreen,
    attendantsScreen,
    qrCodeScreen,
    optionsScreen,
    uraScreen,
    integrationScreen,
    tagScreen,
  },
  data() {
    return {
      dialogReport: false,
      dialogTag: false,
      dialogHistory: false,
      dialogExt: false,
      dialogContact: false,
      showIntegrations: false,
      showChat: true,
    };
  },
  created() {},
  watch: {
    info(newValue) {
      console.log(newValue);
    },
  },
  computed: {
    server() {
      return this.$store.state.serverExpress;
    },
    axios() {
      return this.$store.state.axios;
    },
    clients() {
      return this.$store.state.clients;
    },
    info() {
      return this.$store.state.info;
    },
    isAuth() {
      return this.$store.state.authFailed;
    },
    auth() {
      return this.$store.state.isAuth;
    },
    newMessage() {
      return this.$store.state.newMessage;
    },
  },
  methods: {
    showKanban() {
      this.$store.state.kanbanScreen = true;
    },
    pause() {
      console.log(this.info.pausa);
      if (this.info.pausa) {
        this.axios
          .post(`${this.server}/attendant/unpause`, {
            n: this.info.id_numero,
            c: this.info.id_cliente,
            ai: this.info.id,
          })
          .then((res) => {
            console.log(res.data);
            this.$store.state.info.pausa = false;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.axios
          .post(`${this.server}/attendant/pause`, {
            n: this.info.id_numero,
            c: this.info.id_cliente,
            ai: this.info.id,
          })
          .then((res) => {
            console.log(res.data);
            this.$store.state.info.pausa = true;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    openService() {
      this.$store.state.kanbanScreen = false;
      this.$store.state.openServiceScreen = true;
      this.$store.state.newMessage = 0;
    },
    reload() {
      window.location.reload();
    },
    showGroups() {
      this.$store.state.screens.group = true;
    },
    showTags() {
      this.$store.state.screens.tag = true;
    },
    showExtSelect() {
      this.dialogExt = true;
    },
    showSatisfactionSurvey() {
      this.$store.state.screens.satisfactionSurvey = true;
    },
    getContacts() {
      this.$store.state.interactions.getContacts = true;
    },
    getAttendants() {
      this.$store.state.interactions.getAttendants = true;
    },
    showQrcode() {
      this.$store.state.interactions.showQrcode = true;
    },
    showDepsScreen() {
      this.$store.state.screens.deps = true;
    },
    showOptions() {
      this.$store.state.screens.options = true;
    },
    showUra() {
      this.$store.state.screens.ura = true;
    },
    showReport() {
      this.$store.state.showReport = true;
    },
    showAddClients() {
      this.$store.state.screens.addClients = true;
    },
    showTab() {
      this.$store.state.screens.tab = true;
    },
    getHistory() {
      this.$store.state.history = [];
      this.$store.state.historyBtn = true;
      this.$store.state.interactions.getHistoryRange = {
        atendente: this.info.usuario,
        date: [this.getFormattedDate(), this.getFormattedDate()],
      };
    },
    getFormattedDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style scoped></style>
