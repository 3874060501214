<template>
  <v-dialog v-model="dialog" v-if="dialog" max-width="800px">
    <v-card>
      <v-card-title>Integrações
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn small class="ml-2 text-capitalize" color="red lighten-3" @click="dialogAdd=true">Adicionar opção</v-btn>
      </v-card-title>
      <v-data-table class="ma-3" :headers="header" :items="items" :footer-props="{ itemsPerPageOptions: [10] }">
          <template v-slot:item="{ item }">
              <tr>
                  <td>{{ item.nome }}</td>
                  <td>{{ item.solicitacao }}</td>
                  <td>{{ item.tipo }}</td>  
                  <td>
                      <v-btn small icon @click="integration=item,dialogEdit=true">
                          <v-icon color="primary">mdi-account-edit-outline</v-icon>
                      </v-btn>
                  </td>  
              </tr>
          </template>
      </v-data-table>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small class="ml-2 text-capitalize" @click="(dialog = false)" color="primary">Voltar</v-btn>
      </v-card-actions>
    </v-card>
    <addScreen v-if="dialogAdd"  @hide="dialogAdd = false" @reloadData="data(),dialogAdd = false"/>
    <editScreen  v-if="dialogEdit"  @hide="dialogEdit = false" :integrationProp="integration"  @reloadData="data(),dialogEdit = false"/>
  </v-dialog>
</template>
<script> 
import addScreen from './addScreen.vue'
import editScreen from './editScreen'
export default {
  components: {addScreen,editScreen},
  data() {
    return {
      integration:"",
      dialogEdit:false,
      dialogAdd:false,
      dialog: true,
      header: [
          { text: "Descrição", value: "nome" },
          { text: "Solicitação", value: "solicitacao" },
          { text: "Vinculo", value: "tipo"},
          { text: "Editar", value: "action"},
      ],
      items:[],
      routeGa:""
    };
    
  },
  created(){
    this.data()
  },
  methods: {
    reloadUraScreen() {
    },
    data() {
      this.axios
        .get(`${this.server}/integrations/get_integrations/${this.info.usuario}/${this.info.id_numero}`)
        .then((res) => {
          console.log(res.data)
          this.items = res.data
        })
        .catch((err) => {
          console.log(err);
        });
    },
    action(item){
      console.log(item)
    }
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide")
      }
    },
  },
};
</script>
