<template>
  <v-dialog v-model="dialog" height="300px" width="800px">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title style="font-size: 16px; font-weight: 400"
          >Editar Etiqueta</v-card-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          class="ml-7 mr-7 mt-4"
          label="Título"
          hide-details="auto"
          v-model="tagName"
        />
        <v-row
          ><v-col
            ><div class="ml-7 mr-7 mt-8">
              Selecione uma cor para a etiqueta:
              <v-color-picker
                v-model="selectedColor"
                dot-size="16"
                hide-canvas
                hide-inputs
                hide-sliders
                show-swatches
                swatches-max-height="250"
                :swatches="colorPalette"
              /></div></v-col
          ><v-col>
            <span class="mr-n8">Pré-visualização:</span>
            <v-chip
              class="mt-14 ml-n16"
              :color="selectedColor"
              text-color="white"
            >
              {{ tagName }}
            </v-chip>
          </v-col></v-row
        >
      </v-card-text>
      <v-card-actions class="mt-n8">
        <v-spacer></v-spacer>
        <v-btn
          color="#0D47A1"
          style="color: white"
          @click="updateTask"
          class="text-capitalize"
          small
          >Atualizar etiqueta</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["kanbanId", "permission", "selectionTag"],
  data() {
    return {
      dialog: true,
      showTags: false,
      selectedColor: "",
      tagName: "",
      colorPalette: [
        ["#EF5350", "#FFA726", "#FFCA28"],
        ["#AB47BC", "#CE93D8", "#F48FB1"],
        ["#2196F3", "#81D4FA", "#9E9E9E"],
        ["#4CAF50", "#A5D6A7", "#8D6E63"],
      ],
    };
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("close");
      }
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
  },
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    async updateTask() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
        id_tag: this.selectionTag.id,
        color: this.selectedColor,
        title: this.tagName,
      };
      console.log("Obj:", obj);
      let objCrypt = await this.cryptoMsg(obj);
      console.log(objCrypt);

      const res = await this.axios.post(
        `${this.server}/kanban/updateKanbanEtiqueta`,
        {
          data: objCrypt,
        }
      );
      switch (res.status) {
        case 200:
          this.$emit("close");
          break;
        case 201:
          break;
        case 202:
          break;
        case 203:
          break;
        default:
          console.warn("Resposta inesperada:", res.status);
      }
    },
  },
  created() {
    this.tagName = this.selectionTag.titulo;
    this.selectedColor = this.selectionTag.cor;
  },
};
</script>
