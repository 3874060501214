<template>
  <v-dialog v-model="dialog" persistent max-width="1200px">
    <v-card class="overflow-x-hidden">
      <v-toolbar dense dark color="primary">
        <v-card-title>Adicionar Novo Cartão</v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row dense class="mt-3 ml-10">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dateIni"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formattedDateIni"
              label="Data Inicial"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              style="max-width: 150px"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateIni"
            no-title
            scrollable
            locale="pt-BR"
            @change="$refs.menu.save(dateIni)"
          >
          </v-date-picker>
        </v-menu>
        <v-text-field
          v-model="startTime"
          label="Horário Inicial"
          type="time"
          style="max-width: 100px"
        ></v-text-field>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :return-value.sync="dateEnd"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="ml-5"
              v-model="formattedDateEnd"
              label="Data Entrega"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              persistent-hint
              v-on="on"
              style="max-width: 150px"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateEnd"
            no-title
            scrollable
            locale="pt-BR"
            @change="$refs.menu2.save(dateEnd)"
          ></v-date-picker>
        </v-menu>
        <v-text-field
          v-model="endTime"
          label="Horário Entrega"
          type="time"
          style="max-width: 100px"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-combobox
          style="max-width: 400px"
          v-model="selectedTags"
          :items="tags"
          item-text="titulo"
          item-value="id"
          label="Selecione etiquetas"
          multiple
          clearable
          chips
          deletable-chips
          return-object
          :search-input.sync="search"
          class="mr-10"
          dense
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              :color="data.item.cor"
              dark
              class="ma-2"
              small
            >
              {{ data.item.titulo }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-content v-if="data.item">
              <v-list-item-title>
                <v-chip
                  :color="data.item.cor"
                  small
                  text-color="white"
                  class="ml-5"
                >
                  {{ data.item.titulo }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-combobox>
      </v-row>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="title"
                :rules="titleRules"
                required
                label="Título"
                id="titulo"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n8">
              <v-icon>mdi-text-long</v-icon>
              <label class="body-1 font-weight-medium ml-3">Descrição </label>
              <div class="editor-container mt-n8">
                <vue-editor
                  v-model="description"
                  :editorToolbar="customToolbar"
                  class="editor-content"
                  :rules="descriptionRules"
                  :error="!isDescriptionValid && touched"
                  placeholder="Digite a descrição aqui..."
                  @input="touched = true"
                />
              </div>
              <p
                v-if="!isDescriptionValid && touched"
                class="error-message mt-12 ml-4"
              >
                A descrição é obrigatória.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="#0D47A1"
          style="color: white"
          class="text-capitalize mt-6 mr-4"
          small
          @click="addCard"
          >Adicionar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { VueEditor, Quill } from "vue2-editor";

const fontList = [
  "Arial",
  "Courier",
  "Garamond",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, "-");
}
const fontNames = fontList.map((font) => getFontName(font));
const fonts = Quill.import("formats/font");
fonts.whitelist = fontNames;
Quill.register(fonts, true);

let fontStyles = "";
fontList.forEach((font) => {
  let fontName = getFontName(font);
  fontStyles += `.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=${fontName}]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=${fontName}]::before {
    content: '${font}';
    font-family: '${font}', sans-serif;
  }
  .ql-font-${fontName} {
    font-family: '${font}', sans-serif;
  }`;
});

// Injetar estilos no documento
const node = document.createElement("style");
node.innerHTML = fontStyles;
document.body.appendChild(node);

export default {
  components: {
    VueEditor,
  },
  props: ["idColumn", "idKanban", "permission"],
  data() {
    return {
      dialog: true,
      title: "",
      description: "",
      titleRules: [(v) => !!v || "O título é obrigatório"],
      descriptionRules: [(v) => !!v || "A descrição é obrigatória"],
      selectedTags: [],
      tags: [],
      search: "",
      dateIni: "",
      dateEnd: null,
      menu: false,
      menu2: false,
      picker: null,
      startTime: null,
      endTime: null,
      customToolbar: [
        [{ font: fonts.whitelist }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }, { background: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link", "image", "video"],
        ["clean"],
      ],
      touched: false,
    };
  },
  created() {
    console.log(this.idColumn);
    console.log(this.idKanban);
    setTimeout(() => {
      document.getElementById("titulo").click();
    }, 300);
    this.getTags();
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
    formattedDateIni() {
      if (!this.dateIni) return "";
      return this.formatDateToBrazilian(this.dateIni);
    },
    formattedDateEnd() {
      if (!this.dateEnd) return "";
      return this.formatDateToBrazilian(this.dateEnd);
    },
    isDescriptionValid() {
      return this.description.trim() !== "";
    },
  },
  methods: {
    validateDescription() {
      return this.isDescriptionValid;
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    getCurrentTime() {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0"); // Obtém as horas
      const minutes = String(now.getMinutes()).padStart(2, "0"); // Obtém os minutos

      return `${hours}:${minutes}`;
    },
    formatDateToBrazilian(date) {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    close() {
      this.$emit("closeAdd");
    },
    createCard(objCrypt) {
      const axios = this.$store.state.axios;
      const server = this.$store.state.serverExpress;
      axios
        .post(`${server}/kanban/addCard`, { data: objCrypt })
        .then((response) => {
          console.log("Card added:", response.data);
          this.$root.$emit("updateDataForColumn", this.idColumn);
          this.close();
        })
        .catch((error) => {
          console.error("Error adding card:", error);
        });
    },
    ...mapActions(["cryptoMsg", "decrypt"]),
    async addCard() {
      if (this.title != "" && this.description != "") {
        let obj = {
          id_client: this.info.id_cliente,
          id_attendant: this.info.id,
          id_number: this.info.id_numero,
          id_kanban: this.idKanban,
          id_coluna: this.idColumn,
          title: this.title,
          description: this.description,
          tags: this.selectedTags,
          dateIni: this.dateIni,
          dateEnd: this.dateEnd,
          endTime: this.endTime,
          startTime: this.startTime,
        };
        console.log(obj);
        let objCrypt = await this.cryptoMsg(obj);
        this.createCard(objCrypt);
      } else if (!this.validateDescription()) {
        this.touched = true;
      }
    },
    async getTags() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.idKanban,
      };
      let objCrypt = await this.cryptoMsg(obj);
      const res = await this.axios
        .post(`${this.server}/kanban/getTagsKanban`, { data: objCrypt })
        .then(async (res) => {
          if (res.status == 200) {
            let decrypt = await this.decrypt(res.data.data);
            this.tags = decrypt.data;
          } else {
            this.tags = [];
          }
        });
      console.log(res);
    },
  },
  mounted() {
    if (this.dateIni !== null) this.dateIni = this.getCurrentDate();
    if (this.startTime !== null) this.startTime = this.getCurrentTime();
  },
};
</script>
<style scoped>
.vue2-editor img {
  max-width: 300px;
  max-height: 300px;
  width: 300px;
  height: 300px;
  object-fit: cover;
}
.editor-container {
  position: relative;
  height: 250px; /* Ajuste a altura conforme necessário */
}
.editor-content {
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
}
.error-message {
  color: red;
  font-size: 0.855em; /* Tamanho da fonte consistente */
  margin-top: 0.5em; /* Espaçamento superior */
}
</style>
