<template>
  <v-container>
    <v-card-title class="mt-n5"
      ><v-spacer></v-spacer>
      <v-btn
        v-if="!disabled"
        @click="showAdd = true"
        class="text-capitalize mr-4 mt-n2"
        color="blue darken-1"
        text
        >Nova Tarefa</v-btn
      >
      <v-text-field
        style="max-width: 200px"
        label="Busca"
        append-icon="mdi-magnify"
        dense
        solo
        v-model="search"
        class="text-capitalize mt-4"
      ></v-text-field>
    </v-card-title>
    <v-list
      style="max-height: 68vh"
      class="overflow-y-auto"
      v-if="tasks.length > 0"
    >
      <v-list-item
        @click="openTask(task)"
        v-for="task in filteredList"
        :key="task.id"
      >
        <v-card class="ma-1" style="width: 100%">
          <v-card-title
            >{{ task.id }} - {{ task.titulo }}<v-spacer></v-spacer
            ><v-chip small label :color="colorToTask(task.status)">
              {{ task.status }}
            </v-chip></v-card-title
          >
          <v-card-text>{{ task.descricao }}</v-card-text>
        </v-card>
      </v-list-item>
    </v-list>
    <kanbanTaskAdd
      v-if="showAdd"
      @close="showAdd = false"
      :idKanban="idKanban"
      :permission="permission"
      :selectCard="selectCard"
    />
    <kanbanTaskShow
      v-if="showTask"
      @close="showTask = false"
      :task="selectTask"
      :selectCard="selectCard"
      :permission="permission"
      :idKanban="idKanban"
    />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import kanbanTaskAdd from "./kanbanTaskAdd.vue";
import kanbanTaskShow from "./kanbanTaskShow.vue";
export default {
  components: { kanbanTaskAdd, kanbanTaskShow },
  props: ["selectCard", "idKanban", "permission"],
  data: () => ({
    showAdd: false,
    statusList: ["Aberta", "Iniciada", "Finalizada"],
    tasks: [],
    showTask: false,
    search: "",
  }),
  watch: {},
  computed: {
    disabled() {
      return this.permission === "Leitura";
    },
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
    filteredList() {
      return this.tasks.filter((task) => {
        if (this.search.length < 1) {
          return this.tasks;
        } else {
          return (
            task.titulo.toLowerCase().indexOf(this.search.toLocaleLowerCase()) >
              -1 || task.id.toString().indexOf(this.search) > -1
          );
        }
      });
    },
  },
  created() {
    this.fetchData();
    this.socket.on("updateTask", async (data) => {
      let decrypt = await this.decrypt(data);
      console.log(decrypt);
      if (decrypt.id_card == this.selectCard.id) {
        this.fetchData();
      }
    });
  },
  methods: {
    colorToTask(status) {
      switch (status) {
        case "Aberta":
          return "yellow";
        case "Iniciada":
          return "green";
        case "Finalizada":
          return "blue";
      }
    },
    openTask(task) {
      console.log(task);
      this.selectTask = task;
      this.showTask = true;
    },
    ...mapActions(["cryptoMsg", "decrypt"]),
    async fetchData() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.idKanban,
        id_card: this.selectCard.id,
      };
      let objCrypt = await this.cryptoMsg(obj);
      this.axios
        .post(`${this.server}/kanban/getTask`, { data: objCrypt })
        .then(async (res) => {
          switch (res.status) {
            case 200:
              console.log(res.data.data);
              var result = await this.decrypt(res.data.data);
              console.warn(result);
              this.tasks = result;
              break;
            case 201:
              this.tasks = [];
              break;
            case 202:
              break;
            case 203:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateStatus(task) {
      let obj = {
        id_task: task.id,
        status: task.status,
      };
      let objCrypt = await this.cryptoMsg(obj);
      console.log(obj, objCrypt);
      // this.axios
      //   .post(`${this.server}/kanban/updateTaskStatus`, { data: objCrypt })
      //   .then((res) => {
      //     console.log(res);
      //   });
    },
  },
};
</script>

<style scoped>
.title-wrapper {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.v-list-item-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
