<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title class="mr-10 ml-10"> </v-card-title>
      <v-row style="max-height: 66vh" class="mr-10 ml-10 overflow-y-auto">
        <v-col v-for="file in messages" :key="file.name" cols="12" md="4">
          <v-card class="d-flex align-center justify-center">
            <v-icon class="ml-4" :color="getIconColor(file.tipo)">
              {{ getIcon(file.tipo) }}
            </v-icon>
            <v-card-title class="custom-text">{{
              formatDate(file.horario)
            }}</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="downloadFile(file)">
              <v-icon color="blue darken-1">mdi-download</v-icon>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["messages"],
  data: () => ({
    dialog: true,
  }),
  computed: {
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide");
      }
    },
  },
  created() {
    console.log(this.messages);
  },
  methods: {
    formatDate(date) {
      const options = {
        day: "numeric", // Adicionando a opção para exibir o dia
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return new Date(date).toLocaleString("pt-BR", options);
    },
    getIcon(fileType) {
      switch (fileType) {
        case "pdf":
          return "mdi-file-pdf-box";
        case "imagem":
          return "mdi-file-image";
        case "video":
          return "mdi-file-excel";
        case "audio":
          return "mdi-phone";
        default:
          return "mdi-file";
      }
    },
    getIconColor(fileType) {
      switch (fileType) {
        case "pdf":
          return "red";
        case "imagem":
          return "green";
        case "video":
          return "blue";
        case "audio":
          return "yellow";
        default:
          return "grey";
      }
    },
    downloadFile(file) {
      console.log("Baixando arquivo:", file);
      // const link = document.createElement("a");
      // link.href = `data:${file.tipo};base64,${file.content}`;
      // link.download = file.nome;
      // link.click();
    },
  },
};
</script>

<style>
.custom-text {
  font-size: 12px;
}
</style>
