<template>
  <v-dialog v-model="dialog" max-width="900px">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title>Etiquetas</v-card-title><v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title
        ><v-spacer></v-spacer>
        <v-text-field
          class="mb-2"
          v-model="search"
          style="max-width: 300px"
          clearable
          flat
          hide-details
          append-outer-icon="mdi-magnify"
          label="Procurar"
        ></v-text-field>
        <v-btn
          color="#0D47A1"
          small
          class="ml-2 text-capitalize"
          @click="dialogAdd = true"
          style="color: white"
          >Adicionar Etiqueta</v-btn
        >
      </v-card-title>
      <v-data-table
        class="ma-3"
        :headers="header"
        :items="filteredItems"
        :footer-props="{ itemsPerPageOptions: [10] }"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-chip input-value="true" :color="item.cor">
                {{ item.nome }}
              </v-chip>
            </td>
            <td>
              <v-btn small icon @click="showEdit(item)">
                <v-icon color="primary">mdi-account-edit-outline</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                small
                icon
                @click="(showDelete = true), (itemForDelete = item)"
              >
                <v-icon color="red">mdi-delete-alert-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog v-if="showDelete" v-model="showDelete" width="500px">
      <v-card width="500px">
        <v-toolbar dense dark color="primary">
          <v-card-title style="font-size: 16px; font-weight: 400"
            >Deseja deletar a etiqueta "{{ itemForDelete.nome }}"
            ?</v-card-title
          ><v-spacer></v-spacer>
          <v-btn icon dark @click="showDelete = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="text-capitalize"
            style="color: white"
            @click="deleteTag"
            color="red"
            >Deletar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <addTag v-if="dialogAdd" @hide="dialogAdd = false" @reload="data" />
    <editTag
      v-if="dialogEdit"
      @hide="dialogEdit = false"
      @reload="data"
      :id="editItem.id"
      :color="editItem.cor"
      :name="editItem.nome"
    />
  </v-dialog>
</template>
<script>
import addTag from "./tagAdd.vue";
import editTag from "./tagEdit.vue";
export default {
  components: {
    addTag,
    editTag,
  },
  data() {
    return {
      showDelete: false,
      itemForDelete: "",
      dialogEdit: false,
      editItem: "",
      dialogAdd: false,
      search: "",
      rat: "/tag/get_all/",
      rdt: "/tag/delete/",
      sheet: false,
      sheetDialog: "",
      dialog: true,
      header: [
        { text: "Nome", value: "data0" },
        { text: "Editar", value: "data0" },
        { text: "Apagar", value: "data0" },
      ],
      items: [
        /*{
                data0:"Grupo A",
                check:false,

            }*/
      ],
    };
  },
  created() {
    this.data();
  },
  methods: {
    showEdit(item) {
      this.editItem = item;
      this.dialogEdit = true;
    },
    data() {
      this.axios
        .post(`${this.server}${this.rat}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              console.log(res.data.data);
              this.items = res.data.data;
              break;
            case 201:
              this.items = [];
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    action(item) {
      console.log(item);
    },
    deleteTag() {
      console.log(this.itemForDelete);
      this.axios
        .post(`${this.server}${this.rdt}`, {
          n: this.info.id_numero,
          i: this.itemForDelete.id,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              this.showDelete = false;
              this.itemForDelete = "";
              this.data();
              break;
            case "201":
              this.showDelete = false;
              this.itemForDelete = "";
              this.data();
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        let nome = item.nome.toLowerCase();
        return nome.indexOf(this.search) > -1;
      });
    },
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide");
      }
    },
  },
};
</script>
