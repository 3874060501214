<template>
  <div
    v-if="dialog"
    class="d-flex justify-center align-center"
    style="height: 40vh; width: 100%"
  >
    <canvas id="chartPeakDep"></canvas>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  props: ["date"],
  data() {
    return {
      dialog: false,
      r: "/report/get_peakAttendancesByHourAndDepartment",
      chartData: {
        labels: [],
        datasets: [],
      },
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    date(newValue) {
      if (newValue.length == 2) {
        this.dialog = false;
        this.fetchData();
      }
    },
  },
  methods: {
    fetchData() {
      this.axios
        .post(`${this.server}${this.r}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          d: this.date,
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.data);
            this.dialog = true;
            setTimeout(() => {
              this.$emit("peakAttendancesByHourAndDepartment_ready");
              this.processData(res.data.data);
              this.createChart();
            }, 100);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    processData(data) {
      // Inicializa os rótulos e um objeto para manter os dados dos departamentos
      this.chartData.labels = data.map((item) => `${item.hour}:00`);
      let departmentData = {};

      // Processa cada entrada de dados
      data.forEach((entry) => {
        entry.departments_info.forEach((department) => {
          if (!departmentData[department.departamento]) {
            // Inicializa um array de 24 posições com zeros
            departmentData[department.departamento] = Array(24).fill(0);
          }
          departmentData[department.departamento][entry.hour] =
            department.total_attendances;
        });
      });

      // Converte os dados dos departamentos para datasets
      this.chartData.datasets = Object.keys(departmentData).map(
        (department) => {
          return {
            label: department,
            data: departmentData[department],
            // Gerar cores aleatórias ou definir uma paleta de cores
            color: this.getRandomColor(),
            fill: false,
            borderWidth: 1,
          };
        }
      );
    },
    createChart() {
      const ctx = document.getElementById("chartPeakDep").getContext("2d");
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(ctx, {
        type: "line",
        data: this.chartData,
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: "Volume de Atendimentos por Hora por Departamento",
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
              },
            },
          },
        },
      });
    },
  },
};
</script>
