<template>
  <v-dialog v-model="dialog" v-if="dialog">
    <v-card v-if="!sheet">
      <v-card-title>{{ integration.integracao_nome }}
        <v-btn small class="ml-8 text-capitalize" @click="data" color="success">Atualizar</v-btn>
      <v-spacer></v-spacer>
      <v-text-field id="search" v-model="search" style="width: 60px;" label="Procurar"></v-text-field></v-card-title>
      <v-data-table class="ma-3" :headers="header" :items="filteredItems" :footer-props="{ itemsPerPageOptions: [10] }">
    <template v-slot:item="{ item }">
      <tr>
        <td v-for="headerItem in header" :key="headerItem.value">
          <template v-if="Array.isArray(item[headerItem.value])">
            <v-icon @click="next(item,headerItem)">{{ item[headerItem.value][0] }}</v-icon>
          </template>
          <template v-else>
            {{ item[headerItem.value] }}
          </template>
        </td>
      </tr>
    </template>
  </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small class="ml-2 text-capitalize" @click="(dialog = false)" color="primary">Voltar</v-btn>
      </v-card-actions>
    </v-card>
    <v-sheet v-if="sheet" class="text-center" text-color="red" height="200px">
      <div class="py-2">{{ sheetDialog }}</div>
      <v-row align="center" justify="center">
        <v-col>
          <v-progress-circular indeterminate color="purple"></v-progress-circular>
        </v-col>
      </v-row>
    </v-sheet>
    <integrationScreen v-if="dialogInt"  @hide="dialogInt = false" :integration="integrationNext"/>
  </v-dialog>
</template>
  <script> 
  import integrationScreen from './integrationScreen.vue'
  export default {
    name: 'integrationScreen',
    props: ["integration"],
    components: {integrationScreen},
    data() {
      return {
        integrationNext:{},
        dialogInt:false,
        search: "",
        dialog: true,
        header: [],
        items:[],
        rd:"/integrations/dynamic/",
        sheet:true,
        sheetDialog:"Procurando dados",
      };
    },
    created(){
      this.data()
    },
    methods: {
      removeArrayProperties(obj) {
          return Object.entries(obj)
              .filter(([, value]) => !Array.isArray(value))
              .reduce((acc, [key, value]) => {
                  acc[key] = value;
                  return acc;
              }, {});
      },
      next(next,headerItem){
        console.warn(this.integration)
        console.log(next)
        this.integrationNext.integracao_nome = next[headerItem.value][1]
        this.integrationNext.key = this.integration.key
        this.integrationNext.params = this.removeArrayProperties(next)
        this.dialogInt = true
      },
      transformObjectToHeaders(obj) {
        const headers = Object.keys(obj[0]).map((key) => ({
          text: key,
          value: key,
        }));
        return headers;
      },
      data() {
        console.warn(this.integration)
        this.axios
          .post(`${this.server}${this.rd}`,{
            f:this.integration.integracao_nome,
            c:`${this.info.cliente}_${this.info.numeronome}`,
            k:this.integration.key,
            p:this.integration.params
          })
          .then((res) => {
            console.log(res)
            if(res.status==200){
              if(res.data.message&&res.data.result){
                this.sheet = false
                this.items = res.data.result
                this.header = this.transformObjectToHeaders(res.data.result)
                setTimeout(() => {
                  document.getElementById("search").focus();
                }, 500);
              }else if(res.data.error){
                this.dialog = false
              }else{
                this.dialog = false
              }
            }else{
              this.dialog = false
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      action(item){
        console.log(item)
      }
    },
    computed: {
      filteredItems() {
          return this.items.filter((item) => {
              const search = this.search.toLowerCase();
              return Object.values(item).some((value) =>
                  value && value.toString().toLowerCase().includes(search)
              );
          });
      },
      axios() {
        return this.$store.state.axios;
      },
      server() {
        return this.$store.state.serverExpress;
      },
      info() {
        return this.$store.state.info;
      },
    },
    watch: {
      dialog(newValue) {
        if (!newValue) {
          this.$emit("hide")
        } 
      },
    },
  };
  </script>
  