<template>
  <v-dialog v-model="dialog">
    <v-container class="my-container" fluid>
      <v-card v-if="dialog">
        <v-toolbar dense dark color="primary">
          <v-card-title>Pesquisa de satisfação</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Busca"
            single-line
            hide-details
            dense
            solo
            style="max-width: 200px"
          ></v-text-field>
        </v-card-title>
        <v-row class="row-flex-wrap">
          <v-col class="dep-col">
            <v-data-table
              :headers="headers"
              :items="satisfactionSurvey"
              :footer-props="{ itemsPerPageOptions: [5] }"
              :search="search"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.nome }}</td>
                  <td
                    v-if="
                      info.permissao == 'admin' ||
                      info.permissao == 'supervisor'
                    "
                  >
                    <v-btn
                      small
                      icon
                      @click="
                        (showEditSatisfactionSurvey = true), (editSurvey = item)
                      "
                    >
                      <v-icon color="primary">mdi-account-edit-outline</v-icon>
                    </v-btn>
                  </td>
                  <td
                    v-if="
                      info.permissao == 'admin' ||
                      info.permissao == 'supervisor'
                    "
                  >
                    <v-btn
                      small
                      icon
                      @click="(showDelete = true), (deleteSurvey = item)"
                    >
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="info.permissao == 'admin' || info.permissao == 'supervisor'"
            small
            class="ml-3 mb-n2 text-capitalize"
            color="primary"
            @click="showAddSatisfactionSurvey = true"
            >Nova Pesquisa</v-btn
          >
        </v-card-title>
      </v-card>
      <v-dialog
        v-if="showAddSatisfactionSurvey"
        v-model="showAddSatisfactionSurvey"
        width="920px"
      >
        <v-card width="900px">
          <v-toolbar dense dark color="primary">
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Informações da Pesquisa de Satisfação</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="showAddSatisfactionSurvey = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-overlay :value="answerNeeded" @click="answerNeeded = false">
            <v-alert
              v-if="answerNeeded"
              type="error"
              dense
              transition="fade-transition"
              >A pesquisa deve ter pelo menos uma resposta, e todas as respostas
              precisam estar preenchidas.</v-alert
            >
          </v-overlay>
          <v-overlay
            :value="informationsNeeded"
            @click="informationsNeeded = false"
          >
            <v-alert
              v-if="informationsNeeded"
              type="error"
              dense
              transition="fade-transition"
              >É necessário que a pesquisa tenha Nome, Pergunta e
              Resposta.</v-alert
            >
          </v-overlay>
          <v-card-title
            ><v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="addSatisfactionSurvey"
              color="#0D47A1"
              style="color: white"
              >Adicionar Pesquisa</v-btn
            >
          </v-card-title>
          <div
            style="max-height: 58vh"
            class="overflow-y-auto"
            ref="scrollContainer"
          >
            <form class="ml-8 mr-8">
              <v-text-field
                required
                v-model="newSurveyName"
                label="Nome da Pesquisa"
              ></v-text-field>
              <v-text-field
                required
                v-model="newSurveyQuestion"
                label="Pergunta"
              ></v-text-field>
              <v-card-title
                ><v-spacer></v-spacer>
                <v-btn
                  small
                  class="ml-3 mb-n2 text-capitalize"
                  @click="deleteAnswer"
                  color="red"
                  outlined
                  v-if="this.selectedAnswers.length > 0"
                  >Limpar respostas</v-btn
                ></v-card-title
              >
              <v-row
                v-for="(answer, index) in selectedAnswers"
                :key="index"
                class="answer-field"
              >
                <v-col>
                  <v-text-field
                    v-model="answer.digito"
                    disabled
                    label="Digito"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="answer.texto"
                    label="Texto"
                    :rules="dataRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </form>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize"
              small
              color="blue darken-1"
              style="color: white"
              @click="addSelection"
              >Adicionar Resposta à Pesquisa</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="showEditSatisfactionSurvey"
        v-model="showEditSatisfactionSurvey"
        width="920px"
      >
        <v-card width="900px">
          <v-toolbar dense dark color="primary">
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Informações da Pesquisa de Satisfação</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="showEditSatisfactionSurvey = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-overlay :value="answerNeeded" @click="answerNeeded = false">
            <v-alert
              v-if="answerNeeded"
              type="error"
              dense
              transition="fade-transition"
              >A pesquisa deve ter pelo menos uma resposta, e todas as respostas
              precisam estar preenchidas.</v-alert
            >
          </v-overlay>
          <v-overlay
            :value="informationsNeeded"
            @click="informationsNeeded = false"
          >
            <v-alert
              v-if="informationsNeeded"
              type="error"
              dense
              transition="fade-transition"
              >É necessário que a pesquisa tenha Nome, Pergunta e
              Resposta.</v-alert
            >
          </v-overlay>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="editSatisfactionSurvey"
              color="#0D47A1"
              style="color: white"
              >Salvar Pesquisa</v-btn
            >
          </v-card-title>
          <div
            style="max-height: 58vh"
            class="overflow-y-auto"
            ref="scrollContainer"
          >
            <form class="ml-8 mr-8">
              <v-text-field
                required
                v-model="editSurvey.nome"
                label="Nome da Pesquisa"
              ></v-text-field>
              <v-text-field
                required
                v-model="editSurvey.perguntas"
                label="Pergunta"
              ></v-text-field>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  class="text-capitalize"
                  @click="deleteAnswerEdit"
                  color="red"
                  outlined
                  v-if="this.editSurvey.respostas.length > 0"
                  >Limpar respostas</v-btn
                >
              </v-card-title>
              <v-row
                v-for="(answer, index) in editSurvey.respostas"
                :key="index"
              >
                <v-col>
                  <v-text-field
                    v-model="answer.digito"
                    disabled
                    label="Digito"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="answer.texto"
                    label="Texto"
                    :rules="dataRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </form>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize"
              small
              @click="addSelectionEdit"
              color="blue darken-1"
              style="color: white"
              >Adicionar Resposta à Pesquisa</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="showDelete" v-model="showDelete" width="500px">
        <v-card width="500px">
          <v-toolbar dense dark color="primary">
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Deseja deletar a pesquisa "{{ deleteSurvey.nome }}"
              ?</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="showDelete = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="delSurvey()"
              color="red"
              style="color: white"
              >Deletar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">Pesquisas de satisfação foram atualizados</div>
      </v-sheet>
    </v-bottom-sheet>
  </v-dialog>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  data() {
    return {
      showEditSatisfactionSurvey: false,
      editSurvey: "",
      showDelete: false,
      deleteSurvey: "",
      rangeAnswer: [],
      selectedAnswers: [],
      initDigit: 1,
      newSurveyName: "",
      newSurveyQuestion: "",
      newAnswerRange: "",
      showAddSatisfactionSurvey: false,
      sheet: false,
      dialog: false,
      headers: [
        { text: "Nome", value: "nome" },
        { text: "Editar", value: "" },
        { text: "Apagar", value: "" },
      ],
      answerNeeded: false,
      informationsNeeded: false,
      dataRules: [(v) => !!v || "Esta é uma informação obrigatória"],
      search: "",
    };
  },
  created() {},
  computed: {
    satisfactionSurveyScreen() {
      return this.$store.state.screens.satisfactionSurvey;
    },
    deps() {
      return this.$store.state.info.deps;
    },
    info() {
      return this.$store.state.info;
    },
    satisfactionSurvey() {
      return this.$store.state.satisfactionSurvey;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$store.state.screens.satisfactionSurvey = false;
        this.initDigit = 1;
        this.selectedAnswers = [];
      }
    },
    showAddSatisfactionSurvey(newValue) {
      if (newValue) {
        this.$store.state.screens.satisfactionSurvey = false;
        this.initDigit = 1;
        this.selectedAnswers = [];
      }
    },
    satisfactionSurveyScreen(newValue) {
      if (newValue) {
        this.dialog = true;
        this.$store.state.interactions.getSatisfactionSurvey = {
          id_number: this.$store.state.info.id_numero,
        };
      }
    },
  },
  methods: {
    editSatisfactionSurvey() {
      const allFieldsFilled = this.editSurvey.respostas.every(
        (answer) => answer.texto.trim() !== ""
      );
      console.log("allFieldsFilled", allFieldsFilled);
      if (
        this.editSurvey.nome != "" &&
        this.editSurvey.perguntas != "" &&
        this.editSurvey.respostas.length > 0 &&
        allFieldsFilled
      ) {
        this.$store.state.interactions.deleteSurvey = {
          id: this.editSurvey.id,
        };
        this.$store.state.interactions.addSatisctionSurvey = {
          id_number: this.$store.state.info.id_numero,
          name: this.editSurvey.nome,
          question: this.editSurvey.perguntas,
          answers: this.editSurvey.respostas,
        };
        this.showEditSatisfactionSurvey = false;
        this.newSurveyName = "";
        this.newSurveyQuestion = "";
        this.showAddSatisfactionSurvey = false;
        setTimeout(() => {
          this.$store.state.interactions.getSatisfactionSurvey = {
            id_number: this.$store.state.info.id_numero,
          };
        }, 1000);
      } else if (
        this.editSurvey.nome === "" ||
        this.editSurvey.perguntas === ""
      ) {
        this.informationsNeeded = true;
      } else if (this.editSurvey.respostas.length === 0 || !allFieldsFilled) {
        this.answerNeeded = true;
      }
    },
    delSurvey() {
      this.$store.state.interactions.deleteSurvey = {
        id: this.deleteSurvey.id,
      };
      this.showDelete = false;
      this.deleteSurvey = "";
      setTimeout(() => {
        this.$store.state.interactions.getSatisfactionSurvey = {
          id_number: this.$store.state.info.id_numero,
        };
      }, 1000);
    },
    deleteAnswer() {
      this.selectedAnswers = [];
      this.initDigit = 1;
    },
    deleteAnswerEdit() {
      this.editSurvey.respostas = [];
      this.initDigit = 1;
    },
    addSelectionEdit() {
      this.informationsNeeded = false;
      this.answerNeeded = false;
      this.editSurvey.respostas.push({ digito: this.initDigit, texto: "" });
      this.initDigit++;

      this.$nextTick(() => {
        // Usa requestAnimationFrame para aguardar a próxima atualização do frame
        requestAnimationFrame(() => {
          const scrollContainer = this.$refs.scrollContainer;
          if (scrollContainer) {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
          }
        });
      });
    },
    addSelection() {
      this.informationsNeeded = false;
      this.answerNeeded = false;
      this.selectedAnswers.push({ digito: this.initDigit, texto: "" });
      this.initDigit++;

      this.$nextTick(() => {
        // Usa requestAnimationFrame para aguardar a próxima atualização do frame
        requestAnimationFrame(() => {
          const scrollContainer = this.$refs.scrollContainer;
          if (scrollContainer) {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
          }
        });
      });
    },
    addSatisfactionSurvey() {
      const allFieldsFilled = this.selectedAnswers.every(
        (answer) => answer.texto.trim() !== ""
      );
      if (
        this.newSurveyName != "" &&
        this.newSurveyQuestion != "" &&
        this.selectedAnswers.length > 0 &&
        allFieldsFilled
      ) {
        this.$store.state.interactions.addSatisctionSurvey = {
          id_number: this.$store.state.info.id_numero,
          name: this.newSurveyName,
          question: this.newSurveyQuestion,
          answers: this.selectedAnswers,
        };
        this.newSurveyName = "";
        this.newSurveyQuestion = "";
        this.showAddSatisfactionSurvey = false;
        setTimeout(() => {
          this.$store.state.interactions.getSatisfactionSurvey = {
            id_number: this.$store.state.info.id_numero,
          };
        }, 1000);
      } else if (this.newSurveyName === "" || this.newSurveyQuestion === "") {
        this.informationsNeeded = true;
      } else if (this.selectedAnswers.length === 0 || !allFieldsFilled) {
        this.answerNeeded = true;
      }
    },
  },
};
</script>
