<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!disabled"
        class="text-capitalize"
        color="blue darken-1"
        text
        @click="showDialogAdd = true"
        >Novo Campo</v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          v-for="item in data"
          :key="item.id"
          v-show="item.disponivel"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-card>
            <!-- Adicionando padding interno no v-card -->
            <v-textarea
              :label="item.titulo"
              v-model="item.conteudo"
              required
              class="ml-3 mr-3"
              rows="1"
              :disabled="disabled"
            >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="disabled"
                      @click="
                        (showDelete = true),
                          (item.conteudo = '1'),
                          (selectItem = item)
                      "
                      small
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary"> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Desativar Campo</span>
                </v-tooltip>
              </template>
            </v-textarea>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="showDialogAdd" v-if="showDialogAdd">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-card-title style="font-size: 16px; font-weight: 400"
            >Adicionar Novo Campo</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="showDialogAdd = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Título"
                v-model="title"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#0D47A1"
            style="color: white"
            class="text-capitalize"
            small
            @click="addData"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDelete" persistent height="300px" width="500px">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-card-title style="font-size: 16px; font-weight: 400"
            >Tem certeza que deseja excluir esse dado</v-card-title
          ><v-spacer></v-spacer>
          <v-btn icon dark @click="showDelete = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="text-capitalize"
            @click="disableData(selectItem)"
            color="red"
            style="color: white"
            >Excluir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  props: ["selectCard", "idKanban", "permission"],
  data() {
    return {
      selectItem: "",
      showDelete: false,
      showDialogAdd: false,
      title: "",
      r: "/kanban/getDataToCard",
      ru: "/kanban/updateDataToCard",
      rd: "/kanban/disableDataToCard",
      data: [],
      oldData: [],
    };
  },
  created() {
    console.error(this.selectCard, this.idKanban);
    this.fetchData();
    this.listenUpdate();
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    disabled() {
      return this.permission === "Leitura";
    },
    socket() {
      return this.$store.state.socket;
    },
  },
  mounted() {
    this.$root.$on("saveDetail", () => {
      this.save();
    });
  },
  methods: {
    listenUpdate() {
      this.socket.on("updateCardData", (data) => {
        console.error(data);
        if (data.id_card == this.selectCard.id) {
          this.fetchData();
        }
      });
    },
    disableData(data) {
      console.log("Data: ", data);
      this.axios
        .post(`${this.server}${this.rd}`, {
          id_data: data.id,
          id_client: this.info.id_cliente,
          id_card: this.selectCard.id,
          id_attendant: this.info.id,
          title: data.titulo,
          content: data.conteudo,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              console.log(res.data);
              this.showDelete = false;
              this.fetchData();
              break;
            case 201:
              break;
            case 202:
              break;
            case 203:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    save() {
      this.axios
        .post(`${this.server}${this.ru}`, {
          data: this.data,
          id_client: this.info.id_cliente,
          id_card: this.selectCard.id,
          id_attendant: this.info.id,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              console.log(res.data);
              this.fetchData();
              break;
            case 201:
              break;
            case 202:
              break;
            case 203:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchData() {
      this.axios
        .post(`${this.server}${this.r}`, {
          i: this.selectCard.id,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              console.log(res.data);
              this.data = [...res.data.data];
              break;
            case 201:
              break;
            case 202:
              break;
            case 203:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addData() {
      const axios = this.$store.state.axios;
      const server = this.$store.state.serverExpress;
      axios
        .post(`${server}/kanban/addData`, {
          id: this.idKanban,
          title: this.title,
        })
        .then((res) => {
          console.log(res.data);
          this.showDialogAdd = false;
          this.title = "";
          setTimeout(() => {
            this.fetchData();
          }, 500);
        })
        .catch((error) => {
          console.error("Erro ao atualizar o cartão:", error);
        });
    },
  },
};
</script>
