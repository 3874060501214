<template>
  <v-timeline align="start">
    <v-timeline-item
      v-for="(item, i) in items"
      :key="i"
      :color="item.cor"
      :icon="item.icone"
      fill-dot
      class="ma-2"
    >
      <span slot="opposite">{{ item.usuario }}<br />{{ item.horario }}</span>
      <v-card>
        <v-card-title class="text-h6"> {{ item.acao }} </v-card-title>
        <v-card-text class="bg-white text--primary">
          <pre class="custom-pre">{{ item.info }}</pre>
          <v-chip
            v-for="tag in parseTags(item.etiqueta)"
            :key="tag.id"
            x-small
            :color="tag.cor"
            dark
            >{{ tag.titulo }}</v-chip
          >
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["selectCard", "kanbanId", "permission"],
  data: () => ({
    items: [],
  }),
  created() {
    console.log(this.kanbanId);
    this.fetchData();
    this.socket.on("updateTask", async (data) => {
      let decrypt = await this.decrypt(data);
      console.log(decrypt);
      if (decrypt.id_card == this.selectCard.id) {
        this.fetchData();
      }
    });
    this.socket.on("updateCardData", (data) => {
      console.error(data);
      if (data.id_card == this.selectCard.id) {
        this.fetchData();
      }
    });
    this.socket.on("uploadFiles", async (data) => {
      let decryp = await this.decrypt(data);
      console.log("Decryp: ", decryp);
      if (decryp.id_card == this.selectCard.id) {
        this.fetchData();
      }
    });
  },
  computed: {
    disabled() {
      return this.permission === "Leitura";
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
  },
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    parseTags(etiqueta) {
      try {
        return JSON.parse(etiqueta);
      } catch (e) {
        console.error("Erro ao parsear etiquetas:", e);
        return [];
      }
    },
    async fetchData() {
      const axios = this.$store.state.axios;
      const server = this.$store.state.serverExpress;

      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
        id_card: this.selectCard.id,
      };
      let objCrypt = await this.cryptoMsg(obj);
      axios
        .post(`${server}/kanban/getTimeLine`, { data: objCrypt })
        .then(async (response) => {
          if (response.status == 200) {
            let decrypt = await this.decrypt(response.data.data);
            this.items = decrypt;
          }
        })
        .catch((error) => {
          console.error("Error adding element in kanban:", error);
        });
    },
  },
};
</script>

<style>
.custom-pre {
  white-space: pre-wrap; /* Quebra de linha dentro da pre */
  word-wrap: break-word; /* Quebra de palavras longas */
  overflow-wrap: break-word; /* Quebra de palavras para navegadores mais novos */
  max-width: 100%; /* Garante que não ultrapasse a largura do pai */
}
</style>
