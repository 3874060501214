<template>
  <v-dialog width="600px" v-model="dialog">
    <v-card width="600px">
      <v-card-title
        >Opções<v-spacer></v-spacer>
        <v-btn
          small
          class="text-capitalize"
          v-if="info.opcao != 'T'"
          color="red"
          @click="deleteOptionDep"
          >Apagar opção</v-btn
        >
      </v-card-title>
      <form class="ml-8 mr-8">
        <v-text-field
          v-model="info.opcao"
          v-if="info.opcao != 'T'"
          label="Digito"
        ></v-text-field>
        <v-textarea
          rows="3"
          v-if="info.opcao != 'T'"
          v-model="info.descricao"
          label="Descrição"
        ></v-textarea>
        <v-textarea
          rows="3"
          v-if="info.aplicacao == 'fim'"
          v-model="endMsg"
          label="Mensagem Final"
        ></v-textarea>
        <v-textarea
          rows="3"
          v-if="info.aplicacao == 'fim'"
          v-model="endTimeout"
          label="Tempo para encerrar atendimento"
        ></v-textarea>
        <v-select
          v-model="selectDep"
          v-if="info.aplicacao == 'departamento' || info.aplicacao == 'fim'"
          :items="deps"
          item-text="departamento"
          label="Departamento"
        ></v-select>
        <v-select
          v-if="info.aplicacao == 'departamento'"
          v-model="info.metrica"
          :items="metric"
          label="Metrica de encaminhamento de chamados"
        ></v-select>
      </form>
      <v-card-actions>
        <v-btn
          small
          color="primary"
          @click="dialog = false"
          class="text-capitalize"
          >Voltar</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn small class="text-capitalize" color="red" @click="save"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
      </v-sheet>
    </v-bottom-sheet>
  </v-dialog>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      endMsg: "",
      endTimeout: "",
      selectDep: "",
      sheet: false,
      sheetDialog: "",
      dialog: true,
      info: { ...this.data },
      metric: [{ text: "todos" }, { text: "atendente" }],
    };
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    deps() {
      return this.$store.state.deps;
    },
  },
  methods: {
    //TODO criar sheet corretamente
    deleteOptionDep() {
      this.axios
        .post(`${this.server}/ura/delete_option/`, this.info)
        .then((res) => {
          if (res.status == 200) {
            if (res.data.error) {
              this.sheetDialog = res.data.error;
              this.sheet = true;
              setTimeout(() => {
                this.sheetDialog = "";
                this.sheet = false;
              }, 4000);
            } else {
              this.sheetDialog = res.data.message;
              this.sheet = true;
              setTimeout(() => {
                this.sheetDialog = "";
                this.sheet = false;
                this.$emit("reloadUraScreen", res.data);
              }, 4000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateOptionDep() {
      if (
        this.selectDep != "" &&
        this.info.acao != "" &&
        this.info.opcao != "" &&
        this.info.metrica != ""
      ) {
        let dep = this.deps.find((dep) => dep.departamento == this.selectDep);
        this.info.acao = dep.id;
        this.axios
          .post(`${this.server}/ura/update_option_dep/`, this.info)
          .then((res) => {
            if (res.status == 200) {
              if (res.data.error) {
                this.sheetDialog = res.data.error;
                this.sheet = true;
                setTimeout(() => {
                  this.sheetDialog = "";
                  this.sheet = false;
                }, 4000);
              } else {
                this.sheetDialog = res.data.message;
                this.sheet = true;
                setTimeout(() => {
                  this.dialog = false;
                  this.sheetDialog = "";
                  this.sheet = false;
                  this.$emit("reloadUraScreen", res.data);
                }, 4000);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    updateOptionSub() {
      this.axios
        .post(`${this.server}/ura/update_option_sub/`, this.info)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("reloadUraScreen", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateOptionEnd() {
      let depId = this.deps.find((dep) => dep.departamento == this.selectDep);
      let obj = {
        metric: depId.id,
        timeout: this.endTimeout,
        msg: this.endMsg,
        idEnd: this.info.acao,
        idOption: this.info.id,
        digit: this.info.opcao,
        describe: this.info.descricao,
      };
      this.axios
        .post(`${this.server}/ura/update_option_end/`, obj)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("reloadUraScreen", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateOptionBack() {
      this.axios
        .post(`${this.server}/ura/update_option_back/`, this.info)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("reloadUraScreen", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    save() {
      //TODO Criar edição da opções e verificar todos os tipos
      console.log(this.info);
      switch (this.info.aplicacao) {
        case "departamento":
          this.updateOptionDep();
          break;
        case "ura":
          this.updateOptionSub();
          break;
        case "fim":
          this.updateOptionEnd();
          break;
        case "voltar":
          this.updateOptionBack();
          break;
      }
      /*this.axios
        .post(`${this.server}/ura/update_option/`, this.info)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("reloadUraScreen", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });*/
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.info = "";
        this.$emit("eventClose");
      }
    },
  },
  created() {
    if (this.info.aplicacao === "departamento" && this.info.acao) {
      const selectedDepartment = this.deps.find(
        (dep) => dep.id === this.info.acao
      );
      if (selectedDepartment) {
        this.selectDep = selectedDepartment.departamento;
      }
    } else if (this.info.aplicacao === "fim") {
      this.axios
        .post(`${this.server}/ura/get_option_end/`, this.info)
        .then((res) => {
          if (res.status == 200) {
            this.endMsg = res.data[0].mensagem;
            this.endTimeout = res.data[0].timeout;
            const selectedDepartment = this.deps.find(
              (dep) => dep.id == res.data[0].departamento
            );
            if (selectedDepartment) {
              this.selectDep = selectedDepartment.departamento;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>
