<template>
  <v-dialog v-model="attendantsDialog">
    <v-container class="my-container" fluid>
      <v-card v-if="attendantsDialog">
        <v-toolbar dense dark color="primary">
          <v-card-title>Atendentes</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="attendantsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="mb-2"
            v-model="search"
            clearable
            flat
            hide-details
            append-outer-icon="mdi-magnify"
            label="Procurar"
          ></v-text-field>
          <v-btn
            v-if="info.permissao == 'admin' || info.permissao == 'supervisor'"
            small
            class="ml-3 mb-n2 text-capitalize"
            @click="showAddAttendant = true"
            style="color: white"
            color="#0D47A1"
            >Adicionar</v-btn
          >
        </v-card-title>

        <v-row class="row-flex-wrap">
          <v-col class="dep-col ml-3 mr-3" cols="11" sm="11" md="11">
            <v-data-table
              :headers="headers"
              :items="filteredAttendants"
              :footer-props="{ itemsPerPageOptions: [10] }"
              class="data-table-responsive"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <v-chip small :color="convertColor(convertStatus(item))">
                      {{ convertStatus(item) }}
                    </v-chip>
                  </td>
                  <td>{{ item.nome }}</td>
                  <td>{{ item.abertos }}</td>
                  <td>{{ item.finalizados }}</td>
                  <td
                    v-if="
                      info.permissao == 'admin' ||
                      info.permissao == 'supervisor'
                    "
                  >
                    <v-btn
                      small
                      icon
                      @click="(showEditAttendant = true), (editAt = item)"
                    >
                      <v-icon color="primary">mdi-account-edit-outline</v-icon>
                    </v-btn>
                  </td>
                  <td
                    v-if="
                      info.permissao == 'admin' ||
                      info.permissao == 'supervisor'
                    "
                  >
                    <v-btn small icon @click="getWallet(item)">
                      <v-icon color="success">mdi-wallet-bifold</v-icon>
                    </v-btn>
                  </td>
                  <td
                    v-if="
                      info.permissao == 'admin' ||
                      info.permissao == 'supervisor'
                    "
                  >
                    <v-btn
                      small
                      icon
                      @click="(showAuth = true), (attendantToAuth = item)"
                    >
                      <v-icon color="purple">mdi-login</v-icon>
                    </v-btn>
                  </td>
                  <td
                    v-if="
                      (info.permissao == 'admin' &&
                        item.permissao != 'admin') ||
                      info.permissao == 'supervisor'
                    "
                  >
                    <v-btn
                      small
                      icon
                      @click="(showDeleteAttendant = true), (deleteAt = item)"
                    >
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>

      <v-dialog
        v-if="showEditAttendant"
        v-model="showEditAttendant"
        width="620px"
      >
        <v-card width="600px">
          <v-toolbar dense dark elevation="4" color="primary" outlined>
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Informações do atendente</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="showEditAttendant = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <form class="ma-3">
            <v-text-field
              required
              v-model="editAt.nome"
              label="Nome do atendente"
            ></v-text-field>
            <v-text-field
              required
              disabled
              :rules="emailRules"
              v-model="editAt.usuario"
              label="Email do atendente"
            ></v-text-field>
            <v-text-field
              required
              v-model="editAt.senha"
              label="Senha do atendente"
            ></v-text-field>
            <v-select
              v-show="editAt.permissao != 'admin'"
              class="mb-3"
              required
              v-model="editAt.permissao"
              :items="permissions"
              label="Permissão do atendente"
            ></v-select>
            Departamentos:
            <v-checkbox
              class="ml-3 mr-3"
              v-for="(dep, index) in deps"
              :key="index"
              v-model="selectedDepsEdit"
              :label="dep.departamento"
              :value="dep.id"
            ></v-checkbox>
          </form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="editAttendant"
              color="#0D47A1"
              style="color: white"
              >Salvar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="showAddAttendant"
        v-model="showAddAttendant"
        width="500px"
      >
        <v-card width="500px">
          <v-toolbar dense dark elevation="4" color="primary" outlined>
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Informações do atendente</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="showAddAttendant = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <div style="max-height: 58vh" class="overflow-y-auto">
            <form class="ma-3">
              <v-text-field
                required
                v-model="newAttendantName"
                label="Nome do atendente"
              ></v-text-field>
              <v-text-field
                required
                :rules="emailRules"
                v-model="newAttendantUser"
                label="Email do atendente"
              ></v-text-field>
              <v-text-field
                required
                v-model="newAttendantPass"
                label="Senha do atendente"
              ></v-text-field>
              <v-select
                class="mb-3"
                required
                v-model="newPermission"
                :items="permissions"
                label="Permissão do atendente"
              ></v-select>
              Departamentos:
              <v-checkbox
                class="ml-3 mr-3"
                v-for="(dep, index) in deps"
                :key="index"
                v-model="selectedDeps"
                :label="dep.departamento"
                :value="dep.id"
              ></v-checkbox>
            </form>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="addAttendant"
              color="#0D47A1"
              style="color: white"
              >Adicionar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="showWallet" v-model="showWallet" width="500px">
        <v-card width="500px">
          <v-toolbar dense dark color="primary">
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Contatos na Carteira</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="showWallet = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headersWallet"
            :items="contactsForWallet"
            class="ma-4 elevation-1"
          >
          </v-data-table>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="showDeleteAttendant"
        v-model="showDeleteAttendant"
        width="500px"
      >
        <v-card width="500px">
          <v-toolbar dense dark color="primary">
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Deseja deletar o usuário "{{ deleteAt.nome }}" ?</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="showDeleteAttendant = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="deleteAttendant"
              color="red"
              style="color: white"
              >Deletar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">Atendentes foram atualizados</div>
      </v-sheet>
    </v-bottom-sheet>
    <authScreen
      v-if="showAuth"
      @hide="showAuth = false"
      :id_attendant="attendantToAuth.id"
      :attendantName="attendantToAuth.nome"
    />
  </v-dialog>
</template>

<script>
import authScreen from "./attendant/authScreen";
/* eslint-disable */
export default {
  components: { authScreen },
  data() {
    return {
      attendantToAuth: "",
      showAuth: false,
      showWallet: "",
      headersWallet: [
        { text: "Contato 4Red", value: "contato_4red" },
        { text: "Contato Zap", value: "contato_zap" },
        { text: "Número", value: "numero" },
      ],
      attendantForWallet: "",
      sheet: false,
      showEditAttendant: false,
      editAt: "",
      selectedDeps: [],
      selectedDepsEdit: [],
      deleteAt: "",
      showDeleteAttendant: false,
      newAttendantName: "",
      newAttendantUser: "",
      newAttendantPass: "",
      newPermission: "",
      permissions: [{ text: "atendente" }, { text: "supervisor" }],
      emailRules: [
        (v) => !!v || "O email é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "Email inválido",
        (v) => !this.isEmailDuplicated(v) || "Este email já está sendo usado",
      ],
      headers: [
        { text: "Status", value: "online" },
        { text: "Nome", value: "horario" },
        { text: "Abertos", value: "abertos" },
        { text: "Finalizados", value: "finalizados" },
        { text: "Editar", value: "actions", sortable: false },
        { text: "Carteira", value: "actions", sortable: false },
        { text: "Autenticações e Pausas", value: "actions", sortable: false },
        { text: "Apagar", value: "actions", sortable: false },
      ],
      showAddAttendant: false,
      search: "",
      attendantsDialog: false,
    };
  },
  computed: {
    contactsForWallet() {
      return this.$store.state.contactsForWallet;
    },
    attendants() {
      return this.$store.state.attendants;
    },
    filteredAttendants() {
      return this.attendants.filter((item) => {
        return item.nome.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
    info() {
      return this.$store.state.info;
    },
    deps() {
      return this.$store.state.info.deps;
    },
    computedDepsEdit() {
      return this.deps.map((dep) => {
        return {
          id: dep.id,
          nome: dep.nome,
        };
      });
    },
  },
  watch: {
    contactsForWallet(newValue) {
      this.showWallet = true;
    },
    showEditAttendant(newValue) {
      if (newValue) {
        this.selectedDepsEdit = this.editAt.departamentos.map((dep) =>
          dep.id.toString()
        );
      } else {
        this.selectedDepsEdit = [];
      }
    },
    showAddAttendant(newValue) {
      if (!newValue) {
        this.newAttendantName = "";
        this.newAttendantPass = "";
        this.newPermission = "";
        this.newAttendantUser = "";
        this.selectedDeps = [];
        this.selectedDepsEdit = [];
      }
    },
    attendantsDialog(newValue) {
      if (!newValue) {
        this.$store.state.contacts = "";
        this.$store.state.attendants = "";
        this.$store.state.attendant = "";
        this.selectedDepsEdit = [];
      }
    },
    attendants(newValue) {
      console.log(newValue);
      if (newValue != "") {
        this.attendantsDialog = true;
      } else {
        this.attendantsDialog = false;
      }
    },
  },
  methods: {
    convertColor(status) {
      if (status == "Online") {
        return "success";
      } else if (status == "Online - Pausa") {
        return "gray";
      } else {
        return "red";
      }
    },
    convertStatus(attendant) {
      if (attendant.online && !attendant.pausa) {
        return "Online";
      } else if (attendant.online && attendant.pausa) {
        return "Online - Pausa";
      } else {
        return "Offline";
      }
    },
    getWallet(attendant) {
      this.$store.state.interactions.getContactsForWallet = {
        id: attendant.id,
      };
    },
    deleteAttendant() {
      this.$store.state.interactions.deleteAttendant = {
        id: this.deleteAt.id,
        id_number: this.$store.state.info.id_numero,
      };
      this.showDeleteAttendant = "";
      this.deleteAt = "";
      this.sheet = true;
      setTimeout(() => {
        this.sheet = false;
      }, 2000);
    },
    addAttendant() {
      if (
        this.newAttendantName != "" &&
        this.newAttendantPass != "" &&
        this.newAttendantUser != "" &&
        this.newPermission != "" &&
        this.selectedDeps.length > 0
      ) {
        let newAttendant = {
          name: this.newAttendantName,
          user: this.newAttendantUser,
          pass: this.newAttendantPass,
          permission: this.newPermission,
          id_client: this.$store.state.info.id_cliente,
          id_number: this.$store.state.info.id_numero,
          deps: this.selectedDeps,
        };
        this.showAddAttendant = false;
        this.$store.state.interactions.addAttendant = newAttendant;
        this.sheet = true;
        setTimeout(() => {
          this.sheet = false;
        }, 2000);
      }
    },
    editAttendant() {
      if (
        this.editAt.nome != "" &&
        this.editAt.usuario != "" &&
        this.editAt.senha != "" &&
        this.editAt.permissao != "" &&
        this.selectedDepsEdit.length > 0
      ) {
        this.editAt.newDeps = [];
        this.editAt.newDeps = this.selectedDepsEdit;
        this.showEditAttendant = false;
        this.$store.state.interactions.editAttendant = this.editAt;
        this.selectedDepsEdit = [];
        this.sheet = true;
        setTimeout(() => {
          this.sheet = false;
        }, 2000);
      }
    },
    isEmailDuplicated(email) {
      return this.attendants.some((attendant) => attendant.usuario === email);
    },
    showHistory(attendant) {
      this.$store.state.attendant = attendant;
      if (attendant.historico == null) {
        this.$store.state.history = [
          {
            contato_4red: "",
            contato_zap: "",
            horario: "",
            id: "",
            img: "",
            numero: "",
            status: "",
          },
        ];
      } else {
        this.$store.state.history = attendant.historico;
      }
    },
    showDetail(contact) {
      this.showDetails = true;
      this.redName = contact.contato_4red;
      this.zapName = contact.contato_zap;
      this.id_numero = contact.id_numero;
    },
  },
};
</script>

<style>
.my-container {
  width: 100%;
  white-space: nowrap;
}

.row-flex-wrap {
  overflow-x: auto;
  flex-wrap: nowrap;
}

.dep-card {
  height: 85vh;
  margin: 1px;
  padding: 10px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.service-col {
  margin: 0px;
  width: calc(100% - 20px);
}

@media (max-width: 600px) {
  .dep-col {
    flex-basis: 100%;
  }
}
</style>
