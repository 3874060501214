<template>
  <div class="mt-5">
    <span v-for="(tab, index) in tabs" :key="index">
      Tabulação do departamento {{ tab.tabulacao_nome }}
      <span v-for="(question, index) in tab.perguntas" :key="index">
        <booleanChartVue
          v-if="question.tipo == 'Verdadeiro ou Falso'"
          :question="question"
          :dep="dep"
          :date="date"
        />
        <dropdownChart
          v-if="question.tipo == 'Seleção pré definida'"
          :question="question"
          :dep="dep"
          :date="date"
        />
      </span>
    </span>
  </div>
</template>
<script>
import booleanChartVue from "./booleanChart.vue";
import dropdownChart from "./dropdownChart.vue";

export default {
  props: ["date", "dep"],
  components: {
    booleanChartVue,
    dropdownChart,
  },
  data() {
    return {
      dialog: false,
      r: "/report/getTabulationsByDepartmentAndDateRange",
      tabs: [],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    date(newValue) {
      if (newValue.length == 2) {
        this.dialog = false;
        this.fetchData();
      }
    },
  },
  methods: {
    fetchData() {
      this.axios
        .post(`${this.server}${this.r}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          d: this.date,
          i: this.dep.id,
        })
        .then((res) => {
          if (res.status === 200) {
            this.dialog = true;
            console.warn(res.data.data);
            this.tabs = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
