<template>
  <div
    v-if="dialog"
    class="d-flex justify-center align-center"
    style="height: 40vh"
  >
    <canvas :id="question.pergunta_id"></canvas>
  </div>
</template>
  <script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  props: ["date", "dep", "question"],
  data() {
    return {
      dialog: false,
      r: "/report/getBooleanResponsesByDayForQuestion",
      chartData: {
        labels: [],
        datasets: [],
      },
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    date(newValue) {
      if (newValue.length == 2) {
        this.dialog = false;
        this.fetchData();
      }
    },
  },
  methods: {
    transformData(rows) {
      // Transforma os dados para o formato adequado para o gráfico
      return rows.map((row) => {
        return {
          day: row.day.split("T")[0], // Converte a data ISO para formato mais legível
          count_true: parseInt(row.count_true), // Converte a string para número
          count_false: parseInt(row.count_false), // Converte a string para número
        };
      });
    },
    fetchData() {
      this.axios
        .post(`${this.server}${this.r}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          dt: this.date,
          di: this.dep.id,
          qi: this.question.pergunta_id,
        })
        .then((res) => {
          if (res.status === 200) {
            this.dialog = true;
            console.log(res.data.data);
            console.error(this.transformData(res.data.data));
            setTimeout(() => {
              this.processData(this.transformData(res.data.data));
            }, 100);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    processData(data) {
      this.chartData.labels = data.map((item) => item.day.split("T")[0]);

      this.chartData.datasets = [
        {
          label: "Respostas Verdadeiras",
          data: data.map((item) => parseInt(item.count_true)),
          backgroundColor: "rgba(0, 123, 255, 0.5)",
          borderColor: "rgba(0, 123, 255, 1)",
          borderWidth: 1,
        },
        {
          label: "Respostas Falsas",
          data: data.map((item) => parseInt(item.count_false)),
          backgroundColor: "rgba(255, 0, 0, 0.5)",
          borderColor: "rgba(255, 0, 0, 1)",
          borderWidth: 1,
        },
      ];

      this.createChart();
    },
    getRandomColor() {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      return `rgba(${r}, ${g}, ${b}, 0.2)`;
    },
    createChart() {
      const ctx = document
        .getElementById(this.question.pergunta_id)
        .getContext("2d");
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(ctx, {
        type: "bar",
        data: this.chartData,
        options: {
          plugins: {
            title: {
              display: true,
              text: `${this.question.pergunta}`,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
              },
            },
          },
        },
      });
    },
  },
};
</script>
  