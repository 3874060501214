<template>
  <v-dialog v-model="dialog" max-width="900px">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title style="font-size: 16px; font-weight: 400"
          >Mensagens Rápidas do Departamento "{{ depName }}"</v-card-title
        ><v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table
        class="ma-3"
        :headers="header"
        :items="items"
        :footer-props="{ itemsPerPageOptions: [10] }"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.titulo }}
            </td>
            <td>
              <v-btn
                small
                icon
                @click="(dialogEdit = true), (itemForEdit = item)"
              >
                <v-icon color="primary">mdi-account-edit-outline</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                small
                icon
                @click="(showDelete = true), (itemForDelete = item)"
              >
                <v-icon color="red">mdi-delete-alert-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="dialogAdd = true"
          small
          class="ml-2 text-capitalize"
          color="#0D47A1"
          style="color: white"
          >Adicionar Mensagem Rápida</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog v-if="showDelete" v-model="showDelete" width="500px">
      <v-card width="500px">
        <v-card-title
          >Deseja deletar a mensagem rápida "{{ itemForDelete.titulo }}"
          ?<v-spacer></v-spacer
        ></v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="text-capitalize"
            style="color: white"
            color="red"
            @click="del"
            >Deletar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" width="600px">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-card-title style="font-size: 16px; font-weight: 400"
            >Editar Mensagem Rápida</v-card-title
          ><v-spacer></v-spacer>
          <v-btn icon dark @click="dialogEdit = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <form class="ml-8 mr-8">
          <v-text-field
            v-model="itemForEdit.titulo"
            label="Titulo"
          ></v-text-field>
          <v-textarea v-model="itemForEdit.texto" label="mensagem"></v-textarea>
        </form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="save"
            small
            class="ml-2 text-capitalize"
            color="#0D47A1"
            style="color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAdd" width="600px">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-card-title style="font-size: 16px; font-weight: 400"
            >Adicionar Mensagem Rápida</v-card-title
          ><v-spacer></v-spacer>
          <v-btn icon dark @click="dialogAdd = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <form class="ml-8 mr-8">
          <v-text-field v-model="title" label="Título"></v-text-field>
          <v-textarea v-model="msg" label="Mensagem"></v-textarea>
        </form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="ml-2 text-capitalize"
            @click="add"
            color="#0D47A1"
            style="color: white"
            >Adicionar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
export default {
  props: ["id", "depName"],
  components: {},
  data() {
    return {
      title: "",
      itemForEdit: "",
      msg: "",
      dialogEdit: false,
      dialogAdd: false,
      itemForDelete: "",
      showDelete: false,
      rat: "/fastMessage/get_all/",
      raa: "/fastMessage/add/",
      rs: "/fastMessage/save/",

      rd: "/fastMessage/delete/",
      sheet: false,
      sheetDialog: "",
      dialog: true,
      header: [
        { text: "Titulo", value: "data0" },
        { text: "Editar", value: "data0" },
        { text: "Apagar", value: "data0" },
      ],
      items: [
        /*{
              data0:"Grupo A",
              check:false,

          }*/
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    del() {
      this.axios
        .post(`${this.server}${this.rd}`, {
          i: this.itemForDelete.id,
        })
        .then((res) => {
          if ((res.status = 200 && res.data.message)) {
            this.fetchData();
          }
          this.showDelete = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    save() {
      this.axios
        .post(`${this.server}${this.rs}`, {
          i: this.itemForEdit.id,
          t: this.itemForEdit.titulo,
          m: this.itemForEdit.texto,
        })
        .then((res) => {
          if ((res.status = 200 && res.data.message)) {
            this.fetchData();
          }
          this.dialogEdit = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    add() {
      this.axios
        .post(`${this.server}${this.raa}`, {
          i: this.id,
          t: this.title,
          m: this.msg,
        })
        .then((res) => {
          if ((res.status = 200 && res.data.message)) {
            this.fetchData();
          }
          this.dialogAdd = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchData() {
      this.axios
        .post(`${this.server}${this.rat}`, {
          i: this.id,
        })
        .then((res) => {
          if ((res.status = 200 && res.data.message)) {
            this.items = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide");
      }
    },
  },
};
</script>
