<template>
  <v-card elevation="1" :key="upgrade">
    <v-card-subtitle class="mb-n3" v-for="(option, index) in options" :key="index">
      <v-icon small>mdi-arrow-right-bottom</v-icon>
      {{ option.opcao }} - {{ option.descricao }}
      <v-btn x-small class="ml-2 text-capitalize" @click="(showEdit = true), (optionEdit = option)" color="blue lighten-3">Editar</v-btn>
      <v-btn x-small class="ml-2 text-capitalize" @click="addOption(option)" color="red lighten-3" v-if="option.aplicacao == 'ura'"
        >Adicionar opção</v-btn
      >
      <newUraAdd v-if="showAdd" @reloadUraScreen="reloadUraScreen" @eventClose="showAdd = false" :data="optionEdit" />
      <newUraSub @reloadUraScreen="reloadUraScreen" class="mt-1 ml-5" :id="option.acao" v-if="option.aplicacao == 'ura'" />
      <newUraEdit v-if="showEdit" @reloadUraScreen="reloadUraScreen" @eventClose="showEdit = false" :data="optionEdit" />
    </v-card-subtitle>
  </v-card>
</template>

<script>
import newUraSub from "./newUraSub.vue";
import newUraEdit from "./newUraEdit.vue";
import newUraAdd from "./newUraAdd.vue";

export default {
  name: "newUraSub",
  components: {
    newUraSub,
    newUraEdit,
    newUraAdd,
  },
  props: ["id"],
  data() {
    return {
      upgrade: 0,
      optionEdit: "",
      showAdd: false,
      showEdit: false,
      ura: "",
      options: "",
    };
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  methods: {
    reloadUraScreen() {
      this.showAdd = false;
      this.showEdit = false;
      this.upgrade++;
      this.$emit("reloadUraScreen");
    },
    updateInitialConf(payload) {
      this.ura = payload;
      this.showInitial = false;
    },
    fetchData() {
      this.axios
        .get(`${this.server}/ura/get_ura/${this.info.usuario}/${this.id}`)
        .then((res) => {
          this.ura = res.data.ura;
          this.options = res.data.options;
          this.options.forEach((opt) => {
            if (opt.aplicacao == "ura") {
              opt.id_grupohorario = res.data.ura.id_grupohorario;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editOption(option) {
      this.optionEdit = option; // Defina optionEdit quando a edição for acionada
      this.showEdit = true;
    },
    addOption(option) {
      this.optionEdit = option; // Defina optionEdit quando a edição for acionada
      this.showAdd = true;
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {},
};
</script>
