<template>
  <v-dialog v-model="showAdd">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title style="font-size: 16px; font-weight: 400"
          >Nova Tarefa</v-card-title
        ><v-spacer></v-spacer>
        <v-btn icon dark @click="showAdd = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="overflow-y-auto">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="title"
                label="Título da tarefa"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="description"
                label="Descrição da tarefa"
                required
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                :items="statusList"
                label="Status"
                v-model="status"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="add"
          small
          class="text-capitalize"
          color="#0D47A1"
          style="color: white"
          >Adicionar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["selectCard", "idKanban", "permission"],
  data: () => ({
    showAdd: true,
    title: "",
    description: "",
    status: "",
    statusList: ["Aberta", "Iniciada"],
  }),
  watch: {
    showAdd(newValue) {
      if (!newValue) {
        this.$emit("close");
      }
    },
    title(newValue) {
      if (newValue.length > 27) {
        this.title = this.title.substring(0, 27);
      }
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
  },
  created() {},
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    async add() {
      if (this.title != "" && this.description != "" && this.status != "") {
        let obj = {
          id_client: this.info.id_cliente,
          id_attendant: this.info.id,
          id_number: this.info.id_numero,
          id_kanban: this.idKanban,
          id_card: this.selectCard.id,
          title: this.title,
          description: this.description,
          status: this.status,
        };
        console.log(obj);
        let objCrypt = await this.cryptoMsg(obj);
        console.log(objCrypt);
        this.axios
          .post(`${this.server}/kanban/addTask`, { data: objCrypt })
          .then((res) => {
            if (res.status == 200) {
              this.showAdd = false;
            }
          });
      }
    },
  },
};
</script>

<style scoped></style>
