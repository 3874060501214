var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('draggable',{staticClass:"overflow-y-auto custom-scrollbar",style:(_vm.height()),attrs:{"group":"cartoes","list":_vm.cards,"emptyInsertThreshold":10,"disabled":_vm.isDragDisabled},on:{"change":_vm.changeItem}},_vm._l((_vm.filteredCards),function(element,index){return _c('v-card',{key:index,staticClass:"mt-2 mr-2 ml-2",on:{"click":function($event){return _vm.showCard(element)},"dragover":_vm.handleDragOver,"drop":_vm.stopAutoScroll}},[_vm._l((element.etiquetas),function(tag){return _c('v-chip',{key:tag.id,staticClass:"mt-2 ml-1 mr-1",attrs:{"x-small":"","color":tag.cor,"dark":""}},[_vm._v(_vm._s(tag.titulo)+" ")])}),_c('v-card-subtitle',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(element.titulo)+" ")]),_c('v-card-subtitle',{staticClass:"mt-n6 mb-4",staticStyle:{"line-height":"1.2","margin-bottom":"0","padding-bottom":"0"}},[_vm._v(_vm._s(_vm.cleanAndTruncate(element.descricao, 50)))]),_c('v-chip',{staticClass:"mt-n4 ml-2",attrs:{"color":_vm.dataChipColor(
            element.data_inicial,
            element.data_final,
            element.status
          ),"label":"","text-color":_vm.dataWordColor(
            element.data_inicial,
            element.data_final,
            element.status
          ),"x-small":""}},[(!element.status)?_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("mdi-clock-outline")]):_vm._e(),_vm._v(_vm._s(_vm.calculateStatus( element.data_inicial, element.data_final, element.status ))+" ")],1)],2)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }