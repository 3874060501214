<template>
  <v-dialog class="ma-3" v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title> Selecione seu ramal </v-card-title>
      <v-select
        class="ma-3"
        v-model="selectedExtension"
        :items="extensions"
        label="Ramais"
      >
      </v-select>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small class="text-capitalize" color="red" @click="save"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
      selectedExtension: "",
      extensions: [],
    };
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return "https://painel.4red.com.br:3001";
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide");
      }
    },
  },
  methods: {
    save() {
      localStorage.setItem(
        `${this.$store.state.info.nome}_ramal`,
        this.selectedExtension
      );
      this.$store.state.extension = this.selectedExtension;
      this.dialog = false;
    },
    fetchData() {
      this.axios
        .post(`${this.server}/get_all_ext/`, {
          id: this.info.id_telefonia,
        })
        .then((res) => {
          this.extensions = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    if (localStorage.getItem(`${this.$store.state.info.nome}_ramal`)) {
      this.selectedExtension = localStorage.getItem(
        `${this.$store.state.info.nome}_ramal`
      );
    }
    this.fetchData();
  },
  mounted() {},
};
</script>
