<template>
  <v-dialog width="600px" v-model="dialog">
    <v-card width="600px">
      <v-card-title>Adicionar opção <v-spacer></v-spacer> </v-card-title>
      <v-select
        class="ml-8 mr-8"
        v-model="newOptionType"
        :items="optionType"
        label="Tipo de Opção"
      ></v-select>
      <form
        class="ml-8 mr-8"
        v-if="newOptionType != '' && newOptionType == 'Departamento'"
      >
        <v-text-field v-model="newDigit" label="Digito"></v-text-field>
        <v-textarea
          rows="3"
          v-model="newDescribe"
          label="Descrição"
        ></v-textarea>
        <v-select
          v-model="newDep"
          :items="deps"
          item-text="departamento"
          label="Departamento"
        ></v-select>
        <v-select
          v-model="newMetric"
          :items="metric"
          label="Metrica de encaminhamento de chamados"
        ></v-select>
      </form>
      <form
        class="ml-8 mr-8"
        v-if="newOptionType != '' && newOptionType == 'Integração'"
      >
        <v-select
          v-model="newIntegration"
          :items="integrations"
          item-text="nome"
          label="Selecione a integração"
        ></v-select>
        <v-select
          v-model="newDep"
          :items="deps"
          item-text="departamento"
          label="Departamento"
        ></v-select>
        <v-text-field v-model="newDigit" label="Digito"></v-text-field>
        <v-textarea
          rows="3"
          v-model="newDescribe"
          label="Descrição"
        ></v-textarea>
      </form>
      <form
        class="ml-8 mr-8"
        v-if="newOptionType != '' && newOptionType == 'Voltar'"
      >
        <v-text-field v-model="newDigit" label="Digito"></v-text-field>
        <v-textarea
          rows="3"
          v-model="newDescribe"
          label="Descrição"
        ></v-textarea>
      </form>
      <form
        class="ml-8 mr-8"
        v-if="newOptionType != '' && newOptionType == 'Subnivel'"
      >
        <v-text-field v-model="newDigit" label="Digito"></v-text-field>
        <v-textarea
          rows="3"
          v-model="newDescribe"
          label="Descrição"
        ></v-textarea>
      </form>
      <form
        class="ml-8 mr-8"
        v-if="
          newOptionType != '' &&
          newOptionType == 'Finalizar atendimento e enviar mensagem automatica'
        "
      >
        <v-text-field v-model="newDigit" label="Digito"></v-text-field>
        <v-textarea
          rows="3"
          v-model="newDescribe"
          label="Descrição"
        ></v-textarea>
        <v-textarea
          rows="3"
          v-model="newEndMsg"
          label="Mensagem Final"
        ></v-textarea>
        <v-textarea
          rows="3"
          v-model="newEndTimeout"
          label="Tempo para encerrar atendimento"
        ></v-textarea>
        <v-select
          v-model="newEndDep"
          :items="deps"
          item-text="departamento"
          label="Departamento caso cliente queira continuar o atendimento"
        ></v-select>
      </form>
      <v-card-actions>
        <v-btn
          small
          color="primary"
          @click="dialog = false"
          class="text-capitalize"
          >Voltar</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn small class="text-capitalize" color="red" @click="save"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-bottom-sheet v-model="sheet" persistent>
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
      </v-sheet>
    </v-bottom-sheet>
  </v-dialog>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      newIntegration: "",
      newOptionType: "",
      newDigit: "",
      newDescribe: "",
      newDep: "",
      newMetric: "",
      newEndDep: "",
      newEndMsg: "",
      newEndTimeout: "",
      sheet: false,
      sheetDialog: "",
      dialog: true,
      info: { ...this.data },
      metric: [{ text: "todos" }, { text: "atendente" }],
      integrationsMetrics: ["Encerrar", "Continuar"],
      optionType: [
        { text: "Subnivel" },
        { text: "Departamento" },
        { text: "Finalizar atendimento e enviar mensagem automatica" },
        { text: "Integração" },
        { text: "Voltar" },
      ],
    };
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    deps() {
      return this.$store.state.deps;
    },
    idNumber() {
      return this.$store.state.info.id_numero;
    },
    integrations() {
      return this.$store.state.integrations;
    },
  },
  methods: {
    //TODO validar sobre quando a opção esta num subnivel
    addOptionDep() {
      this.axios
        .post(`${this.server}/ura/add_option_dep/`, {
          describe: this.newDescribe,
          digit: this.newDigit,
          dep: this.newDep,
          metric: this.newMetric,
          ura: this.info.id,
          number: this.idNumber,
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.error) {
              this.sheetDialog = res.data.error;
              this.sheet = true;
              setTimeout(() => {
                this.sheetDialog = "";
                this.sheet = false;
              }, 4000);
            } else {
              this.sheetDialog = res.data.message;
              this.sheet = true;
              setTimeout(() => {
                this.dialog = false;
                this.sheetDialog = "";
                this.sheet = false;
                this.$emit("reloadUraScreen");
              }, 4000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addBack() {
      this.axios
        .post(`${this.server}/ura/add_option_back/`, {
          describe: this.newDescribe,
          digit: this.newDigit,
          ura: this.info.id,
          number: this.idNumber,
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.error) {
              this.sheetDialog = res.data.error;
              this.sheet = true;
              setTimeout(() => {
                this.sheetDialog = "";
                this.sheet = false;
              }, 4000);
            } else {
              this.sheetDialog = res.data.message;
              this.sheet = true;
              setTimeout(() => {
                this.dialog = false;
                this.sheetDialog = "";
                this.sheet = false;
                this.$emit("reloadUraScreen", res.data);
              }, 4000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addSub() {
      this.axios
        .post(`${this.server}/ura/add_option_sub/`, {
          describe: this.newDescribe,
          digit: this.newDigit,
          ura: this.info.id,
          number: this.idNumber,
          group: this.info.id_grupohorario,
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.error) {
              this.sheetDialog = res.data.error;
              this.sheet = true;
              setTimeout(() => {
                this.sheetDialog = "";
                this.sheet = false;
              }, 4000);
            } else {
              this.sheetDialog = res.data.message;
              this.sheet = true;
              setTimeout(() => {
                this.dialog = false;
                this.sheetDialog = "";
                this.sheet = false;
                this.$emit("reloadUraScreen", res.data);
              }, 4000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addEnd() {
      this.axios
        .post(`${this.server}/ura/add_option_end/`, {
          ura: this.info.id,
          dep: this.newEndDep,
          digit: this.newDigit,
          msg: this.newEndMsg,
          timeout: this.newEndTimeout,
          describe: this.newDescribe,
          number: this.idNumber,
        })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            if (res.data.error) {
              this.sheetDialog = res.data.error;
              this.sheet = true;
              setTimeout(() => {
                this.sheetDialog = "";
                this.sheet = false;
              }, 4000);
            } else {
              this.sheetDialog = res.data.message;
              this.sheet = true;
              setTimeout(() => {
                this.dialog = false;
                this.sheetDialog = "";
                this.sheet = false;
                this.$emit("reloadUraScreen", res.data);
              }, 4000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addIntegration() {
      let dep = this.deps.find((dep) => dep.departamento == this.newDep);
      let integration = this.integrations.find(
        (integration) => integration.nome == this.newIntegration
      );
      let obj = {
        ura: this.info.id,
        dep: dep.id,
        digit: this.newDigit,
        describe: this.newDescribe,
        number: this.idNumber,
        metric: "Continuar",
        integration: integration.id,
      };
      this.axios
        .post(`${this.server}/ura/add_option_integration/`, obj)
        .then((res) => {
          if (res.status == 200) {
            if (res.data.error) {
              this.sheetDialog = res.data.error;
              this.sheet = true;
              setTimeout(() => {
                this.sheetDialog = "";
                this.sheet = false;
              }, 4000);
            } else {
              this.sheetDialog = res.data.message;
              this.sheet = true;
              setTimeout(() => {
                this.dialog = false;
                this.sheetDialog = "";
                this.sheet = false;
                this.$emit("reloadUraScreen", res.data);
              }, 4000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    save() {
      switch (this.newOptionType) {
        case "Departamento":
          this.addOptionDep();
          break;
        case "Subnivel":
          this.addSub();
          break;
        case "Finalizar atendimento e enviar mensagem automatica":
          this.addEnd();
          break;
        case "Integração":
          this.addIntegration();
          break;
        case "Voltar":
          this.addBack();
          break;
      }
      /*this.axios
        .post(`${this.server}/ura/edit_initial_conf/`, this.info)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("updateInitialConf", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });*/
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.info = "";
        this.$emit("eventClose");
      }
    },
  },
  created() {
    if (this.info.id_ura) {
      this.info.id = this.info.acao;
    }
  },
};
</script>
