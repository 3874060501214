<template>
  <v-dialog v-model="dialog" max-width="900px">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title>Grupos de Contatos</v-card-title><v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          class="mb-2"
          v-model="search"
          style="max-width: 300px"
          clearable
          flat
          hide-details
          append-outer-icon="mdi-magnify"
          label="Procurar"
          dense
        ></v-text-field>
        <v-btn
          small
          @click="showAdd = true"
          color="#0D47A1"
          style="color: white"
          class="ml-2 text-capitalize"
          >Adicionar Grupo</v-btn
        >
      </v-card-title>
      <v-data-table
        class="ma-3"
        :headers="header"
        :items="filteredItems"
        :footer-props="{ itemsPerPageOptions: [10] }"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.nome }}</td>
            <td>
              <v-btn
                small
                icon
                @click="
                  (showList = true), (itemId = item.id), (groupName = item.nome)
                "
              >
                <v-icon color="primary">mdi-clipboard-list-outline</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                small
                icon
                @click="
                  (showSend = true), (itemId = item.id), (groupName = item.nome)
                "
              >
                <v-icon color="success">mdi-message-fast-outline</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                small
                icon
                @click="
                  (showInfo = true), (itemId = item.id), (groupName = item.nome)
                "
              >
                <v-icon color="purple lighten-2">mdi-file-cabinet</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                small
                icon
                @click="
                  (showHistory = true),
                    (itemId = item.id),
                    (groupName = item.nome)
                "
              >
                <v-icon color="primary lighten-3">mdi-history</v-icon>
              </v-btn>
            </td>
            <td
              v-if="info.permissao == 'admin' || info.permissao == 'supervisor'"
              @click="
                (showTag = true), (groupName = item.nome), (itemId = item.id)
              "
            >
              <v-btn small icon>
                <v-icon color="#C2185BFF">mdi-tag-multiple-outline</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                small
                icon
                @click="(showDelete = true), (itemForDelete = item)"
              >
                <v-icon color="red">mdi-delete-alert-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog v-if="showDelete" v-model="showDelete" width="500px">
      <v-card width="500px">
        <v-card-title
          >Deseja deletar o grupo "{{ itemForDelete.nome }}" ?<v-spacer
          ></v-spacer
        ></v-card-title>
        <v-card-actions>
          <v-btn small class="text-capitalize" @click="deleteGroup" color="red"
            >Deletar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            small
            class="text-capitalize"
            @click="showDelete = false"
            color="primary"
            >Voltar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <groupAdd v-if="showAdd" @closeAdd="showAdd = false" @reload="data" />
    <groupContactList
      v-if="showList"
      @closeList="showList = false"
      @reload="data"
      :id="itemId"
      :groupName="groupName"
    />
    <historyScreen
      v-if="showHistory"
      @closeHistory="showHistory = false"
      :id="itemId"
      :groupName="groupName"
    />
    <infoScreen
      v-if="showInfo"
      @closeInfo="showInfo = false"
      :id="itemId"
      :groupName="groupName"
    />
    <sendMessage
      v-if="showSend"
      @closeSend="showSend = false"
      :id="itemId"
      :groupName="groupName"
    />
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
      </v-sheet>
    </v-bottom-sheet>
    <tagScreen
      v-if="showTag"
      @hide="showTag = false"
      :id="itemId"
      :depName="groupName"
    />
  </v-dialog>
</template>
<script>
import tagScreen from "./tagScreen";
import groupAdd from "./groupAdd.vue";
import groupContactList from "./groupContactList.vue";
import historyScreen from "./historyScreen.vue";
import infoScreen from "./infoScreen.vue";
import sendMessage from "./sendMessage.vue";

export default {
  components: {
    tagScreen,
    groupAdd,
    groupContactList,
    historyScreen,
    infoScreen,
    sendMessage,
  },
  data() {
    return {
      showTag: false,
      search: "",
      showSend: false,
      showInfo: false,
      showDelete: false,
      itemForDelete: "",
      groupName: "",
      itemId: "",
      showAdd: false,
      showList: false,
      showHistory: false,
      rgag: "/groups/get_all_groups/",
      rd: "/groups/delete/",
      sheet: false,
      sheetDialog: "",
      dialog: false,
      header: [
        { text: "Grupo", value: "data0" },
        { text: "Lista contatos", value: "action" },
        { text: "Enviar Mensagem", value: "action" },
        { text: "Informações", value: "action" },
        { text: "Chamados", value: "action" },
        { text: "Etiquetas", value: "" },
        { text: "Remover", value: "action" },
      ],
      items: [
        /*{
                data0:"Grupo A",
                check:false,

            }*/
      ],
    };
  },
  methods: {
    deleteGroup() {
      this.axios
        .post(`${this.server}${this.rd}`, {
          i: this.itemForDelete.id,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              this.sheetDialog = res.data.message;
              this.sheet = true;
              setTimeout(() => {
                this.sheet = false;
                this.data();
                this.showDelete = false;
              }, 4000);
              break;
            case "201":
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    data() {
      this.axios
        .post(`${this.server}${this.rgag}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              console.log(res.data.data);
              this.items = res.data.data;
              break;
            case "201":
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    action(item) {
      console.log(item);
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        let nome = item.nome.toLowerCase();
        return nome.indexOf(this.search) > -1;
      });
    },
    axios() {
      return this.$store.state.axios;
    },
    screen() {
      return this.$store.state.screens.group;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    screen(newValue) {
      if (newValue) {
        this.dialog = true;
        this.data();
      } else {
        this.dialog = false;
      }
    },
    dialog(newValue) {
      if (!newValue) {
        this.$store.state.screens.group = false;
      }
    },
  },
};
</script>
