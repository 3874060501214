<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title
        >Assistente virtual
        <v-spacer></v-spacer>
        <v-btn
          small
          class="text-capitalize ml-5"
          color="teal lighten-3"
          @click="showInitial = true"
          >Configurações Iniciais</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          small
          class="ml-2 text-capitalize"
          @click="(showAdd = true), (optionEdit = ura)"
          color="red lighten-3"
          >Adicionar opção</v-btn
        >
      </v-card-title>
      <v-card class="ml-10 mt-5 mr-10">
        <v-card-subtitle
          class="mt-n3"
          v-for="(option, index) in options"
          :key="index"
        >
          <v-icon small>mdi-arrow-right-bottom</v-icon>
          {{ option.opcao }} - {{ option.descricao }}
          <v-btn
            x-small
            class="ml-2 text-capitalize"
            @click="(showEdit = true), (optionEdit = option)"
            color="blue lighten-3"
            >Editar</v-btn
          >
          <v-btn
            x-small
            class="ml-2 text-capitalize"
            @click="(showAdd = true), (optionEdit = option)"
            color="red lighten-3"
            v-if="option.aplicacao == 'ura'"
            >Adicionar opção</v-btn
          >
          <newUraSub
            :key="upgrade"
            class="mt-1 ml-5"
            @reloadUraScreen="reloadUraScreen"
            :id="option.acao"
            v-if="option.aplicacao == 'ura'"
          />
        </v-card-subtitle>
      </v-card>
    </v-card>
    <newUraInitialConf
      v-if="showInitial"
      @updateInitialConf="updateInitialConf"
      @eventClose="showInitial = false"
      :data="this.ura"
    />
    <newUraEdit
      v-if="showEdit"
      @reloadUraScreen="reloadUraScreen"
      @eventClose="showEdit = false"
      :data="this.optionEdit"
    />
    <newUraAdd
      v-if="showAdd"
      @reloadUraScreen="reloadUraScreen"
      @eventClose="showAdd = false"
      :data="this.optionEdit"
    />
  </v-dialog>
</template>
<script>
import newUraSub from "./newUraSub.vue";
import newUraInitialConf from "./newUraInitialConf.vue";
import newUraEdit from "./newUraEdit.vue";
import newUraAdd from "./newUraAdd.vue";

export default {
  components: { newUraSub, newUraInitialConf, newUraEdit, newUraAdd },
  data() {
    return {
      upgrade: 0,
      optionEdit: "",
      showInitial: false,
      showEdit: false,
      showAdd: false,
      dialog: false,
      ura: "",
      options: "",
    };
  },
  methods: {
    reloadUraScreen() {
      this.dialog = false;
      setTimeout(() => {
        this.dialog = true;
        this.upgrade++;
      }, 200);
    },
    updateInitialConf(payload) {
      this.ura = payload;
      this.showInitial = false;
    },
    getIntegrations() {
      this.axios
        .get(
          `${this.server}/integrations/get_integrations/${this.info.usuario}/${this.info.id_numero}`
        )
        .then((res) => {
          this.$store.state.integrations = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    screen() {
      return this.$store.state.screens.ura;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    screen(newValue) {
      if (newValue) {
        this.dialog = true;
        this.getIntegrations();
      } else {
        this.dialog = false;
      }
    },
    dialog(newValue) {
      if (!newValue) {
        this.$store.state.screens.ura = false;
      } else {
        this.axios
          .get(
            `${this.server}/ura/get_first_ura/${this.info.usuario}/${this.info.id_numero}`
          )
          .then((res) => {
            this.ura = res.data.ura;
            this.options = res.data.options;
            this.options.forEach((opt) => {
              if (opt.aplicacao == "ura") {
                opt.id_grupohorario = res.data.ura.id_grupohorario;
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  created() {
    this.axios
      .post(`${this.server}/ura/get_deps/`, { id: this.info.id_numero })
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$store.state.deps = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
