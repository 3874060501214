<template>
  <v-container class="ml-1 mr-1 mt-n3" fluid>
    <v-row align="center" justify="start" :key="kanbansKey">
      <v-spacer></v-spacer>
      <v-btn
        v-if="selectKanban != ''"
        class="mt-n2"
        icon
        @click="(showSearch = true), focusSearch()"
      >
        <v-icon v-if="!showSearch" color="#1E88E5">mdi-magnify</v-icon>
      </v-btn>
      <v-text-field
        v-if="showSearch"
        class="mt-n4"
        v-model="search"
        clearable
        hide-details
        append-icon="mdi-magnify"
        label="Busca"
        id="search"
      ></v-text-field>
      <v-btn
        v-if="selectKanban != ''"
        class="mt-n2"
        icon
        @click="(showCalendar = true), clearSearch()"
      >
        <v-icon color="#1E88E5">mdi-calendar-month </v-icon>
      </v-btn>
      <v-col class="d-flex" cols="auto">
        <v-select
          label="Quadros"
          :items="kanbans"
          item-text="titulo"
          item-value="id"
          style="max-width: 300px"
          v-model="selectKanban"
          :key="kanbansKey"
        ></v-select>
        <v-col class="d-flex" cols="auto">
          <v-menu :key="kanbansKey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="(showAddKanban = true), clearSearch()">
                <v-list-item-title
                  >Adicionar Novo Quadro</v-list-item-title
                > </v-list-item
              ><v-list-item
                @click="deleteKanban()"
                v-if="
                  selectKanban != '' &&
                  (permission == 'Dono' || permission == 'Escrita')
                "
              >
                <v-list-item-title>Apagar Quadro</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  selectKanban != '' &&
                  (permission == 'Dono' || permission == 'Escrita')
                "
                @click="(showAutomation = true), clearSearch()"
              >
                <v-list-item-title>Automação</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  selectKanban != '' &&
                  (permission == 'Dono' || permission == 'Escrita')
                "
                @click="(showTags = true), clearSearch()"
              >
                <v-list-item-title>Etiquetas</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  selectKanban != '' &&
                  (permission == 'Dono' || permission == 'Escrita')
                "
                @click="(showArchivedCards = true), clearSearch()"
              >
                <v-list-item-title>Listar Cartões Arquivados</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="(showPermission = true), clearSearch()"
                v-if="
                  selectKanban != '' &&
                  (permission == 'Dono' || permission == 'Escrita')
                "
              >
                <v-list-item-title>Permissões</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-col>
    </v-row>
    <kanbanColumn
      class="mt-n8"
      :key="selectKanban"
      @showAddCard="showAddCard"
      @showAddColumn="showAddColumn"
      v-if="selectKanban != ''"
      :kanbanId="selectKanban"
      :permission="permission"
      :search="search"
    />
    <kanbanCardAdd
      v-if="dialogAdd"
      :idColumn="columnIdAdd"
      :idKanban="selectKanban"
      @closeAdd="dialogAdd = false"
    ></kanbanCardAdd>
    <kanbanColumnAddVue
      v-if="dialogAddColumn"
      :idKanban="selectKanban"
      :columnOrder="columnOrder"
      :position="columnIdAddPosition"
      :idColumn="columnIdAdd"
      @closeAddColumn="dialogAddColumn = false"
    ></kanbanColumnAddVue>
    <kanbanCardDetails
      v-if="showCardDetail"
      @closeCardDetail="showCardDetail = false"
      :selectCard="selectCard"
      :kanbanId="selectKanban"
      :permission="permission"
    />
    <kanbanPermissionVue
      v-if="showPermission"
      @closePermission="showPermission = false"
      :kanbanId="selectKanban"
    />
    <kanbanAdd v-if="showAddKanban" @closeAddKanban="showAddKanban = false" />
    <kanbanAutomationScreen
      @close="showAutomation = false"
      v-if="showAutomation"
      :selectCard="selectCard"
      :kanbanId="selectKanban"
      :permission="permission"
    />
    <kanbanShowArchivedCards
      @close="showArchivedCards = false"
      v-if="showArchivedCards"
      :kanbanId="selectKanban"
      :permission="permission"
    />
    <kanbanTagScreen
      @close="showTags = false"
      v-if="showTags"
      :kanbanId="selectKanban"
      :permission="permission"
    />
    <kanbanCalendar
      v-if="showCalendar"
      @close="showCalendar = false"
      :kanbanId="selectKanban"
      :permission="permission"
    />
  </v-container>
</template>

<script>
import kanbanAdd from "./kanbanAdd.vue";
import kanbanCardDetails from "./kanbanCardDetails.vue";
import kanbanColumn from "./kanbanColumn";
import kanbanCardAdd from "./kanbanCardAdd.vue";
import kanbanColumnAddVue from "./kanbanColumnAdd.vue";
import kanbanPermissionVue from "./kanbanPermission.vue";
import kanbanAutomationScreen from "./automation/kanbanAutomationScreen.vue";
import kanbanShowArchivedCards from "./KanbanShowArchivedCards.vue";
import kanbanTagScreen from "./kanbanTag/kanbanTagScreen.vue";
import kanbanCalendar from "./calendar/kanbanCalendar.vue";
export default {
  components: {
    kanbanAdd,
    kanbanPermissionVue,
    kanbanColumn,
    kanbanCardAdd,
    kanbanColumnAddVue,
    kanbanCardDetails,
    kanbanAutomationScreen,
    kanbanShowArchivedCards,
    kanbanTagScreen,
    kanbanCalendar,
  },
  data() {
    return {
      showPermission: false,
      showRemoveKanban: false,
      showAddKanban: false,
      selectCard: "",
      showCardDetail: false,
      columnIdAdd: "",
      dialogAdd: false,
      dialogAddColumn: false,
      kanbans: [],
      selectCol: [],
      r: "/kanban/getKanban/",
      permission: "",
      showAutomation: false,
      showArchivedCards: false,
      showTags: false,
      showSearch: false,
      search: "",
      selectKanban: "",
      showCalendar: false,
    };
  },
  created() {
    this.listenUpdate();

    this.fetchData();
  },
  mounted() {
    this.$root.$on("showCardDetail", (card) => {
      console.warn(card);
      this.selectCard = card;
      console.log("ShowCArdDetail: ", this.showCardDetail);
      this.showCardDetail = true;
    });
    this.$root.$on("updateKanban", (data) => {
      console.warn(data);
      this.fetchData();
      setTimeout(() => {
        this.selectKanban = data;
        this.showAddKanban = false;
        this.$root.$emit("columnAdded", this.data);
      }, 500);
    });
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
    kanbansKey() {
      return this.kanbans.toString();
    },
  },
  methods: {
    saveSelection(selection) {
      console.log("saveSelection: ", selection);
      localStorage.setItem("kanbanLastSelection" + this.info.id, selection);
    },
    focusSearch() {
      setTimeout(() => {
        document.getElementById("search").click();
      }, 100);
    },
    listenUpdate() {
      this.socket.on("updatePermission", () => {
        this.fetchData();
      });
    },
    deleteKanban() {
      if (
        confirm(
          "Você tem certeza que deseja apagar este Quadro? Isto é irreversível."
        )
      ) {
        this.axios
          .post(`${this.server}/kanban/deleteKanban`, {
            id: this.selectKanban,
            id_cliente: this.info.id_cliente,
          })
          .then(() => {
            alert("Quadro apagada com sucesso!");
            this.selectKanban = "";
            setTimeout(() => {
              this.kanbans = [];
              this.fetchData();
            }, 500);
          })
          .catch((error) => {
            console.error(
              "Erro ao apagar o Quadro:",
              error.response.data.error
            );
            alert(error.response.data.error);
          });
      }
    },
    showAddCard(id) {
      this.columnIdAdd = id;
      this.dialogAdd = true;
      this.clearSearch();
    },
    showAddColumn(data) {
      console.log(data);
      this.columnOrder = data.order;
      this.columnIdAddPosition = data.position;
      this.columnIdAdd = data.id;
      this.dialogAddColumn = true;
      this.clearSearch();
    },
    fetchData() {
      this.axios
        .post(`${this.server}${this.r}`, {
          n: this.info.id_numero,
          i: this.info.id,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              console.log(res.data);
              if (res.data.error) {
                this.kanbans = [];
                this.selectKanban = "";
              } else {
                if (this.kanbans.toString() != res.data.kanban.toString()) {
                  console.log(res.data.kanban);
                  this.kanbans = [...res.data.kanban];
                  const lastSelection = localStorage.getItem(
                    "kanbanLastSelection" + this.info.id
                  );

                  // Verifica se a seleção está presente na nova lista de kanbans
                  if (
                    lastSelection &&
                    this.kanbans.some((k) => k.id === parseInt(lastSelection))
                  ) {
                    this.selectKanban = parseInt(lastSelection);
                  } else {
                    this.selectKanban = "";
                  }
                }
              }
              break;
            case 201:
              break;
            case 202:
              break;
            case 203:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearSearch() {
      this.showSearch = false;
      this.search = "";
    },
  },
  watch: {
    search(newValue) {
      console.log(newValue);
      this.$store.state.searchKanbanCards = newValue;
    },
    selectKanban(newValue) {
      if (newValue != "") {
        let kanban = this.kanbans.find((item) => item.id == newValue);
        if (kanban) {
          this.permission = kanban.tipo;
          this.saveSelection(newValue);
          this.clearSearch();
        }
      }
    },
  },
};
</script>

<style>
.kanban-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
l
