<template>
  <v-dialog v-model="dialog" v-if="dialog" max-width="820px">
    <v-card width="805px">
      <v-toolbar dense dark color="primary">
        <v-card-title style="font-size: 16px; font-weight: 400"
          >Histórico</v-card-title
        ><v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row class="ml-5 mt-5">
        <v-col cols="1">
          <v-avatar size="72px">
            <img
              alt="Avatar"
              v-if="contact.img != 'undefined' && contact.img != 'null'"
              :src="contact.img"
            />
            <v-icon alt="Avatar" v-else>mdi-account</v-icon>
          </v-avatar>
        </v-col>
        <v-col cols="4" class="mt-n5" style="min-width: 220px">
          <v-card-title class="">{{ contact.contato_4red }}</v-card-title>
          <v-card-subtitle v-if="!edit"
            >{{ contact.contato_zap }} -
            {{ contact.numero.split("@")[0].substring(2) }}</v-card-subtitle
          >
          <v-text-field
            id="nameEdit"
            v-model="newName"
            clearable
            label="Nome de cadastro"
            :error="btnBlink"
            class="ml-6 mt-n3"
            v-else
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="ml-6">
          <v-select
            label="Grupo"
            :disabled="!edit"
            outlined
            v-model="selectGroup"
            item-text="nome"
            :items="groups"
            dense
          ></v-select>
        </v-col>
        <v-col cols="3" class="ml-6">
          <v-select
            label="Carteira"
            :disabled="!edit"
            outlined
            v-model="selectWallet"
            item-text="nome"
            :items="attendants"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="mt-n5">
        <v-spacer></v-spacer>
        <v-btn
          small
          v-show="
            !edit &&
            !btnOpenService &&
            (info.permissao == 'admin' || info.permissao == 'supervisor')
          "
          style="color: white"
          color="red lighten-1"
          class="mr-4 text-capitalize text-white"
          @click="finish"
          >Encerrar atendimento aberto</v-btn
        >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-show="!edit && btnOpenService"
              v-bind="attrs"
              v-on="on"
              style="color: white"
              color="red lighten-1"
              class="mr-4 text-capitalize text-white"
              >Novo Atendimento</v-btn
            >
          </template>
          <v-list nudge-top="50" dense>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title class=""
                >Escolha o departamento</v-list-item-title
              >
              <!-- Título personalizado com classe headline para torná-lo maior ou mais destacado -->
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link v-for="dep in deps" :key="dep.departamento">
              <v-list-item-content @click="openService(contact, dep.id)">{{
                dep.departamento
              }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          small
          style="color: white"
          :color="btnEditColor"
          class="mr-10 text-capitalize text-white"
          @click="editSave()"
          >{{ btnEditWords }}</v-btn
        >
        <v-btn
          small
          style="color: white"
          color="red"
          class="mr-10 text-capitalize text-white"
          @click="hiddenContact()"
          v-if="info.permissao == 'admin' || info.permissao == 'supervisor'"
          >{{ btnHiddenWords }}</v-btn
        >
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-text-field
          style="max-width: 200px"
          v-model="search"
          label="Procurar"
          class="mr-3 mr-5"
        ></v-text-field>
      </v-row>
      <v-data-table
        class="ma-3 overflow-y-auto mt-n5"
        style="max-height: 50vh"
        v-if="showHistory"
        :headers="header"
        :items="filteredItems"
        :footer-props="{ itemsPerPageOptions: [10] }"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.nome }}</td>
            <td>{{ formatDate(item.horario) }}</td>
            <td>{{ formatDate(item.horario_ultima_mensagem) }}</td>
            <td>{{ item.status }}</td>
            <td>
              <v-btn small icon @click="getConversation(item)">
                <v-icon color="red">mdi-chat-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-container v-else class="text-center">
        <v-row align="center" justify="center">
          <v-col>
            <v-progress-circular
              indeterminate
              color="purple"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <chatHistory
      v-if="showChat"
      @closeChat="showChat = false"
      :conversation="conversation"
      :service="conversation"
    />
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
        <v-row align="center" justify="center">
          <v-col>
            <v-progress-circular
              indeterminate
              color="purple"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
  </v-dialog>
</template>
<script>
import chatHistory from "../chatHistory.vue";
export default {
  props: ["contactProp"],
  components: { chatHistory },
  data() {
    return {
      openedService: "",
      btnOpenService: false,
      contact: this.contactProp,
      sheet: false,
      sheetDialog: "Atualizando contato, aguarde.",
      newName: "",
      btnBlink: true,
      btnEditColor: "blue lighten-1",
      btnEditWords: "Editar",
      btnHiddenWords: "Ocultar",
      edit: false,
      showChat: false,
      dialog: true,
      contacts: [],
      rgag: "/groups/get_all_groups/",
      rgaa: "/attendant/get_all/",
      rhc: "/history/get_for_contact",
      routeC: "/history/get_conversation",
      rcv: "/contact/check_open_service",
      routeE: "/contact/edit",
      routeO: "/contact/hidden",
      routeD: "/contact/unhidden",
      groups: [],
      selectGroup: "",
      attendants: [],
      selectWallet: "",
      search: "",
      header: [
        { text: "Atendente", value: "nome" },
        { text: "Inicio do atendimento", value: "horario" },
        { text: "Horario ultima mensagem", value: "horario_ultima_mensagem" },
        { text: "Status", value: "status" },
        { text: "Chamado", value: "action" },
      ],
      showHistory: false,
      history: [],
    };
  },
  created() {
    this.data();
    console.warn(this.contact);
    if (!this.contact.disponivel) {
      this.btnHiddenWords = "Disponibilizar contato";
    }
  },
  methods: {
    finish() {
      this.$store.state.interactions.finish = {
        clientId: this.$store.state.info.id_cliente,
        serviceId: this.openedService.id,
        attendantId: this.$store.state.info.id,
        attendantName: this.$store.state.info.nome,
        destiny: this.contact.numero,
        depId: this.openedService.id_departamento,
        origin: this.$store.state.info.numero,
        client: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
        silence: true,
      };
      setTimeout(() => {
        this.getHistory();
        this.verifyOpenService();
      }, 1000);
    },
    hiddenContact() {
      if (this.contact.disponivel) {
        this.axios
          .post(`${this.server}${this.routeO}`, {
            i: this.contact.id,
          })
          .then(() => {
            this.$emit("update");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.axios
          .post(`${this.server}${this.routeD}`, {
            i: this.contact.id,
          })
          .then(() => {
            this.$emit("update");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    editSave() {
      if (!this.edit) {
        this.newName = this.contact.contato_zap;
        this.edit = true;
        this.btnEditColor = "red lighten-1";
        this.btnEditWords = "Salvar";
        setTimeout(() => {
          document.getElementById("nameEdit").focus();
          setTimeout(() => {
            this.btnBlink = false;
          }, 800);
        }, 200);
      } else {
        this.btnBlink = true;
        this.edit = false;
        this.btnEditColor = "blue lighten-1";
        this.btnEditWords = "Editar";
        this.selectGroup =
          this.groups.find((group) => group.nome == this.selectGroup)?.id || 0;
        this.selectWallet =
          this.attendants.find((wallet) => wallet.nome == this.selectWallet)
            ?.id || 0;
        this.newName = this.newName === "" ? " " : this.newName;
        this.sheet = true;
        this.axios
          .post(`${this.server}${this.routeE}`, {
            i: this.contact.id,
            g: this.selectGroup,
            w: this.selectWallet,
            n: this.newName,
          })
          .then((res) => {
            console.log(res);
            if (res.data.error) {
              this.sheetDialog = res.data.error;
            } else if (res.data.message) {
              this.sheetDialog = res.data.message;
              this.contact.contato_zap = res.data.details[0].contato_zap;
              this.contact.id_carteira = res.data.details[0].id_carteira;
              this.contact.id_grupo = res.data.details[0].id_grupo;
              let group = this.groups.find(
                (group) => group.id == res.data.details[0].id_grupo
              );
              if (group) {
                this.contact.grupo = group.nome;
              }
              this.data();
            }
            setTimeout(() => {
              this.sheet = false;
              this.sheetDialog = "Atualizando contato, aguarde.";
            }, 3000);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    openService(contact, id_dep) {
      if (contact.id_grupo == null || contact.id_grupo == "") {
        contact.id_grupo = 0;
      }
      this.$store.state.interactions.openService = {
        id_grupo: contact.id_grupo,
        id_departamento: id_dep,
        id_atendente: this.$store.state.info.id,
        nome_atendente: this.$store.state.info.nome,
        numero_destino: contact.numero,
        numero_origem: this.$store.state.info.numero,
        cliente: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
      };
      this.$emit("hideForOpen");
    },
    verifyOpenService() {
      this.axios
        .post(`${this.server}${this.rcv}`, {
          i: this.contact.id,
        })
        .then((res) => {
          console.warn(res.data.service);
          if (res.data.message && res.status == 200) {
            console.log(this.edit, this.btnOpenService);
            this.btnOpenService = true;
          } else {
            this.openedService = res.data.service[0];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getConversation(item) {
      this.axios
        .post(`${this.server}${this.routeC}`, {
          i: item.id,
        })
        .then((res) => {
          this.service = item;
          this.conversation = res.data.data;
          this.showChat = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getHistory() {
      this.axios
        .post(`${this.server}${this.rhc}`, {
          n: this.info.id_numero,
          c: this.contact.id,
          a: this.info.id,
        })
        .then((res) => {
          console.log(res.data);
          switch (res.status) {
            case 200:
              this.history = res.data.services;
              this.showHistory = true;
              break;
            case "201":
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAttendants() {
      let attendant;
      this.axios
        .post(`${this.server}${this.rgaa}`, {
          n: this.info.id_numero,
        })
        .then((res) => {
          console.log(res.data);
          switch (res.status) {
            case 200:
              this.attendants = res.data.attendants;
              this.attendants.unshift({ nome: "Nenhum", id: 0 });
              attendant = this.attendants.find(
                (attendant) => attendant.id == this.contact.id_carteira
              );
              if (attendant) {
                this.selectWallet = attendant.nome;
              }
              break;
            case "201":
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGroups() {
      this.axios
        .post(`${this.server}${this.rgag}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              this.groups = res.data.data;
              this.groups.unshift({ nome: "Nenhum", id: 0 });
              this.selectGroup = this.contact.grupo;
              break;
            case "201":
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    data() {
      this.getGroups();
      this.getAttendants();
      this.verifyOpenService();
      this.getHistory();
      console.log(this.info);
    },
    action(item) {
      console.log(item);
    },
    formatDate(date) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return new Date(date).toLocaleString("pt-BR", options);
    },
  },
  computed: {
    deps() {
      return this.$store.state.info.deps;
    },
    filteredItems() {
      const search = this.search.toLowerCase();
      return this.history.filter((item) => {
        let horario = this.formatDate(item.horario);
        let atendente = item.nome.toLowerCase();
        let status = item.status.toLowerCase();
        return (
          horario.indexOf(search) > -1 ||
          atendente.indexOf(search) > -1 ||
          status.indexOf(search) > -1
        );
      });
    },
    axios() {
      return this.$store.state.axios;
    },
    screen() {
      return this.$store.state.screens.group;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    screen(newValue) {
      if (newValue) {
        this.dialog = true;
        this.data();
      } else {
        this.dialog = false;
      }
    },
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide");
      }
    },
  },
};
</script>

<style scoped>
.clickable-item {
  cursor: pointer; /* Altera o cursor para o formato de clique */
}
.pulse-highlight {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    background-color: transparent; /* Cor inicial */
  }
  50% {
    background-color: #ff5722; /* Cor intermediária */
  }
  100% {
    background-color: transparent; /* Cor final */
  }
}
</style>
