<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title>Automações</v-card-title><v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <form>
          <v-row justify="end" class="mt-3">
            <v-col class="justify-center" cols="auto"
              ><v-text-field
                style="max-width: 200px"
                label="Busca"
                append-icon="mdi-magnify"
                dense
                solo
                v-model="search"
              ></v-text-field
            ></v-col>
          </v-row>
        </form>
        <div style="max-height: 58vh" class="overflow-y-auto">
          <v-card
            @click="(showEdit = true), (selectionAutomation = automation)"
            class="ma-2"
            v-for="automation in filteredList"
            :key="automation.id"
          >
            <v-card-title
              >{{ automation.nome }}<v-spacer></v-spacer>
              <v-btn icon>
                <v-switch dense disabled v-model="automation.ativo"></v-switch>
              </v-btn>
              <v-btn
                icon
                @click.stop="
                  (showDelete = true), (selectionAutomation = automation)
                "
              >
                <v-icon color="red">mdi-delete-outline</v-icon>
              </v-btn> </v-card-title
            ><v-card-subtitle class="custom-subtitle"
              >Criado por {{ automation.nome_atendente }}</v-card-subtitle
            >
            <v-card-text>
              <v-row>
                <v-col class="custom-text">
                  <v-icon color="blue darken-2"> mdi-arrow-right-thick </v-icon>
                  Evento: {{ automation.tipo_evento }} <br />
                  <v-chip class="ml-7" label small color="grey lighten-3">
                    Coluna de origem:
                    {{ automation.titulo_coluna_origem }}</v-chip
                  >
                </v-col>
                <v-col class="custom-text">
                  <v-icon
                    color="blue darken-2"
                    v-if="automation.tipo_acao == 'Criar cartão'"
                  >
                    mdi-credit-card-plus-outline
                  </v-icon>

                  <v-icon
                    color="blue darken-2"
                    v-else-if="automation.tipo_acao == 'Enviar mensagem'"
                  >
                    mdi-message-text-fast-outline
                  </v-icon>
                  Ação: {{ automation.tipo_acao }}<br />
                  <v-chip
                    class="ml-7"
                    color="grey lighten-3"
                    label
                    small
                    v-if="automation.tipo_acao == 'Criar cartão'"
                  >
                    Quadro destino:
                    {{ automation.titulo_kanban_destino }}</v-chip
                  >
                  <br />
                  <v-chip
                    class="ml-7"
                    color="grey lighten-3"
                    label
                    small
                    v-if="automation.tipo_acao == 'Criar cartão'"
                  >
                    Coluna destino:
                    {{ automation.titulo_coluna_destino }}</v-chip
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="#0D47A1"
          style="color: white"
          class="text-capitalize"
          small
          @click="showAdd = true"
          >Nova Automação</v-btn
        >
      </v-card-actions>
    </v-card>
    <kanbanAutomationAdd
      v-if="showAdd"
      @close="showAdd = false"
      :kanbanId="kanbanId"
      :permission="permission"
    />
    <kanbanAutomationEdit
      v-if="showEdit"
      @close="showEdit = false"
      :selectionAutomation="selectionAutomation"
      :kanbanId="kanbanId"
      :permission="permission"
    />
    <v-dialog v-model="showDelete" persistent height="300px" width="500px">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-card-title style="font-size: 16px; font-weight: 400"
            >Tem certeza que deseja excluir essa automação?</v-card-title
          ><v-spacer></v-spacer>
          <v-btn icon dark @click="showDelete = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="text-capitalize"
            @click="deleteAutomation"
            color="red"
            style="color: white"
            >Excluir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import kanbanAutomationAdd from "./kanbanAutomationAdd.vue";
import kanbanAutomationEdit from "./kanbanAutomationEdit.vue";
export default {
  props: ["selectCard", "kanbanId", "permission"],
  components: { kanbanAutomationAdd, kanbanAutomationEdit },
  data() {
    return {
      showDelete: false,
      showEdit: false,
      selectionAutomation: "",
      dialog: true,
      showAdd: false,
      search: "",
      automations: [],
    };
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("close");
      }
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
    filteredList() {
      return this.automations.filter((automation) => {
        if (this.search.length < 1) {
          return this.automations;
        } else {
          return (
            automation.nome
              .toLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) > -1
          );
        }
      });
    },
  },
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    listenAddAutomation() {
      this.socket.on("addAutomation", async (data) => {
        let decryp = await this.decrypt(data);
        var decrypData = await this.decrypt(decryp.data);
        console.log("Decryp: ", decrypData);
        if (decrypData.data.id_kanban == this.kanbanId) {
          setTimeout(() => {
            this.fecthData();
          }, 1000);
        }
      });
    },
    async fecthData() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
      };
      console.log(obj);

      let objCrypt = await this.cryptoMsg(obj);
      console.log(objCrypt);

      this.axios
        .post(`${this.server}/kanban/getAutomationsKanban`, { data: objCrypt })
        .then(async (res) => {
          if (res.status == 200) {
            let decrypt = await this.decrypt(res.data.data);
            this.automations = decrypt;
            console.log("automations:", this.automations);
          } else {
            this.automations = [];
          }
        });
    },
    async deleteAutomation() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
        id_automation: this.selectionAutomation.id,
      };
      console.log(obj);
      let objCrypt = await this.cryptoMsg(obj);
      console.log(objCrypt);

      this.axios
        .post(`${this.server}/kanban/deleteAutomation`, { data: objCrypt })
        .then(async (res) => {
          if (res.status == 200) {
            this.showDelete = false;
          }
        });
    },
  },
  created() {
    this.listenAddAutomation();
    this.fecthData();
  },
};
</script>
<style>
.custom-text {
  color: #333; /* Ajuste a cor conforme necessário */
}
.custom-subtitle {
  font-size: 12px; /* Ajuste o tamanho conforme necessário */
}
</style>
