<template>
  <v-card v-model="model">
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="2">
        <v-text-field
          v-model="search"
          label="Procurar"
          class="mx-3"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2">
        <v-select
          v-model="selectedAttendant"
          :items="attendantItems"
          label="Atendente"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2">
        <v-select
          v-model="selectedDepartment"
          :items="departmentItems"
          label="Departamento"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2">
        <v-select
          v-model="selectedGroup"
          :items="groupItems"
          label="Grupo"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2">
        <v-select
          class="mr-3"
          v-model="selectedTag"
          :items="tagItems"
          label="Selecione uma Tag"
        ></v-select>
      </v-col>
    </v-row>
    <v-data-table
      v-if="extract.length > 0"
      dense
      :headers="headers"
      :items="filteredItems"
      class="ma-3"
    >
      <template v-slot:[`item.contato_4red`]="{ item }">
        <div style="display: flex; align-items: center">
          <v-avatar size="36px" style="margin-right: 10px">
            <img :src="item.img" alt="contact" />
          </v-avatar>
          <div>
            <div>{{ truncate(item.contato_4red) }}</div>
            <div>{{ truncate(item.contato_zap) }}</div>
          </div>
        </div>
      </template>
      <template v-slot:[`item.tags`]="{ item }">
        <v-chip-group>
          <v-chip
            small
            v-for="tag in item.tags"
            :key="tag.nome"
            :color="tag.cor"
          >
          </v-chip>
        </v-chip-group>
      </template>
      <template v-slot:[`item.nome_grupo`]="{ item }">
        <span>{{ truncate(item.nome_grupo) }}</span>
      </template>
      <template v-slot:[`item.chamado`]="{ item }">
        <v-tooltip left :disabled="!hasTabulacoes(item)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              v-bind="attrs"
              v-on="on"
              @click="getConversation(item)"
            >
              <v-icon color="red">mdi-chat-outline</v-icon>
            </v-btn>
          </template>
          <div v-html="formatTabulacaoTooltip(item.tabulacoes)"></div>
        </v-tooltip>
      </template>
    </v-data-table>
    <chatHistory
      v-if="showChat"
      @closeChat="showChat = false"
      :conversation="conversation"
      :service="conversation"
    />
  </v-card>
</template>

<script>
import chatHistory from "../../chatHistory.vue";

export default {
  props: ["date"],
  components: { chatHistory },
  data: () => ({
    showChat: false,
    conversation: "",
    routeC: "/history/get_conversation",
    selectedTag: "Todos",
    selectedDepartment: "Todos",
    selectedGroup: "Todos",
    selectedAttendant: "Todos",
    search: "",
    extract: [],
    headers: [
      { text: "Protocolo", value: "id_atendimento" },

      { text: "Nome", value: "contato_4red" },
      { text: "Número", value: "numero_formatado" },
      { text: "Horário Inicial", value: "horario_inicial" },
      { text: "Atendente", value: "nome_atendente" },
      { text: "Departamento", value: "nome_departamento" },

      { text: "Status", value: "status" },
      //{ text: 'Última Mensagem', value: 'horario_ultima_mensagem' },
      { text: "Grupo", value: "nome_grupo" },
      { text: "Tags", value: "tags" },
      { text: "Chamado", value: "chamado" },

      // Adicione mais cabeçalhos se necessário
    ],
    headline_ready: false,
    model: 0,
    rd: "/report/getAtendimentosDetails",
  }),
  created() {
    this.fetchData();
  },
  computed: {
    tagItems() {
      const allTags = new Set();
      this.extract.forEach((item) => {
        if (item.tags && item.tags.length > 0) {
          item.tags.forEach((tag) => allTags.add(tag.nome));
        }
      });
      return ["Todos", ...Array.from(allTags).sort()];
    },
    departmentItems() {
      const departments = this.extract.map((item) => item.nome_departamento);
      const uniqueDepartments = Array.from(new Set(departments)).sort();
      return ["Todos", ...uniqueDepartments];
    },
    groupItems() {
      const groups = this.extract.map((item) => item.nome_grupo || "Sem Grupo");
      const uniqueGroups = Array.from(new Set(groups)).sort();
      return ["Todos", ...uniqueGroups];
    },
    attendantItems() {
      const attendants = this.extract.map((item) => item.nome_atendente);
      const uniqueAttendants = Array.from(new Set(attendants)).sort();
      return ["Todos", ...uniqueAttendants];
    },
    filteredItems() {
      return this.extract.filter(
        (item) =>
          (this.selectedDepartment === "Todos" ||
            item.nome_departamento === this.selectedDepartment) &&
          (this.selectedGroup === "Todos" ||
            item.nome_grupo === this.selectedGroup) &&
          (this.selectedTag === "Todos" ||
            (item.tags &&
              item.tags.some((tag) => tag.nome === this.selectedTag))) &&
          (this.selectedAttendant === "Todos" ||
            item.nome_atendente === this.selectedAttendant) &&
          (!this.search ||
            Object.values(item).some((value) =>
              String(value).toLowerCase().includes(this.search.toLowerCase())
            ))
      );
    },
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    date(newValue) {
      if (newValue.length == 2) {
        this.dialog = false;
        this.fetchData();
      }
    },
  },
  methods: {
    hasTabulacoes(item) {
      return item.tabulacoes && item.tabulacoes.length > 0;
    },
    formatTabulacaoTooltip(dados) {
      // Verifica se dados é null ou undefined. Se for, retorna uma string vazia ou um valor padrão.
      if (!dados) {
        return ""; // Ou algum valor padrão que você queira mostrar
      }

      // Continua com a operação de mapeamento se dados não for null
      return dados.map((d) => `${d.pergunta}: ${d.resposta}<br>`).join("");
    },

    getConversation(item) {
      console.log(item);
      this.axios
        .post(`${this.server}${this.routeC}`, {
          i: item.id_atendimento,
        })
        .then((res) => {
          this.service = item;
          this.conversation = res.data.data;
          this.showChat = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    truncate(text) {
      if (!text) {
        return "";
      }
      return text.length > 17 ? text.substring(0, 10) + "..." : text;
    },
    fetchData() {
      try {
        this.axios
          .post(`${this.server}${this.rd}`, {
            n: this.info.id_numero,
            c: this.info.id_cliente,
            d: this.date,
          })
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data.data);
              this.extract = res.data.data;
            }
          });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>