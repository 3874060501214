<template>
  <v-dialog v-model="showTask">
    <v-card>
      <v-card-text>
        <v-card-title class="mt-n3 mr-n5">
          <v-spacer></v-spacer>
          <v-select
            :disabled="disabled"
            style="max-width: 150px"
            :items="statusList"
            label="Status"
            v-model="status"
            chips
          >
            <template v-slot:selection="">
              <v-chip
                close-icon="mdi-close-outline"
                small
                label
                :color="colorToTask(status)"
              >
                {{ status }}
              </v-chip>
            </template>
          </v-select>
        </v-card-title>
        <v-row class="mt-n10">
          <v-col cols="12">
            <v-text-field
              :disabled="disabled"
              v-model="title"
              label="Título da tarefa"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-n6">
          <v-col cols="12">
            <v-textarea
              :disabled="disabled"
              v-model="description"
              label="Descrição da tarefa"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="#0D47A1"
          style="color: white"
          class="text-capitalize"
          small
          @click="
            () => {
              save();
              showTask = false;
            }
          "
          >Salvar</v-btn
        >
        <v-btn
          color="blue darken-1"
          class="text-capitalize"
          text
          small
          @click="showTask = false"
          >Voltar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["selectCard", "idKanban", "permission", "task"],
  data: () => ({
    showTask: true,
    title: "",
    description: "",
    status: "",
    statusList: ["Aberta", "Iniciada", "Finalizada", "Excluir"],
  }),
  watch: {
    showTask(newValue) {
      if (!newValue) {
        this.$emit("close");
      }
    },
  },

  created() {
    console.log(this.task);
    this.title = this.task.titulo;
    this.description = this.task.descricao;
    this.status = this.task.status;
  },
  computed: {
    disabled() {
      return this.permission === "Leitura";
    },
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
  },
  methods: {
    colorToTask(status) {
      switch (status) {
        case "Aberta":
          return "yellow";
        case "Iniciada":
          return "green";
        case "Finalizada":
          return "blue";
        case "Excluir":
          return "red";
      }
    },
    async save() {
      if (
        this.task.titulo != this.title ||
        this.task.descricao != this.description ||
        this.task.status != this.status
      ) {
        if (this.title != "" && this.description != "") {
          let obj = {
            id_client: this.info.id_cliente,
            id_attendant: this.info.id,
            id_number: this.info.id_numero,
            id_kanban: this.idKanban,
            id_task: this.task.id,
            title: this.title,
            description: this.description,
            status: this.status,
            id_card: this.selectCard.id,
          };

          let objCrypt = await this.cryptoMsg(obj);
          console.log(obj);

          this.axios
            .post(`${this.server}/kanban/updateTask`, { data: objCrypt })
            .then(async (res) => {
              switch (res.status) {
                case 200:
                  this.showTask = false;
                  break;
                case 201:
                  break;
                case 202:
                  break;
                case 203:
                  break;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    ...mapActions(["cryptoMsg", "decrypt"]),
  },
};
</script>
