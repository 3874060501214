<template>
  <v-dialog v-model="dialog" v-if="dialog">
    <v-card>
      <v-toolbar class="mb-3" dense dark color="primary">
        <v-card-title>Histórico</v-card-title><v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table
        class="ml-3 mr-3"
        v-if="showHistory"
        :headers="header"
        :items="filteredItems"
        :footer-props="{ itemsPerPageOptions: [10] }"
      >
        <template v-slot:top>
          <v-row justify="end">
            <v-text-field
              style="max-width: 200px"
              v-model="search"
              label="Procurar"
              class="mr-3"
            ></v-text-field>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  class="mr-2"
                  style="max-width: 200px"
                  label="Periodo"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                dense
                range
                locale="pt-BR"
                v-model="date"
                :active-picker.sync="activePicker"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
                min="1950-01-01"
                @change="save"
              ></v-date-picker>
            </v-menu>
            <v-select
              v-model="selectAttendant"
              style="max-width: 170px"
              class="mr-3"
              :items="attendantList"
              v-if="info.permissao != 'atendente'"
              label="Atendentes"
            ></v-select>
            <v-text-field
              style="max-width: 250px"
              v-model="searchService"
              label="Procurar dentro do atendimento"
              class="mr-3"
              append-icon="mdi-magnify"
              @click:append="searchInService()"
            ></v-text-field>
          </v-row>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.nome }}</td>
            <td>{{ item.contato_4red }}</td>
            <td>{{ item.numero.split("@")[0].substring(2) }}</td>
            <td>{{ formatDate(item.horario) }}</td>
            <td>{{ formatDate(item.horario_ultima_mensagem) }}</td>
            <td>{{ item.status }}</td>
            <td>
              <v-btn small icon @click="getConversation(item)">
                <v-icon color="red">mdi-chat-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-container v-else class="text-center">
        <v-row align="center" justify="center">
          <v-col>
            <v-progress-circular
              indeterminate
              color="purple"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="searchInServiceWait">
        <v-container class="text-center">
          <v-row align="center" justify="center">
            <v-col>
              <v-progress-circular
                indeterminate
                color="purple"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
      </v-dialog>
    </v-card>
    <chatHistory
      v-if="showChat"
      @closeChat="showChat = false"
      :conversation="conversation"
      :service="conversation"
    />
  </v-dialog>
</template>
<script>
import chatHistory from "../chatHistory.vue";
export default {
  components: { chatHistory },
  data() {
    return {
      searchInServiceWait: false,
      searchService: "",
      selectAttendant: "Todos",
      attendantList: "",
      selectedDate: "",
      menu: false,
      activePicker: null,
      date: null,
      sheet: false,
      showChat: false,
      dialog: true,
      rhc: "/history/get_for_attendant",
      rhcs: "/history/get_for_attendant_search",
      routeC: "/history/get_conversation",
      search: "",
      header: [
        { text: "Atendente", value: "nome" },
        { text: "Contato", value: "contato_4red" },
        { text: "Numero", value: "numero" },
        { text: "Inicio do atendimento", value: "horario" },
        { text: "Horario ultima mensagem", value: "horario_ultima_mensagem" },
        { text: "Status", value: "status" },
        { text: "Chamado", value: "action" },
      ],
      showHistory: false,
      history: [],
    };
  },
  created() {
    this.data();
  },
  methods: {
    searchInService() {
      this.searchInServiceWait = true;
      this.axios
        .post(`${this.server}${this.rhcs}`, {
          n: this.info.id_numero,
          a: this.info.id,
          di: this.getFormattedDate(),
          de: this.getFormattedDate(),
          s: this.searchService,
        })
        .then((res) => {
          console.log(res.data);
          switch (res.status) {
            case 200:
              this.history = res.data.services;
              this.attendantList = [
                ...new Set(this.history.map((item) => item.nome)),
              ];
              this.attendantList.unshift("Todos");
              console.log(this.attendantList);
              this.searchInServiceWait = false;
              break;
            case 201:
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case 202:
              break;
            case 203:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    save(date) {
      this.showHistory = false;
      this.menu = false;
      this.selectedDate = date;
      this.axios
        .post(`${this.server}${this.rhc}`, {
          n: this.info.id_numero,
          a: this.info.id,
          di: date[0],
          de: date[1],
        })
        .then((res) => {
          console.log(res.data);
          switch (res.status) {
            case 200:
              this.history = res.data.services;
              this.attendantList = [
                ...new Set(this.history.map((item) => item.nome)),
              ];
              this.attendantList.unshift("Todos");
              console.log(this.attendantList);
              this.showHistory = true;
              break;
            case "201":
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getConversation(item) {
      this.axios
        .post(`${this.server}${this.routeC}`, {
          i: item.id,
        })
        .then((res) => {
          this.service = item;
          this.conversation = res.data.data;
          this.showChat = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFormattedDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    getHistory() {
      this.axios
        .post(`${this.server}${this.rhc}`, {
          n: this.info.id_numero,
          a: this.info.id,
          di: this.getFormattedDate(),
          de: this.getFormattedDate(),
        })
        .then((res) => {
          console.log(res.data);
          switch (res.status) {
            case 200:
              this.history = res.data.services;
              this.attendantList = [
                ...new Set(this.history.map((item) => item.nome)),
              ];
              this.attendantList.unshift("Todos");
              console.log(this.attendantList);
              this.showHistory = true;
              break;
            case "201":
              //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    data() {
      this.getHistory();
    },
    action(item) {
      console.log(item);
    },
    formatDate(date) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return new Date(date).toLocaleString("pt-BR", options);
    },
  },
  computed: {
    filteredItems() {
      const search = this.search.toLowerCase();
      return this.history.filter((item) => {
        let horario = this.formatDate(item.horario);
        let atendente = item.nome.toLowerCase();
        let status = item.status.toLowerCase();
        let nome = item.contato_4red.toLowerCase();
        let numero = item.numero;
        if (this.selectAttendant == "Todos") {
          return (
            horario.indexOf(search) > -1 ||
            atendente.indexOf(search) > -1 ||
            status.indexOf(search) > -1 ||
            nome.indexOf(search) > -1 ||
            numero.indexOf(search) > -1
          );
        } else {
          return (
            (horario.indexOf(search) > -1 &&
              this.selectAttendant.toLocaleLowerCase() == atendente) ||
            (atendente.indexOf(search) > -1 &&
              this.selectAttendant.toLocaleLowerCase() == atendente) ||
            (status.indexOf(search) > -1 &&
              this.selectAttendant.toLocaleLowerCase() == atendente) ||
            (nome.indexOf(search) > -1 &&
              this.selectAttendant.toLocaleLowerCase() == atendente) ||
            (numero.indexOf(search) > -1 &&
              this.selectAttendant.toLocaleLowerCase() == atendente)
          );
        }
      });
    },
    axios() {
      return this.$store.state.axios;
    },
    screen() {
      return this.$store.state.screens.group;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    searchService(newValue) {
      if (newValue.length > 3) {
        console.log(newValue);
        this.searchInService();
      }
    },
    screen(newValue) {
      if (newValue) {
        this.dialog = true;
        this.data();
      } else {
        this.dialog = false;
      }
    },
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide");
      }
    },
  },
};
</script>

<style>
.clickable-item {
  cursor: pointer; /* Altera o cursor para o formato de clique */
}
.pulse-highlight {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    background-color: transparent; /* Cor inicial */
  }
  50% {
    background-color: #ff5722; /* Cor intermediária */
  }
  100% {
    background-color: transparent; /* Cor final */
  }
}
</style>
