<template>
  <v-dialog v-model="dialog" v-if="dialog" max-width="500px">
    <v-card width="500px">
      <v-card-title
        >Informações da integração <v-spacer></v-spacer
      ></v-card-title>
      <form class="ma-3">
        <v-text-field
          v-model="newName"
          id="newName"
          label="Nome (Ex: ConsultaCPF)"
        ></v-text-field>
        <v-textarea
          rows="3"
          v-model="newQuestion"
          label="Solitação de dados, (Ex: Por favor insira seu CPF)"
        ></v-textarea>
        <v-select label="Vinculo" v-model="type" :items="types"></v-select>
      </form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          class="text-capitalize"
          color="primary"
          @click="dialog = false"
          >Voltar</v-btn
        >
        <v-btn
          small
          class="text-capitalize"
          @click="add"
          style="color: white"
          color="red lighten-1"
          >Adicionar</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
        <v-row align="center" justify="center">
          <v-col>
            <v-progress-circular
              indeterminate
              color="purple"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
  </v-dialog>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      type: "",
      types: ["ura", "departamento", "ura_encaminhar"],
      sheet: false,
      sheetDialog: "Adicionando integração",
      newName: "",
      newQuestion: "",
      dialog: true,
      ria: "/integrations/add",
    };
  },
  created() {
    setTimeout(() => {
      document.getElementById("newName").focus();
    }, 300);
  },
  methods: {
    add() {
      this.newName = this.newName.replace(/[^a-zA-Z]/g, "");
      this.sheet = true;
      this.axios
        .post(`${this.server}${this.ria}`, {
          n: this.info.id_numero,
          nn: this.newName,
          nq: this.newQuestion,
          nt: this.type,
        })
        .then((res) => {
          console.log(res.data);
          if (res.status == 200 && res.data.error) {
            this.sheetDialog = `ERRO: ${res.data.error}`;
            setTimeout(() => {
              this.sheet = false;
              this.sheetDialog = "Adicionando integração.";
            }, 5000);
          } else if (res.status == 200 && res.data.message) {
            this.sheetDialog = res.data.message;
            this.$emit("reloadData");
            setTimeout(() => {
              this.sheet = false;
              this.sheetDialog = "Adicionando integração.";
            }, 5000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    screen() {
      return this.$store.state.screens.group;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    screen(newValue) {
      if (newValue) {
        this.dialog = true;
        this.data();
      } else {
        this.dialog = false;
      }
    },
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide");
      }
    },
  },
};
</script>

<style>
</style>
