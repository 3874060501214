<template>
  <v-dialog persistent v-model="dialog">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title>Lista de Usuários</v-card-title><v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-list>
          <v-list-item-group v-if="users.length > 0" class="overflow-y-auto">
            <v-list-item dense v-for="(user, index) in users" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ user.nome }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.usuario }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-select
                  :disabled="user.permissao == 'Dono'"
                  label="Permissões"
                  :items="permissions"
                  style="max-width: 120px"
                  v-model="user.permissao"
                  @change="registerChange(index, user)"
                ></v-select>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          class="text-capitalize"
          color="#0D47A1"
          style="color: white"
          @click="saveChanges"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["kanbanId"],
  data() {
    return {
      changedUsers: [],
      dialog: true,
      users: [],
      permissions: ["Automação", "Leitura", "Escrita", "Dono", "Nenhuma"],
    };
  },
  created() {},
  mounted() {
    console.log(this.kanbanId);
    this.fetchData();
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  methods: {
    saveChanges() {
      let updates = [];
      for (let [index, data] of Object.entries(this.changedUsers)) {
        if (data.modified !== data.original) {
          updates.push({
            id_atendente: this.users[index].id,
            permissao: data.modified,
            id_quadro: this.kanbanId,
          });
        }
      }
      console.log(updates);
      this.axios
        .post(`${this.server}/kanban/updatePermission`, {
          id_client: this.info.id_cliente,
          id_kanban: this.kanbanId,
          data: updates,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              this.close();
              break;
            case "201":
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    registerChange(index, user) {
      this.changedUsers[index] = {
        ...this.changedUsers[index],
        modified: user.permissao,
      };
    },
    fetchData() {
      this.axios
        .post(`${this.server}/kanban/getAttendant`, {
          i: this.info.id_numero,
          ik: this.kanbanId,
          ia: this.info.id,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              console.warn(res.data);
              this.users = res.data.attendants;
              break;
            case "201":
              break;
            case "202":
              break;
            case "203":
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    close() {
      this.dialog = false;
      this.$emit("closePermission");
    },
  },
};
</script>

<style></style>
