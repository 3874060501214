<template>
  <v-dialog v-model="dialog" width="600px">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title style="font-size: 16px; font-weight: 400"
          >Editar Etiqueta</v-card-title
        ><v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <form class="ml-8 mr-8">
        <v-text-field v-model="newName" label="Nome"></v-text-field>
        <v-color-picker
          v-model="newColor"
          dot-size="25"
          hide-canvas
          hide-inputs
          hide-mode-switch
          hide-sliders
          show-swatches
          swatches-max-height="200"
        ></v-color-picker>
      </form>
      <v-card-actions class="mt-3">
        <v-spacer></v-spacer>
        <v-btn
          small
          class="ml-2 text-capitalize"
          style="color: white"
          @click="add"
          color="red"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
      </v-sheet>
    </v-bottom-sheet>
  </v-dialog>
</template>
<script>
export default {
  props: ["name", "color", "id"],
  components: {},
  data() {
    return {
      sheet: false,
      sheetDialog: "",
      dialog: true,
      newName: "",
      newColor: "",
      route: "/tag/edit",
    };
  },
  created() {
    this.newName = this.name;
    this.newColor = this.color;
  },
  methods: {
    data() {
      /*this.axios
        .get(`${this.server}/integrations/get_integrations/${this.info.usuario}/${this.info.id_numero}`)
        .then((res) => {
          this.$store.state.integrations = res.data;
        })
        .catch((err) => {
          console.log(err);
        });*/
    },
    add() {
      console.log(this.name, this.color);
      this.axios
        .post(`${this.server}${this.route}`, {
          i: this.id,
          c: this.info.id_cliente,
          nm: this.newName,
          cl: this.newColor,
        })
        .then((res) => {
          if (res.status == 200) {
            this.sheetDialog = res.data.message;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
              this.dialog = false;
              this.$emit("reload");
            }, 4000);
          } else {
            this.sheetDialog = res.data.error;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
            }, 4000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide");
      }
    },
  },
};
</script>
