<template>
  <v-container>
    <v-card-title class="mt-n5">
      <v-spacer></v-spacer>
      <v-btn
        v-if="!disabled"
        @click="openFileInput"
        class="text-capitalize mr-4 mb-2"
        color="blue darken-1"
        text
      >
        Adicionar arquivo
      </v-btn>
      <v-file-input
        @change="sendFile"
        id="fileInput"
        v-model="file"
        style="display: none"
      ></v-file-input>
    </v-card-title>
    <v-row style="max-height: 66vh" class="overflow-y-auto mt-n8">
      <v-col v-for="file in files" :key="file.name" cols="12" md="4">
        <v-card class="d-flex align-center justify-center">
          <v-icon class="ml-4" :color="getIconColor(file.tipo)">
            {{ getIcon(file.tipo) }}
          </v-icon>
          <v-card-title class="custom-text">{{ file.nome }}</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="downloadFile(file)">
            <v-icon color="blue darken-1">mdi-download</v-icon>
          </v-btn>
          <v-btn
            class="mr-2"
            icon
            @click.stop="(showDelete = true), (selectionFile = file)"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <template
      ><v-dialog v-model="showDelete" persistent height="300px" width="500px">
        <v-card>
          <v-toolbar dense dark color="primary">
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Tem certeza que deseja excluir esse arquivo?</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="showDelete = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="deleteFile"
              color="red"
              style="color: white"
              >Excluir</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog></template
    >
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["selectCard", "idKanban", "permission"],
  data: () => ({
    files: [],
    file: null,
    showDelete: false,
    selectionFile: "",
  }),
  computed: {
    disabled() {
      return this.permission === "Leitura";
    },
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
  },
  created() {
    this.fetchData();
    this.socket.on("uploadFiles", async (data) => {
      let decryp = await this.decrypt(data);
      console.log("Decryp: ", decryp);
      if (decryp.id_card == this.selectCard.id) {
        this.fetchData();
      }
    });
  },
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    async openFileInput() {
      let input = document.getElementById("fileInput");
      input.click();
    },
    async sendFile() {
      if (!this.file) {
        alert("Nenhum arquivo selecionado");
        return;
      }
      const formData = new FormData();
      formData.append("file", this.file);

      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.idKanban,
        id_card: this.selectCard.id,
      };

      try {
        let objCrypt = await this.cryptoMsg(obj);
        formData.append("data", objCrypt);

        const response = await this.axios.post(
          `${this.server}/kanban/uploadFiles`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Arquivo enviado com sucesso:", response.data);
        // Atualiza a lista de arquivos após o upload
      } catch (error) {
        console.error("Erro ao enviar arquivo:", error);
      }
    },
    async deleteFile() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.idKanban,
        id_file: this.selectionFile.id,
        id_card: this.selectCard.id,
        file_name: this.selectionFile.nome,
        file_size: this.selectionFile.tamanho,
        file_type: this.selectionFile.tipo,
      };
      console.log(obj);
      let objCrypt = await this.cryptoMsg(obj);

      this.axios
        .post(`${this.server}/kanban/deleteFile`, { data: objCrypt })
        .then(async (res) => {
          if (res.status == 200) {
            this.showDelete = false;
          }
        });
    },
    async fetchData() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.idKanban,
        id_card: this.selectCard.id,
      };

      try {
        let objCrypt = await this.cryptoMsg(obj);
        const res = await this.axios.post(`${this.server}/kanban/getFiles`, {
          data: objCrypt,
        });

        switch (res.status) {
          case 200:
            if (res.data.data.length > 0) {
              this.files = res.data.data.map((file) => ({
                ...file,
                content: this.arrayBufferToBase64(file.arquivo.data),
              }));
            }
            break;
          case 201:
            this.files = [];
            break;
          case 202:
            break;
          case 203:
            break;
          default:
            console.warn("Resposta inesperada:", res.status);
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    },
    arrayBufferToBase64(arrayBuffer) {
      let binary = "";
      const bytes = new Uint8Array(arrayBuffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    getIcon(fileType) {
      switch (fileType) {
        case "application/pdf":
          return "mdi-file-pdf-box";
        case "image/png":
        case "image/jpeg":
          return "mdi-file-image";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "mdi-file-excel";
        default:
          return "mdi-file";
      }
    },
    getIconColor(fileType) {
      switch (fileType) {
        case "application/pdf":
          return "red";
        case "image/png":
        case "image/jpeg":
          return "green";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "blue";
        default:
          return "grey";
      }
    },
    downloadFile(file) {
      console.log("Baixando arquivo:", file);
      const link = document.createElement("a");
      link.href = `data:${file.tipo};base64,${file.content}`;
      link.download = file.nome;
      link.click();
    },
  },
};
</script>

<style>
.custom-text {
  font-size: 12px;
}
</style>
