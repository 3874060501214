<template>
  <div
    v-if="dialog"
    class="d-flex justify-center align-center"
    style="height: 40vh"
  >
    <canvas id="chartgroup"></canvas>
  </div>
</template>
  <script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  props: ["date"],
  data() {
    return {
      dialog: false,
      r: "/report/countCallsByGroupAndDay",
      chartData: {
        labels: [],
        datasets: [],
      },
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    date(newValue) {
      if (newValue.length == 2) {
        this.dialog = false;
        this.fetchData();
      }
    },
  },
  methods: {
    transformData(rows) {
      // Organiza os dados por grupo e limita o nome do grupo a 15 caracteres
      const dataByGroup = rows.map((row) => ({
        grupo:
          (row.grupo || "Sem Grupo").length > 15
            ? (row.grupo || "Sem Grupo").substr(0, 15) + "..."
            : row.grupo || "Sem Grupo",
        count: parseInt(row.count, 10),
      }));

      // Ordena os grupos do menor para o maior número de atendimentos
      dataByGroup.sort((a, b) => a.count - b.count);

      return dataByGroup;
    },

    fetchData() {
      this.axios
        .post(`${this.server}${this.r}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          d: this.date,
        })
        .then((res) => {
          if (res.status === 200) {
            this.dialog = true;
            console.error(res.data.data);
            setTimeout(() => {
              this.processData(this.transformData(res.data.data));
              this.$emit("countCallsByGroupAndDay_ready");
            }, 100);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    processData(dataByGroup) {
      // Mapeia os grupos e suas contagens para os datasets
      this.chartData.labels = dataByGroup.map((item) => item.grupo);
      this.chartData.datasets = [
        {
          label: "Atendimentos",
          data: dataByGroup.map((item) => item.count),
          backgroundColor: dataByGroup.map(() => this.getRandomColor()),
          borderColor: dataByGroup.map(() => this.getRandomColor()),
          borderWidth: 1,
        },
      ];

      // Cria o gráfico com os dados processados
      this.createChart();
    },

    getRandomColor() {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      return `rgba(${r}, ${g}, ${b}, 0.2)`;
    },
    createChart() {
      const ctx = document.getElementById("chartgroup").getContext("2d");
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(ctx, {
        type: "bar", // Use "bar" para barras agrupadas ou "stackedBar" para empilhadas
        data: this.chartData,
        options: {
          plugins: {
            title: {
              display: true,
              text: "Atendimentos por Grupo",
            },
            legend: {
              display: true,
              position: "top", // Posiciona a legenda no topo
            },
          },
          scales: {
            x: {
              stacked: true, // Se for um gráfico empilhado, remova essa linha para agrupado
            },
            y: {
              stacked: true, // Se for um gráfico empilhado, remova essa linha para agrupado
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
};
</script>
  