<template>
  <div
    v-if="dialog"
    class="d-flex justify-center align-center"
    style="height: 40vh"
  >
    <canvas id="chart1"></canvas>
  </div>
</template>
  <script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  props: ["date"],
  data() {
    return {
      dialog: false,
      r: "/report/countCallsByDepartmentAndDay",
      chartData: {
        labels: [],
        datasets: [],
      },
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    date(newValue) {
      if (newValue.length == 2) {
        this.dialog = false;
        this.fetchData();
      }
    },
  },
  methods: {
    transformData(rows) {
      const dataByDay = {};
      rows.forEach((row) => {
        const day = row.day.split("T")[0];
        if (!dataByDay[day]) {
          dataByDay[day] = {};
        }
        if (!dataByDay[day][row.departamento]) {
          dataByDay[day][row.departamento] = 0;
        }
        dataByDay[day][row.departamento] += parseInt(row.count, 10);
      });
      return dataByDay;
    },
    fetchData() {
      this.axios
        .post(`${this.server}${this.r}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          d: this.date,
        })
        .then((res) => {
          if (res.status === 200) {
            this.dialog = true;
            console.log(this.transformData(res.data.data));
            setTimeout(() => {
              this.processData(this.transformData(res.data.data));
              this.$emit("callsByDepartmentAndDay_ready");
            }, 100);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    processData(transformedData) {
      const departments = new Set();
      const labels = Object.keys(transformedData);
      labels.forEach((day) => {
        Object.keys(transformedData[day]).forEach((department) => {
          departments.add(department);
        });
      });
      this.chartData.datasets = Array.from(departments).map((department) => ({
        label: department,
        data: labels.map((day) => transformedData[day][department] || 0),
        backgroundColor: this.getRandomColor(),
        borderColor: this.getRandomColor(),
        borderWidth: 1,
      }));
      this.chartData.labels = labels;
      this.createChart();
    },
    getRandomColor() {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      return `rgba(${r}, ${g}, ${b}, 0.2)`;
    },
    createChart() {
      const ctx = document.getElementById("chart1").getContext("2d");
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(ctx, {
        type: "bar",
        data: this.chartData,
        options: {
          plugins: {
            title: {
              display: true,
              text: "Atendimentos por Dia por Departamento",
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
              },
            },
          },
        },
      });
    },
  },
};
</script>
  