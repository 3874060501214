<template>
  <v-dialog width="600px" v-model="dialog">
    <v-card>
      <v-card-title>Configurações iniciais </v-card-title>
      <form class="ma-3">
        <v-textarea
          rows="3"
          label="Mensagem de boas vindas"
          v-model="info.msg_inicial"
        ></v-textarea>
        <v-textarea
          rows="3"
          label="Mensagem pos seleção"
          v-model="info.msg_final"
        ></v-textarea>
        <v-textarea
          rows="3"
          label="Mensagem fora de horario"
          v-model="info.msg_forahorario"
        ></v-textarea>
        <v-text-field
          label="Tempo maximo de permanencia na ura em segundos"
          v-model="info.tempo_timeout"
        ></v-text-field>
        <v-select
          v-model="newDep"
          :items="deps"
          item-text="departamento"
          label="Departamento destino para Grupos"
          item-value="id"
        ></v-select>
      </form>
      <v-card-actions>
        <v-btn
          small
          color="primary"
          class="text-capitalize"
          @click="dialog = false"
          >Voltar</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn small class="text-capitalize" color="red" @click="save"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
      </v-sheet>
    </v-bottom-sheet>
  </v-dialog>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      newDep: "",
      dialog: true,
      info: { ...this.data },
      sheet: false,
      sheetDialog: "",
    };
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    deps() {
      return this.$store.state.deps;
    },
  },
  methods: {
    save() {
      this.info.departamento_grupo = this.newDep;
      this.axios
        .post(`${this.server}/ura/edit_initial_conf/`, this.info)
        .then((res) => {
          if (res.status == 200) {
            if (res.data.error) {
              this.sheetDialog = res.data.error;
              this.sheet = true;
              setTimeout(() => {
                this.sheetDialog = "";
                this.sheet = false;
              }, 4000);
            } else {
              this.sheetDialog = res.data.message;
              this.sheet = true;
              setTimeout(() => {
                this.sheetDialog = "";
                this.sheet = false;
                this.$emit("updateInitialConf", res.data.data);
              }, 4000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.info = "";
        this.$emit("eventClose");
      }
    },
    newDep(newValue) {
      console.log(newValue);
    },
  },
  created() {
    console.log(this.data);
  },
};
</script>
