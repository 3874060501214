<template>
  <v-dialog v-model="dialog" width="700px">
    <v-card :style="{ height: '75vh' }">
      <v-card-title class="text-center">
        Enviar para contatos do grupo "{{ groupName }}"
        <v-spacer></v-spacer>
        <v-card-actions>
          <!-- Botão para Abrir o Upload de Imagem -->
          <v-btn icon @click="triggerFileInput">
            <v-icon>mdi-paperclip</v-icon>
          </v-btn>
          <!-- Input de Arquivo Oculto -->
          <input
            type="file"
            ref="fileInput"
            style="display: none"
            accept="image/*"
            @change="uploadFile"
          />
          <!-- Pré-visualização da Imagem -->
          <div v-if="showImgPreview" class="ml-2">
            <img
              :src="imagePreviewUrl"
              alt="Imagem selecionada"
              style="max-width: 100px; height: auto"
            />
            <v-btn icon small @click="removeImage">
              <v-icon color="red">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card-title>

      <!-- Área para Upload de Imagem -->

      <!-- Campo de Texto para Mensagem -->
      <v-textarea
        :disabled="disable"
        v-model="text"
        class="ml-5 mr-5"
        rows="18"
      ></v-textarea>

      <v-card-actions>
        <v-btn small class="ml-2 text-capitalize" @click="save" color="red"
          >Enviar</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          small
          class="ml-2 text-capitalize"
          @click="dialog = false"
          color="primary"
          >Voltar</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
      </v-sheet>
    </v-bottom-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: "SendMessage",
  props: ["id", "groupName"],
  data() {
    return {
      text: "",
      search: "",
      sheet: false,
      sheetDialog: "",
      dialog: true,
      route: "/whatsapp_send/send_text_group",
      disable: false,
      // Variáveis para o upload de imagem
      file: null,
      showImgPreview: false,
      imagePreviewUrl: "",
    };
  },
  methods: {
    // Método para abrir o seletor de arquivos
    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    // Método para lidar com o upload de arquivo
    uploadFile(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;
        this.showImgPreview = true;
        this.imagePreviewUrl = URL.createObjectURL(file);
      }
    },

    // Método para remover a imagem selecionada
    removeImage() {
      this.file = null;
      this.showImgPreview = false;
      this.imagePreviewUrl = "";
    },

    // Método para enviar a mensagem e a imagem
    save() {
      if (this.file) {
        // Envia a imagem primeiro
        this.sendImageGroup()
          .then(() => {
            // Depois, envia o texto se houver
            if (this.text.trim() !== "") {
              return this.sendTextGroup();
            }
          })
          .then(() => {
            this.showSuccessMessage();
            // Reseta os campos após o envio
            this.text = "";
            this.removeImage();
          })
          .catch((error) => {
            console.error("Erro ao enviar mensagem ou imagem:", error);
          });
      } else {
        // Sem imagem, envia apenas o texto
        if (this.text.trim() !== "") {
          this.sendTextGroup()
            .then(() => {
              this.showSuccessMessage();
              // Reseta o campo de texto após o envio
              this.text = "";
            })
            .catch((error) => {
              console.error("Erro ao enviar mensagem:", error);
            });
        } else {
          // Nada para enviar
          this.sheetDialog = "Nenhuma mensagem ou imagem para enviar.";
          this.sheet = true;
          setTimeout(() => {
            this.sheet = false;
          }, 4000);
        }
      }
    },

    // Método para enviar a imagem para a rota send_img_group
    sendImageGroup() {
      const formData = new FormData();
      formData.append("image", this.file);
      formData.append("i", this.id);
      formData.append("t", "imagem");
      formData.append(
        "cliente",
        `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`
      );
      formData.append("n", this.$store.state.info.numero);
      formData.append("ci", this.$store.state.info.id_numero);

      return this.axios
        .post(`${this.server}/whatsapp_send/send_img_group`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status == 200) {
            // Imagem enviada com sucesso
            console.log("Imagem enviada com sucesso:", res.data);
          } else {
            this.sheetDialog = res.data.error;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
            }, 4000);
          }
        })
        .catch((err) => {
          console.error("Erro ao enviar imagem:", err);
          throw err; // Repassa o erro para ser capturado no método save()
        });
    },

    // Método para enviar o texto para a rota send_text_group
    sendTextGroup() {
      return this.axios
        .post(`${this.server}${this.route}`, {
          i: this.id,
          c: this.text,
          t: "texto",
          cliente: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
          n: this.$store.state.info.numero,
          ci: this.$store.state.info.id_numero,
        })
        .then((res) => {
          if (res.status == 200) {
            console.log("Mensagem enviada com sucesso:", res.data);
          } else {
            this.sheetDialog = res.data.error;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
            }, 4000);
          }
        })
        .catch((err) => {
          console.log(err);
          throw err; // Repassa o erro para ser capturado no método save()
        });
    },

    // Método para exibir mensagem de sucesso
    showSuccessMessage() {
      this.sheetDialog = "Mensagem enviada com sucesso.";
      this.sheet = true;
      setTimeout(() => {
        this.sheet = false;
        this.dialog = false;
        this.$emit("reload");
      }, 4000);
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("closeSend");
      }
    },
  },
};
</script>
