<template>
  <v-dialog v-model="dialog">
    <v-container class="my-container" fluid>
      <v-card v-if="dialog">
        <v-toolbar dense dark color="primary">
          <v-card-title>Departamentos</v-card-title><v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="mb-2"
            v-model="search"
            clearable
            flat
            hide-details
            append-outer-icon="mdi-magnify"
            label="Procurar"
          ></v-text-field>
          <v-btn
            v-if="info.permissao == 'admin'"
            small
            class="ml-3 mb-n2 text-capitalize"
            @click="showAddDep = true"
            color="#0D47A1"
            style="color: white"
            >Adicionar</v-btn
          >
        </v-card-title>
        <v-row class="row-flex-wrap">
          <v-col class="dep-col ml-3 mr-3" cols="11" sm="11" md="11">
            <v-data-table
              :headers="headers"
              :items="filteredDeps"
              :footer-props="{ itemsPerPageOptions: [20] }"
              class="data-table-responsive"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.departamento }}</td>
                  <td>{{ item.services.length }}</td>
                  <td v-if="info.permissao == 'admin'">
                    <v-btn small icon @click="showTab(item)">
                      <v-icon color="primary">mdi-head-question-outline</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="info.permissao == 'admin'">
                    <v-btn small icon @click="showSatisfactionSurvey(item)">
                      <v-icon color="purple">mdi-account-search-outline</v-icon>
                    </v-btn>
                  </td>
                  <td
                    v-if="
                      info.permissao == 'admin' && item.departamento != 'Admin'
                    "
                  >
                    <v-btn small icon @click="showIntegration(item)">
                      <v-icon color="success"
                        >mdi-shield-link-variant-outline</v-icon
                      >
                    </v-btn>
                  </td>
                  <td
                    v-if="
                      (info.permissao == 'admin' ||
                        info.permissao == 'supervisor') &&
                      item.departamento != 'Admin'
                    "
                    @click="
                      (showTag = true),
                        (depName = item.departamento),
                        (depId = item.id)
                    "
                  >
                    <v-btn small icon>
                      <v-icon color="#C2185BFF"
                        >mdi-tag-multiple-outline</v-icon
                      >
                    </v-btn>
                  </td>
                  <td
                    v-if="
                      (info.permissao == 'admin' ||
                        info.permissao == 'supervisor') &&
                      item.departamento != 'Admin'
                    "
                    @click="
                      (showFast = true),
                        (depName = item.departamento),
                        (depId = item.id)
                    "
                  >
                    <v-btn small icon>
                      <v-icon color="#C2185BF8"
                        >mdi-message-fast-outline</v-icon
                      >
                    </v-btn>
                  </td>
                  <td
                    v-if="
                      info.permissao == 'admin' && item.departamento != 'Admin'
                    "
                  >
                    <v-btn small icon>
                      <v-icon
                        @click="
                          (showEditDep = true),
                            (editId = item.id),
                            (editName = item.departamento),
                            (editMsg = item.mensagem_inicial),
                            (editMsgEnd = item.mensagem_final),
                            print(item)
                        "
                        color="primary"
                        >mdi-account-edit-outline</v-icon
                      >
                    </v-btn>
                  </td>
                  <td
                    v-if="
                      info.permissao == 'admin' && item.departamento != 'Admin'
                    "
                  >
                    <v-btn
                      small
                      icon
                      @click="(showDeleteDep = true), (deleteDep = item)"
                    >
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <v-dialog v-if="showEditDep" v-model="showEditDep" width="500px">
        <v-card width="500px">
          <v-toolbar dense dark color="primary">
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Informações do Departamento</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="showEditDep = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <form class="ma-3">
            <v-text-field
              required
              v-model="editName"
              label="Nome do Departamento"
            ></v-text-field>
            <v-textarea
              rows="3"
              required
              v-model="editMsg"
              label="Mensagem Inicial do Departamento"
            ></v-textarea>
            <v-textarea
              rows="3"
              required
              v-model="editMsgEnd"
              label="Mensagem Final do Departamento"
            ></v-textarea>
          </form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="editDep"
              color="#0D47A1"
              style="color: white"
              >Salvar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="showAddDep" v-model="showAddDep" width="500px">
        <v-card width="500px">
          <v-toolbar dense dark color="primary">
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Informações do Departamento</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="showAddDep = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <form class="ma-3">
            <v-text-field
              required
              v-model="newDepName"
              label="Nome do Departamento"
            ></v-text-field>
            <v-textarea
              rows="3"
              required
              v-model="newDepMsg"
              label="Mensagem Inicial do Departamento"
            ></v-textarea>
            <v-textarea
              rows="3"
              required
              v-model="newDepMsgEnd"
              label="Mensagem Final do Departamento"
            ></v-textarea>
          </form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="addDep"
              color="#0D47A1"
              style="color: white"
              >Adicionar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="showDeleteDep" v-model="showDeleteDep" width="500px">
        <v-card width="500px">
          <v-toolbar dense dark color="primary">
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Deseja deletar o departamento "{{ deleteDep.departamento }}"
              ?</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="showDeleteDep = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="deleteDepartament"
              color="red"
              style="color: white"
              >Deletar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="showSatisfaction"
        v-model="showSatisfaction"
        width="720px"
      >
        <v-card width="700px">
          <v-toolbar dense dark color="primary">
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Escolha a Pesquisa</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="showSatisfaction = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title
            ><v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="deleteSurvey"
              color="red"
              style="color: white"
              >Remover Pesquisa do departamento</v-btn
            >
          </v-card-title>
          <form class="ma-3">
            <v-select
              class="ml-8 mr-8"
              v-model="newSatisfaction"
              :items="satisfactions"
              item-text="nome"
              label="Pesquisa"
            ></v-select>
          </form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="saveSatisfaction"
              color="#0D47A1"
              style="color: white"
              >Salvar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="tabScreen" v-model="tabScreen" width="720px">
        <v-card width="700px">
          <v-toolbar dense dark color="primary">
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Escolha a tabulação</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="tabScreen = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title
            ><v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="removeTab"
              color="red"
              style="color: white"
              >Remover tabulação do departamento</v-btn
            >
          </v-card-title>
          <form class="ma-3">
            <v-select
              class="ml-8 mr-8"
              v-model="newTab"
              :items="tabs"
              item-text="tab.nome"
              label="Tabulação"
            ></v-select>
          </form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="saveTab"
              color="#0D47A1"
              style="color: white"
              >Salvar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="integrationScreen"
        v-model="integrationScreen"
        width="720px"
      >
        <v-card width="700px">
          <v-toolbar dense dark color="primary">
            <v-card-title style="font-size: 16px; font-weight: 400"
              >Integrações</v-card-title
            ><v-spacer></v-spacer>
            <v-btn icon dark @click="integrationScreen = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <div v-for="objeto in selectedIntegrations" :key="objeto.id">
            <v-checkbox
              v-if="objeto.tipo == 'departamento'"
              v-model="objeto.selecionado"
              :label="objeto.nome"
            ></v-checkbox>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="saveIntegrations()"
              color="#0D47A1"
              style="color: white"
              >Salvar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">Departamentos foram atualizados</div>
      </v-sheet>
    </v-bottom-sheet>
    <tagScreen
      v-if="showTag"
      @hide="showTag = false"
      :id="depId"
      :depName="depName"
    />
    <fastScreen
      v-if="showFast"
      @hide="showFast = false"
      :id="depId"
      :depName="depName"
    />
  </v-dialog>
</template>

<script>
import tagScreen from "./deps/tagScreen";
import fastScreen from "./deps/fastScreen";

/* eslint-disable */
export default {
  components: {
    tagScreen,
    fastScreen,
  },
  data() {
    return {
      showFast: false,
      editMsgEnd: "",
      newDepMsgEnd: "",
      depId: "",
      depName: "",
      showTag: false,
      editId: "",
      editName: "",
      editMsg: "",
      showEditDep: false,
      newDepMsg: "",
      newSatisfaction: "",
      showSatisfaction: false,
      depToSatisfaction: "",
      selectedIntegrations: [],
      depToInt: "",
      depToTab: "",
      newTab: "",
      tabScreen: false,
      integrationScreen: false,
      sheet: false,
      search: "",
      dialog: false,
      newDepName: "",
      showDeleteDep: "",
      deleteDep: "",
      headers: [
        { text: "Departamento", value: "departamento" },
        { text: "Chamados Abertos", value: "" },
        { text: "Tabulação", value: "" },
        { text: "Pesquisa de Satisfação", value: "" },
        { text: "Integrações", value: "" },
        { text: "Etiquetas", value: "" },
        { text: "Mensagem Rápida", value: "" },
        { text: "Editar", value: "" },
        { text: "Apagar", value: "" },
      ],
      showAddDep: false,
    };
  },
  computed: {
    filteredDeps() {
      return this.deps.filter((item) => {
        return (
          item.departamento.toLowerCase().indexOf(this.search.toLowerCase()) >
          -1
        );
      });
    },
    deps() {
      return this.$store.state.info.deps;
    },
    tabs() {
      return this.$store.state.tabs;
    },
    depsDialog() {
      return this.$store.state.screens.deps;
    },
    info() {
      return this.$store.state.info;
    },
    integrations() {
      return this.$store.state.integrations;
    },
    satisfactions() {
      return this.$store.state.satisfactionSurvey;
    },
  },

  watch: {
    showEditDep(newValue) {
      console.log(this.editMsgEnd);
    },
    integrations(newValue) {
      this.selectedIntegrations = newValue.map((objeto) => {
        return {
          ...objeto,
          selecionado: false,
        };
      });
      console.log(newValue);
      console.log(this.selectedIntegrations);
    },
    depsDialog(newValue) {
      if (newValue) {
        this.dialog = true;
      }
    },
    dialog(newValue) {
      if (!newValue) {
        this.$store.state.screens.deps = "";
      }
    },
  },
  methods: {
    print(item) {
      console.log(item);
    },
    handleCheckboxChange(objeto) {
      let int = this.selectedIntegrations.find((int) => (int.id = objeto.id));
      if (int.selecionado) {
        int.selecionado = false;
      } else {
        int.selecionado = true;
      }
    },
    removeTab() {
      let tab = this.tabs.find((tab) => tab.tab.nome == this.newTab);
      this.$store.state.interactions.removeTab = {
        id_dep: this.depToTab.id,
      };
      this.tabScreen = false;
      this.depToTab = "";
      this.newTab = "";
    },
    saveTab() {
      let tab = this.tabs.find((tab) => tab.tab.nome == this.newTab);
      this.$store.state.interactions.insertDepToTab = {
        id_dep: this.depToTab.id,
        id_tab: tab.tab.id,
      };
      this.tabScreen = false;
      this.depToTab = "";
      this.newTab = "";
    },
    saveSatisfaction() {
      let survey = this.satisfactions.find(
        (survey) => survey.nome == this.newSatisfaction
      );
      this.$store.state.interactions.insertDepToSatisfaction = {
        id_dep: this.depToSatisfaction.id,
        id_survey: survey.id,
      };
      this.showSatisfaction = false;
      this.newSatisfaction = "";
      this.depToSatisfaction = "";
    },
    showIntegration(item) {
      this.$store.state.interactions.getIntegrations = {
        id_number: this.$store.state.info.id_numero,
      };
      this.depToInt = item;
      this.integrationScreen = true;
    },
    deleteSurvey() {
      this.$store.state.interactions.deleteDepSurvey = {
        id_dep: this.depToSatisfaction.id,
      };
    },
    showSatisfactionSurvey(item) {
      console.log(item);
      this.depToSatisfaction = item;
      this.$store.state.interactions.getSatisfactionSurvey = {
        id_number: this.$store.state.info.id_numero,
      };
      this.showSatisfaction = true;
    },
    showTab(item) {
      this.depToTab = item;
      this.$store.state.interactions.getTabs = {
        id_number: this.$store.state.info.id_numero,
      };
      this.tabScreen = true;
    },
    deleteDepartament() {
      this.$store.state.interactions.deleteDep = {
        id: this.deleteDep.id,
        id_number: this.$store.state.info.id_numero,
        id_admin: this.$store.state.info.id,
      };
      this.showDeleteDep = "";
      this.deleteDep = "";
      this.sheet = true;
      setTimeout(() => {
        this.sheet = false;
      }, 2000);
    },
    saveIntegrations() {
      this.integrationScreen = false;
      let ints = this.selectedIntegrations
        .filter((objeto) => objeto.selecionado)
        .map((objeto) => objeto.id);
      this.$store.state.interactions.addIntegrationToDep = {
        id_dep: this.depToInt.id,
        integrations: ints,
      };
    },
    editDep() {
      this.showEditDep = false;
      this.$store.state.interactions.editDep = {
        id: this.editId,
        name: this.editName,
        msg: this.editMsg,
        msg_end: this.editMsgEnd,
        id_admin: this.$store.state.info.id,
      };
      setTimeout(() => {
        this.sheet = false;
      }, 2000);
    },
    addDep() {
      if (this.newDepName != "") {
        let newDep = {
          name: this.newDepName,
          id_client: this.$store.state.info.id_cliente,
          id_number: this.$store.state.info.id_numero,
          id_admin: this.$store.state.info.id,
          msg: this.newDepMsg,
          msg_end: this.newDepMsgEnd,
        };
        this.showAddDep = false;
        this.$store.state.interactions.addDep = newDep;
        this.newDepName = "";
        this.sheet = true;
        setTimeout(() => {
          this.sheet = false;
        }, 2000);
      }
    },
  },
};
</script>

<style>
.my-container {
  width: 100%;
  white-space: nowrap;
}

.row-flex-wrap {
  overflow-x: auto;
  flex-wrap: nowrap;
}

.dep-card {
  height: 85vh;
  margin: 1px;
  padding: 10px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.service-col {
  margin: 0px;
  width: calc(100% - 20px);
}

@media (max-width: 600px) {
  .dep-col {
    flex-basis: 100%;
  }
}
</style>
