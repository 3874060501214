<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title>Adicionar Nova Coluna</v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="title"
                label="Título"
                :rules="titleRules"
                required
                maxlength="60"
                counter
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="false">
              <v-textarea
                v-model="description"
                label="Descrição"
                :rules="descriptionRules"
                required
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="#0D47A1"
          style="color: white"
          class="text-capitalize"
          small
          @click="addColumn"
          >Adicionar</v-btn
        >
        <v-btn
          color="blue-accent-4"
          class="text-capitalize"
          small
          @click="close"
          >Cancelar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["columnOrder", "idKanban", "position", "idColumn"],
  data() {
    return {
      dialog: true,
      title: "",
      description: "",
      titleRules: [(v) => !!v || "O título é obrigatório"],
      descriptionRules: [(v) => !!v || "A descrição é obrigatória"],
    };
  },
  created() {
    console.log(this.idColumn, this.columnOrder, this.idKanban, this.position);
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
  },
  methods: {
    close() {
      this.$emit("closeAddColumn");
    },
    addColumn() {
      if (
        this.title &&
        this.idKanban &&
        typeof this.position !== "undefined" &&
        this.columnOrder !== undefined
      ) {
        const positionOffset = this.position === "right" ? 1 : 0;
        const newColumnOrder = parseInt(this.columnOrder) + positionOffset;
        const columnData = {
          title: this.title,
          description: this.description,
          id_kanban: this.idKanban,
          new_order: newColumnOrder,
        };

        this.createColumn(columnData);
      } else {
        console.error(
          "Dados necessários para adicionar a coluna estão incompletos."
        );
      }
    },

    createColumn(columnData) {
      const axios = this.$store.state.axios;
      const server = this.$store.state.serverExpress;
      axios
        .post(`${server}/kanban/addColumn`, {
          data: columnData,
          id_client: this.info.id_cliente,
          id_kanban: this.idKanban,
        })
        .then((response) => {
          console.log("Column added:", response.data);
          this.$root.$emit("updateDataForColumn", response.data.id);
          setTimeout(() => {
            this.$root.$emit("columnAdded", this.idKanban);
            setTimeout(() => {
              this.close();
            }, 100);
          }, 100);
        })
        .catch((error) => {
          console.error("Error adding column:", error);
        });
    },
  },
};
</script>
