<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-tabs
          v-model="tab"
          show-arrows
          center-active
          background-color="primary"
          dark
        >
          <v-tab class="text-capitalize">Geral</v-tab>
          <v-tab class="text-capitalize">Dados</v-tab>
          <v-tab class="text-capitalize">Arquivos</v-tab>
          <v-tab class="text-capitalize">Tarefas</v-tab>
          <v-tab class="text-capitalize">Timeline</v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="overflow-y-auto overflow-x-hidden">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card-title>
              <v-row dense class="mt-n1 ml-n7">
                <v-spacer></v-spacer>
                <!-- Data Inicial e Horário Inicial -->
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dateIni"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedDateIni"
                      label="Data Inicial"
                      prepend-icon="mdi-calendar"
                      style="width: 125px"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="disabled"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateIni"
                    no-title
                    scrollable
                    locale="pt-BR"
                    @change="$refs.menu.save(dateIni), changeDates()"
                  >
                  </v-date-picker>
                </v-menu>
                <v-text-field
                  v-model="startTime"
                  label="Horário Inicial"
                  type="time"
                  @blur="changeDates()"
                  :disabled="disabled"
                ></v-text-field>
                <!-- Data Final e Horário Final -->
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="dateEnd"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="ml-5"
                      v-model="formattedDateEnd"
                      label="Data Entrega"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      style="width: 125px"
                      persistent-hint
                      v-on="on"
                      :disabled="disabled"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateEnd"
                    no-title
                    scrollable
                    locale="pt-BR"
                    @change="$refs.menu2.save(dateEnd), changeDates()"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  v-model="endTime"
                  label="Horário Entrega"
                  type="time"
                  @blur="changeDates()"
                  :disabled="disabled"
                ></v-text-field>
                <v-chip
                  v-if="calculateStatus(dateIni, dateEnd)"
                  :color="dataChipColor(dateIni, dateEnd)"
                  label
                  text-color="white"
                  class="mt-4 ml-5"
                  small
                >
                  {{ calculateStatus(dateIni, dateEnd) }}
                </v-chip>
                <v-spacer></v-spacer>
                <v-combobox
                  style="max-width: 400px"
                  v-model="selectedTags"
                  :items="tags"
                  item-text="titulo"
                  item-value="id"
                  label="Etiquetas"
                  multiple
                  clearable
                  chips
                  deletable-chips
                  return-object
                  :search-input.sync="search"
                  @blur="handleBlur"
                  small
                  class="mr-10"
                  :disabled="disabled"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :color="data.item.cor"
                      dark
                      class="ma-2"
                      small
                    >
                      {{ data.item.titulo }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content v-if="data.item">
                      <v-list-item-title>
                        <v-chip
                          :color="data.item.cor"
                          small
                          text-color="white"
                          class="ml-5"
                        >
                          {{ data.item.titulo }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-combobox>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="title"
                    :disabled="disabled"
                    label="Título"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mt-n8">
                  <v-icon>mdi-text-long</v-icon>
                  <label class="body-1 font-weight-medium ml-3"
                    >Descrição</label
                  >
                  <div class="editor-container">
                    <vue-editor
                      v-model="description"
                      :editorToolbar="customToolbar"
                      class="editor-content mt-n8"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row
                ><v-col cols="12"
                  ><v-checkbox
                    class="mt-8"
                    :disabled="disabled"
                    v-model="checkbox"
                    label="Concluir Cartão"
                    @click="checkboxStatus()"
                  >
                  </v-checkbox></v-col
              ></v-row>
            </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <kanbanData
              class="overflow-y-auto"
              style="max-height: 72vh"
              :selectCard="selectCard"
              :idKanban="kanbanId"
              :permission="permission"
            />
          </v-tab-item>
          <v-tab-item>
            <kanbanArchives
              :selectCard="selectCard"
              :idKanban="kanbanId"
              :permission="permission"
            />
          </v-tab-item>
          <v-tab-item>
            <kanbanTask
              style="max-height: 72vh"
              :selectCard="selectCard"
              :idKanban="kanbanId"
              :permission="permission"
            />
          </v-tab-item>
          <v-tab-item class="overflow-y-auto" style="height: 72vh">
            <kanbanTimeline
              :selectCard="selectCard"
              :kanbanId="kanbanId"
              :permission="permission"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
      <v-card-actions>
        <v-alert
          v-if="showAlert"
          border="left"
          colored-border
          type="success"
          elevation="2"
          dense
          >Informações atualizadas com sucesso</v-alert
        >
        <v-spacer></v-spacer>
        <v-btn
          v-if="!disabled"
          color="#0D47A1"
          style="color: white"
          class="text-capitalize"
          @click="updateCard"
          dense
          >Salvar</v-btn
        >
        <v-btn
          v-if="!disabled"
          color="blue-accent-4"
          class="text-capitalize"
          small
          @click="showCardFinish = true"
          >Arquivar</v-btn
        >
        <v-btn
          v-if="disabled"
          color="blue-accent-4"
          class="text-capitalize"
          small
          @click="close"
          >Voltar</v-btn
        >
      </v-card-actions>
    </v-card>
    <kanbanCardFinish
      v-if="showCardFinish"
      :card="selectCard"
      @close="showCardFinish = false"
      @archive="archive, (dialog = false), (showCardFinish = false)"
      :kanbanId="kanbanId"
      :permission="permission"
    />
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import kanbanCardFinish from "./kanbanCardFinish.vue";
import kanbanTimeline from "./kanbanTimeline.vue";
import kanbanData from "./kanbanData.vue";
import kanbanTask from "./task/kanbanTask.vue";
import kanbanArchives from "./archives/kanbanArchives.vue";
import { VueEditor, Quill } from "vue2-editor";

const fontList = [
  "Arial",
  "Courier",
  "Garamond",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, "-");
}
const fontNames = fontList.map((font) => getFontName(font));
const fonts = Quill.import("formats/font");
fonts.whitelist = fontNames;
Quill.register(fonts, true);

let fontStyles = "";
fontList.forEach((font) => {
  let fontName = getFontName(font);
  fontStyles += `.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=${fontName}]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=${fontName}]::before {
    content: '${font}';
    font-family: '${font}', sans-serif;
  }
  .ql-font-${fontName} {
    font-family: '${font}', sans-serif;
  }`;
});

// Injetar estilos no documento
const node = document.createElement("style");
node.innerHTML = fontStyles;
document.body.appendChild(node);

export default {
  components: {
    kanbanCardFinish,
    kanbanTimeline,
    kanbanData,
    kanbanTask,
    kanbanArchives,
    VueEditor,
  },
  props: ["selectCard", "kanbanId", "permission"],
  data() {
    return {
      dialog: true,
      tab: null,
      description: "",
      title: "",
      showCardFinish: false,
      tags: [],
      search: "",
      selectedTags: [],
      dateIni: null,
      dateEnd: null,
      menu: false,
      menu2: false,
      picker: null,
      startTime: null,
      endTime: null,
      checkbox: false,
      customToolbar: [
        [{ font: fonts.whitelist }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }, { background: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link", "image", "video"],
        ["clean"],
      ],
      showAlert: false,
      updatedInformation: false,
    };
  },
  mounted() {
    console.log("selectCard:", this.selectCard);
    this.title = this.selectCard.titulo;
    this.description = this.selectCard.descricao;
    this.selectedTags = this.selectCard.etiquetas;
    this.dateIni = this.selectCard.data_inicial;
    this.startTime = this.selectCard.hora_inicial;
    this.dateEnd = this.selectCard.data_final;
    this.endTime = this.selectCard.hora_final;
    this.listenUpdate();
  },
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    listenUpdate() {
      console.log("this.dateIni", this.dateIni);
      this.socket.on("updateCards", async (data) => {
        let decryp = await this.decrypt(data);
        console.log("decryp", decryp);
        if (decryp.data) {
          if (this.selectCard.id == decryp.data.id) {
            this.description = decryp.data.descricao;
            this.title = decryp.data.titulo;
          }
          if (decryp.data?.checkbox !== this.selectCard.status) {
            this.checkbox = decryp.data.status;
          }
        }
      });
    },
    async updateCard() {
      this.updatedInformation = true;
      const axios = this.$store.state.axios;
      const server = this.$store.state.serverExpress;
      let obj = {
        id: this.selectCard.id,
        titulo: this.title,
        descricao: this.description,
        id_cliente: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
        id_coluna: this.selectCard.id_coluna,
      };
      let objCrypt = await this.cryptoMsg(obj);
      axios
        .post(`${server}/kanban/updateCardDetails`, { data: objCrypt })
        .then(() => {
          this.$root.$emit("updateDataForColumn", this.selectCard.id_coluna);
          this.$root.$emit("saveDetail");
        })
        .catch((error) => {
          console.error("Erro ao atualizar o cartão:", error);
        });
      this.updatedInformation = false;
    },
    close() {
      this.$emit("closeCardDetail");
      this.dialog = false;
    },
    archive() {
      this.$root.$emit("updateDataForColumn", this.selectCard.id_coluna);
      this.$emit("closeCardDetail");
      this.dialog = false;
    },
    async getTags() {
      const axios = this.$store.state.axios;
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
      };
      let objCrypt = await this.cryptoMsg(obj);
      axios
        .post(`${this.server}/kanban/getTagsKanban`, { data: objCrypt })
        .then(async (res) => {
          if (res.status == 200) {
            let decrypt = await this.decrypt(res.data.data);
            this.tags = decrypt.data;
          } else {
            this.tags = [];
          }
        });
    },
    async handleBlur() {
      const axios = this.$store.state.axios;
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
        id_card: this.selectCard.id,
        id_column: this.selectCard.id_coluna,
        tags: this.selectedTags,
      };
      let objCrypt = await this.cryptoMsg(obj);
      axios
        .post(`${this.server}/kanban/addTagsToCard`, { data: objCrypt })
        .then(async (res) => {
          if (res.status == 200) {
            console.log("Sucesso handleBlur");
          } else {
            this.tags = [];
          }
        });
    },
    async changeDates() {
      const axios = this.$store.state.axios;
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
        id_card: this.selectCard.id,
        id_column: this.selectCard.id_coluna,
        dateIni: this.dateIni,
        startTime: this.startTime,
        dateEnd: this.dateEnd,
        endTime: this.endTime,
      };
      let objCrypt = await this.cryptoMsg(obj);
      axios
        .post(`${this.server}/kanban/updateDatesToCard`, { data: objCrypt })
        .then(async (res) => {
          console.log(res);
          if (res.status == 200) {
            console.log("Sucesso changeDates");
          }
        });
    },
    isNullOrEmpty(value) {
      return !value || value.trim().length === 0;
    },
    formatDateToBrazilian(date) {
      if (!date) return null; // Trata valores nulos ou indefinidos
      let dataObj;
      // Verifica se a data está no formato ISO 8601
      if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/.test(date)) {
        dataObj = new Date(date);
      } else {
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      }
      const dia = String(dataObj.getDate()).padStart(2, "0");
      const mes = String(dataObj.getMonth() + 1).padStart(2, "0");
      const ano = dataObj.getFullYear();
      return `${dia}/${mes}/${ano}`;
    },
    calculateStatus(dateIni, dateEnd) {
      if (this.checkbox) {
        return "Cartão concluído";
      } else if (!this.isNullOrEmpty(dateIni) && !this.isNullOrEmpty(dateEnd)) {
        const endDate = new Date(dateEnd);
        const startDate = new Date(dateIni);
        const diffDays = Math.ceil(
          (endDate - startDate) / (1000 * 60 * 60 * 24)
        ); // Diferença em dias
        if (diffDays >= 7) {
          return "Entrega dentro do prazo";
        } else if (diffDays > 0 && diffDays < 7) {
          return "Entregar em breve";
        } else if (startDate > endDate) {
          return "Entrega em atraso";
        }
      }
    },
    dataChipColor(dateIni, dateEnd) {
      if (this.checkbox) {
        return "#1B5E20";
      } else if (!this.isNullOrEmpty(dateIni) && !this.isNullOrEmpty(dateEnd)) {
        const status = this.calculateStatus(dateIni, dateEnd);
        switch (status) {
          case "Entrega dentro do prazo":
            return "#1B5E20";
          case "Entregar em breve":
            return "#FFAB00";
          case "Entrega em atraso":
            return "#D50000";
        }
      }
    },
    async checkboxStatus() {
      const axios = this.$store.state.axios;
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
        id_card: this.selectCard.id,
        id_column: this.selectCard.id_coluna,
        status: this.checkbox,
      };
      console.log("checkboxStatus: ", obj);
      let objCrypt = await this.cryptoMsg(obj);
      axios
        .post(`${this.server}/kanban/updateStatusCard`, { data: objCrypt })
        .then(async (res) => {
          if (res.status == 200) {
            console.log(res);
          } else {
            this.tags = [];
          }
        });
    },
  },
  computed: {
    disabled() {
      return this.permission === "Leitura";
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    formattedDateIni() {
      if (!this.dateIni) return "";
      return this.formatDateToBrazilian(this.dateIni);
    },
    formattedDateEnd() {
      if (!this.dateEnd) return "";
      return this.formatDateToBrazilian(this.dateEnd);
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue && !this.showCardFinish) {
        this.$emit("closeCardDetail");
      }
    },
    updatedInformation(newValue) {
      if (newValue) {
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 3000);
      }
    },
  },
  created() {
    this.getTags();
    this.checkbox = this.selectCard.status;
    console.log("SelectedCard: ", this.selectCard);
  },
};
</script>

<style scoped>
.small-textarea {
  font-size: 14px;
}
.editor-container {
  position: relative;
  height: 350px; /* Ajuste a altura conforme necessário */
}
.editor-content {
  position: absolute;
  top: 40px; /* Ajuste a altura de acordo com a toolbar */
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
