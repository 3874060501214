<template>
        <v-row class="mt-3" align="center" justify="center">
        <v-col cols="3">
            <div class="text-center">
                <div class="headline">{{awating}}</div>
                <div>Aguardando</div>
            </div>
        </v-col>
        <v-col cols="3">
            <div class="text-center">
                <div class="headline">{{ inService }}</div>
                <div>Em Atendimento</div>
            </div>
        </v-col>
        <v-col cols="3">
            <div class="text-center">
                <div class="headline">{{finish}}</div>
                <div>Finalizados</div>
            </div>
        </v-col>
        <v-col cols="3">
            <div class="text-center">
                <div class="headline">{{total}}</div>
                <div>Total</div>
            </div>
        </v-col>
        </v-row>
</template>

<script>
export default {
    props:['date'],
    components:{
       
    },
    data(){
        return{
            r:"/report/headline",
            items:[{count:0},{count:0},{count:0},{count:0}]
        }
    },
    created(){
        console.log(this.date)
        this.data()
    },
    watch:{
        date(newValue){
           if(newValue.length==2){
            this.data()
           }
        }
    },
    computed:{
        awating(){
            return this.items[0]?.count
        },
        inService(){
            return this.items[1]?.count
        },
        finish(){
            return this.items[2]?.count
        },
        total(){
            return this.items[3]?.count
        },
        axios() {
            return this.$store.state.axios;
        },
        server() {
            return this.$store.state.serverExpress;
        },
        info() {
            return this.$store.state.info;
        },
    },
    methods:{
        data(){
            this.axios
            .post(`${this.server}${this.r}`,{
                n:this.info.id_numero,
                c:this.info.id_cliente,
                d:this.date
            })
            .then((res) => {
                switch(res.status){
                    case 200:
                        console.log(res.data.data)
                        this.items = res.data.data
                        this.$emit("headline_ready")
                        break;
                    case 201:
                        this.items = []
                        //IDEIA como não enconrtou registro posso colocar um tutorial sobre a função/tela
                        break;
                    case "202":
                        break;
                    case "203":                    
                        break;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }
}
</script>