<template>
  <v-dialog v-model="dialog" max-width="780px">
    <v-toolbar dense dark color="primary">
      <v-card-title>Histórico Chat</v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card
      class="overflow-y-auto"
      v-chat-scroll
      style="height: 500px; overflow-y: auto"
      id="chatContainer"
    >
      <v-list id="download">
        <v-list-item
          dense
          v-for="lineOfChat in conversation"
          :key="lineOfChat.id"
          three-line
        >
          <v-list-item-content
            class="mt-1 text-right rounded grey lighten-2"
            v-if="!lineOfChat.sentido"
          >
            <v-list-item-title
              v-if="lineOfChat.tipo == 'texto'"
              class="mr-1 text-wrap font-weight-light body-1"
            >
              {{ lineOfChat.mensagem }}
              <v-avatar size="36px" class="mr-3">
                <v-icon alt="Avatar">mdi-account</v-icon>
              </v-avatar>
            </v-list-item-title>
            <v-list-item-title
              v-if="lineOfChat.tipo == 'imagem'"
              class="mr-1 text-wrap font-weight-light body-1"
            >
              <a :href="convertPath(lineOfChat.mensagem)" download>
                <img :src="convertPath(lineOfChat.mensagem)" width="400" />
              </a>
              <v-avatar size="36px" class="mr-3">
                <v-icon alt="Avatar">mdi-account</v-icon>
              </v-avatar>
            </v-list-item-title>
            <v-list-item-title
              v-else-if="lineOfChat.tipo == 'video'"
              class="ml-1 text-wrap font-weight-light body-1"
            >
              <a :href="convertPath(lineOfChat.mensagem)" download>
                <video controls id="video">
                  <source id="source" :src="convertPath(lineOfChat.mensagem)" />
                </video>
              </a>
              <v-avatar size="36px" class="mr-3">
                <v-icon alt="Avatar">mdi-account</v-icon>
              </v-avatar>
            </v-list-item-title>
            <v-list-item-title
              v-if="lineOfChat.tipo == 'pdf'"
              class="mr-1 text-wrap font-weight-light body-1"
            >
              <div style="margin-left: 3px; height: 200px; width: 400px">
                <a :href="convertPath(lineOfChat.mensagem)" download>
                  <pdf :src="convertPath(lineOfChat.mensagem)" />
                </a>
              </div>
              <v-avatar size="36px" class="mr-3">
                <v-icon alt="Avatar">mdi-account</v-icon>
              </v-avatar>
            </v-list-item-title>
            <v-list-item-title
              v-else-if="lineOfChat.tipo == 'audio'"
              class="ml-1 text-wrap font-weight-light body-1"
            >
              <a :href="convertPath(lineOfChat.mensagem)" download>
                <audio controls id="audio">
                  <source
                    id="source"
                    :src="convertPath(lineOfChat.mensagem)"
                    type="audio/ogg"
                  />
                </audio>
              </a>
              <v-avatar size="36px" class="mr-3">
                <v-icon alt="Avatar">mdi-account</v-icon>
              </v-avatar>
            </v-list-item-title>
            <v-list-item-title
              v-else-if="lineOfChat.tipo == 'file'"
              class="ml-1 text-wrap font-weight-light body-1"
            >
              <a :href="convertPath(lineOfChat.mensagem)" download> Arquivo </a>
              <v-avatar size="36px" class="mr-3">
                <v-icon alt="Avatar">mdi-account</v-icon>
              </v-avatar>
            </v-list-item-title>
            <v-list-item-subtitle class="mr-4 caption">
              {{ formatDate(lineOfChat.horario) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content
            class="mt-1 text-left rounded grey lighten-1"
            v-if="lineOfChat.sentido"
          >
            <v-list-item-title
              v-if="lineOfChat.tipo == 'texto'"
              class="ml-1 text-wrap font-weight-light body-1"
            >
              <v-avatar size="36px" class="mr-3">
                <img
                  alt="Avatar"
                  v-if="service.img != 'undefined' && service.img != 'null'"
                  :src="service.img"
                />
                <v-icon alt="Avatar" v-else>mdi-account</v-icon>
              </v-avatar>
              {{ lineOfChat.mensagem }}
            </v-list-item-title>
            <v-list-item-title
              v-else-if="lineOfChat.tipo == 'imagem'"
              class="ml-1 text-wrap font-weight-light body-1"
            >
              <v-avatar size="36px" class="mr-3">
                <img
                  alt="Avatar"
                  v-if="service.img != 'undefined' && service.img != 'null'"
                  :src="service.img"
                />
                <v-icon alt="Avatar" v-else>mdi-account</v-icon>
              </v-avatar>
              <a :href="convertPath(lineOfChat.mensagem)" download>
                <img :src="convertPath(lineOfChat.mensagem)" width="400" />
              </a>
            </v-list-item-title>
            <v-list-item-title
              v-if="lineOfChat.tipo == 'pdf'"
              class="mr-1 text-wrap font-weight-light body-1"
            >
              <v-avatar size="36px" class="mr-3">
                <img
                  alt="Avatar"
                  v-if="service.img != 'undefined' && service.img != 'null'"
                  :src="service.img"
                />
                <v-icon alt="Avatar" v-else>mdi-account</v-icon>
              </v-avatar>
              <div style="margin-left: 42px; height: 200px; width: 400px">
                <a :href="convertPath(lineOfChat.mensagem)" download>
                  <pdf :src="convertPath(lineOfChat.mensagem)" />
                </a>
              </div>
            </v-list-item-title>
            <v-list-item-title
              v-else-if="lineOfChat.tipo == 'audio'"
              class="ml-1 text-wrap font-weight-light body-1"
            >
              <v-avatar size="36px" class="mr-3">
                <img
                  alt="Avatar"
                  v-if="service.img != 'undefined' && service.img != 'null'"
                  :src="service.img"
                />
                <v-icon alt="Avatar" v-else>mdi-account</v-icon>
              </v-avatar>
              <a :href="convertPath(lineOfChat.mensagem)" download>
                <audio controls id="audio">
                  <source
                    id="source"
                    :src="convertPath(lineOfChat.mensagem)"
                    type="audio/ogg"
                  />
                </audio>
              </a>
            </v-list-item-title>
            <v-list-item-title
              v-else-if="lineOfChat.tipo == 'video'"
              class="ml-1 text-wrap font-weight-light body-1"
            >
              <v-avatar size="36px" class="mr-3">
                <img
                  alt="Avatar"
                  v-if="service.img != 'undefined' && service.img != 'null'"
                  :src="service.img"
                />
                <v-icon alt="Avatar" v-else>mdi-account</v-icon>
              </v-avatar>
              <a :href="convertPath(lineOfChat.mensagem)" download>
                <video controls id="video">
                  <source id="source" :src="convertPath(lineOfChat.mensagem)" />
                </video>
              </a>
            </v-list-item-title>
            <v-list-item-title
              v-else-if="lineOfChat.tipo == 'file'"
              class="ml-1 text-wrap font-weight-light body-1"
            >
              <v-avatar size="36px" class="mr-3">
                <img
                  alt="Avatar"
                  v-if="service.img != 'undefined' && service.img != 'null'"
                  :src="service.img"
                />
                <v-icon alt="Avatar" v-else>mdi-account</v-icon>
              </v-avatar>
              <a :href="convertPath(lineOfChat.mensagem)" download> Arquivo </a>
            </v-list-item-title>
            <v-list-item-subtitle class="ml-4 caption">
              {{ formatDate(lineOfChat.horario) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import html2canvas from "html2canvas";
import VueChatScroll from "vue-chat-scroll";
import pdf from "vue-pdf";

import jsPDF from "jspdf";
export default {
  directives: {
    "chat-scroll": VueChatScroll,
  },
  props: ["conversation", "service"],
  components: { pdf },
  data() {
    return {
      dialog: true,
    };
  },
  created() {
    console.log(this.conversation);
  },
  methods: {
    async download() {
      const reportElement = document.getElementById("download");

      const pageHeight = 1430;

      const contentHeight = reportElement.scrollHeight;

      const totalPages = Math.ceil(contentHeight / pageHeight);

      const pdf = new jsPDF();

      for (let pageNumber = 0; pageNumber < totalPages; pageNumber++) {
        const startY = pageNumber * pageHeight;
        const endY = Math.min((pageNumber + 1) * pageHeight, contentHeight);

        const canvas = await html2canvas(reportElement, {
          height: endY - startY,
          width: reportElement.offsetWidth,
          y: startY,
        });

        const imageData = canvas.toDataURL("image/png");

        const imgWidth = pdf.internal.pageSize.getWidth() - 20;
        const imgHeight =
          (endY - startY) * (imgWidth / reportElement.offsetWidth);

        pdf.addImage(imageData, "PNG", 10, 10, imgWidth, imgHeight);

        if (pageNumber < totalPages - 1) {
          pdf.addPage();
        }
      }

      pdf.save("relatorio.pdf");
    },
    formatDate(date) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return new Date(date).toLocaleString("pt-BR", options);
    },
    convertPath(path) {
      return `https://${
        window.location.href.split("/")[2].split(":")[0]
      }:3020/midias${path.substring(10)}`;
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    screen() {
      return this.$store.state.screens.group;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("closeChat");
      }
    },
  },
};
</script>
