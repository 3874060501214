var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-main',[_c('div',{staticClass:"d-flex"},[_c('headerBar'),(
          ((_vm.auth && _vm.info.permissao == 'admin') ||
            _vm.info.permissao == 'supervisor' ||
            _vm.info.permissao == 'atendente') &&
          _vm.layout == 'true'
        )?_c('servicesScreen',{directives:[{name:"show",rawName:"v-show",value:(!_vm.kanbanScreen),expression:"!kanbanScreen"}]}):_vm._e(),(
          ((_vm.auth && _vm.info.permissao == 'admin') ||
            _vm.info.permissao == 'supervisor' ||
            _vm.info.permissao == 'atendente') &&
          _vm.layout == 'false'
        )?_c('servicesScreenNew',{directives:[{name:"show",rawName:"v-show",value:(!_vm.kanbanScreen),expression:"!kanbanScreen"}]}):(_vm.clients != 'false')?_c('clientsScreen'):_vm._e(),(_vm.kanbanScreen)?_c('kanbanScreen'):_vm._e(),(_vm.auth)?_c('depScreen'):_vm._e(),(_vm.auth)?_c('groupScreen'):_vm._e()],1),(_vm.auth && _vm.info == '')?_c('v-sheet',{staticClass:"text-center",attrs:{"text-color":"red","height":"200px"}},[_c('div',{staticClass:"py-2"},[_vm._v("Validando Credenciais")]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"purple"}})],1)],1)],1):_vm._e()],1),_c('wsClient'),_c('v-bottom-sheet',{attrs:{"persistent":""},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center"},[_c('v-snackbar',{attrs:{"color":"red","bottom":true,"timeout":"-1"},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(_vm._s(_vm.sheetDialog))])])],1)],1),_c('v-snackbar',{attrs:{"color":"red","bottom":true,"timeout":"-1"},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(_vm._s(_vm.sheetDialog))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }