<template>
  <v-app>
    <v-main>
      <div class="d-flex">
        <headerBar />
        <servicesScreen
          v-if="
            ((auth && info.permissao == 'admin') ||
              info.permissao == 'supervisor' ||
              info.permissao == 'atendente') &&
            layout == 'true'
          "
          v-show="!kanbanScreen"
        />
        <servicesScreenNew
          v-if="
            ((auth && info.permissao == 'admin') ||
              info.permissao == 'supervisor' ||
              info.permissao == 'atendente') &&
            layout == 'false'
          "
          v-show="!kanbanScreen"
        />
        <clientsScreen v-else-if="clients != 'false'" />
        <kanbanScreen v-if="kanbanScreen" />
        <depScreen v-if="auth" />
        <groupScreen v-if="auth" />
      </div>
      <v-sheet
        v-if="auth && info == ''"
        class="text-center"
        text-color="red"
        height="200px"
      >
        <div class="py-2">Validando Credenciais</div>
        <v-row align="center" justify="center">
          <v-col>
            <v-progress-circular
              indeterminate
              color="purple"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-sheet>
    </v-main>
    <wsClient />
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center">
        <v-snackbar color="red" v-model="sheet" :bottom="true" timeout="-1">
          <span style="color: black">{{ sheetDialog }}</span>
        </v-snackbar>
      </v-sheet>
    </v-bottom-sheet>
    <v-snackbar v-model="showSnackbar" color="red" :bottom="true" timeout="-1">
      <span style="color: black">{{ sheetDialog }}</span>
    </v-snackbar>
  </v-app>
</template>

<script>
/* eslint-disable */
import headerBar from "./components/header/headerBar";
import wsClient from "./components/wsClient.vue";
import servicesScreen from "./components/servicesScreen.vue";
import servicesScreenNew from "./components/servicesScreen/serviceScreen.vue";
import clientsScreen from "./components/clientsScreen.vue";
import depScreen from "./components/depsScreen.vue";
import groupScreen from "./components/group/groupScreen.vue";
import axios from "axios";
import kanbanScreen from "./components/kanban/kanbanScreen";

export default {
  data() {
    return {
      sheet: false,
      sheetDialog: "",
      showSnackbar: false,
    };
  },
  name: "App",
  components: {
    servicesScreenNew,
    headerBar,
    wsClient,
    servicesScreen,
    clientsScreen,
    kanbanScreen,
    depScreen,
    groupScreen,
  },
  created() {
    this.$store.state.axios = axios;
  },
  computed: {
    layout() {
      return this.$store.state.screens.kanbanLayout;
    },
    kanbanScreen() {
      return this.$store.state.kanbanScreen;
    },
    status() {
      return this.$store.state.libStatus;
    },
    height() {
      return window.screen.height - (window.screen.height * 10) / 100;
    },
    auth() {
      return this.$store.state.isAuth;
    },
    info() {
      return this.$store.state.info;
    },
    clients() {
      return this.$store.state.clients;
    },
  },
  watch: {
    status(newValue) {
      console.log(newValue);
      if (newValue != "inChat") {
        if (this.info.permissao != "admin") {
          this.sheet = true;
          this.sheetDialog =
            "Para prosseguir, é necessário realizar a leitura do QR Code.\n O sistema permanecerá inacessível até que a leitura seja concluída. Por favor, contate um administrador para proceder com a operação.";
        } else {
          this.showSnackbar = true;
          this.sheetDialog =
            "Para realizar a leitura do QR Code, por favor, navegue até as abas 'Admin' > 'QR Code'.";
        }
      } else {
        this.sheet = false;
        this.showSnackbar = false;
      }
    },
    info(newValue) {
      console.log(newValue);
      console.log(localStorage.getItem(`${newValue.nome}_kanbanLayout`));
      let layout = localStorage.getItem(`${this.info.nome}_kanbanLayout`);
      console.log(localStorage.getItem(`${this.info.nome}_kanbanLayout`));
      if (layout == null || layout == "undefined") {
        layout = "true";
      }
      console.warn(layout);
      this.$store.state.screens.kanbanLayout = layout;
      if (newValue != "") {
        this.$store.state.axios.defaults.headers.common[
          "x-access"
        ] = `${this.info.token}`;
      }
    },
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
