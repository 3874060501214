<template>
  <div>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>
          Chat com {{ attendant.nome }}
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="showFiles = true"
                v-bind="attrs"
                v-on="on"
                icon
                class="mr-3 rounded-circle"
              >
                <v-icon color="primary">mdi-file-multiple</v-icon>
              </v-btn>
            </template>
            <span>Arquivos</span>
          </v-tooltip>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div
            ref="chatContainer"
            class="chat-window"
            v-chat-scroll
            @scroll="handleScroll"
          >
            <v-list>
              <v-list-item
                v-for="message in displayedMessages"
                :key="message.id"
                three-line
              >
                <!-- Mensagem Enviada -->
                <v-list-item-content
                  class="mt-1 text-right rounded grey lighten-2"
                  v-if="message.id_origem == info.id"
                >
                  <v-list-item-title
                    class="mr-1 text-wrap font-weight-light body-1"
                  >
                    <!-- Exibir conteúdo textual, áudio ou mídia -->
                    <template v-if="message.tipo === 'audio'">
                      <audio
                        :src="midiaServer + '/' + convertPaht(message.arquivo)"
                        controls
                      ></audio>
                    </template>
                    <template v-else-if="message.tipo === 'image'">
                      <img
                        :src="midiaServer + '/' + convertPaht(message.arquivo)"
                        class="preview-image"
                      />
                      <v-btn icon @click="downloadFile(message)">
                        <v-icon color="blue darken-1">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <template v-else-if="message.tipo === 'video'">
                      <video
                        :src="midiaServer + '/' + convertPaht(message.arquivo)"
                        controls
                        class="preview-video"
                      ></video>
                      <v-btn icon @click="downloadFile(message)">
                        <v-icon color="blue darken-1">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <template v-else-if="message.tipo === 'pdf'">
                      <embed
                        :src="midiaServer + '/' + convertPaht(message.arquivo)"
                        type="application/pdf"
                        class="preview-pdf"
                      />
                      <v-btn icon @click="downloadFile(message)">
                        <v-icon color="blue darken-1">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <template v-else-if="message.tipo === null">
                      {{ message.conteudo }}
                    </template>
                    <template v-else>
                      {{ fileName(message) }}
                      <v-btn icon @click="downloadFile(message)">
                        <v-icon color="blue darken-1">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <v-avatar size="36px">
                      <v-icon alt="Avatar">mdi-account</v-icon>
                    </v-avatar>
                  </v-list-item-title>
                  <v-list-item-subtitle class="mr-4 caption">
                    {{ message.horario }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <!-- Mensagem Recebida -->
                <v-list-item-content
                  class="mt-1 text-left rounded grey lighten-1"
                  v-if="message.id_destino == info.id"
                >
                  <v-list-item-title
                    class="ml-1 text-wrap font-weight-light body-1"
                  >
                    <!-- Exibir conteúdo textual, áudio ou mídia -->
                    <v-avatar size="36px" class="mr-3">
                      <v-icon alt="Avatar">mdi-account</v-icon>
                    </v-avatar>
                    <template v-if="message.tipo === 'audio'">
                      <audio
                        :src="midiaServer + '/' + convertPaht(message.arquivo)"
                        controls
                      ></audio>
                      <v-btn icon @click="downloadFile(message)">
                        <v-icon color="blue darken-1">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <template v-else-if="message.tipo === 'image'">
                      <img
                        :src="midiaServer + '/' + convertPaht(message.arquivo)"
                        class="preview-image"
                      />
                      <v-btn icon @click="downloadFile(message)">
                        <v-icon color="blue darken-1">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <template v-else-if="message.tipo === 'video'">
                      <video
                        :src="midiaServer + '/' + convertPaht(message.arquivo)"
                        controls
                        class="preview-video"
                      ></video>
                      <v-btn icon @click="downloadFile(message)">
                        <v-icon color="blue darken-1">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <template v-else-if="message.tipo === 'pdf'">
                      <embed
                        :src="midiaServer + '/' + convertPaht(message.arquivo)"
                        type="application/pdf"
                        class="preview-pdf"
                      />
                      <v-btn icon @click="downloadFile(message)">
                        <v-icon color="blue darken-1">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <template v-else-if="message.tipo == null">
                      {{ message.conteudo }}
                    </template>
                    <template v-else>
                      {{ fileName(message) }}
                      <v-btn icon @click="downloadFile(message)">
                        <v-icon color="blue darken-1">mdi-download</v-icon>
                      </v-btn>
                    </template>
                  </v-list-item-title>
                  <v-list-item-subtitle class="ml-4 caption">
                    {{ message.horario }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>

        <v-card-actions v-if="!selectedFile && !audioURL">
          <v-textarea
            v-model="newMessage"
            label="Digite sua mensagem"
            hide-details
            rows="3"
            @keydown="handleKeyDown"
          ></v-textarea>

          <v-btn icon @click="$refs.fileInput.click()" class="mt-7"
            ><v-icon>mdi-paperclip</v-icon>
          </v-btn>
          <input
            ref="fileInput"
            type="file"
            style="display: none"
            @change="handleFileUpload"
            accept="image/*,video/*,application/pdf"
          />

          <div v-if="isRecording" class="microphone-button mt-7">
            <v-btn icon @click="stopRecording">
              <v-icon color="red">mdi-microphone-off</v-icon>
            </v-btn>
          </div>
          <div v-else class="microphone-button mt-7">
            <v-btn icon @click="startRecording">
              <v-icon>mdi-microphone</v-icon>
            </v-btn>
          </div>
        </v-card-actions>
        <!-- Exibir botões para enviar/cancelar após selecionar um arquivo -->
        <v-card-actions
          v-if="selectedFile"
          class="file-controls d-flex align-center"
        >
          <!-- Mostra o nome do arquivo -->
          <span>{{ selectedFile.name }}</span>

          <!-- Espaçador para empurrar os botões para o lado direito -->
          <v-spacer></v-spacer>

          <!-- Botão de cancelar seleção de arquivo -->
          <v-btn icon color="red" @click="cancelFile">
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

          <!-- Botão de enviar arquivo -->
          <v-btn @click="sendMessage" color="primary">Enviar</v-btn>
        </v-card-actions>
        <v-card v-if="audioURL" class="audio-preview">
          <audio :src="audioURL" controls class="ma-3"></audio>
          <div class="audio-controls">
            <v-btn icon color="red" @click="cancelAudio">
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
            <v-btn @click="sendAudio" color="primary mr-3">Enviar</v-btn>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
    <filesScreen
      v-if="showFiles"
      @hide="showFiles = false"
      :messages="messageFiles()"
    />
  </div>
</template>

<script>
import VueChatScroll from "vue-chat-scroll";
import { mapActions } from "vuex";
import filesScreen from "./filesScreen.vue";

export default {
  components: { filesScreen },
  directives: {
    "chat-scroll": VueChatScroll,
  },
  props: {
    attendant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showFiles: false,
      dialog: true,
      messages: [],
      displayedMessages: [],
      newMessage: "",
      selectedFile: null,
      audioURL: null,
      recordedAudio: null,
      mediaRecorder: null,
      isRecording: false,
      messagesPerPage: 50,
      currentPage: 1,
      isLoading: false,
      initialLoad: true,
      lastScrollTop: 0,
    };
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
    midiaServer() {
      return this.server.split(":3")[0] + ":3020";
    },
  },
  methods: {
    fileName(message) {
      return message.arquivo.split("/")[3];
    },
    downloadFile(file) {
      console.log("Baixando arquivo:", file);
      const link = document.createElement("a");
      link.href = `${this.midiaServer}/${this.convertPaht(file.arquivo)}`;
      link.download = file.arquivo.split("/")[2];
      link.click();
    },
    messageFiles() {
      return this.messages.filter((msg) => {
        return msg.tipo != "texto" && msg.tipo != null && msg.tipo != "";
      });
    },
    convertPaht(path) {
      return "midias" + path.split("midia")[1];
    },
    ...mapActions(["cryptoMsg", "decrypt"]),
    async fetchMessages() {
      try {
        let obj = {
          id_client: this.info.id_cliente,
          id_attendant: this.info.id,
          id_number: this.info.id_numero,
          id_destiny: this.attendant.id,
        };
        let objCrypt = await this.cryptoMsg(obj);
        this.axios
          .post(`${this.server}/chat/messages/`, { data: objCrypt })
          .then(async (res) => {
            switch (res.status) {
              case 200:
                this.messages = await this.decrypt(res.data.data);
                console.log(this.messages);
                this.updateDisplayedMessages();
                break;
              case 201:
                this.messages = [];
                break;
            }
          });
      } catch (error) {
        console.error("Erro ao buscar mensagens:", error);
      }
    },
    async sendMessage() {
      if (this.newMessage.trim() === "" && !this.selectedFile) return;

      try {
        let messageObj = {
          id_client: this.info.id_cliente,
          id_origin: this.info.id,
          id_destiny: this.attendant.id,
          content: this.newMessage,
          file: null,
          tipo: this.selectedFile ? "file" : "text",
        };

        if (this.selectedFile) {
          await this.uploadFile();
          this.newMessage = "";
          this.selectedFile = null;
          this.fetchMessages(); // Atualiza a lista de mensagens após o envio
        } else {
          let encryptedMessageObj = await this.cryptoMsg(messageObj);

          await this.axios.post(`${this.server}/chat/messages/new`, {
            data: encryptedMessageObj,
          });

          this.newMessage = "";
          this.selectedFile = null;
          this.fetchMessages(); // Atualiza a lista de mensagens após o envio
        }
      } catch (error) {
        console.error("Erro ao enviar mensagem:", error);
      }
    },
    getFileType(mimeType) {
      if (mimeType.startsWith("image/")) return "image";
      if (mimeType.startsWith("video/")) return "video";
      if (mimeType === "application/pdf") return "pdf";
      return "file";
    },
    async uploadFile() {
      const formData = new FormData();
      formData.append("id_origin", this.info.id);
      formData.append("id_destiny", this.attendant.id);
      formData.append("id_client", this.info.id_cliente);
      formData.append("file", this.selectedFile);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      try {
        const response = await this.axios.post(
          `${this.server}/chat/messages/file`,
          formData,
          config
        );
        this.selectedFile.path = response.data.filePath;
      } catch (error) {
        console.error("Erro ao enviar arquivo:", error);
      }
    },
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    cancelFile() {
      this.selectedFile = null;
    },
    handleKeyDown(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        this.sendMessage();
      }
    },
    startRecording() {
      if (!navigator.mediaDevices) return;

      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.start();

        const audioChunks = [];
        this.mediaRecorder.addEventListener("dataavailable", (event) => {
          audioChunks.push(event.data);
        });

        this.mediaRecorder.addEventListener("stop", () => {
          const audioBlob = new Blob(audioChunks);
          this.audioURL = URL.createObjectURL(audioBlob);
          this.recordedAudio = audioBlob;
        });

        this.isRecording = true;
      });
    },
    stopRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.isRecording = false;
      }
    },
    cancelAudio() {
      this.recordedAudio = null;
      this.audioURL = null;
    },
    async sendAudio() {
      if (this.recordedAudio) {
        try {
          const formData = new FormData();
          formData.append(
            "audio",
            this.recordedAudio,
            `audio-${Date.now()}.webm`
          );
          formData.append("id_origin", this.info.id);
          formData.append("id_destiny", this.attendant.id);
          formData.append("id_client", this.info.id_cliente);

          let config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          await this.axios.post(
            `${this.server}/chat/messages/audio`,
            formData,
            config
          );

          this.cancelAudio(); // Limpa o estado de áudio após o envio
          this.fetchMessages(); // Atualiza a lista de mensagens
        } catch (error) {
          console.error("Erro ao enviar áudio:", error);
        }
      }
    },
    updateDisplayedMessages() {
      const start = Math.max(
        this.messages.length - this.currentPage * this.messagesPerPage,
        0
      );
      const end = this.messages.length;
      this.displayedMessages = this.messages.slice(start, end);
      this.scrollToBottom();
    },
    handleScroll(event) {
      const container = event.target;
      const scrollTop = container.scrollTop;

      if (
        scrollTop === 0 &&
        !this.isLoading &&
        this.displayedMessages.length < this.messages.length
      ) {
        this.isLoading = true;
        this.currentPage++;
        this.loadMoreMessages();
      }

      this.lastScrollTop = scrollTop;
    },
    loadMoreMessages() {
      this.updateDisplayedMessages();
      this.isLoading = false;

      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        if (container) {
          container.scrollTop = container.scrollHeight / this.currentPage;
        }
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        if (container) {
          setTimeout(() => {
            container.scrollTop = container.scrollHeight;
          }, 100);
        }
      });
    },
    formatDate(date) {
      const options = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return new Date(date).toLocaleString("pt-BR", options);
    },
  },
  created() {
    this.fetchMessages();
    this.socket.on("chatUpdate", async (data) => {
      let decrypted = await this.decrypt(data);
      if (decrypted.id_destiny == this.info.id) {
        this.fetchMessages();
        this.scrollToBottom();
        this.$emit("new");
      }
    });
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped>
.chat-window {
  height: 60vh;
  overflow-y: auto;
}

.preview-image,
.preview-video,
.preview-pdf {
  max-width: 200px;
  max-height: 200px;
}

.preview-pdf {
  width: 100%;
  height: auto;
}

.microphone-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.audio-preview,
.file-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audio-controls,
.file-controls {
  display: flex;
  gap: 10px;
}
</style>
