<template>
  <v-container fluid>
    <v-card>
      <v-card-title v-if="service.contato_zap == ''"
        >{{ service.contato_4red }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="dialogDetail = true"
              v-bind="attrs"
              v-on="on"
              icon
              class="ml-1 rounded-circle"
            >
              <v-icon color="primary">mdi-account-details-outline</v-icon>
            </v-btn>
          </template>
          <span>Editar Contato</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <tagSelect
          :id_dep="service.id_departamento"
          :id_group="service.id_grupo"
          :id_service="service.id_atendimento"
        />
        <v-menu offset-y v-if="service.integracao.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon class="mr-3 rounded-circle">
              <v-icon color="success">mdi-shield-link-variant-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in service.integracao"
              :key="index"
            >
              <v-btn
                @click="showInt(item)"
                class="mr-8 text-capitalize"
                color="primary"
                >{{ item.integracao_nome }}</v-btn
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip bottom v-if="service.id_grupo > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="(showInfo = true), (itemId = service.id_grupo)">
              <v-icon color="purple lighten-2" v-bind="attrs" v-on="on"
                >mdi-account-group-outline</v-icon
              >
            </v-btn>
          </template>
          <span>Informações do grupo</span>
        </v-tooltip>
        <v-tooltip bottom v-if="extension != '' && info.telefonia">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="makeCall"
              v-bind="attrs"
              color="red"
              v-on="on"
              small
              icon
            >
              <v-icon dark> mdi-phone-in-talk-outline </v-icon>
            </v-btn>
          </template>
          <span>Ligar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="showTransfer = true"
              v-bind="attrs"
              v-on="on"
              icon
              class="mr-3 rounded-circle"
            >
              <v-icon color="primary">mdi-swap-horizontal-bold</v-icon>
            </v-btn>
          </template>
          <span>Transferir Chamado</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="showFiles = true"
              v-bind="attrs"
              v-on="on"
              icon
              class="mr-3 rounded-circle"
            >
              <v-icon color="primary">mdi-file-multiple</v-icon>
            </v-btn>
          </template>
          <span>Arquivos</span>
        </v-tooltip>
        <v-btn @click="finish" class="text-capitalize" small color="#E57373"
          >Encerrar</v-btn
        ><v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }"
            ><v-btn
              v-bind="attrs"
              v-on="on"
              @click="finishSilence"
              class="text-capitalize ml-2"
              v-if="info.permissao == 'supervisor' || info.permissao == 'admin'"
              small
              color="#E57373"
              ><v-icon>mdi-television-speaker-off</v-icon></v-btn
            ></template
          >
          <span>Encerrar atendimento sem notificar o cliente</span>
        </v-tooltip>
      </v-card-title>
      <v-card-title v-else
        >{{ service.contato_zap }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="dialogDetail = true"
              v-bind="attrs"
              v-on="on"
              icon
              class="ml-1 rounded-circle"
            >
              <v-icon color="primary">mdi-account-details-outline</v-icon>
            </v-btn>
          </template>
          <span>Editar Contato</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <tagSelect
          :id_dep="service.id_departamento"
          :id_group="service.id_grupo"
          :id_service="service.id_atendimento"
        />
        <v-menu offset-y v-if="service.integracao.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon class="mr-3 rounded-circle">
              <v-icon color="success">mdi-shield-link-variant-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in service.integracao"
              :key="index"
            >
              <v-btn
                @click="showInt(item)"
                class="mr-8 text-capitalize"
                color="primary"
                >{{ item.integracao_nome }}</v-btn
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip bottom v-if="service.id_grupo > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="(showInfo = true), (itemId = service.id_grupo)">
              <v-icon color="purple lighten-2" v-bind="attrs" v-on="on"
                >mdi-account-group-outline</v-icon
              >
            </v-btn>
          </template>
          <span>Informações do grupo</span>
        </v-tooltip>
        <v-tooltip bottom v-if="extension != '' && info.telefonia">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="makeCall"
              v-bind="attrs"
              color="red"
              v-on="on"
              small
              icon
            >
              <v-icon dark> mdi-phone-in-talk-outline </v-icon>
            </v-btn>
          </template>
          <span>Ligar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="showTransfer = true"
              v-bind="attrs"
              v-on="on"
              icon
              class="mr-3 rounded-circle"
            >
              <v-icon color="primary">mdi-swap-horizontal-bold</v-icon>
            </v-btn>
          </template>
          <span>Transferir Chamado</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="false"
              @click="showFiles = true"
              v-bind="attrs"
              v-on="on"
              icon
              class="mr-3 rounded-circle"
            >
              <v-icon color="primary">mdi-file-multiple</v-icon>
            </v-btn>
          </template>
          <span>Arquivos</span>
        </v-tooltip>
        <v-btn @click="finish" class="text-capitalize" small color="#E57373"
          >Encerrar</v-btn
        ><v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }"
            ><v-btn
              v-bind="attrs"
              v-on="on"
              @click="finishSilence"
              class="text-capitalize ml-2"
              v-if="info.permissao == 'supervisor' || info.permissao == 'admin'"
              small
              color="#E57373"
              ><v-icon>mdi-television-speaker-off</v-icon></v-btn
            ></template
          >
          <span>Encerrar atendimento sem notificar o cliente</span>
        </v-tooltip></v-card-title
      >
      <v-card
        class="overflow-y-auto custom-scrollbar"
        v-chat-scroll
        style="height: 80vh; overflow-y: auto"
        id="chatContainer"
        @scroll="onScroll"
      >
        <v-list>
          <v-list-item
            dense
            v-for="lineOfChat in visibleMessages"
            :key="lineOfChat.id"
            three-line
          >
            <v-list-item-content
              class="mt-1 text-right rounded grey lighten-2"
              v-if="!lineOfChat.sentido"
            >
              <v-list-item v-if="lineOfChat.id_resposta">
                <v-list-item-content
                  class="rounded grey lighten-3"
                  v-if="lineOfChat.id_resposta.length > 0"
                >
                  <span
                    class="pr-2"
                    v-if="findMessageType(lineOfChat.id_resposta) == 'imagem'"
                  >
                    <a>
                      <img
                        @click="
                          (img = convertPath(
                            findMessage(lineOfChat.id_resposta)
                          )),
                            (showImg = true)
                        "
                        :src="convertPath(findMessage(lineOfChat.id_resposta))"
                        width="400"
                      />
                    </a>
                  </span>
                  <span
                    class="pr-2"
                    v-if="findMessageType(lineOfChat.id_resposta) == 'pdf'"
                  >
                    <div
                      @click="
                        downloadImage(
                          (img = convertPath(
                            findMessage(lineOfChat.id_resposta)
                          ))
                        )
                      "
                      style="margin-left: 42px; height: 200px; width: 400px"
                    >
                      <a>
                        <pdf
                          :src="
                            convertPath(findMessage(lineOfChat.id_resposta))
                          "
                        />
                      </a>
                    </div>
                  </span>
                  <span
                    class="pr-2"
                    v-if="findMessageType(lineOfChat.id_resposta) == 'audio'"
                  >
                    <audio controls id="audio">
                      <source
                        id="source"
                        :src="convertPath(findMessage(lineOfChat.id_resposta))"
                        type="audio/ogg"
                      />
                    </audio>
                  </span>
                  <span
                    class="pr-2"
                    v-if="findMessageType(lineOfChat.id_resposta) == 'file'"
                  >
                    <a :href="convertPath(findMessage(lineOfChat.id_resposta))">
                      Arquivo
                    </a>
                  </span>
                  <span
                    class="pr-2"
                    v-if="findMessageType(lineOfChat.id_resposta) == 'video'"
                  >
                    <video width="400px" height="400px" controls id="video">
                      <source
                        id="source"
                        :src="convertPath(findMessage(lineOfChat.id_resposta))"
                      />
                    </video>
                  </span>
                  <span class="pr-2" v-else>
                    {{ findMessage(lineOfChat.id_resposta) }}
                  </span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item-title
                v-if="lineOfChat.tipo == 'texto'"
                class="mr-1 text-wrap font-weight-light body-1"
              >
                {{ lineOfChat.mensagem }}
                <v-avatar size="36px">
                  <v-icon alt="Avatar">mdi-account</v-icon>
                </v-avatar>
                <v-tooltip
                  bottom
                  v-if="
                    lineOfChat.id_mensagem_lib &&
                    lineOfChat.id_mensagem_lib.length > 0
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="reply = lineOfChat"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      class="ml-1 rounded-circle"
                    >
                      <v-icon color="grey">mdi-reply</v-icon>
                    </v-btn>
                  </template>
                  <span>Responder</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-title
                v-if="lineOfChat.tipo == 'imagem'"
                class="mr-1 text-wrap font-weight-light body-1"
              >
                <a>
                  <img
                    @click="
                      (img = convertPath(lineOfChat.mensagem)), (showImg = true)
                    "
                    :src="convertPath(lineOfChat.mensagem)"
                    width="400"
                  />
                </a>
                <v-avatar size="36px" class="mr-3">
                  <v-icon alt="Avatar">mdi-account</v-icon>
                </v-avatar>
              </v-list-item-title>
              <v-list-item-title
                v-else-if="lineOfChat.tipo == 'video'"
                class="ml-1 text-wrap font-weight-light body-1"
              >
                <video width="400px" height="400px" controls id="video">
                  <source id="source" :src="convertPath(lineOfChat.mensagem)" />
                </video>
                <v-avatar size="36px" class="mr-3">
                  <v-icon alt="Avatar">mdi-account</v-icon>
                </v-avatar>
              </v-list-item-title>
              <v-list-item-title
                v-if="lineOfChat.tipo == 'pdf'"
                class="mr-1 text-wrap font-weight-light body-1"
              >
                <div
                  @click="
                    downloadImage((img = convertPath(lineOfChat.mensagem)))
                  "
                  style="margin-left: 3px; height: 200px; width: 400px"
                >
                  <a>
                    <pdf :src="convertPath(lineOfChat.mensagem)" />
                  </a>
                </div>
                <v-avatar size="36px" class="mr-3">
                  <v-icon alt="Avatar">mdi-account</v-icon>
                </v-avatar>
              </v-list-item-title>
              <v-list-item-title
                v-else-if="lineOfChat.tipo == 'audio'"
                class="ml-1 text-wrap font-weight-light body-1"
              >
                <audio controls id="audio">
                  <source
                    id="source"
                    :src="convertPath(lineOfChat.mensagem)"
                    type="audio/ogg"
                  />
                </audio>
                <v-avatar size="36px" class="mr-3">
                  <v-icon alt="Avatar">mdi-account</v-icon>
                </v-avatar>
              </v-list-item-title>
              <v-list-item-title
                v-else-if="lineOfChat.tipo == 'gravacao'"
                class="ml-1 text-wrap font-weight-light body-1"
              >
                <audio controls id="audio">
                  <source
                    id="source"
                    :src="lineOfChat.mensagem"
                    type="audio/ogg"
                  />
                </audio>
                <v-avatar size="36px" class="mr-3">
                  <v-icon alt="Avatar">mdi-account</v-icon>
                </v-avatar>
              </v-list-item-title>
              <v-list-item-title
                v-else-if="lineOfChat.tipo == 'file'"
                class="ml-1 text-wrap font-weight-light body-1"
              >
                <a :href="convertPath(lineOfChat.mensagem)"> Arquivo </a>
                <v-avatar size="36px" class="mr-3">
                  <v-icon alt="Avatar">mdi-account</v-icon>
                </v-avatar>
              </v-list-item-title>
              <v-list-item-subtitle class="mr-4 caption">
                {{ formatDate(lineOfChat.horario) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content
              class="mt-1 text-left rounded grey lighten-1"
              v-if="lineOfChat.sentido"
            >
              <v-list-item v-if="lineOfChat.id_resposta">
                <v-list-item-content
                  class="rounded grey lighten-2"
                  v-if="lineOfChat.id_resposta.length > 0"
                >
                  <span
                    class="pr-2"
                    v-if="findMessageType(lineOfChat.id_resposta) == 'imagem'"
                  >
                    <a>
                      <img
                        @click="
                          (img = convertPath(
                            findMessage(lineOfChat.id_resposta)
                          )),
                            (showImg = true)
                        "
                        :src="convertPath(findMessage(lineOfChat.id_resposta))"
                        width="400"
                      />
                    </a>
                  </span>
                  <span
                    class="pr-2"
                    v-if="findMessageType(lineOfChat.id_resposta) == 'pdf'"
                  >
                    <div
                      @click="
                        downloadImage(
                          (img = convertPath(
                            findMessage(lineOfChat.id_resposta)
                          ))
                        )
                      "
                      style="margin-left: 42px; height: 200px; width: 400px"
                    >
                      <a>
                        <pdf
                          :src="
                            convertPath(findMessage(lineOfChat.id_resposta))
                          "
                        />
                      </a>
                    </div>
                  </span>
                  <span
                    class="pr-2"
                    v-if="findMessageType(lineOfChat.id_resposta) == 'audio'"
                  >
                    <audio controls id="audio">
                      <source
                        id="source"
                        :src="convertPath(findMessage(lineOfChat.id_resposta))"
                        type="audio/ogg"
                      />
                    </audio>
                  </span>
                  <span
                    class="pr-2"
                    v-if="findMessageType(lineOfChat.id_resposta) == 'file'"
                  >
                    <a :href="convertPath(findMessage(lineOfChat.id_resposta))">
                      Arquivo
                    </a>
                  </span>
                  <span
                    class="pr-2"
                    v-if="findMessageType(lineOfChat.id_resposta) == 'video'"
                  >
                    <video width="400px" height="400px" controls id="video">
                      <source
                        id="source"
                        :src="convertPath(findMessage(lineOfChat.id_resposta))"
                      />
                    </video>
                  </span>
                  <span class="pr-2" v-else>
                    {{ findMessage(lineOfChat.id_resposta) }}
                  </span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item-title
                v-if="lineOfChat.tipo == 'texto'"
                class="ml-1 text-wrap font-weight-light body-1"
              >
                <v-tooltip
                  bottom
                  v-if="
                    lineOfChat.id_mensagem_lib &&
                    lineOfChat.id_mensagem_lib.length > 0
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="reply = lineOfChat"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      class="ml-1 rounded-circle"
                    >
                      <v-icon class="rotate-icon" color="grey"
                        >mdi-reply</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Responder</span>
                </v-tooltip>
                <v-avatar size="36px" class="mr-3">
                  <img
                    alt="Avatar"
                    v-if="service.img != 'undefined' && service.img != 'null'"
                    :src="service.img"
                  />
                  <v-icon alt="Avatar" v-else>mdi-account</v-icon>
                </v-avatar>
                {{ lineOfChat.mensagem }}
              </v-list-item-title>
              <v-list-item-title
                v-else-if="lineOfChat.tipo == 'imagem'"
                class="ml-1 text-wrap font-weight-light body-1"
              >
                <v-avatar size="36px" class="mr-3">
                  <img
                    alt="Avatar"
                    v-if="service.img != 'undefined' && service.img != 'null'"
                    :src="service.img"
                  />
                  <v-icon alt="Avatar" v-else>mdi-account</v-icon>
                </v-avatar>
                <a>
                  <img
                    @click="
                      (img = convertPath(lineOfChat.mensagem)), (showImg = true)
                    "
                    :src="convertPath(lineOfChat.mensagem)"
                    width="400"
                  />
                </a>
              </v-list-item-title>
              <v-list-item-title
                v-if="lineOfChat.tipo == 'pdf'"
                class="mr-1 text-wrap font-weight-light body-1"
              >
                <v-avatar size="36px" class="mr-3">
                  <img
                    alt="Avatar"
                    v-if="service.img != 'undefined' && service.img != 'null'"
                    :src="service.img"
                  />
                  <v-icon alt="Avatar" v-else>mdi-account</v-icon>
                </v-avatar>
                <div
                  @click="
                    downloadImage((img = convertPath(lineOfChat.mensagem)))
                  "
                  style="margin-left: 42px; height: 200px; width: 400px"
                >
                  <a>
                    <pdf :src="convertPath(lineOfChat.mensagem)" />
                  </a>
                </div>
              </v-list-item-title>
              <v-list-item-title
                v-else-if="lineOfChat.tipo == 'audio'"
                class="ml-1 text-wrap font-weight-light body-1"
              >
                <v-avatar size="36px" class="mr-3">
                  <img
                    alt="Avatar"
                    v-if="service.img != 'undefined' && service.img != 'null'"
                    :src="service.img"
                  />
                  <v-icon alt="Avatar" v-else>mdi-account</v-icon>
                </v-avatar>
                <audio controls id="audio">
                  <source
                    id="source"
                    :src="convertPath(lineOfChat.mensagem)"
                    type="audio/ogg"
                  />
                </audio>
              </v-list-item-title>
              <v-list-item-title
                v-else-if="lineOfChat.tipo == 'video'"
                class="ml-1 text-wrap font-weight-light body-1"
              >
                <v-avatar size="36px" class="mr-3">
                  <img
                    alt="Avatar"
                    v-if="service.img != 'undefined' && service.img != 'null'"
                    :src="service.img"
                  />
                  <v-icon alt="Avatar" v-else>mdi-account</v-icon>
                </v-avatar>
                <video width="400px" height="400px" controls id="video">
                  <source id="source" :src="convertPath(lineOfChat.mensagem)" />
                </video>
              </v-list-item-title>
              <v-list-item-title
                v-else-if="lineOfChat.tipo == 'file'"
                class="ml-1 text-wrap font-weight-light body-1"
              >
                <v-avatar size="36px" class="mr-3">
                  <img
                    alt="Avatar"
                    v-if="service.img != 'undefined' && service.img != 'null'"
                    :src="service.img"
                  />
                  <v-icon alt="Avatar" v-else>mdi-account</v-icon>
                </v-avatar>
                <a :href="convertPath(lineOfChat.mensagem)"> Arquivo </a>
              </v-list-item-title>
              <!--- <v-list-item-subtitle class="mb-n1 ml-4 caption">
                            {{lineOfChat.status}}
                        </v-list-item-subtitle>--->
              <v-list-item-subtitle class="ml-4 caption">
                {{ formatDate(lineOfChat.horario) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-text v-show="showImgPreview">
          <div class="text-center">
            <div>Pré-visualização</div>
            <img
              width="200px"
              height="200px"
              src=""
              id="showImgPreview"
              alt="Imagem colada"
            />
          </div>
        </v-card-text>
        <v-card-text v-show="showVideoPreview">
          <div class="text-center">
            <div>Pré-visualização</div>
            <video
              width="200px"
              height="200px"
              controls
              src=""
              id="showVideoPreview"
              alt="Video colado"
            ></video>
          </div>
        </v-card-text>
        <v-card-text v-show="showPdfPreview">
          <div class="text-center">
            <div>Pré-visualização</div>
            <iframe id="showPdfPreview" src="" />
          </div>
        </v-card-text>
        <v-card-text v-show="showAudioPreview">
          <div class="text-center">
            <div>Pré-visualização</div>
            <audio controls id="showAudioPreview" src="" />
          </div>
        </v-card-text>
        <v-card-subtitle class="mt-n4 mb-n4">
          Status da ultima mensagem enviada:
          {{ service.ultima_mensagem_status }}
        </v-card-subtitle>
        <v-alert
          dense
          prominent
          outlined
          border="left"
          v-if="reply != ''"
          color="success"
          class="ml-11 mb-n2"
        >
          <v-row align="center">
            <v-col class="grow">
              <v-icon class="rotate-icon" color="grey">mdi-reply</v-icon>
              {{ reply.mensagem }}
            </v-col>
            <v-col class="shrink">
              <v-btn icon @click="reply = ''" class="ml-1 rounded-circle">
                <v-icon color="grey">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
      <v-textarea
        rows="3"
        no-resize
        hide-details="true"
        v-model="messagem"
        id="messagem"
        outlined
        :disabled="mensagemDisabled"
        @keyup.enter="sendMessageToZap($event)"
      >
        <template v-slot:prepend>
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn small class="ml-2 mt-5" icon v-on="on"
                ><v-icon>mdi-paperclip</v-icon></v-btn
              >
            </template>
            <v-list>
              <v-list-item v-for="(type, index) in fileTypes" :key="index">
                <v-btn
                  class="text-capitalize mt-5"
                  text
                  @click="
                    selectedType = type;
                    menu = false;
                  "
                >
                  <v-icon>{{ type.icon }}</v-icon>
                  {{ type.name }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:append>
          <v-btn
            v-if="mensagemDisabled"
            class="mr-2 text-capitalize mt-5"
            small
            color="red"
            @click="
              clearFile(),
                (showImgPreview = false),
                (showVideoPreview = false),
                (showPdfPreview = false),
                (showAudioPreview = false)
            "
            >Cancelar</v-btn
          >
          <v-menu
            class="mt-5"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mt-5" v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(message, index) in fastMessages"
                :key="index"
                @click="(messagem = message.texto), (menu = false)"
              >
                <v-list-item-title>{{ message.texto }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            class="mr-2 mt-5"
            :class="{ recording: isRecording }"
            small
            color="primary"
            @click="startRecording"
            icon
            v-if="!isRecording"
            ><v-icon>mdi-microphone</v-icon></v-btn
          >
          <v-btn
            class="mr-2 mt-5"
            :class="{ recording: isRecording }"
            small
            color="red"
            @click="startRecording"
            icon
            v-else
            ><v-icon>mdi-microphone</v-icon></v-btn
          >

          <v-btn
            class="mr-n1 text-capitalize mt-5"
            small
            color="primary"
            @click="
              sendMessageToZap(),
                (showImgPreview = false),
                (showVideoPreview = false),
                (showPdfPreview = false),
                (showAudioPreview = false)
            "
            >Enviar</v-btn
          >
        </template>
      </v-textarea>
    </v-card>
    <v-file-input
      :accept="acceptType"
      @change="uploadFile"
      id="fileInput"
      v-show="false"
      class="mt-n4 ml-2 mr-n3"
      :hide-input="true"
    >
    </v-file-input>
    <v-dialog v-model="showDetails" width="410px">
      <v-card width="400px">
        <v-card-title
          >Informações do contato <v-spacer></v-spacer>
          <v-btn
            @click="edit"
            class="text-capitalize"
            small
            :color="editbtnColor"
            >{{ editWords }}</v-btn
          ></v-card-title
        >
        <form class="ma-3">
          <v-text-field
            disabled
            v-model="redName"
            label="Nome Whatsapp"
          ></v-text-field>
          <v-text-field
            :disabled="editDetails"
            v-model="zapName"
            label="Nome de Cadastro"
          ></v-text-field>
        </form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showTransfer">
      <v-container class="my-container" fluid>
        <v-card>
          <v-card-title
            >Transferencia de Atendimento <v-spacer></v-spacer>
            <v-select
              label="Para"
              :items="['Atendente', 'Departamento']"
              v-model="selectTypeTransfer"
            ></v-select>
          </v-card-title>
          <v-row class="row-flex-wrap">
            <v-col
              class="dep-col ml-3"
              v-if="selectTypeTransfer == 'Atendente'"
            >
              <v-toolbar-title class="grey--black">
                Atendentes
              </v-toolbar-title>
              <v-list
                color="#ECEFF1"
                style="max-height: 50vh; overflow-y: auto"
                v-if="transferAttendant != ''"
              >
                <v-list-item-group v-model="selectedAttendant" color="red">
                  <v-list-item
                    v-for="attendant in transferAttendant"
                    :key="attendant.id"
                    @click="idTrasnfer = attendant.id"
                  >
                    <v-list-item-title>{{ attendant.nome }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col class="dep-col" v-if="selectTypeTransfer == 'Atendente'">
              <v-toolbar-title class="grey--black">
                Departamentos
              </v-toolbar-title>
              <v-list
                color="#ECEFF1"
                class="mr-3"
                style="max-height: 50vh; overflow-y: auto"
                v-if="transferDepsAttendant != ''"
              >
                <v-list-item-group v-model="selectedDepAttendant" color="red">
                  <v-list-item
                    v-for="dep in transferDepsAttendant"
                    :key="dep.id"
                    @click="idTrasnfer = dep.id"
                  >
                    <v-list-item-title>{{
                      dep.departamento
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col class="dep-col" v-if="selectTypeTransfer == 'Departamento'">
              <v-toolbar-title class="grey--black">
                Departamentos
              </v-toolbar-title>
              <v-list
                color="#ECEFF1"
                class="mr-3"
                style="max-height: 50vh; overflow-y: auto"
                v-if="transferDeps != ''"
              >
                <v-list-item-group v-model="selectedDep" color="red">
                  <v-list-item
                    v-for="dep in transferDeps"
                    :key="dep.id"
                    @click="idTrasnfer = dep.id"
                  >
                    <v-list-item-title>{{
                      dep.departamento
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize"
              @click="transferService"
              small
              color="#E57373"
              >Transferir</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
    <v-dialog persistent v-model="showTab" v-if="showTab">
      <v-card>
        <v-card-title>Informações da tabulação</v-card-title>
        <form class="ma-3">
          <v-text-field
            disabled
            v-model="tab[0].tab.nome"
            label="Nome da tabulação"
          ></v-text-field>
          <div v-for="question in tab[0].questions" :key="question.id">
            <div>{{ question.question }}</div>
            <div v-if="question.type === 'Seleção pré definida'">
              <v-select
                required
                v-model="question.answer"
                :items="
                  question.itemsDrop
                    ? question.itemsDrop.map((option) => option.opcao)
                    : []
                "
                label="Opção"
              ></v-select>
            </div>
            <div v-if="question.type === 'Verdadeiro ou Falso'">
              <v-radio-group required v-model="question.answer" row>
                <v-radio label="Sim" value="true"></v-radio>
                <v-radio label="Não" value="false"></v-radio>
              </v-radio-group>
            </div>

            <div v-if="question.type === 'Texto'">
              <v-text-field
                required
                v-model="question.answer"
                label="Resposta"
              ></v-text-field>
            </div>
          </div>
        </form>

        <v-card-actions>
          <v-btn
            class="text-capitalize"
            @click="showTab = false"
            small
            color="primary"
            >Voltar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            @click="finishWithTab"
            small
            color="red"
            >Enviar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-alert v-if="answerMissing" border="left" color="red lighten-2" dark>
          Preencha todas as respostas
        </v-alert>
      </v-card>
    </v-dialog>
    <v-dialog v-if="showImg" v-model="showImg">
      <v-card>
        <v-card-title>Pre visualização<v-spacer></v-spacer></v-card-title>
        <div class="text-center">
          <div>Pré-visualização</div>
          <img :src="img" @click="downloadImage(img)" />
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="text-capitalize"
            @click="showImg = false"
            color="primary"
            >Voltar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialogIntKey" v-model="dialogIntKey">
      <v-card>
        <v-card-title>{{ wordsInt }}<v-spacer></v-spacer></v-card-title>
        <v-text-field
          id="intKey"
          class="ml-10 mr-10"
          v-model="intKey"
          label=""
        ></v-text-field>
        <v-card-actions>
          <v-btn small class="text-capitalize" @click="showIntKey()" color="red"
            >Consultar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            small
            class="text-capitalize"
            @click="dialogIntKey = false"
            color="primary"
            >Voltar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <infoScreen
      v-if="showInfo"
      @closeInfo="showInfo = false"
      :id="itemId"
      :groupName="groupName"
    />
    <contactDetail
      v-if="dialogDetail"
      @hide="dialogDetail = false"
      :contactProp="contact"
    />
    <integrationScreen
      v-if="dialogInt"
      @hide="dialogInt = false"
      :integration="integration"
    />
    <filesScreen
      v-if="showFiles"
      @hide="showFiles = false"
      :messages="messageFiles()"
    />
  </v-container>
</template>

<script>
//TODO resolver problema alteração de nomes
import VueChatScroll from "vue-chat-scroll";
import saveAs from "file-saver";
import pdf from "vue-pdf";
import infoScreen from "./group/infoScreen.vue";
import contactDetail from "./contact/contactDetail.vue";
import integrationScreen from "./chat/integrationScreen.vue";
import tagSelect from "./chat/tagSelect.vue";
import filesScreen from "./chat/filesScreen.vue";

export default {
  directives: {
    "chat-scroll": VueChatScroll,
  },
  components: {
    pdf,
    infoScreen,
    contactDetail,
    integrationScreen,
    tagSelect,
    filesScreen,
  },
  data() {
    return {
      showFiles: false,
      visibleMessages: [], // Mensagens visíveis no momento
      messagesPerPage: 30, // Quantidade de mensagens por página
      currentPage: 1, //
      isLoading: false, // Flag para evitar múltiplos carregamentos simultâneos
      initialLoad: true, // Flag para determinar se é o carregamento inicial
      lastScrollTop: 0, // Última posição de rolagem
      transferDepsAttendant: "",
      selectedDepAttendant: "",
      selectTypeTransfer: "",
      silence: false,
      wordsInt: "",
      intKey: "",
      dialogIntKey: false,
      dialogInt: false,
      integration: {},
      dialogDetail: false,
      reply: "",
      itemId: "",
      groupName: "",
      showInfo: false,
      showAddWallet: false,
      showDeleteWallet: false,
      answerMissing: false,
      headersQuestions: [
        { text: "Tipo", value: "type" },
        { text: "Pergunta", value: "question" },
        { text: "Ação", value: "action", sortable: false },
      ],
      img: "",
      contact: "",
      showImg: "",
      showTab: false,
      audio: "",
      idTrasnfer: "",
      selectedAttendant: "",
      selectedDep: "",
      editWords: "Editar",
      showTransfer: false,
      showDetails: false,
      editDetails: true,
      zapName: "",
      redName: "",
      editbtnColor: "primary",
      messagem: "",
      mensagemDisabled: false,
      isRecording: false,
      mediaRecorder: "",
      showImgPreview: false,
      showVideoPreview: false,
      showPdfPreview: false,
      showAudioPreview: false,
      file: "",
      chunks: [],
      fastMessages: [],
      menu: false,
      fileTypes: [
        { icon: "mdi-file-pdf-box", name: "PDF", accept: ".pdf" },
        { icon: "mdi-file-image", name: "Imagem", accept: "image/*" },
        { icon: "mdi-file-music", name: "Audio", accept: "audio/*" },
        { icon: "mdi-file-video", name: "Video", accept: "video/*" },
        { icon: "mdi-file", name: "Arquivo", accept: "" },
      ],
      selectedType: null,
      acceptType: "",
      selectedFile: null,
    };
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    messages: {
      handler() {
        this.loadMessages();
        this.scrollToBottom();
      },
      deep: true,
    },
    selectedAttendant(newValue) {
      console.log(newValue);
      if (newValue != null) {
        this.transferDepsAttendant =
          this.transferAttendant[newValue].departamentos;
        console.log(this.transferDepsAttendant);
      }
    },
    showTransfer(newValue) {
      if (newValue) {
        this.$store.state.interactions.getInfoForTransfer =
          this.service.id_numero;
      }
    },
    showDetails(newValue) {
      if (!newValue) {
        this.editDetails = true;
        this.editWords = "Editar";
        this.editbtnColor = "primary";
      }
    },
    reply(newValue) {
      if (newValue != "") {
        setTimeout(() => {
          let div = document.getElementById("chatContainer");
          div.scrollTop = div.scrollHeight;
          document.getElementById("messagem").focus();
        }, 100);
      }
    },
    conversation(newValue) {
      console.error(newValue);
      if (newValue != "") {
        setTimeout(() => {
          let div = document.getElementById("chatContainer");
          div.scrollTop = div.scrollHeight;
        }, 100);
      }
    },
    selectedType(newValue) {
      if (newValue != "") {
        this.acceptType = newValue.accept;
        setTimeout(() => {
          document.getElementById("fileInput").click();
          this.selectedType = "";
        }, 200);
      }
    },
    sendIntegrationRes(newValue) {
      this.messagem = newValue;
      this.sendMessageToZap();
    },
    audio(newValue) {
      if (newValue != "") {
        document.getElementById("showAudioPreview").src = this.audio;
        this.showAudioPreview = true;
        setTimeout(() => {
          let div = document.getElementById("chatContainer");
          div.scrollTop = div.scrollHeight;
        }, 200);
      }
    },
    tab(newValue) {
      console.log(newValue);
    },
  },
  mounted() {
    this.loadMessages();
    this.scrollToBottom();

    setTimeout(() => {
      let div = document.getElementById("chatContainer");
      div.scrollTop = div.scrollHeight;
      document.getElementById("messagem").focus();
      document.getElementById("messagem").addEventListener("paste", (event) => {
        // Acesse o conteúdo da área de transferência
        const clipboardData = event.clipboardData || window.clipboardData;

        // Verifique se a área de transferência possui imagens
        if (clipboardData && clipboardData.items) {
          for (const item of clipboardData.items) {
            if (item.type.indexOf("image") !== -1) {
              this.acceptType = "imagem/*";
              // É uma imagem! Faça algo com ela, como exibir ou processar
              const file = item.getAsFile();

              // Converta a imagem para um formato que pode ser inserido em v-textarea
              const imageAsDataUrl = URL.createObjectURL(file);
              document.getElementById("showImgPreview").src = imageAsDataUrl;
              this.messagem = file.name;
              this.mensagemDisabled = true;
              this.file = file;
              this.showImgPreview = true;
              setTimeout(() => {
                let div = document.getElementById("chatContainer");
                div.scrollTop = div.scrollHeight;
              }, 200);
            } else if (item.type.indexOf("video") !== -1) {
              // É uma imagem! Faça algo com ela, como exibir ou processar
              const file = item.getAsFile();

              // Converta a imagem para um formato que pode ser inserido em v-textarea
              const imageAsDataUrl = URL.createObjectURL(file);
              document.getElementById("showVideoPreview").src = imageAsDataUrl;
              this.acceptType = file.type;
              this.messagem = file.name;
              this.mensagemDisabled = true;
              this.file = file;
              this.showVideoPreview = true;
              setTimeout(() => {
                let div = document.getElementById("chatContainer");
                div.scrollTop = div.scrollHeight;
              }, 200);
            } else if (item.type.indexOf("pdf") !== -1) {
              // É uma imagem! Faça algo com ela, como exibir ou processar
              const file = item.getAsFile();

              // Converta a imagem para um formato que pode ser inserido em v-textarea
              const imageAsDataUrl = URL.createObjectURL(file);
              document.getElementById("showPdfPreview").src = imageAsDataUrl;
              this.acceptType = "pdf/*";
              this.messagem = file.name;
              this.mensagemDisabled = true;
              this.file = file;
              this.showPdfPreview = true;
              setTimeout(() => {
                let div = document.getElementById("chatContainer");
                div.scrollTop = div.scrollHeight;
              }, 200);
            } else if (item.type.indexOf("audio") !== -1) {
              // É uma imagem! Faça algo com ela, como exibir ou processar
              const file = item.getAsFile();

              // Converta a imagem para um formato que pode ser inserido em v-textarea
              const imageAsDataUrl = URL.createObjectURL(file);
              document.getElementById("showAudioPreview").src = imageAsDataUrl;
              this.acceptType = file.type;
              this.messagem = file.name;
              this.mensagemDisabled = true;
              this.file = file;
              this.showAudioPreview = true;
              setTimeout(() => {
                let div = document.getElementById("chatContainer");
                div.scrollTop = div.scrollHeight;
              }, 200);
            }
          }
        }
      });
    }, 100);
  },
  computed: {
    extension() {
      return this.$store.state.extension;
    },
    tab() {
      return this.$store.state.tabForDep;
    },
    conversation() {
      return this.$store.state.conversation;
    },
    service() {
      return this.$store.state.serviceFromChat;
    },
    transferDeps() {
      return this.$store.state.transferDeps;
    },
    transferAttendant() {
      return this.$store.state.transferAttendant;
    },
    sendIntegrationRes() {
      return this.$store.state.sendIntegrationRes;
    },
    info() {
      return this.$store.state.info;
    },
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
  },
  created() {
    console.warn(this.service);
    this.axios
      .post(`${this.server}/fastMessage/get_all/`, {
        i: this.service.id_departamento,
      })
      .then((res) => {
        if ((res.status = 200 && res.data.message)) {
          this.fastMessages = res.data.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    window.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        this.$store.state.newMessage = 0;
        this.$emit("hide");
      }
    });
    this.axios
      .post(`${this.server}/contact/get_one_contact`, {
        i: this.service.id_contato,
      })
      .then((res) => {
        console.log(res.data.contact);
        this.contact = res.data.contact[0];
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    messageFiles() {
      return this.messages.filter((msg) => {
        return msg.tipo != "texto";
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = document.getElementById("chatContainer");
        container.scrollTop = container.scrollHeight;
      });
    },
    loadMessages() {
      console.error("Carregando novas");
      // Carrega as mensagens mais recentes
      this.visibleMessages = this.messages.slice(
        -this.messagesPerPage * this.currentPage
      );
      if (this.initialLoad) {
        this.scrollToBottom();
        this.initialLoad = false;
      }
    },
    onScroll(event) {
      const container = event.target;
      const scrollTop = container.scrollTop;

      // Detecta se o usuário está rolando para cima e chegou no topo
      if (
        scrollTop === 0 &&
        !this.isLoading &&
        this.visibleMessages.length < this.messages.length
      ) {
        this.isLoading = true;
        this.currentPage++;
        this.loadMoreMessages();
      }

      // Atualiza a última posição de rolagem
      this.lastScrollTop = scrollTop;
    },
    loadMoreMessages() {
      // Adiciona mais mensagens antigas ao início da lista visível
      const start =
        this.messages.length - this.messagesPerPage * this.currentPage;
      const end = start + this.messagesPerPage;
      const newMessages = this.messages.slice(Math.max(start, 0), end);
      this.visibleMessages = [...newMessages, ...this.visibleMessages];
      this.isLoading = false;

      // Mantém a posição de rolagem ao carregar mais mensagens
      this.$nextTick(() => {
        const container = document.getElementById("chatContainer");
        container.scrollTop = container.scrollHeight / this.currentPage;
      });
    },
    showIntKey() {
      this.integration.key = this.intKey;
      this.integration.params = "";
      this.dialogInt = true;
    },
    showInt(int) {
      console.log(int);
      if (int.integracao_solicitacao != "") {
        this.integration = int;
        this.wordsInt = int.integracao_solicitacao;
        this.dialogIntKey = true;
        setTimeout(() => {
          document.getElementById("intKey").focus();
        }, 500);
      } else {
        this.integration = int;
        this.dialogInt = true;
      }
    },
    findMessage(id) {
      let message = this.conversation.find((message) => message.id == id);
      if (message) {
        console.log(message);
        return message.mensagem;
      }
    },
    findMessageType(id) {
      let message = this.conversation.find((message) => message.id == id);
      if (message) {
        console.log(message);
        return message.tipo;
      }
    },
    makeCall() {
      this.axios
        .post(`https://painel.4red.com.br:3001/originate_call/`, {
          id: this.info.id_telefonia,
          ext: this.extension,
          destiny: this.service.numero,
          id_service: this.service.id_atendimento,
          attendant: this.info.id,
        })
        .then(() => {
          this.$store.state.interactions.sendMessage = {
            cliente: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
            tipo: "texto",
            origem: this.$store.state.info.numero,
            nomeOrigem: this.$store.state.info.nome,
            destino: this.service.numero,
            conteudo: "Iniciando chamada",
            serviceId: this.service.id_atendimento,
            attendantId: this.$store.state.info.id,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    wallet() {
      this.showDeleteWallet = false;
      this.showAddWallet = false;
      if (this.service.id_carteira == this.info.id) {
        this.service.id_carteira = 0;
        this.$store.state.interactions.addWallet = {
          id_contact: this.service.id_contato,
          id_attendant: 0,
          id_number: this.$store.state.info.id_numero,
        };
      } else {
        this.service.id_carteira = this.info.id;
        this.$store.state.interactions.addWallet = {
          id_contact: this.service.id_contato,
          id_attendant: this.info.id,
          id_number: this.$store.state.info.id_numero,
        };
      }
    },
    showHistory() {
      this.$store.state.historyBtn = true;
      this.$store.state.interactions.getHistoryRange = {
        atendente: this.info.usuario,
        date: [this.getFormattedDate(), this.getFormattedDate()],
      };
    },
    getFormattedDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    sendIntegration(integration) {
      this.$store.state.interactions.sendIntegration = {
        client: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
        number: this.service.numero,
        function: integration.integracao_nome,
      };
    },
    finishWithTab() {
      const allAnswersFilled = this.tab[0].questions.every(
        (question) => question.answer !== ""
      );
      if (allAnswersFilled) {
        this.$store.state.interactions.insertTabAnswer = {
          tab: this.tab[0],
          service_id: this.service.id_atendimento,
        };
        this.$store.state.interactions.finish = {
          clientId: this.$store.state.info.id_cliente,
          serviceId: this.service.id_atendimento,
          attendantId: this.$store.state.info.id,
          attendantName: this.$store.state.info.nome,
          destiny: this.service.numero,
          depId: this.service.id_departamento,
          origin: this.$store.state.info.numero,
          client: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
          silence: this.silence,
        };
      } else {
        this.answerMissing = true;
      }
    },
    clearFile() {
      this.mensagemDisabled = false;
      this.messagem = "";
    },
    transferService() {
      console.log(
        this.selectedDepAttendant,
        this.selectedAttendant,
        this.selectTypeTransfer
      );
      if (this.selectTypeTransfer == "Atendente") {
        console.log("AQUI");
        console.log(this.transferDepsAttendant[this.selectedDepAttendant]);
        console.log(this.transferAttendant[this.selectedAttendant]);
        this.axios
          .post(`${this.server}/attendant/transfer_service_attendant`, {
            service_id: this.service.id_atendimento,
            dep_id: this.transferDepsAttendant[this.selectedDepAttendant].id,
            attendant_id: this.transferAttendant[this.selectedAttendant].id,
            attendant_name: this.transferAttendant[this.selectedAttendant].nome,
            cliente: this.info.cliente + "_" + this.info.numeronome,
            numero: this.info.numero,
            destino: this.service.numero,
          })
          .then((res) => {
            if (res.data.message == "Atendimento transferido") {
              setTimeout(() => {
                this.$store.state.conversation = "";
              }, 1200);
            }
          });
        console.log({
          service_id: this.service.id_atendimento,
          dep_id: this.transferDeps[this.selectedDepAttendant].id,
          attendant_id: this.transferAttendant[this.selectedAttendant].id,
          attendant_name: this.transferAttendant[this.selectedAttendant].nome,
          cliente: this.info.cliente + "_" + this.info.numeronome,
          numero: this.info.numero,
          destino: this.service.numero,
        });
      } else if (this.selectTypeTransfer == "Departamento") {
        this.$store.state.info.deps.forEach((dep) => {
          let service = dep.services.findIndex(
            (service) => service.id_atendimento == this.service.id_atendimento
          );
          if (service !== -1) {
            dep.services.splice(service, 1);
          }
        });
        this.$store.state.interactions.transferServiceToDep = {
          id_atendente: this.$store.state.info.id,
          id_atendimento: this.service.id_atendimento,
          id_destino: this.idTrasnfer,
          destino: this.transferDeps.find((dep) => dep.id == this.idTrasnfer)
            .departamento,
          numero_destino: this.service.numero,
          numero_origem: this.$store.state.info.numero,
          cliente: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
        };
        this.$store.state.transferAttendant = "";
        this.$store.state.transferDeps = "";
        setTimeout(() => {
          this.$store.state.conversation = "";
        }, 1200);
      }
    },
    edit() {
      if (this.editDetails) {
        this.editDetails = false;
        this.editWords = "Salvar";
        this.editbtnColor = "#E57373";
      } else {
        let editContact = {
          id_numero: this.service.id_numero,
          contato_4red: this.redName,
          contato_zap: this.zapName,
          numero: this.service.numero,
        };
        this.$store.state.interactions.editContact = editContact;
        this.showDetails = false;
      }
    },
    showDetail() {
      this.showDetails = true;
      this.redName = this.service.contato_4red;
      this.zapName = this.service.contato_zap;
    },
    startRecording() {
      if (this.isRecording) {
        this.mediaRecorder.stop();
        this.isRecording = false;
      } else {
        this.isRecording = true;
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          // eslint-disable-next-line no-undef
          this.mediaRecorder = new MediaRecorder(stream);
          let chunks = [];
          this.mediaRecorder.ondataavailable = (data) => {
            chunks.push(data.data);
          };
          this.mediaRecorder.onstop = () => {
            let blob = new Blob(chunks, { type: "audio/webm;code:opus" });
            let reader = new window.FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              this.mensagemDisabled = true;
              this.messagem = "Audio";
              this.audio = reader.result;
            };
          };
          this.mediaRecorder.start();
        });
      }
    },
    uploadFile(event) {
      this.messagem = event.name;
      this.mensagemDisabled = true;
      this.file = event;
      if (this.acceptType == "") {
        this.acceptType = "file/*";
      } else if (this.acceptType == ".pdf") {
        this.acceptType = "pdf/*";
        const imageAsDataUrl = URL.createObjectURL(this.file);
        document.getElementById("showPdfPreview").src = imageAsDataUrl;
        this.showPdfPreview = true;
        setTimeout(() => {
          let div = document.getElementById("chatContainer");
          div.scrollTop = div.scrollHeight;
        }, 200);
      } else if (this.acceptType == "image/*") {
        this.acceptType = "imagem/*";
        const imageAsDataUrl = URL.createObjectURL(this.file);
        document.getElementById("showImgPreview").src = imageAsDataUrl;
        this.showImgPreview = true;
        setTimeout(() => {
          let div = document.getElementById("chatContainer");
          div.scrollTop = div.scrollHeight;
        }, 200);
      } else if (this.acceptType == "audio/*") {
        this.acceptType = "audio/*";
        const imageAsDataUrl = URL.createObjectURL(this.file);
        document.getElementById("showAudioPreview").src = imageAsDataUrl;
        this.showAudioPreview = true;
        setTimeout(() => {
          let div = document.getElementById("chatContainer");
          div.scrollTop = div.scrollHeight;
        }, 200);
      } else if (this.acceptType == "video/*") {
        this.acceptType = "video/*";
        const imageAsDataUrl = URL.createObjectURL(this.file);
        document.getElementById("showVideoPreview").src = imageAsDataUrl;
        this.showVideoPreview = true;
        setTimeout(() => {
          let div = document.getElementById("chatContainer");
          div.scrollTop = div.scrollHeight;
        }, 200);
      }
      /* this.$store.state.interactions.sendFile = {
        cliente: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
        tipo: this.acceptType,
        origem: this.$store.state.info.numero,
        nomeOrigem: this.$store.state.info.nome,
        destino: this.service.numero,
        conteudo: event,
        serviceId: this.service.id_atendimento,
        attendantId: this.$store.state.info.id,
        fileName: event.name,
      };*/
    },
    downloadImage(url) {
      let filename = "imagem"; // obter o nome do arquivo a partir da URL
      fetch(url) // buscar a imagem
        .then((response) => response.blob()) // transformar a resposta em um blob
        .then((blob) => saveAs(blob, filename));
    },
    convertPath(path) {
      return `https://${
        window.location.href.split("/")[2].split(":")[0]
      }:3020/midias${path.substring(10)}`;
    },
    finish() {
      console.log(this.service);
      this.$store.state.interactions.getTabForDep = {
        id: this.service.id_departamento,
      };
      setTimeout(() => {
        if (this.$store.state.tabForDep.length == 0) {
          this.$store.state.interactions.finish = {
            clientId: this.$store.state.info.id_cliente,
            serviceId: this.service.id_atendimento,
            attendantId: this.$store.state.info.id,
            attendantName: this.$store.state.info.nome,
            destiny: this.service.numero,
            depId: this.service.id_departamento,
            origin: this.$store.state.info.numero,
            client: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
          };
        } else {
          this.showTab = true;
        }
      }, 1500);

      /* ;*/
    },
    finishSilence() {
      this.silence = true;
      this.$store.state.interactions.getTabForDep = {
        id: this.service.id_departamento,
      };
      setTimeout(() => {
        if (this.$store.state.tabForDep.length == 0) {
          this.$store.state.interactions.finish = {
            clientId: this.$store.state.info.id_cliente,
            serviceId: this.service.id_atendimento,
            attendantId: this.$store.state.info.id,
            attendantName: this.$store.state.info.nome,
            destiny: this.service.numero,
            depId: this.service.id_departamento,
            origin: this.$store.state.info.numero,
            client: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
            silence: true,
          };
        } else {
          this.showTab = true;
        }
      }, 1500);

      /* ;*/
    },
    sendMessageToZap(event) {
      console.log(event);
      if (event?.shiftKey) {
        return;
      }
      if (this.mensagemDisabled) {
        if (this.messagem == "Audio") {
          this.$store.state.interactions.sendFile = {
            cliente: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
            tipo: "audio/webm",
            origem: this.$store.state.info.numero,
            nomeOrigem: this.$store.state.info.nome,
            destino: this.service.numero,
            conteudo: this.audio,
            serviceId: this.service.id_atendimento,
            attendantId: this.$store.state.info.id,
            fileName: "audio.webm",
            isRecorder: true,
          };
          this.audio = "";
          this.mensagemDisabled = false;
          this.messagem = "";
        } else {
          this.$store.state.interactions.sendFile = {
            cliente: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
            tipo: this.acceptType,
            origem: this.$store.state.info.numero,
            nomeOrigem: this.$store.state.info.nome,
            destino: this.service.numero,
            conteudo: this.file,
            serviceId: this.service.id_atendimento,
            attendantId: this.$store.state.info.id,
            fileName: this.file.name,
          };
          this.mensagemDisabled = false;
          this.messagem = "";
        }
      } else {
        if (this.messagem.trim() !== "") {
          if (this.reply == "") {
            this.$store.state.interactions.sendMessage = {
              cliente: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
              tipo: "texto",
              origem: this.$store.state.info.numero,
              nomeOrigem: this.$store.state.info.nome,
              destino: this.service.numero,
              conteudo: `${this.$store.state.info.nome}: ${this.messagem}`,
              serviceId: this.service.id_atendimento,
              attendantId: this.$store.state.info.id,
            };
            this.messagem = "";
          } else {
            this.$store.state.interactions.sendMessage = {
              cliente: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
              tipo: "texto",
              origem: this.$store.state.info.numero,
              nomeOrigem: this.$store.state.info.nome,
              destino: this.service.numero,
              conteudo: `${this.$store.state.info.nome}: ${this.messagem}`,
              serviceId: this.service.id_atendimento,
              attendantId: this.$store.state.info.id,
              reply: this.reply.id_mensagem_lib,
            };
            this.messagem = "";
            this.reply = "";
          }
        }
      }
    },
    formatDate(date) {
      const options = {
        day: "numeric", // Adicionando a opção para exibir o dia
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return new Date(date).toLocaleString("pt-BR", options);
    },
  },
};
</script>
<style>
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor do fundo da barra de rolagem */
  border-radius: 10px; /* Bordas arredondadas */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Cor da barra de rolagem */
  border-radius: 10px; /* Bordas arredondadas */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Cor da barra de rolagem quando hover */
}
.recording {
  animation: pulse 1s infinite;
}
.rotate-icon {
  transform: scaleX(-1); /* Inverte o ícone horizontalmente */
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
