<template>
  <v-dialog v-model="dialog">
    <v-card class="overflow-x-hidden overflow-y-hidden">
      <v-toolbar dense dark color="primary">
        <v-card-title> Calendário </v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row class="fill-height">
        <v-col>
          <v-sheet>
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Hoje
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Dia</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Semana</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Mês</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet style="height: 72vh" class="ma-1">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              locale="pt-br"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
            ></v-calendar>
            <kanbanCardDetails
              v-if="showCardDetail"
              @closeCardDetail="(showCardDetail = false), fetchData()"
              :selectCard="this.selectCard"
              :kanbanId="kanbanId"
              :permission="permission"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import kanbanCardDetails from "../kanbanCardDetails.vue";
export default {
  props: ["kanbanId", "permission"],
  components: {
    kanbanCardDetails,
  },
  data: () => ({
    dialog: true,
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Mês",
      week: "Semana",
      day: "Dia",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    showCardDetail: false,
    selectCard: "",
    expiredDate: false,
  }),
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    async fetchData() {
      let obj = {
        id_client: this.info.id_cliente,
        id_attendant: this.info.id,
        id_number: this.info.id_numero,
        id_kanban: this.kanbanId,
      };
      let objCrypt = await this.cryptoMsg(obj);
      this.axios
        .post(`${this.server}/kanban/getDatesFromCalendar`, { data: objCrypt })
        .then(async (res) => {
          switch (res.status) {
            case 200:
              var decrypt = await this.decrypt(res.data.data);
              var data = decrypt.data;
              if (data.length === 0) return;
              var events = data.map((item) => {
                var end;
                this.expiredDate = false;
                if (item.hora_final) {
                  end = new Date(
                    `${this.formatDateToISO(item.data_final)}T${
                      item.hora_final
                    }`
                  );
                } else {
                  end = new Date(
                    `${this.formatDateToISO(item.data_final)}T23:59:00`
                  );
                }
                if (
                  !this.isNullOrEmpty(item.data_inicial) &&
                  !this.isNullOrEmpty(item.data_final)
                ) {
                  const startDate = new Date(item.data_inicial);
                  const endDate = new Date(item.data_final);
                  if (startDate > endDate) {
                    this.expiredDate = true;
                  }
                }
                return {
                  name: `- ${item.titulo}`, // Exibindo a hora formatada e o título
                  details: item.descricao,
                  start: end,
                  end: end,
                  color: this.getEventColorBasedOnStatus(item.status),
                  etiquetas: item.etiquetas,
                  timed: item.hora_inicial !== null || item.hora_final !== null,
                  id_coluna: item.id_coluna,
                  card: item,
                };
              });

              this.events = events;
              break;
            case 201:
            case 202:
            case 203:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatDateToISO(dateString) {
      // Cria um objeto Date a partir da string original
      const date = new Date(dateString);
      // Extrai o ano, mês e dia, formatando no padrão 'YYYY-MM-DD'
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Adiciona o zero à esquerda, se necessário
      const day = String(date.getDate()).padStart(2, "0"); // Adiciona o zero à esquerda, se necessário
      // Retorna a data formatada
      return `${year}-${month}-${day}`;
    },
    isNullOrEmpty(value) {
      return !value || value.trim().length === 0;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      if (this.expiredDate) {
        return "red";
      }
      return event.color || "defaultColor";
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      this.selectCard = event.card;
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.showCardDetail = true))
        );
      };

      if (this.showCardDetail) {
        this.showCardDetail = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    getEventColorBasedOnStatus(status) {
      // Define a cor padrão como "red" caso expiredDate seja verdadeiro
      if (this.expiredDate) {
        return "red";
      }
      const statusColors = {
        true: "green", // Por exemplo, status true (concluído) = verde
        false: "orange", // Status false (pendente) = laranja
      };
      return statusColors[status] || "gray";
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("close");
      }
    },
  },
};
</script>

<style></style>
