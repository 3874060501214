<template>
  <v-dialog v-model="dialog">
    <v-container class="my-container" fluid>
      <v-card v-if="dialog">
        <v-toolbar dense dark color="primary">
          <v-card-title>Tabulação</v-card-title><v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="mb-2"
            v-model="search"
            clearable
            flat
            hide-details
            append-outer-icon="mdi-magnify"
            label="Procurar"
          ></v-text-field>
          <v-btn
            v-if="info.permissao == 'admin'"
            small
            class="ml-3 mb-n2 text-capitalize"
            @click="showAddTab = true"
            color="#0D47A1"
            >Adicionar</v-btn
          >
        </v-card-title>
        <v-row class="row-flex-wrap">
          <v-col class="dep-col ml-3 mr-3">
            <v-data-table
              :headers="headers"
              :items="filteredTabs"
              :footer-props="{ itemsPerPageOptions: [20] }"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.tab.nome }}</td>
                  <td v-if="info.permissao == 'admin'">
                    <v-btn small icon>
                      <v-icon color="primary" @click="editTab(item)"
                        >mdi-account-edit-outline</v-icon
                      >
                    </v-btn>
                  </td>
                  <td v-if="info.permissao == 'admin'">
                    <v-btn
                      small
                      icon
                      @click="(deleteTab = item), (showDeleteTab = true)"
                    >
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <v-dialog v-if="showEditTab" v-model="showEditTab" width="920px">
        <v-card width="900px">
          <v-card-title
            >Informações da tabulação<v-spacer></v-spacer>
            <v-btn
              v-if="info.permissao == 'admin'"
              small
              class="ml-3 mb-n2 text-capitalize"
              @click="showAddQuestion = true"
              color="primary"
              >Adicionar Pergunta</v-btn
            ></v-card-title
          >
          <form class="ma-3">
            <v-text-field
              required
              v-model="editTabItem.tab.nome"
              label="Nome da tabulação"
            ></v-text-field>
          </form>
          <v-row class="row-flex-wrap">
            <v-col class="dep-col ml-3 mr-3">
              <v-data-table
                v-if="editTabItem.questions.length > 0"
                :headers="headersQuestions"
                :items="editTabItem.questions"
                :footer-props="{ itemsPerPageOptions: [20] }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.type }}</td>
                    <td>
                      {{ item.question }}
                    </td>
                    <td>
                      <v-btn small icon>
                        <v-icon color="red" @click="deleteQuestion(item)"
                          >mdi-delete</v-icon
                        >
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              small
              class="text-capitalize"
              @click="showAddTab = false"
              color="primary"
              >Voltar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn small class="text-capitalize" color="red">Adicionar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="showAddTab" v-model="showAddTab" width="920px">
        <v-card width="900px">
          <v-card-title
            >Informações da tabulação<v-spacer></v-spacer>
            <v-btn
              v-if="info.permissao == 'admin'"
              small
              class="ml-3 mb-n2 text-capitalize"
              @click="showAddQuestion = true"
              color="primary"
              >Adicionar Pergunta</v-btn
            ></v-card-title
          >
          <form class="ma-3">
            <v-text-field
              required
              v-model="newTabName"
              label="Nome da tabulação"
            ></v-text-field>
          </form>
          <v-row class="row-flex-wrap">
            <v-col class="dep-col ml-3 mr-3">
              <v-data-table
                v-if="questions.length > 0"
                :headers="headersQuestions"
                :items="questions"
                :footer-props="{ itemsPerPageOptions: [20] }"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.type }}</td>
                    <td>
                      {{ item.question }}
                    </td>
                    <td>
                      <v-btn small icon>
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              small
              class="text-capitalize"
              @click="showAddTab = false"
              color="primary"
              >Voltar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn small class="text-capitalize" @click="addTab" color="red"
              >Adicionar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="showAddQuestion" v-model="showAddQuestion" width="620px">
        <v-card width="600px">
          <v-card-title
            >Informações da pergunta<v-spacer></v-spacer>
            <v-btn
              v-if="newQuestionType == 'Seleção pré definida'"
              @click="addItemDrop"
              small
              class="ml-3 mb-n2 text-capitalize"
              color="primary"
              >Adicionar Item</v-btn
            >
          </v-card-title>
          <form class="ma-3">
            <v-select
              class="ml-8 mr-8"
              v-model="newQuestionType"
              :items="questionType"
              label="Tipo de Pergunta"
            ></v-select>
          </form>
          <form
            class="ml-8 mr-8"
            v-if="newQuestionType != '' && newQuestionType == 'Texto'"
          >
            <v-text-field
              v-model="newQuestionText"
              label="Pergunta"
            ></v-text-field>
          </form>
          <form
            class="ml-8 mr-8"
            v-if="
              newQuestionType != '' && newQuestionType == 'Verdadeiro ou Falso'
            "
          >
            <v-text-field
              v-model="newQuestionText"
              label="Pergunta"
            ></v-text-field>
          </form>
          <form
            class="ml-8 mr-8"
            v-if="
              newQuestionType != '' && newQuestionType == 'Seleção pré definida'
            "
          >
            <v-text-field
              v-model="newQuestionText"
              label="Pergunta"
            ></v-text-field>
            <span v-for="(item, index) in newQuestionDropdownAll" :key="index">
              <div class="item-container">
                <div class="index">Item {{ index + 1 }} -</div>
                <v-text-field
                  v-model="newQuestionDropdownAll[index].item"
                  class="text-field"
                ></v-text-field>
              </div>
            </span>
          </form>
          <v-card-actions>
            <v-btn
              small
              class="text-capitalize"
              @click="showAddQuestion = false"
              color="primary"
              >Voltar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="addQuestion"
              color="red"
              >Adicionar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="showDeleteTab" v-model="showDeleteTab" width="500px">
        <v-card width="500px">
          <v-card-title
            >Deseja deletar a tabulaçao "{{ deleteTab.tab.nome }}" ?<v-spacer
            ></v-spacer
          ></v-card-title>
          <v-card-actions>
            <v-btn
              small
              class="text-capitalize"
              @click="deleteTabulation(deleteTab.tab)"
              color="red"
              >Deletar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              small
              class="text-capitalize"
              @click="showDeleteTab = false"
              color="primary"
              >Voltar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">Tabulações foram atualizados</div>
      </v-sheet>
    </v-bottom-sheet>
  </v-dialog>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  data() {
    return {
      showDeleteTab: false,
      deleteTab: "",
      editTabItem: "",
      showEditTab: false,
      showAddQuestion: false,
      showAddTab: false,
      sheet: false,
      search: "",
      dialog: false,
      questions: [],
      questionType: [
        { text: "Texto" },
        { text: "Verdadeiro ou Falso" },
        { text: "Seleção pré definida" },
      ],
      newTabName: "",
      newQuestionType: "",
      newQuestionText: "",
      newQuestionDropdownAll: [{ item: "" }],
      headers: [
        { text: "Nome", value: "nome" },
        { text: "Editar", value: "" },
        { text: "Apagar", value: "" },
      ],
      headersQuestions: [
        { text: "Tipo", value: "type" },
        { text: "Pergunta", value: "question" },
        { text: "Apagar", value: "" },
      ],
      headersQuestionsNewDrop: [
        { text: "Item", value: "item" },
        { text: "Apagar", value: "" },
      ],
    };
  },
  computed: {
    filteredTabs() {
      return this.tabs.filter((item) => {
        return (
          item.tab.nome.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    deps() {
      return this.$store.state.info.deps;
    },
    tabs() {
      return this.$store.state.tabs;
    },
    tabDialog() {
      return this.$store.state.screens.tab;
    },
    info() {
      return this.$store.state.info;
    },
  },

  watch: {
    showAddTab(newValue) {
      if (!newValue) {
        this.clear();
      }
    },
    tabDialog(newValue) {
      if (newValue) {
        this.dialog = true;
        this.$store.state.interactions.getTabs = {
          id_number: this.$store.state.info.id_numero,
        };
      }
    },
    dialog(newValue) {
      if (!newValue) {
        this.clear();
        this.$store.state.screens.tab = "";
      }
    },
  },
  methods: {
    clear() {
      this.questions = [];
      this.newTabName = "";
      this.newQuestionType = "";
      this.newQuestionText = "";
      (this.newQuestionDropdownAll = [{ item: "" }]), (this.deleteTab = "");
      this.editTabItem = "";
    },
    deleteTabulation(tab) {
      this.showDeleteTab = false;
      this.$store.state.interactions.deleteTab = { id: tab.id };
      setTimeout(() => {
        this.$store.state.interactions.getTabs = {
          id_number: this.$store.state.info.id_numero,
        };
      }, 1500);
    },
    deleteQuestion(question) {
      let index = this.editTabItem.questions.findIndex(
        (ques) => ques.question == question.question
      );
      this.editTabItem.questions.splice(index, 1);
    },
    editTab(tab) {
      this.editTabItem = tab;
      this.showEditTab = true;
    },
    addItemDrop() {
      this.newQuestionDropdownAll.push({ item: "" });
    },
    addTab() {
      if (this.newTabName != "") {
        this.$store.state.interactions.addTab = {
          tab: this.newTabName,
          questions: this.questions,
          id_number: this.$store.state.info.id_numero,
        };
        this.showAddTab = false;
        setTimeout(() => {
          this.$store.state.interactions.getTabs = {
            id_number: this.$store.state.info.id_numero,
          };
        }, 1500);
      }
    },
    addQuestion() {
      if (this.newQuestionType) {
        if (this.editTabItem == "") {
          this.questions.push({
            type: this.newQuestionType,
            question: this.newQuestionText,
            itemsDrop: this.newQuestionDropdownAll,
          });
          this.showAddQuestion = false;
          this.newQuestionText = "";
          this.newQuestionType = "";
          this.newQuestionDropdownAll = [];
        } else {
          this.editTabItem.questions.push({
            type: this.newQuestionType,
            question: this.newQuestionText,
            itemsDrop: this.newQuestionDropdownAll,
          });
          this.showAddQuestion = false;
          this.newQuestionText = "";
          this.newQuestionType = "";
          this.newQuestionDropdownAll = [];
        }
      }
    },
  },
};
</script>

<style>
.my-container {
  width: 100%;
  white-space: nowrap;
}

.row-flex-wrap {
  overflow-x: auto;
  flex-wrap: nowrap;
}
.item-container {
  display: flex;
  align-items: center;
}

.index {
  margin-right: 10px; /* Ajuste a margem conforme necessário */
}
.dep-card {
  height: 85vh;
  margin: 1px;
  padding: 10px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.service-col {
  margin: 0px;
  width: calc(100% - 20px);
}

@media (max-width: 600px) {
  .dep-col {
    flex-basis: 100%;
  }
}
</style>
