<template>
  <v-dialog v-model="dialog" >
    <v-card>
      <v-card-title
        >Historico de chamados do grupo "{{ groupName }}"
        <v-spacer></v-spacer>
        <v-text-field class="mb-2" v-model="search"  style="max-width: 300px;" clearable flat hide-details append-outer-icon="mdi-magnify" label="Procurar"></v-text-field>
      </v-card-title>
      <v-data-table class="ma-3" :headers="header" :items="filteredItems" :footer-props="{ itemsPerPageOptions: [10] }">
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-avatar size="36px">
                <img alt="Avatar" v-if="item.img != 'undefined' && item.img != 'null'" :src="item.img" />
                <v-icon alt="Avatar" v-else>mdi-account</v-icon>
              </v-avatar>
              {{ item.contato_4red }}
            </td>
            <td>{{ item.contato_zap }}</td>
            <td>{{ item.numero.split("55")[1].split("@")[0] }}</td>
            <td>{{ formatDate(item.horario) }}</td>
            <td>{{ formatDate(item.horario_ultima_mensagem) }}</td>
            <td>{{ item.nome }}</td>
            <td>{{ item.status }}</td>
            <td>
              <v-btn small icon @click="getConversation(item)"> 
                <v-icon color="red">mdi-chat-outline</v-icon>
              </v-btn>
            </td>                    
          </tr>
        </template>
      </v-data-table>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small class="ml-2 text-capitalize" @click="(dialog = false)" color="primary">Voltar</v-btn>
      </v-card-actions>
    </v-card>
    <chatHistory v-if="showChat" @closeChat="showChat = false" :conversation="conversation" :service="conversation"/>
  </v-dialog>
</template>
<script> 
import chatHistory from '../chatHistory.vue'
export default {

  props: ["id","groupName"],
  components: {chatHistory},
  data() {
    return {
      service:"",
      showChat:false,
      conversation:"",
      search:"",
      header: [
        { text: "Nome whatsapp", value: "contato_4red" },
        { text: "Nome cadastro", value: "contato_zap" },
        { text: "Numero", value: "numero" },
        { text: "Inicio do atendimento", value: "horario" },
        { text: 'Horario ultima mensagem', value: 'horario_ultima_mensagem' },
        { text: 'Atendente', value: 'nome' },
        { text: 'Status', value: 'status' },
        { text: 'Chamado', value: 'action' },
      ],
      items: [],
      dialog: true,
      route:"/history/get_for_group",
      routeC:"/history/get_conversation"
    };
  },
  created(){
    this.data()
  },
  methods: {
    getConversation(item){
      this.axios
        .post(`${this.server}${this.routeC}`,{
          i:item.id
        })
        .then((res) => {
          this.service = item
          this.conversation = res.data.data
          this.showChat = true
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatDate(date) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return new Date(date).toLocaleString("pt-BR", options);
    },
    data() {
      this.axios
        .post(`${this.server}${this.route}`,{
          i:this.id
        })
        .then((res) => {
          this.items = res.data.data
        })
        .catch((err) => {
          console.log(err);
        });
    },
    add(){
      this.axios
        .post(`${this.server}${this.route}`,{
          n:this.info.id_numero,
          c:this.info.id_cliente,
          g:this.name
        })
        .then((res) => {
          if(res.status==200){
            this.sheetDialog = res.data.message
            this.sheet = true
            setTimeout(() => {
              this.sheet = false
              this.dialog = false
              this.$emit("reload")
            }, 4000);
          }else{
            this.sheetDialog = res.data.error
            this.sheet = true
            setTimeout(() => {
              this.sheet = false
            }, 4000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  computed: {
    filteredItems() {
      const search = this.search.toLowerCase();
      
      return this.items.filter((item) => {
        let contato_4red = item.contato_4red.toLowerCase();
        let numero = item.numero.toLowerCase();
        let contato_zap = item.contato_zap.toLowerCase();
        let horario = this.formatDate(item.horario) 
        let atendente = item.nome.toLowerCase();
        let status = item.status.toLowerCase();
        return (
          contato_4red.indexOf(search) > -1 ||
          numero.indexOf(search) > -1 ||
          contato_zap.indexOf(search) > -1 ||
          horario.indexOf(search) > -1 ||
          atendente.indexOf(search) > -1 ||
          status.indexOf(search) > -1 
        );
      });
    },
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialog(newValue){
      if(!newValue){
        this.$emit("closeHistory")
      }
    }
  },
};
</script>
