<template>
  <div
    v-if="dialog"
    class="d-flex justify-center align-center"
    style="height: 40vh"
  >
    <canvas :id="`online${dep.id}`"></canvas>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  props: ["date", "dep"],
  data() {
    return {
      dialog: false,
      r: "/report/get_calculateOnlineTimeByAttendantAndDay",
      chartData: {
        labels: [],
        datasets: [],
      },
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    date(newValue) {
      if (newValue.length == 2) {
        this.dialog = false;
        this.fetchData();
      }
    },
  },
  methods: {
    transformData(rows) {
      const dataByDay = {};
      rows.forEach((row) => {
        if (row.day) {
          const day = row.day.split("T")[0];
          const atendenteKey = row.nome_atendente; // Usando o nome do atendente
          if (!dataByDay[day]) {
            dataByDay[day] = {};
          }
          dataByDay[day][atendenteKey] = this.timeToMinutes(row.online_time);
        }
      });
      return Object.entries(dataByDay).map(([day, attendances]) => ({
        day,
        ...attendances,
      }));
    },

    fetchData() {
      console.log(this.dep);
      this.axios
        .post(`${this.server}${this.r}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          d: this.date,
          i: this.dep.id,
        })
        .then((res) => {
          if (res.status === 200) {
            this.dialog = true;
            console.error(res.data.data);
            console.log(this.transformData(res.data.data));
            setTimeout(() => {
              this.processData(this.transformData(res.data.data));
            }, 100);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    timeToMinutes(timeStr) {
      const [hours, minutes, seconds] = timeStr.split(":").map(Number);
      return hours * 60 + minutes + seconds / 60;
    },
    processData(data) {
      // Obter uma lista de todos os atendentes únicos
      const atendentes = new Set(
        data.flatMap((dayData) =>
          Object.keys(dayData).filter((key) => key !== "day")
        )
      );

      // Preparar os datasets, um para cada atendente
      this.chartData.datasets = Array.from(atendentes).map((atendente) => {
        const color = this.getRandomColor(); // Gerar uma cor aleatória para cada atendente
        return {
          label: atendente,
          data: data.map((dayData) => dayData[atendente] || 0), // Usar 0 se o atendente não tiver dados para um dia
          backgroundColor: color,
          borderColor: color,
          borderWidth: 1,
        };
      });

      // Preparar os labels do gráfico, que são as datas
      this.chartData.labels = data.map((dayData) => dayData.day);

      // Chamar a função para criar/atualizar o gráfico
      this.createChart();
    },

    getRandomColor() {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      return `rgba(${r}, ${g}, ${b}, 0.2)`;
    },
    createChart() {
      const ctx = document
        .getElementById(`online${this.dep.id}`)
        .getContext("2d");
      if (this.chart) {
        this.chart.destroy(); // Destruir o gráfico anterior se existir
      }
      // Criar o novo gráfico
      this.chart = new Chart(ctx, {
        type: "bar",
        data: this.chartData,
        options: {
          responsive: true, // Adicionar para tornar o gráfico responsivo
          plugins: {
            title: {
              display: true,
              text: `Tempo online dos atendimentos em minutos por atendente por Dia no Departamento ${this.dep.departamento}`,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
              },
            },
          },
        },
      });
    },
  },
};
</script>
