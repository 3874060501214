<template>
  <v-container v-if="columns.length > 0">
    <v-row
      class="kanban-row custom-scrollbar"
      ref="kanbanRow"
      @dragover="handleDragOver"
      @drop="stopAutoScroll"
    >
      <v-col
        cols="12"
        md="3"
        v-for="(coluna, index) in columns"
        class="kanban-column"
        :key="index"
      >
        <v-card color="#ECEFF1">
          <div>
            <v-toolbar color="#ECEFF1" dense elevation="0">
              <v-card-title
                contenteditable="true"
                @blur="updateTitle"
                @input="handleInput"
                @keydown="handleKeydown"
                @click="selectAllText"
                class="editable-title mt-14"
                :data-id="coluna.id"
                :data-titulo="coluna.titulo"
                ref="titleElement"
                title="Editar"
              >
                {{ coluna.titulo }}
              </v-card-title>
              <v-spacer></v-spacer>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="showAddCard(coluna.id)">
                    <v-list-item-title>Adicionar Cartão</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="showAddColumn('left', coluna.ordem, coluna.id)"
                  >
                    <v-list-item-title
                      >Adicionar Coluna à Esquerda</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    @click="showAddColumn('right', coluna.ordem, coluna.id)"
                  >
                    <v-list-item-title
                      >Adicionar Coluna à Direita</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    @click="deleteColumn(coluna.id)"
                    v-if="columns.length > 1"
                  >
                    <v-list-item-title>Apagar Coluna</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </div>

          <div class="mt-8">
            <kanbanCard
              :idColumn="coluna.id"
              :idKanban="kanbanId"
              :permission="permission"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import kanbanCard from "./kanbanCard.vue";
import { mapActions } from "vuex";
export default {
  props: ["kanbanId", "permission"],
  components: {
    kanbanCard,
  },
  data() {
    return {
      columns: [],
      newTitle: "",
      isMaxLengthReached: false,
      editedColumnId: null,
      autoScrollTimeout: null,
      scrollDirection: 0, // 0 = parado, -1 = esquerda, 1 = direita
    };
  },
  created() {
    this.fetchData();
    this.listenUpdate();
  },
  mounted() {
    this.$root.$on("columnAdded", () => {
      this.fetchData();
    });
  },
  methods: {
    ...mapActions(["cryptoMsg", "decrypt"]),
    handleKeydown(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        this.updateTitle(event);
        document.activeElement.blur();
      }
      const maxLength = 60; // Limite de caracteres
      const element = event.target;
      const currentLength = element.innerText.length;

      // Previne a inserção de novos caracteres se o limite for atingido
      if (
        currentLength >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this.isMaxLengthReached = true;
        this.editedColumnId = element.getAttribute("data-id");
      } else {
        this.isMaxLengthReached = false;
        this.editedColumnId = null;
      }
    },
    handleInput(event) {
      const maxLength = 60; // Limite de caracteres
      const element = event.target;

      // Corrige o texto para garantir que não ultrapasse o limite
      if (element.innerText.length > maxLength) {
        element.innerText = element.innerText.slice(0, maxLength);
        this.isMaxLengthReached = true;
        this.editedColumnId = element.getAttribute("data-id");
      } else {
        this.isMaxLengthReached = false;
        this.editedColumnId = null;
      }
    },
    selectAllText(event) {
      const range = document.createRange();
      const titleElement = event.target;
      if (titleElement) {
        range.selectNodeContents(titleElement);
      }
    },
    async updateTitle(event) {
      const titleElement = event.target;
      const columnId = titleElement.getAttribute("data-id");
      this.newTitle = titleElement.innerText;

      if (this.newTitle === "") {
        titleElement.innerText = titleElement.getAttribute("data-titulo");
      } else {
        let obj = {
          id_client: this.info.id_cliente,
          id_attendant: this.info.id,
          id_number: this.info.id_numero,
          id_kanban: this.kanbanId,
          id_column: columnId,
          title: this.newTitle,
        };

        let objCrypt = await this.cryptoMsg(obj);

        this.axios
          .post(`${this.server}/kanban/updateColumn`, {
            data: objCrypt,
          })
          .then((response) => {
            console.log("Column update:", response.data);
          })
          .catch((error) => {
            console.error("Error adding column:", error);
          });
      }
    },
    listenUpdate() {
      this.socket.on("updateKanban", (data) => {
        if (data.id_kanban == this.kanbanId) {
          this.fetchData();
        }
      });
    },
    deleteColumn(columnId) {
      if (
        confirm(
          "Você tem certeza que deseja apagar esta coluna? Isto é irreversível."
        )
      ) {
        this.axios
          .post(`${this.server}/kanban/deleteColumn`, {
            id: columnId,
            id_cliente: this.info.id_cliente,
            id_kanban: this.kanbanId,
          })
          .then(() => {
            alert("Coluna apagada com sucesso!");
            this.fetchData();
          })
          .catch((error) => {
            console.error(
              "Erro ao apagar a coluna:",
              error.response.data.error
            );
            alert(error.response.data.error);
          });
      }
    },
    showAddCard(id) {
      this.$emit("showAddCard", id);
    },
    showAddColumn(position, order, id) {
      this.$emit("showAddColumn", { order: order, position: position, id: id });
    },
    fetchData() {
      this.columns = [];
      this.axios
        .post(`${this.server}/kanban/getColumns/`, {
          i: this.kanbanId,
        })
        .then((res) => {
          this.columns = res.data.columns;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    startAutoScroll(speed) {
      const scroll = () => {
        const kanbanRow = this.$refs.kanbanRow;

        if (this.scrollDirection === -1 && kanbanRow.scrollLeft > 0) {
          kanbanRow.scrollLeft -= speed;
        } else if (
          this.scrollDirection === 1 &&
          kanbanRow.scrollLeft + kanbanRow.clientWidth < kanbanRow.scrollWidth
        ) {
          kanbanRow.scrollLeft += speed;
        }

        if (this.scrollDirection !== 0) {
          this.autoScrollTimeout = requestAnimationFrame(scroll);
        }
      };

      this.autoScrollTimeout = requestAnimationFrame(scroll);
    },

    stopAutoScroll() {
      cancelAnimationFrame(this.autoScrollTimeout);
      this.scrollDirection = 0;
    },

    handleDragOver(event) {
      const kanbanRow = this.$refs.kanbanRow;
      const boundingRect = kanbanRow.getBoundingClientRect();
      const mouseX = event.clientX - boundingRect.left;
      const containerWidth = boundingRect.width;

      const leftThreshold = containerWidth * 0.15;
      const rightThreshold = containerWidth * 0.85;

      if (mouseX < leftThreshold) {
        this.scrollDirection = -1;
        this.startAutoScroll(10);
      } else if (mouseX > rightThreshold) {
        this.scrollDirection = 1;
        this.startAutoScroll(10);
      } else {
        this.stopAutoScroll();
      }
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
    socket() {
      return this.$store.state.socket;
    },
  },
};
</script>

<style scoped>
.kanban-row {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.kanban-column {
  min-height: 400px;
  padding: 10px;
}
.editable-title {
  white-space: normal;
  font-weight: 450;
  cursor: text;
  display: inline-block;
  font-size: 18px;
  line-height: 1.2; /* Ajuste o valor conforme necessário */
  word-break: break-word; /* Permite a quebra de palavras longas se necessário */
  overflow-wrap: break-word; /* Quebra palavras longas se necessário */
}
.custom-scrollbar::-webkit-scrollbar {
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
