<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title>Adicionar Novo Quadro</v-card-title><v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="overflow-y-auto">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="title"
                label="Título do quadro"
                :rules="titleRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="false">
              <v-textarea
                v-model="description"
                label="Descrição"
                :rules="descriptionRules"
                required
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="titleColumn"
                label="Título da primeira coluna"
                :rules="titleRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="false">
              <v-textarea
                v-model="descriptionColumn"
                label="Descrição da primeira coluna"
                :rules="descriptionRules"
                required
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          class="text-capitalize"
          color="#0D47A1"
          style="color: white"
          @click="createKanban"
          >Adicionar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      descriptionColumn: "",
      titleColumn: "",
      dialog: true,
      title: "",
      description: "",
      titleRules: [(v) => !!v || "O título é obrigatório"],
      descriptionRules: [(v) => !!v || "A descrição é obrigatória"],
    };
  },
  created() {},
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  methods: {
    close() {
      this.$emit("closeAddKanban");
    },
    createKanban() {
      if (this.title != "" && this.titleColumn != "") {
        this.axios
          .post(`${this.server}/kanban/addKanban`, {
            i: this.info.id_numero,
            ia: this.info.id,
            kanbanTitle: this.title,
            kanbanDescription: this.description,
            columnTitle: this.titleColumn,
            columnDescription: this.descriptionColumn,
          })
          .then((res) => {
            switch (res.status) {
              case 200:
                console.warn(res.data.data[0].id);
                this.$root.$emit("updateKanban", res.data.data[0].id);
                break;
              case 201:
                break;
              case 202:
                break;
              case 203:
                break;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
