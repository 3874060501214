<template>
  <v-container class="my-container mt-n5 ml-n5" fluid>
    <chat
      :messages="conversation"
      v-if="chatDialog"
      @hide="chatDialog = false"
    />
    <v-row v-else class="row-flex-wrap mt-3 ml-1">
      <v-col v-for="dep in deps" :key="dep.id" class="dep-col">
        <v-card-title class="sticky-title mt-n7">
          {{ dep.departamento }}</v-card-title
        >
        <v-card
          class="dep-card overflow-y-auto mt-n3"
          :width="depCol"
          elevation="7"
          color="#ECEFF1"
        >
          <v-row>
            <v-col
              v-for="service in dep.services"
              :key="service.id_atendimento"
              class="service-col"
            >
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    v-bind="attrs"
                    v-on="on"
                    elevation="10"
                    @click="serviceInteract(service)"
                    class="mt-5"
                    width="350px"
                    height="105px"
                    :color="colorCard(service)"
                  >
                    <v-card-title class="mr-1 mt-n6">
                      <v-avatar size="36px">
                        <img
                          alt="Avatar"
                          v-if="
                            service.img != 'undefined' && service.img != 'null'
                          "
                          :src="service.img"
                        />
                        <v-icon alt="Avatar" v-else>mdi-account</v-icon>
                      </v-avatar>
                      <span class="ml-2" v-if="service.contato_zap == ''">
                        {{ service.contato_4red.substring(0, 7) }}
                      </span>
                      <span v-else class="ml-2">
                        {{ service.contato_zap.substring(0, 7) }}
                      </span>
                      <v-icon small class="ml-6">mdi-phone</v-icon>
                      <span class="text-subtitle-2">
                        {{
                          service.numero
                            .substring(2)
                            .split("@")[0]
                            .substring(0, 11)
                        }}
                      </span>
                      <v-spacer></v-spacer>
                      <v-icon
                        v-show="
                          (info.permissao == 'admin' ||
                            info.permissao == 'supervisor') &&
                          info.id == service.id_atendente
                        "
                        color="red"
                      >
                        mdi-star-outline
                      </v-icon>
                    </v-card-title>
                    <v-card-text
                      class="ml-2 mt-n4"
                      v-if="service.tipo == 'texto'"
                      style="height: 70px"
                    >
                      <v-icon>mdi-chat-alert-outline</v-icon>
                      {{ service.mensagem.substring(0, 25) }}
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Enviado'"
                        >mdi-check</v-icon
                      >
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Recebido'"
                        >mdi-check-all</v-icon
                      >
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Lido'"
                        color="blue"
                        >mdi-check-all</v-icon
                      >
                      <br />
                      <v-icon x-small>mdi-clock</v-icon>
                      <span class="mr-2">
                        {{ formatDate(service.horario) }}
                      </span>
                      <v-chip
                        class="mr-1"
                        v-for="(tag, index) in service.tags"
                        small
                        :key="index"
                        input-value="true"
                        :color="tag.cor"
                      ></v-chip>
                    </v-card-text>
                    <v-card-text
                      class="ml-2 mt-n4"
                      v-else-if="service.tipo == 'imagem'"
                      style="height: 70px"
                    >
                      <v-icon>mdi-chat-alert-outline</v-icon>
                      Imagem
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Enviado'"
                        >mdi-check</v-icon
                      >
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Recebido'"
                        >mdi-check-all</v-icon
                      >
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Lido'"
                        color="blue"
                        >mdi-check-all</v-icon
                      >
                      <br />
                      <v-icon x-small>mdi-clock</v-icon>
                      <span class="mr-2">
                        {{ formatDate(service.horario) }}
                      </span>
                      <v-chip
                        class="mr-1"
                        v-for="(tag, index) in service.tags"
                        small
                        :key="index"
                        input-value="true"
                        :color="tag.cor"
                      ></v-chip>
                    </v-card-text>
                    <v-card-text
                      class="ml-2 mt-n4"
                      v-else-if="
                        service.tipo == 'audio' || service.tipo == 'gravacao'
                      "
                      style="height: 70px"
                    >
                      <v-icon>mdi-chat-alert-outline</v-icon>
                      Audio
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Enviado'"
                        >mdi-check</v-icon
                      >
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Recebido'"
                        >mdi-check-all</v-icon
                      >
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Lido'"
                        color="blue"
                        >mdi-check-all</v-icon
                      >
                      <br />
                      <v-icon x-small>mdi-clock</v-icon>
                      <span class="mr-2">
                        {{ formatDate(service.horario) }}
                      </span>
                      <v-chip
                        class="mr-1"
                        v-for="(tag, index) in service.tags"
                        small
                        :key="index"
                        input-value="true"
                        :color="tag.cor"
                      ></v-chip>
                    </v-card-text>
                    <v-card-text
                      class="ml-2 mt-n4"
                      v-else-if="service.tipo == 'video'"
                      style="height: 70px"
                    >
                      <v-icon>mdi-chat-alert-outline</v-icon>
                      Video
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Enviado'"
                        >mdi-check</v-icon
                      >
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Recebido'"
                        >mdi-check-all</v-icon
                      >
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Lido'"
                        color="blue"
                        >mdi-check-all</v-icon
                      >
                      <br />
                      <v-icon x-small>mdi-clock</v-icon>
                      <span class="mr-2">
                        {{ formatDate(service.horario) }}
                      </span>
                      <v-chip
                        class="mr-1"
                        v-for="(tag, index) in service.tags"
                        small
                        :key="index"
                        input-value="true"
                        :color="tag.cor"
                      ></v-chip>
                    </v-card-text>
                    <v-card-text
                      class="ml-2mt-n4"
                      v-else-if="
                        service.tipo == 'file' || service.tipo == 'pdf'
                      "
                      style="height: 70px"
                    >
                      <v-icon>mdi-chat-alert-outline</v-icon>
                      Arquivo
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Enviado'"
                        >mdi-check</v-icon
                      >
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Recebido'"
                        >mdi-check-all</v-icon
                      >
                      <v-icon
                        small
                        v-if="service.ultima_mensagem_status == 'Lido'"
                        color="blue"
                        >mdi-check-all</v-icon
                      >
                      <br />
                      <v-icon x-small>mdi-clock</v-icon>
                      <span class="mr-2">
                        {{ formatDate(service.horario) }}
                      </span>
                      <v-chip
                        class="mr-1"
                        v-for="(tag, index) in service.tags"
                        small
                        :key="index"
                        input-value="true"
                        :color="tag.cor"
                      ></v-chip>
                    </v-card-text>
                  </v-card>
                </template>
                <span>
                  Atendente: {{ service.nome_atendente }}<br />
                  Nome Whatsapp: {{ service.contato_4red }}<br />
                  Nome de Cadastro: {{ service.contato_zap }}<br />
                  Numero: {{ service.numero.split("@")[0] }}<br />
                  Status: {{ service.status }}<br />
                  Etiquetas: {{ converttags(service.tags) }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chat from "./chatScreen.vue";

/* eslint-disable */
export default {
  components: {
    Chat,
  },
  data() {
    return {
      chatDialog: false,
    };
  },
  computed: {
    newMessage() {
      return this.$store.state.newMessage;
    },
    openServiceScreen() {
      return this.$store.state.openServiceScreen;
    },
    depCol() {
      return `${this.$store.state.screens.servicesDepCol * 380}px`;
    },
    deps() {
      return this.$store.state.info.deps;
    },
    conversation() {
      return this.$store.state.conversation;
    },
    numCols() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 5; // número de colunas desejado para telas grandes
      } else if (this.$vuetify.breakpoint.smAndUp) {
        return 3; // número de colunas desejado para telas médias
      } else {
        return 1; // número de colunas desejado para telas pequenas
      }
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    newMessage(newValue) {
      if (!this.chatDialog && newValue != 0) {
        this.$store.state.newMessage = 0;
      }
    },
    openServiceScreen(newValue) {
      if (newValue) {
        this.$store.state.tabForDep;
        this.chatDialog = false;
        this.$store.state.newMessage = 0;
        this.$store.state.conversation = "";
        this.$store.state.serviceFromChat = "";
        this.$store.state.openServiceScreen = false;
      }
    },
    conversation(newValue) {
      if (newValue != "") {
        this.chatDialog = true;
      } else if (newValue == "") {
        this.$store.state.tabForDep;
        this.chatDialog = false;
        this.$store.state.conversation = "";
        this.$store.state.serviceFromChat = "";
      }
    },
    chatDialog(newValue) {
      if (!newValue) {
        this.$store.state.conversation = "";
        this.$store.state.serviceFromChat = "";
      }
    },
  },
  created() {
    let depCol = localStorage.getItem(
      `${this.$store.state.info.nome}_depColValue`
    );
    if (!depCol) {
      this.$store.state.screens.servicesDepCol = 1;
    } else {
      this.$store.state.screens.servicesDepCol = depCol;
    }
  },
  methods: {
    converttags(tags) {
      return tags?.map((obj) => obj.nome).join(", ");
    },
    calculateMaxWidth() {
      // Calcule a largura máxima como uma porcentagem da largura da janela
      // Por exemplo, para 80% da largura da janela, você pode fazer:
      const windowWidth = window.innerHeight;
      const maxWidthPercentage = 87; // 80% da largura da janela
      const maxWidth = (windowWidth * maxWidthPercentage) / 100;

      return `${maxWidth}px`;
    },
    formatDate(date) {
      const options = {
        day: "numeric", // Adicionando a opção para exibir o dia
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return new Date(date).toLocaleString("pt-BR", options);
    },
    colorCard(service) {
      if (service.status == "aguardando") {
        return "#EF9A9A";
      } else if (!service.visualizado) {
        return "#FFF9C4";
      } else {
        return "#E3F2FD";
      }
    },
    btnColor(service) {
      if (service.status == "aguardando") {
        return "success";
      } else if (service.status == "em atendimento") {
        return "primary";
      }
    },
    btnWords(service) {
      if (service.status == "aguardando") {
        return "Pegar atendimento";
      } else if (service.status == "em atendimento") {
        return "Abrir conversa";
      }
    },
    serviceInteract(service) {
      let integrations = this.$store.state.info.deps.find(
        (dep) => service.id_departamento == dep.id
      );
      service.integracao = integrations.integracoes;
      if (service.status.includes("aguardando")) {
        this.$store.state.interactions.askForService = {
          clientId: this.$store.state.info.id_cliente,
          serviceId: service.id_atendimento,
          attendantId: this.$store.state.info.id,
          attendantName: this.$store.state.info.nome,
          destiny: service.numero,
          depId: service.id_departamento,
          origin: this.$store.state.info.numero,
          client: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
        };
        setTimeout(() => {
          this.$store.state.serviceFromChat = service;
          this.$store.state.interactions.getService = {
            serviceId: service.id_atendimento,
          };
        }, 200);
      } else if (
        service.status.includes("em atendimento") ||
        service.status.includes("nova")
      ) {
        service.status = "em atendimento";
        service.visualizado = true;
        this.$store.state.serviceFromChat = service;
        this.$store.state.interactions.getService = {
          serviceId: service.id_atendimento,
        };
      }
    },
  },
};
</script>

<style>
.my-container {
  width: 100%;
  white-space: nowrap;
}

.row-flex-wrap {
  overflow-x: auto;
  flex-wrap: nowrap;
}

.dep-card {
  height: 85vh;
  margin: 1px;
  padding: 10px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.service-col {
  margin: 0px;
  width: calc(100% - 20px);
}

@media (max-width: 600px) {
  .dep-col {
    flex-basis: 100%;
  }
}
</style>
