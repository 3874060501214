<template>
  <div v-if="dialog" class="d-flex justify-center align-center" style="height: 40vh;width:100%;">
      <canvas id="chart" ></canvas>
  </div>
</template>
<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  props:['date'],
  data(){
    return{
      dialog:false,
      r:"/report/countEntrantsAndFinishedCallsByDay",
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Entrantes',
            data: [],
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          },
          {
            label: 'Finalizados',
            data: [],
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
          }
        ]
      }
    }
  },
    created() {
      this.fetchData();
    },
    computed:{
      axios() {
            return this.$store.state.axios;
        },
        server() {
            return this.$store.state.serverExpress;
        },
        info() {
            return this.$store.state.info;
        },
    },
    watch:{
      date(newValue){
        if(newValue.length==2){
          this.dialog = false
          this.fetchData()
        }
      }
    },
    methods: {
      fetchData() {
      this.axios
        .post(`${this.server}${this.r}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          d: this.date
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data.data)
            this.dialog = true
            setTimeout(() => {
              this.$emit("IncomingAndCompletedChart_ready")
              this.processData(res.data.data);
              this.createChart();
            }, 100);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    processData(data) {
      this.chartData.labels = data.map(item => item.day.split('T')[0]);
      this.chartData.datasets[0].data = data.map(item => item.entrantes_count);
      this.chartData.datasets[1].data = data.map(item => item.finalizados_count);
    },
    createChart() {
      const ctx = document.getElementById("chart").getContext("2d");
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(ctx, {
        type: "bar",
        data: this.chartData,
        options: {
          plugins: {
            title: {
              display: true,
              text: "Quantidade de Atendimentos por Dia"
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
              },
            },
          },
        },
      });
    }
    }
}
</script>
