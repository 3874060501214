<template>
  <v-carousel v-model="model" hide-delimiters height="auto">
    <headLine :date="date" @headline_ready="headline_ready = true" />
    <v-carousel-item class="ma-3 carousel-item-container">
      <IncomingAndCompletedChart
        :date="date"
        v-if="headline_ready"
        @IncomingAndCompletedChart_ready="
          IncomingAndCompletedChart_ready = true
        "
      />
      <v-sheet class="text-center" v-else>
        <v-row align="center" justify="center">
          <v-col>
            <v-progress-circular
              indeterminate
              color="purple"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-sheet>
      <callsByDepartmentAndDay
        :date="date"
        v-if="IncomingAndCompletedChart_ready"
        @callsByDepartmentAndDay_ready="callsByDepartmentAndDay_ready = true"
      />
      <countCallsByGroupAndDay
        :date="date"
        v-if="callsByDepartmentAndDay_ready"
        @countCallsByGroupAndDay_ready="countCallsByGroupAndDay_ready = true"
      />
      <v-sheet class="text-center" v-else>
        <v-row align="center" justify="center">
          <v-col>
            <v-progress-circular
              indeterminate
              color="purple"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-sheet>
      <peakAttendancesByHour
        :date="date"
        v-if="countCallsByGroupAndDay_ready"
        @peakAttendancesByHour_ready="peakAttendancesByHour_ready = true"
      />
      <v-sheet class="text-center" v-else>
        <v-row align="center" justify="center">
          <v-col>
            <v-progress-circular
              indeterminate
              color="purple"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-sheet>
      <peakAttendancesByHourAndDepartment
        :date="date"
        v-if="peakAttendancesByHour_ready"
        @peakAttendancesByHourAndDepartment_ready="
          peakAttendancesByHourAndDepartment_ready = true
        "
      />
      <v-sheet class="text-center" v-else>
        <v-row align="center" justify="center">
          <v-col>
            <v-progress-circular
              indeterminate
              color="purple"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-sheet>
    </v-carousel-item>
    <span v-for="(dep, index) in deps" :key="index">
      <v-carousel-item class="ma-3 carousel-item-container text-center">
        Departamento {{ dep.departamento }}
        <v-sheet
          class="text-center"
          v-if="!peakAttendancesByHourAndDepartment_ready"
        >
          <v-row align="center" justify="center">
            <v-col>
              <v-progress-circular
                indeterminate
                color="purple"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-sheet>
        <attendantByDepartament
          :date="date"
          :dep="dep"
          v-if="peakAttendancesByHourAndDepartment_ready"
        />
        <avarageAttendantByDepartament
          :date="date"
          :dep="dep"
          v-if="peakAttendancesByHourAndDepartment_ready"
        />
        <calculateOnlineTimeByAttendantAndDay
          :date="date"
          :dep="dep"
          v-if="peakAttendancesByHourAndDepartment_ready"
        />
        <calculatePauseTimeByAttendantAndDay
          :date="date"
          :dep="dep"
          v-if="peakAttendancesByHourAndDepartment_ready"
        />
        <tabsScreen
          :date="date"
          :dep="dep"
          v-if="peakAttendancesByHourAndDepartment_ready"
        />
      </v-carousel-item>
    </span>
  </v-carousel>
</template>

<script>
import headLine from "../headLine.vue";
import IncomingAndCompletedChart from "./general/IncomingAndCompletedChart.vue";
import callsByDepartmentAndDay from "./general/callsByDepartmentAndDay.vue";
import countCallsByGroupAndDay from "./general/countCallsByGroupAndDay.vue";
import attendantByDepartament from "./deps/attendantByDepartament.vue";
import avarageAttendantByDepartament from "./deps/avarageAttendantByDepartament.vue";
import peakAttendancesByHour from "./general/peakAttendancesByHour.vue";
import peakAttendancesByHourAndDepartment from "./general/peakAttendancesByHourAndDepartment.vue";
import calculateOnlineTimeByAttendantAndDay from "./deps/calculateOnlineTimeByAttendantAndDay.vue";
import calculatePauseTimeByAttendantAndDay from "./deps/calculatePauseTimeByAttendantAndDay.vue";
import tabsScreen from "./deps/tabs/tabsScreen.vue";

export default {
  props: ["date"],
  components: {
    tabsScreen,
    headLine,
    IncomingAndCompletedChart,
    callsByDepartmentAndDay,
    attendantByDepartament,
    avarageAttendantByDepartament,
    peakAttendancesByHour,
    peakAttendancesByHourAndDepartment,
    calculateOnlineTimeByAttendantAndDay,
    calculatePauseTimeByAttendantAndDay,
    countCallsByGroupAndDay,
  },
  data: () => ({
    deps: [],
    headline_ready: false,
    peakAttendancesByHourAndDepartment_ready: false,
    IncomingAndCompletedChart_ready: false,
    callsByDepartmentAndDay_ready: false,
    peakAttendancesByHour_ready: false,
    countCallsByGroupAndDay_ready: false,
    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    rd: "/report/get_deps",
  }),
  watch: {
    date(newValue) {
      if (newValue.length == 2) {
        this.headline_ready = false;
        this.IncomingAndCompletedChart_ready = false;
        this.callsByDepartmentAndDay_ready = false;
      }
    },
  },
  created() {
    this.fetchData();
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  methods: {
    fetchData() {
      try {
        this.axios
          .post(`${this.server}${this.rd}`, {
            n: this.info.id_numero,
            c: this.info.id_cliente,
          })
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data.data);
              this.deps = res.data.data;
            }
          });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style>
.carousel-item-container {
  max-height: 60vh; /* Ajuste conforme necessário */
  overflow-y: auto; /* Isso adiciona uma barra de rolagem se o conteúdo exceder a altura máxima */
}
</style>