<template>
  <v-dialog v-model="dialog" width="600px">
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-card-title style="font-size: 16px; font-weight: 400"
          >Adicionar Grupo de Contatos</v-card-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <form class="ml-8 mr-8">
        <v-text-field v-model="name" label="Nome"></v-text-field>
      </form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="add"
          small
          class="ml-2 text-capitalize"
          color="#0D47A1"
          style="color: white"
          >Adicionar</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
      </v-sheet>
    </v-bottom-sheet>
  </v-dialog>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      sheet: false,
      sheetDialog: "",
      dialog: true,
      name: "",
      route: "/groups/add",
    };
  },
  methods: {
    data() {
      /*this.axios
        .get(`${this.server}/integrations/get_integrations/${this.info.usuario}/${this.info.id_numero}`)
        .then((res) => {
          this.$store.state.integrations = res.data;
        })
        .catch((err) => {
          console.log(err);
        });*/
    },
    add() {
      this.axios
        .post(`${this.server}${this.route}`, {
          n: this.info.id_numero,
          c: this.info.id_cliente,
          g: this.name,
        })
        .then((res) => {
          if (res.status == 200) {
            this.sheetDialog = res.data.message;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
              this.dialog = false;
              this.$emit("reload");
            }, 4000);
          } else {
            this.sheetDialog = res.data.error;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
            }, 4000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("closeAdd");
      }
    },
  },
};
</script>
