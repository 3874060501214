<template>
  <v-dialog v-model="dialog" width="700px" >
    <v-card :style="{ 'height': '70vh' }">
      <v-card-title class="text-center">
        Informações do grupo "{{ groupName }}"
        <v-spacer></v-spacer>      
      </v-card-title>
      <v-textarea  :disabled="disable" class="ml-5 mr-5"  v-model="textInfo" rows="18" ></v-textarea>
      <v-card-actions>
        <v-btn small class="ml-2 text-capitalize" @click="saveInfo" color="red">Salvar</v-btn>
        <v-spacer></v-spacer>
        <v-btn small class="ml-2 text-capitalize" @click="(dialog = false)" color="primary">Voltar</v-btn>
      </v-card-actions>
    </v-card>
    <v-bottom-sheet persistent v-model="sheet">
      <v-sheet class="text-center" text-color="red" height="200px">
        <div class="py-2">{{ sheetDialog }}</div>
      </v-sheet>
    </v-bottom-sheet>
  </v-dialog>
</template>

<script>
export default {
  props: ["id"],
  components: {},
  data() {
    return {
      groupName:"",
      textInfo:"",
      search:"",
      sheet: false,
      sheetDialog: "",
      dialog: true,
      route: "/groups/get_info_text",
      routeS: "/groups/save_info",
      disable:false
    };
  },
  created() {
    
  },
  mounted(){
    this.getInfoText();
  },
  methods: {
    getInfoText() {
      this.axios
        .post(`${this.server}${this.route}`, {
          i: this.id,
          t:"texto"
        })
        .then((res) => {
          if (res.status == 200) {
            console.log(this.groupName)
            this.textInfo = res.data.data[0].conteudo
            console.log(this.textInfo)
            this.groupName = res.data.data[0].nome
          } else if(res.status!=201){
            this.sheetDialog = res.data.error;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
            }, 4000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveInfo(){
      this.axios
        .post(`${this.server}${this.routeS}`, {
          i: this.id,
          c: this.textInfo,
          t: "texto",
        })
        .then((res) => {
          if (res.status == 200) {
            this.sheetDialog = res.data.message;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
              this.dialog = false;
              this.$emit("reload");
            }, 4000);
          } else {
            this.sheetDialog = res.data.error;
            this.sheet = true;
            setTimeout(() => {
              this.sheet = false;
            }, 4000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  computed: {
    axios() {
      return this.$store.state.axios;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$emit("closeInfo");
      }
    }
  },
};
</script>
