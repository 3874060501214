import Vue from "vue";
import Vuex from "vuex";
import CryptoJS from "crypto-js";
Vue.use(Vuex);

/* eslint-disable no-new */
const store = new Vuex.Store({
  actions: {
    cryptoMsg({ state }, msg) {
      let jsonString = JSON.stringify(msg);
      let encrypted = CryptoJS.AES.encrypt(
        jsonString,
        state.interactions.token,
        {
          iv: state.interactions.iv,
        }
      );
      return encrypted.toString();
    },
    decrypt({ state }, msg) {
      // Descriptografa a mensagem usando AES
      var decrypted = CryptoJS.AES.decrypt(msg, state.interactions.token, {
        iv: CryptoJS.enc.Utf8.parse(state.interactions.iv),
      });
      // Converte a string decifrada em um objeto JSON
      var decryptedJsonString = decrypted.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedJsonString);
    },
  },
  state: {
    searchKanbanCards: "",
    socket: "",
    kanbanScreen: false,
    libStatus: "",
    newMessage: 0,
    openServiceScreen: false,
    //PRODUCAO
    //serverExpress: "https://127.0.0.1:3030",
    serverExpress: "",
    axios: "",
    attendantsForWallet: "",
    transferAttendant: "",
    transferDeps: "",
    serviceFromChat: "",
    conversation: "",
    isAuth: false,
    authFailed: false,
    agent: "",
    agentPass: "",
    info: "",
    extension: "",
    contacts: "",
    history: "",
    attendants: "",
    showReport: false,
    historyConversation: "",
    dataForReport: "",
    attendant: "",
    report: "",
    ura: "",
    uraSub: "",
    optionEnd: "",
    tabForDep: "",
    clients: "false",
    integrations: "",
    screens: {
      kanbanLayout: true,
      tag: false,
      login: false,
      options: false,
      ura: false,
      servicesDepCol: 1,
      addClients: false,
      deps: false,
      integrations: false,
      tab: false,
      satisfactionSurvey: false,
      group: false,
    },
    reportPeak: "",
    historyBtn: false,
    reportMediaSurvey: "",
    reportTotals: "",
    reportSurvey: "",
    tabReport: "",
    sendIntegrationRes: "",
    contactsForWallet: "",
    tabs: [],
    deps: [],
    satisfactionSurvey: [],
    interactions: {
      editDep: "",
      insertDepToSatisfaction: "",
      deleteDepSurvey: "",
      deleteSurvey: "",
      getSatisfactionSurvey: "",
      addSatisctionSurvey: "",
      addOptionInteraction: "",
      sendIntegration: "",
      addIntegrationToDep: "",
      deleteIntegration: "",
      addIntegration: "",
      getIntegrations: "",
      getContactsForWallet: "",
      addWallet: "",
      insertTabAnswer: "",
      getAttendantsForWallet: "",
      removeTab: "",
      deleteTab: "",
      getTabForDep: "",
      insertDepToTab: "",
      qrCodeMake: "",
      getTabs: "",
      rebootClient: "",
      addTab: "",
      editOptionsEnd: "",
      getOptionEnd: "",
      addOptionEnd: "",
      deleteOptionSub: "",
      deleteOptionDep: "",
      uraSubComponent: {
        editUraData: "",
        editoptionsData: "",
      },
      addOptionsSub: "",
      editUra: "",
      editOptions: "",
      addOptionDepUra: "",
      editAttendant: "",
      deleteDep: "",
      addDep: "",
      addAttendant: "",
      deleteAttendant: "",
      addClient: "",
      getUra: "",
      getUraSub: "",
      getReportFull: "",
      getReportDep: "",
      getDataForReport: "",
      showQrcode: false,
      addContact: "",
      openService: "",
      getHistoryConversation: "",
      getHistory: "",
      getHistoryRange: "",
      askForService: "",
      getService: "",
      sendMessage: "",
      finish: "",
      sendFile: "",
      editContact: "",
      getInfoForTransfer: "",
      transferServiceToDep: "",
      transferServiceToAttendant: "",
      getContacts: "",
      getAttendants: "",
      token: "640f6e69Interface26883",
      iv: "_3926883",
    },
  },
});

export default store;
