<template>
  <v-dialog v-model="dialog" v-if="dialog" max-width="900px">
    <v-card>
      <span v-if="contacts.length > 0">
        <v-toolbar dense dark color="primary">
          <v-card-title>Contatos</v-card-title><v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          ><form class="mt-n6">
            <v-row justify="end" class="mt-3">
              <v-col class="justify-center" cols="auto"
                ><v-text-field
                  class="mt-n4"
                  style="max-width: 200px"
                  v-model="search"
                  clearable
                  flat
                  hide-details
                  append-outer-icon="mdi-magnify"
                  label="Procurar"
                  id="search"
                ></v-text-field
              ></v-col>
            </v-row>
          </form>
          <div style="max-height: 58vh" class="overflow-y-auto">
            <v-data-table
              class="ma-3"
              :headers="header"
              :items="filteredContacts"
              :footer-props="{ itemsPerPageOptions: [10] }"
            >
              <template v-slot:item="{ item }">
                <tr
                  v-if="
                    item.disponivel ||
                    info.permissao == 'admin' ||
                    info.permissao == 'supervisor'
                  "
                >
                  <td>
                    <v-avatar size="36px" class="mr-3">
                      <img
                        alt="Avatar"
                        v-if="item.img != 'undefined' && item.img != 'null'"
                        :src="item.img"
                      />
                      <v-icon alt="Avatar" v-else>mdi-account</v-icon>
                    </v-avatar>
                    {{ item.contato_4red }}
                  </td>
                  <td>{{ item.contato_zap }}</td>
                  <td>{{ item.numero.split("@")[0].substring(2) }}</td>
                  <td>
                    <v-btn small icon @click="showDetail(item)">
                      <v-icon color="primary"
                        >mdi-account-details-outline</v-icon
                      >
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="text-capitalize"
            style="color: white"
            @click="dialogAdd = true"
            color="#0D47A1"
            >Adicionar Contato</v-btn
          >
        </v-card-actions>
      </span>
      <v-container v-else class="fill-height text-center">
        <v-row align="center" justify="center">
          <v-col>
            <v-progress-circular
              indeterminate
              color="purple"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <contactAdd
      v-if="dialogAdd"
      @hide="dialogAdd = false"
      @newContact="newContact"
    />
    <contactDetail
      v-if="dialogDetail"
      @update="(dialogDetail = false), data()"
      @hide="dialogDetail = false"
      @hideForOpen="(dialogDetail = false), (dialog = false)"
      :contactProp="selectedContact"
    />
  </v-dialog>
</template>
<script>
import contactDetail from "./contactDetail.vue";
import contactAdd from "./contactAdd.vue";

export default {
  components: {
    contactDetail,
    contactAdd,
  },
  data() {
    return {
      search: "",
      dialog: true,
      dialogDetail: false,
      dialogAdd: false,
      contacts: [],
      selectedContact: "",
      header: [
        { text: "Nome Whatsapp", value: "contato_4red" },
        { text: "Nome Cadastro", value: "contato_zap" },
        { text: "Numero", value: "numero" },
        { text: "Detalhes", value: "action" },
      ],
    };
  },
  created() {
    this.data();
  },
  methods: {
    async newContact(contact) {
      await this.data();
      console.log(contact);
      this.dialogAdd = false;
      this.selectedContact = contact;
      this.dialogDetail = true;
    },
    reloadUraScreen() {},
    data() {
      this.axios
        .post(`${this.server}/contact/get_all_contacts`, {
          i: this.info.id_numero,
        })
        .then((res) => {
          console.error(res.data.contacts);
          this.contacts = res.data.contacts;
          setTimeout(() => {
            let search = document.getElementById("search");
            search.focus();
          }, 500);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showDetail(item) {
      this.selectedContact = item;
      this.dialogDetail = true;
    },
  },
  computed: {
    filteredContacts() {
      const search = this.search.toLowerCase();
      return this.contacts.filter((item) => {
        let contato_4red = item.contato_4red.toLowerCase();
        let numero = item.numero.toLowerCase();
        let contato_zap = item.contato_zap.toLowerCase();
        return (
          contato_4red.indexOf(search) > -1 ||
          numero.indexOf(search) > -1 ||
          contato_zap.indexOf(search) > -1
        );
      });
    },
    axios() {
      return this.$store.state.axios;
    },
    screen() {
      return this.$store.state.screens.group;
    },
    server() {
      return this.$store.state.serverExpress;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    dialogDetail(newValue) {
      if (!newValue) {
        this.contactDetail = "";
      }
    },
    screen(newValue) {
      if (newValue) {
        this.dialog = true;
        this.data();
      } else {
        this.dialog = false;
      }
    },
    dialog(newValue) {
      if (!newValue) {
        this.$emit("hide");
      }
    },
  },
};
</script>
