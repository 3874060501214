<template>
  <v-container class="my-container ml-n4" fluid>
    <v-row class="row-flex-wrap ml-1">
      <v-col style="max-width: 28vw">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Pesquisar"
          solo
          dense
        ></v-text-field>
        <v-select
          v-model="selectedDepartment"
          :items="departments"
          label="Selecionar Departamento"
          dense
          outlined
        ></v-select>
        <v-list
          three-line
          class="mt-n3 overflow-y-auto custom-scrollbar"
          style="height: 85vh"
        >
          <v-tooltip
            bottom
            v-for="(item, index) in filteredServices"
            :key="index"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                :class="{ 'selected-item': selectedService === item }"
                @click="selectService(item)"
                v-bind="attrs"
                v-on="on"
                :style="{ backgroundColor: colorCard(item) }"
              >
                <v-list-item-avatar>
                  <v-img
                    v-if="item.img !== 'undefined' && item.img !== 'null'"
                    :src="item.img"
                  ></v-img>
                  <v-icon alt="Avatar" v-else>mdi-account</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="list-item-title">
                    <span>{{ item.contato_4red.substring(0, 10) }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="item.tipo == 'texto'">{{
                    item.mensagem
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-else>Midia</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <span class="grey--text ml-2 timestamp">{{
                    formatDate(item.horario)
                  }}</span>
                  <v-icon
                    color="primary"
                    small
                    v-if="item.ultima_mensagem_status === 'Lido'"
                    >mdi-check-all</v-icon
                  >
                  <v-icon
                    small
                    v-else-if="item.ultima_mensagem_status === 'Entregue'"
                    >mdi-check-all</v-icon
                  >
                  <v-icon small v-else>mdi-check</v-icon>
                  <v-chip class="ml-2" color="primary" x-small label>
                    {{ item.departamento }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </template>
            <span>
              Atendente: {{ item.nome_atendente }}<br />
              Nome Whatsapp: {{ item.contato_4red }}<br />
              Nome de Cadastro: {{ item.contato_zap }}<br />
              Numero: {{ item.numero.split("@")[0] }}<br />
              Status: {{ item.status }}<br />
              <span v-if="item.tipo == 'texto'"
                >Ultima Mensagem: {{ item.mensagem }}</span
              ><span v-else>Ultima Mensagem: midia</span><br />
              Etiquetas:
              {{ converttags(item.tags) }}
            </span>
          </v-tooltip>
        </v-list>
      </v-col>
      <v-col class="mt-n5 ml-n6" style="max-width: 68vw; max-height: 55vh">
        <chat
          :messages="conversation"
          v-if="chatDialog"
          @hide="chatDialog = false"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chat from "../chatScreen.vue";

/* eslint-disable */
export default {
  components: {
    Chat,
  },
  data() {
    return {
      chatDialog: false,
      search: "",
      selectedService: null,
      selectedDepartment: "Todos",
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    services() {
      return this.deps.reduce((acc, dep) => {
        const { departamento, services } = dep;
        services.forEach((service) => {
          service.departamento = departamento;
          acc.push(service);
        });
        return acc;
      }, []);
    },
    departments() {
      const departments = this.services.map((service) => service.departamento);
      return ["Todos", ...new Set(departments)];
    },
    filteredServices() {
      return this.sortedServices.filter((service) => {
        const matchesDepartment =
          this.selectedDepartment === "Todos" ||
          service.departamento === this.selectedDepartment;
        const matchesSearch = service.contato_4red
          .toLowerCase()
          .includes(this.search.toLowerCase());
        return matchesDepartment && matchesSearch;
      });
    },
    newMessage() {
      return this.$store.state.newMessage;
    },
    sendMessage() {
      return this.$store.state.interactions.sendMessage;
    },
    openServiceScreen() {
      return this.$store.state.openServiceScreen;
    },
    deps() {
      return this.$store.state.info.deps;
    },
    conversation() {
      return this.$store.state.conversation;
    },
    info() {
      return this.$store.state.info;
    },
    sortedServices() {
      console.log("Sorting services...");
      return this.services.slice().sort((a, b) => {
        if (a.status === "aguardando" && b.status !== "aguardando") {
          return -1;
        } else if (a.status !== "aguardando" && b.status === "aguardando") {
          return 1;
        } else if (a.status === "aguardando" && b.status === "aguardando") {
          return new Date(a.horario) - new Date(b.horario); // mais antigo primeiro
        } else if (
          a.status === "em atendimento" &&
          b.status === "em atendimento"
        ) {
          if (!a.visualizado && b.visualizado) {
            return -1;
          } else if (a.visualizado && !b.visualizado) {
            return 1;
          } else if (!a.visualizado && !b.visualizado) {
            return new Date(b.horario) - new Date(a.horario); // mais recente primeiro
          } else {
            return new Date(b.horario) - new Date(a.horario); // mais recente primeiro
          }
        } else {
          return 0;
        }
      });
    },
  },
  watch: {
    sendMessage(newMessage) {
      if (newMessage) {
        this.sortServices();
      }
    },
    newMessage(newMessage) {
      if (newMessage) {
        this.sortServices();
      }
    },
    openServiceScreen(newValue) {
      if (newValue) {
        this.$store.state.tabForDep;
        this.chatDialog = false;
        this.$store.state.newMessage = 0;
        this.$store.state.conversation = "";
        this.$store.state.serviceFromChat = "";
        this.$store.state.openServiceScreen = false;
      }
    },
    conversation(newValue) {
      if (newValue != "") {
        this.chatDialog = true;
      } else if (newValue == "") {
        this.$store.state.tabForDep;
        this.chatDialog = false;
        this.$store.state.conversation = "";
        this.$store.state.serviceFromChat = "";
      }
    },
    chatDialog(newValue) {
      if (!newValue) {
        this.$store.state.conversation = "";
        this.$store.state.serviceFromChat = "";
      }
    },
    selectedDepartment() {
      this.sortServices();
    },
    search() {
      this.sortServices();
    },
  },
  created() {
    setTimeout(() => {
      console.log(this.services);
    }, 2000);
    let depCol = localStorage.getItem(
      `${this.$store.state.info.nome}_depColValue`
    );
    if (!depCol) {
      this.$store.state.screens.servicesDepCol = 1;
    } else {
      this.$store.state.screens.servicesDepCol = depCol;
    }
  },
  methods: {
    sortServices() {
      this.services.sort((a, b) => {
        if (a.status === "aguardando" && b.status !== "aguardando") {
          return -1;
        } else if (a.status !== "aguardando" && b.status === "aguardando") {
          return 1;
        } else if (a.status === "aguardando" && b.status === "aguardando") {
          return new Date(a.horario) - new Date(b.horario); // mais antigo primeiro
        } else if (
          a.status === "em atendimento" &&
          b.status === "em atendimento"
        ) {
          if (!a.visualizado && b.visualizado) {
            return -1;
          } else if (a.visualizado && !b.visualizado) {
            return 1;
          } else if (!a.visualizado && !b.visualizado) {
            return new Date(b.horario) - new Date(a.horario); // mais recente primeiro
          } else {
            return new Date(b.horario) - new Date(a.horario); // mais recente primeiro
          }
        } else {
          return 0;
        }
      });
    },
    colorCard(service) {
      if (service.status == "aguardando") {
        return "#EF9A9A";
      } else if (!service.visualizado) {
        return "#FFF9C4";
      }
    },
    selectService(item) {
      this.$store.state.tabForDep;
      this.chatDialog = false;
      this.$store.state.conversation = "";
      this.$store.state.serviceFromChat = "";
      setTimeout(() => {
        this.selectedService = item;
        this.serviceInteract(item);
      }, 1);
    },
    converttags(tags) {
      return tags?.map((obj) => obj.nome).join(", ");
    },
    formatDate(date) {
      const now = new Date();
      const targetDate = new Date(date);
      const isToday =
        now.getDate() === targetDate.getDate() &&
        now.getMonth() === targetDate.getMonth() &&
        now.getFullYear() === targetDate.getFullYear();

      if (isToday) {
        const options = { hour: "numeric", minute: "numeric", hour12: true };
        return targetDate.toLocaleString("pt-BR", options);
      } else {
        const options = { day: "numeric", month: "numeric", year: "numeric" };
        return targetDate.toLocaleString("pt-BR", options);
      }
    },
    serviceInteract(service) {
      let integrations = this.$store.state.info.deps.find(
        (dep) => service.id_departamento == dep.id
      );
      service.integracao = integrations.integracoes;
      if (service.status.includes("aguardando")) {
        this.$store.state.interactions.askForService = {
          clientId: this.$store.state.info.id_cliente,
          serviceId: service.id_atendimento,
          attendantId: this.$store.state.info.id,
          attendantName: this.$store.state.info.nome,
          destiny: service.numero,
          depId: service.id_departamento,
          origin: this.$store.state.info.numero,
          client: `${this.$store.state.info.cliente}_${this.$store.state.info.numeronome}`,
        };
        setTimeout(() => {
          this.$store.state.serviceFromChat = service;
          this.$store.state.interactions.getService = {
            serviceId: service.id_atendimento,
          };
        }, 200);
      } else if (
        service.status.includes("em atendimento") ||
        service.status.includes("nova")
      ) {
        service.status = "em atendimento";
        service.visualizado = true;
        this.$store.state.serviceFromChat = service;
        this.$store.state.interactions.getService = {
          serviceId: service.id_atendimento,
        };
      }
    },
  },
};
</script>

<style>
.selected-item {
  background-color: #e3f2fd; /* Ou qualquer cor que você deseje para destacar */
}
.list-item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timestamp {
  font-size: 0.8em; /* Diminui o tamanho da fonte */
}

.list-item-action {
  margin-left: auto;
}
.my-container {
  width: 100%;
  white-space: nowrap;
}

.row-flex-wrap {
  overflow-x: auto;
  flex-wrap: nowrap;
}

.dep-card {
  height: 85vh;
  margin: 1px;
  padding: 10px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.service-col {
  margin: 0px;
  width: calc(100% - 20px);
}

@media (max-width: 600px) {
  .dep-col {
    flex-basis: 100%;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor do fundo da barra de rolagem */
  border-radius: 10px; /* Bordas arredondadas */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Cor da barra de rolagem */
  border-radius: 10px; /* Bordas arredondadas */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Cor da barra de rolagem quando hover */
}
</style>
